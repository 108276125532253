import React, {useEffect, useState, useContext, useRef} from 'react';
import { Ionicons } from "@expo/vector-icons";
import * as DocumentPicker from 'expo-document-picker';
import apiClient from "../../../../utils/apiClient";

import {
  View, Text, Icon, Center, Button,
  ScrollView, FormControl, HStack,
  Input, TextArea, Select, Modal, Pressable, Divider, useColorModeValue
} from "native-base";

import Map from "../../../../components/Map"
import {formatBytes} from "../../../../utils/StringTool";
import {TouchableOpacity, Platform} from "react-native";
import {AuthContext} from "../../../../utils/context/auth";
import {localInfo, translate} from "../../../../utils/Translate";
import SelectList from "../../../../components/SelectList";
import NoItemYet from "../../../../components/NoItemYet";
import CountryStatesSelectList from "../../../../components/CountryStatesSelectList";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ route, navigation }) => {

  const { priceTables, priceTablePreview, branchId } = route.params
  const { isCompany, hasPermissionTo } = useContext(AuthContext);

  const [branches, setBranches] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [participationTypes, setParticipationTypes] = useState([]);
  const [showMapModal, setShowMapModal] = useState(false);
  const [mapInfo, setMapInfo] = useState(translate('screens.publishTender.info.placeholder.location'));
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [participationType, setParticipationType] = useState();
  const [canPublishTender, setCanPublishTender] = useState(true);

  const originalBranches = useRef([])
  const originalStates = useRef([])
  const webFiles = useRef({});

  let location;

  const tenderData = useRef({
    tender : {
      title : null,
      description : null,
      latitude : null,
      longitude : null,
      closing_date : null,
      bid_initial_price : null,
      participation_cost : '',
      tva : null,
      language_id : null,
      branch_id : null,
      branch : {},
      state_id : null,
      state : {},
      currency_id : null,
      currency : {},
      participationtype_id : null,
      is_owner : true,
      has_participate : false,
    },
    pointTables: [],
    pointTablePreview: [],
    priceTables: priceTables,
    priceTablePreview: priceTablePreview,
    reviewSteps: [],
    criteria: {hasPriceTable: true},
    requiredDocuments: [],
    attachments: []
  });

  const pickDocument = async () => {
    let file = await DocumentPicker.getDocumentAsync({
      multiple: true
    });

    if (!!file.type && file.type === 'cancel'){
      return;
    }

    if(Platform.OS === 'web'){
      // WEB
      const files = file.output;
      webFiles.current = files;

      Promise.all(
        Object.keys(files).map(
          (_key, i) =>
            new Promise((resolve) => {
              const reader      = new FileReader();
              const currentFile = files[i];

              reader.onload = () => {
                resolve({
                  uri: reader.result,
                  type: currentFile.type,
                  name: currentFile.name,
                  size: currentFile.size
                });
              };
              reader.readAsDataURL(currentFile);
            })
        )
      ).then(result => {
        setFilesToUpload(result)
      })

      return;
    }

    let filesToUp = [];

    for (let fileToUp of file.assets){
      filesToUp.push({
        uri: fileToUp.uri,
        type: fileToUp.mimeType,
        name: fileToUp.name,
        size: fileToUp.size
      })
    }

    setFilesToUpload([...filesToUp, ...filesToUpload])

  };

  const handleTitleText = (text) => {
    if ('title' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.title
      setInputErrors(errors);
    }

    tenderData.current = {
      ...tenderData.current,
      tender: {
        ...tenderData.current.tender,
        title: text,
      }
    }
  }

  const handleCostText = (text) => {
    if ('participationCost' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.participationCost
      setInputErrors(errors);
    }
    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    tenderData.current.tender.participation_cost =  text.replace(regex, '')
  }

  const handleDescriptionText = (text) => {
    if ('description' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.description
      setInputErrors(errors);
    }

    tenderData.current = {
      ...tenderData.current,
      tender: {
        ...tenderData.current.tender,
        description: text,
      }
    }
  }

  const handleMapPress = (mapInfo) => location = mapInfo;

  function saveMapLocation(){
    setMapInfo(`${location.address} (${location.coordinate.latitude.toFixed(5)}, ${location.coordinate.longitude.toFixed(5)})`)

    tenderData.current = {
      ...tenderData.current,
      tender: {
        ...tenderData.current.tender,
        latitude: location.coordinate.latitude,
        longitude: location.coordinate.longitude,
      }
    }
    setShowMapModal(false);
  }

  const handleBranchSelect = (branchId) => {

    if ('branch' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.branch
      setInputErrors(errors);
    }

    tenderData.current.tender.branch_id = branchId
    tenderData.current.tender.branch = getObjectById(originalBranches.current, branchId)
  }

  const handleStateSelect = (stateId, countryID) => {
    if ('state' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.state
      setInputErrors(errors);
    }

    tenderData.current.tender.state_id    = stateId
    tenderData.current.tender.country_id  = countryID
    tenderData.current.tender.state       = getObjectById(originalStates.current, stateId)
  }

  const handleLanguageSelect = (itemValue) => {
    if ('language' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.language
      setInputErrors(errors);
    }
    tenderData.current = {
      ...tenderData.current,
      tender: {
        ...tenderData.current.tender,
        language_id: itemValue,
      }
    }
  }

  const handleParticipationTypeSelect = (itemValue) => {
    if ('participation' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.participation
      setInputErrors(errors);
    }
    tenderData.current = {
      ...tenderData.current,
      tender: {
        ...tenderData.current.tender,
        participationtype_id: itemValue,
      }
    }

    let participationType = participationTypes.find(p => p.id === parseInt(itemValue))
    setParticipationType(participationType)
  }

  function getObjectById (array, id){
    return array.find(x => x.id === parseInt(id));
  }

  const validate = () => {

    if (!tenderData.current.tender.title) {
      setInputErrors({ ...inputErrors,
        title: translate('screens.publishTender.info.validation.title')
      });
      return false;
    } else if (!tenderData.current.tender.description) {
      setInputErrors({ ...inputErrors,
        description: translate('screens.publishTender.info.validation.description')
      });
      return false;
    } else if (!tenderData.current.tender.branch_id) {
      setInputErrors({ ...inputErrors,
        branch: translate('screens.publishTender.info.validation.branch')
      });
      return false;
    } else if (!tenderData.current.tender.state_id){
      setInputErrors({ ...inputErrors,
        state: translate('screens.publishTender.info.validation.state')
      });
      return false;
    } else if (!tenderData.current.tender.language_id){
      setInputErrors({ ...inputErrors,
        language: translate('screens.publishTender.info.validation.language')
      });
      return false;
    } else if (!tenderData.current.tender.participationtype_id && isCompany){
      setInputErrors({ ...inputErrors,
        participation: translate('screens.publishTender.info.validation.participation')
      });
      return false;
    } else if (!!participationType && participationType.type.en === 'Paid' && tenderData.current.tender.participation_cost.length === 0){
      setInputErrors({ ...inputErrors,
        participationCost: translate('screens.publishTender.info.validation.participationCost')
      });
      return false;
    }

    return true;
  };

  function getBranches(){
    apiClient.get(`branches`).then( response => {
      originalBranches.current = response.data
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error));
  }

  function getLanguages(){
    apiClient.get(`languages`).then( response => {
      setLanguages(response.data)
    }).catch(error => console.log(error));
  }

  function getParticipationTypes(){
    apiClient.get(`participation-types`).then( response => {
      setParticipationTypes(response.data)
    }).catch(error => console.log(error));
  }

  function setBranchesOfLanguage(branchesList, language){
    let mapBranches = branchesList.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
        language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(mapBranches)
  }

  const handleNextButton = () => {

    if (!validate()) return;

    tenderData.current.attachments = filesToUpload;
    tenderData.current.webAttachments = webFiles.current;

    navigation.navigate('GroupTenderRequiredDocuments', {tenderData: tenderData.current})
  }

  useEffect(() => {
    (async() => {
      const canCreateTender = await hasPermissionTo('publish tenders')

      setCanPublishTender(canCreateTender)
    })()

    getLanguages()
    getParticipationTypes()
    getBranches()
  }, []);

  if (isCompany && !canPublishTender){
    return (
      <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
        <View mx={3}>
          <View mt={4}/>
          <Title title={translate('screens.publishTender.title')} />
          <SpacerMY space={3}/>

          <NoItemYet text={translate('screens.publishTender.noPermission')} />
        </View>
      </View>
    )
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4}>

        <View mt={4}/>
        <Title title={translate('screens.publishTender.title')} />
        <SpacerMY space={3}/>

        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={26}>
          {translate('screens.publishTender.info.description')}
        </Text>

        <Center>
          <FormControl mt={4} isInvalid={'title' in inputErrors}>
            <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.publishTender.info.placeholder.title')} rounded={10}
              size="xl" borderColor="gray.500"
              pl={4} py={3}
              onChangeText={handleTitleText}
            />
            {
              'title' in inputErrors ? (
                <FormControl.ErrorMessage>
                  {inputErrors.title}
                </FormControl.ErrorMessage>
              ) : null
            }
          </FormControl>

          <FormControl mt={3} isInvalid={'description' in inputErrors}>
            <TextArea
              textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.publishTender.info.placeholder.description')} h={260}
              rounded={10} size="xl" borderColor="gray.500"
              onChangeText={handleDescriptionText}
            />
            {
              'description' in inputErrors ? (
                <FormControl.ErrorMessage>
                  {inputErrors.description}
                </FormControl.ErrorMessage>
              ) : null
            }
          </FormControl>

          <View mt={3} w="100%">
            <FormControl isInvalid={'language' in inputErrors}>
              <Select
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                selectedValue={tenderData.current.tender.language_id}
                accessibilityLabel="language"
                placeholder={translate('screens.publishTender.info.placeholder.language')}
                _selectedItem={{bg: "primary.100"}}
                borderRadius={10} size="xl" borderColor="gray.500" pl={4} py={3}
                onValueChange={handleLanguageSelect}
                dropdownIcon={
                  <Icon
                    as={Ionicons} name={"chevron-down-sharp"} size="5" color="darkgray"
                    key={
                      Math.random()
                    }
                  />
                }
              >
                {
                  languages.map((language, index) => {
                    return (
                      <Select.Item key={index} label={language.name[localInfo.local]} value={`${language.id}`} />
                    )
                  })
                }
              </Select>
              {
                'language' in inputErrors ? (
                  <FormControl.ErrorMessage>
                    {inputErrors.language}
                  </FormControl.ErrorMessage>
                ) : null
              }
            </FormControl>
          </View>

          <Pressable w="100%" onPress={() => setShowMapModal(true)}>
            <FormControl mt={4}>
              <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} placeholder={mapInfo} isReadOnly rounded={10} size="xl"
                     pointerEvents="none" borderColor="gray.500" pl={4} py={3}
              />
            </FormControl>
          </Pressable>

          {
            branches.length ? (
              <View mt={3} w="100%">
                <SelectList
                  selectText={translate('misc.branches')}
                  modalHeaderText={translate('screens.auth.interests.selectBranches')}
                  doneText={translate('misc.done')}
                  cancelText={translate('misc.cancel')}
                  isMultiSelect={false}
                  onDone={handleBranchSelect}
                  items={branches}
                  selectedId={!!branchId ? branchId : null}
                />
                {
                  'branch' in inputErrors ? (
                    <Text fontSize={15} color="danger.400">
                      {inputErrors.branch}
                    </Text>
                  ) : null
                }
              </View>
            ) : null
          }

          <View mt={3} w="100%">
            <CountryStatesSelectList
              selectText={translate('misc.states')}
              modalHeaderText={translate('screens.auth.interests.selectCountryStates')}
              doneText={translate('misc.done')}
              cancelText={translate('misc.cancel')}
              isMultiSelect={false}
              onDone={handleStateSelect}
            />
            {
              'state' in inputErrors ? (
                <Text fontSize={16} color="danger.400">
                  {inputErrors.state}
                </Text>
              ) : null
            }
          </View>

          {
            isCompany ? (
              <View mt={3} w="100%">
                <FormControl isInvalid={'participation' in inputErrors}>
                  <Select
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    selectedValue={tenderData.current.tender.participationtype_id}
                    accessibilityLabel="Field type"
                    placeholder={translate('screens.publishTender.info.placeholder.participationType')}
                    _selectedItem={{bg: "primary.100"}}
                    borderRadius={10} size="xl" borderColor="gray.500" pl={4} py={3}
                    onValueChange={handleParticipationTypeSelect}
                    dropdownIcon={
                      <Icon
                        as={Ionicons} name={"chevron-down-sharp"} size="5" color="darkgray"
                        key={
                          Math.random()
                        }
                      />
                    }
                  >
                    {
                      participationTypes.map((participationType, index) => {
                        return (
                          <Select.Item key={index} label={participationType.type[localInfo.local]} value={`${participationType.id}`} />
                        )
                      })
                    }
                  </Select>
                  {
                    'participation' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.participation}
                      </FormControl.ErrorMessage>
                    ) : null
                  }
                </FormControl>
              </View>
            ) : null
          }

          {
            !!participationType && participationType.type.en === 'Paid' ? (
              <FormControl mt={4} isInvalid={'participationCost' in inputErrors}>
                <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  placeholder={translate('screens.publishTender.info.placeholder.cost')} rounded={10}
                  size="xl" borderColor="gray.500"
                  pl={4} py={3}
                  keyboardType="number-pad"
                  onChangeText={handleCostText}
                />
                <FormControl.HelperText>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                    {translate('screens.publishTender.info.participationCostNote')}
                  </Text>
                </FormControl.HelperText>
                {
                  'participationCost' in inputErrors ? (
                    <FormControl.ErrorMessage>
                      {inputErrors.participationCost}
                    </FormControl.ErrorMessage>
                  ) : null
                }
              </FormControl>
            ) : null
          }

        </Center>

        <View mt={9} mb={3} mx="8">
          <Divider thickness="1" bg="appPrimary" />
        </View>

        <FormControl mt={5}>
          <FormControl.Label _text={{ fontSize: 20 }} >
            {translate('screens.publishTender.info.uploadFile')}
          </FormControl.Label>
          <Button
            variant="outline"
            leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" size="sm" />}
            onPress={pickDocument}
          >
            {translate('screens.publishTender.info.clickToUpload')}
          </Button>
        </FormControl>

        {
          filesToUpload.length > 0 ? (
            <View mt={2}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500}>
                {translate('screens.publishTender.info.filesToUpload')}:
              </Text>
              {
                filesToUpload.map((file, index) => (
                  <View key={index}>
                    <HStack alignItems="center">
                      <TouchableOpacity onPress={() => {
                        setFilesToUpload(filesToUpload.filter((_, fileIndex) => fileIndex !== index));
                      }}>
                        <Icon as={Ionicons} name="trash-bin-outline" size={5} color="appSecondary" />
                      </TouchableOpacity>
                      <View mx={1}/>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                        {file.name} - {formatBytes(file.size)}
                      </Text>
                    </HStack>
                  </View>
                ))
              }
            </View>
          ) : null
        }

        <View mt={9} mb={3} mx="8">
          <Divider thickness="1" bg="appPrimary" />
        </View>

        <Center mb={16} mt={10}>
          <Button
            w="85%" size="lg" color="appPrimary" borderRadius={8}
            onPress={ handleNextButton }
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.next')}
            </Text>
          </Button>
        </Center>

        {/* Map Modal */}
        <Center>
          <Modal size={["xl", "xl", "xl"]} isOpen={showMapModal} onClose={() => setShowMapModal(false)}>
            <Modal.Content maxWidth="100%">
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.publishTender.info.tenderLocation')}
              </Modal.Header>
              <Modal.Body>
                <Map onPress={handleMapPress} />
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowMapModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={() => {
                    saveMapLocation()
                  }}>
                    {translate('misc.save')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}
