import React from "react";
import {Box, Text, View, Pressable, useColorModeValue, Avatar, HStack, Center, Icon} from "native-base";
import {useNavigation} from "@react-navigation/native";
import {translate} from "../utils/Translate";
import {MaterialIcons} from "@expo/vector-icons";
import {shorten} from "../utils/StringTool";

export default (
  {
    tenderId, title, branch, avatar, location, offerPoints, tenderPoints,
    date, bidAmount, currency
  }) => {
  const navigation = useNavigation();

  return (
    <Pressable width={["100%", "100%", "32%"]}  onPress={() => navigation.navigate('Tender', {tenderId})}>
      <Box py={2} bg={useColorModeValue("#F8F8F8", "coolGray.700")} borderRadius={8} shadow={3} mt={3} ml={[0, 0, 1]}>
        <View
          _dark={{ bg: "coolGray.700"}} m={2}
          minH={["100", "100", "190"]}
        >

          <View flexDirection="row">
            <View>
              <Avatar
                mt={2} size="lg"
                source={{ uri : avatar}}
              />
            </View>

            <View w="78%" ml={2}>
              <Text textAlign="left" fontSize={24} fontWeight={500} >
                {shorten(title, 40)}
              </Text>
              <HStack>
                <View
                  w="60%"
                  bg={useColorModeValue("appAccent", "appAccentDark")}
                  mt={1} px={2} borderRadius={20} alignSelf="flex-start"
                >
                  <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                    {branch}
                  </Text>
                </View>
                <Center w="40%" flexDirection="row">
                  <Icon as={<MaterialIcons name="location-pin"/>} size={6} ml="2"/>
                  <Text textAlign="left" fontWeight={500} fontSize={16}>
                    {location}
                  </Text>
                </Center>
              </HStack>
            </View>
          </View>

          {
            !!offerPoints && !!tenderPoints ? (
              <View mt={4} px={2} bg="appSecondary" borderRadius={20} alignSelf="flex-start">
                <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                  {translate('components.outgoingOfferCard.yourPoints')} : {offerPoints} / {tenderPoints}
                </Text>
              </View>
            ) : null
          }

          <View mt={3}>
            {
              !!bidAmount ? (
                <Text textAlign="left" fontWeight={500} fontSize={16}>
                  {translate('components.offerCard.bidAmount')}: {bidAmount} {currency}
                </Text>
              ) : null
            }

            <Text textAlign="left" fontWeight={500} fontSize={16}>
              {translate('components.offerCard.submissionDate')}: {date}
            </Text>
          </View>

        </View>
      </Box>
    </Pressable>
  )
}
