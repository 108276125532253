function getBase64File(fileUri, fileName = 'file-name'){
  return new Promise(resolve => {
    fetch(fileUri)
      .then(res => res.blob())
      .then(blob => {
        let type = fileUri.substring(fileUri.indexOf(":")+1, fileUri.indexOf(";"))
        let extension = fileUri.substring(fileUri.indexOf("/")+1, fileUri.indexOf(";"))

        const file = new File([blob], `${fileName}.${extension}`,{ type })

        resolve(file)
      })
  })
}

export {getBase64File}
