import React from 'react';

import { StyleSheet } from 'react-native';
import {
  Pressable,
  View,
  Image,
  Text, Box, Center, Button, useColorModeValue,
} from "native-base";
import {localInfo, translate} from "../../utils/Translate";
import documentIcon from '../../assets/icons/document-outline.png';
import {Platform} from "react-native";

export default function UnderReview({ navigation }) {

    return (
      <Box
        bg={useColorModeValue("white", "coolGray.800")}
        flex={1} flexDirection="column" justifyContent="space-between"
      >

        <View mt={12}>
          <Pressable
            m={5}
            onPress={() => navigation.goBack()}>
            <Image
              alt="back icon"
              source={require('../../assets/icons/back_arrow.png')}
            />
          </Pressable>

          <Center mt={12}  px={3}>
            <Image alt="review icon" size={300} resizeMode={"contain"} source={documentIcon} />

            <Center mt={1}>
              <Text textAlign="center" fontWeight={600} fontSize={28} color="appPrimary">
                {translate('screens.auth.underReview.accountUnderReview')}
              </Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={4} fontWeight={500} fontSize={24}>
                {translate('screens.auth.underReview.reviewingAccount')}
              </Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} textAlign="center" fontWeight={500} fontSize={24} >
                {translate('screens.auth.underReview.checkBack')}
              </Text>
            </Center>
          </Center>

        </View>

        <Center mb={10}>
          <Button
            w="85%"
            onPress={ () => navigation.goBack() }
            size="lg"
            color="appPrimary"
            borderRadius={8}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.Ok')}
            </Text>
          </Button>
        </Center>
      </Box>
    );
}



