import React from 'react';
import {Feather, Ionicons, MaterialIcons} from "@expo/vector-icons";

import {
  View, Box, Text, Icon, HStack, Center, Pressable, Image,
} from "native-base";
import ccpLogo from "../assets/ccp.png";

function getLogo(method){
  if (method === 'CCP'){
    return ccpLogo;
  }

  return ''
}

export default ({ navigation, id, method, screen, available,
                  reviewType, reviewDuration, price, offerData,
                  tenderId, logo }) => {

  return (
    <Pressable
      width={["100%", "100%", "32%"]}
      onPress={() => {
        navigation.navigate(screen, {methodId: id, price, offerData, tenderId})
      }}>
      <Box bg="#F8F8F8" borderRadius={8} shadow={3} mt={3} mb={1} ml={[0, 0, 1]}>
        <View
          borderTopRadius={8}
          bg='appPrimary'
          justifyContent="space-between"
        >
          <Center>
            <Image my={2} size={20} w={120} alt="Alternate Text" source={{uri : logo}} />
          </Center>
        </View>

        <Center>
          <Text textAlign="left" fontSize={24} fontWeight={500}>
            {method}
          </Text>
        </Center>

        <View m={2}>
          <HStack alignItems="center">
            <Icon color="appPrimary" as={<MaterialIcons name="payment"/>} size={8}/>
            <Text textAlign="left" mx={2} fontSize={17}>
              {available}
            </Text>
          </HStack>
          <View mt={1} />
          <HStack alignItems="center">
            <Icon color="appPrimary" as={<Feather name="loader" />} size={8}/>
            <Text textAlign="left" mx={2} fontSize={17}>
              {reviewType}
            </Text>
          </HStack>
          <View mt={1} />
          <HStack alignItems="center">
            <Icon color="appPrimary" as={<Ionicons name="time-outline"/>} size={8}/>
            <Text textAlign="left" mx={2} fontSize={17}>
              {reviewDuration}
            </Text>
          </HStack>
        </View>
      </Box>
    </Pressable>
  )
}
