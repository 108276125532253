import React, {useEffect, useRef, useState} from 'react';

import {
  View, Text, ScrollView, HStack, FormControl, Button, Icon, Center, Modal, useColorModeValue, Spinner
} from "native-base";
import {Ionicons} from "@expo/vector-icons";
import apiClient from "../utils/apiClient";
import {Platform, TouchableOpacity} from "react-native";
import {formatBytes} from "../utils/StringTool";
import {localInfo, translate} from "../utils/Translate";
import * as ImagePicker from "expo-image-picker";
import {getBase64File} from "../utils/FileTools";

const Title = ({ title, size, length }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={size} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={length}/>
  </View>
}

const SpacerMY = () => {
  return <View my={3}/>
}

export default ({ route, navigation }) => {

  const { price, methodId, offerData, tenderId } = route.params

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [ccpAccount, setCcpAccount] = useState(null);
  const [paymentProof, setPaymentProof] = React.useState(null);
  const [isSending, setIsSending] = React.useState(false);

  const webPaymentProof = useRef();

  const pickImage = async () => {
    let image = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (image.cancelled) return

    if (Platform.OS === "web"){
      getBase64File(image.assets[0].uri).then((file) => webPaymentProof.current = file)
    }

    setPaymentProof(image.assets[0]);
  };

  function getFileName(file){
    return file.uri.substring(file.uri.lastIndexOf("/") + 1);
  }
  function getFileType(file){
    return file.uri.substring(file.uri.lastIndexOf(".") + 1);
  }

  const submitOffer = () => {
    setIsSending(true)

    const data = new FormData()
    data.append('data', JSON.stringify({
      ...offerData,
      isPaid: 1,
      methodId: methodId,
    }))
    if (!!filesToUpload && Platform.OS !== "web"){
      data.append('files[]', {
        uri: paymentProof.uri,
        type: `${paymentProof.type}/${getFileType(paymentProof)}`,
        name: getFileName(paymentProof)
      })
    }
    if (Platform.OS === "web" && !!webPaymentProof){
      data.append('files[]', webPaymentProof.current)
    }

    apiClient.post(`tenders/${tenderId}/offers`, data, {isFormData: true}).then(() => {
      setShowConfirmModal(false)
      setIsSending(false)
      navigation.navigate("PaymentReview")
    }).catch((error) => console.log(error.response.data))
  }

  const handleIPaidButton = () => {
    setShowConfirmModal(true)
  }

  function getCcpAccount(){
    apiClient.get(`ccp-account`).then((res) => {
      setCcpAccount(res.data)
    }).catch((error) => console.log(error.response.data))
  }

  useEffect(() => {
    getCcpAccount()
  }, [])

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView mx={4} mt={4}>
        <Title length={60} size={28} title={translate('screens.ccp.CCP')} />

        <SpacerMY/>

        <HStack alignItems="center" justifyContent="space-between">
          <Title title={translate('misc.price') + ":"} length={30} size={24}  />
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={26} fontWeight={500} color="appPrimary">
            {price} {translate('misc.currency')}
          </Text>
        </HStack>

        <SpacerMY/>

        <Title length={30} size={24} title={translate('screens.ccp.CCPInfo')} />
        <View mt={2}/>

        {
          !!ccpAccount ? (
            <View>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20}>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600}>
                  {`${translate('screens.ccp.fullName')} : `}
                </Text>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {ccpAccount.full_name}
                </Text>
              </Text>

              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20}>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600}>
                  {`${translate('screens.ccp.CCP')} : `}
                </Text>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {ccpAccount.ccp}
                </Text>
              </Text>

              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20}>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600}>
                  {`${translate('screens.ccp.RIP')} : `}
                </Text>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {ccpAccount.rip}
                </Text>
              </Text>

              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20}>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600}>
                  {`${translate('screens.ccp.address')} : `}
                </Text>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {ccpAccount.address}
                </Text>
              </Text>
            </View>
          ) : null
        }

        <SpacerMY/>
        <SpacerMY/>

        <Title length={60} size={28} title={translate('screens.ccp.paymentProof')} />

        <FormControl mt={5}>
          <Button
            variant="outline"
            leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" size="sm" />}
            onPress={pickImage}
          >
            {translate('screens.ccp.uploadPaymentProof')}
          </Button>
        </FormControl>
        {
          filesToUpload.length > 0 ? (
            <View mt={2}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500}>
                {translate('misc.filesToUpload')}:
              </Text>
              {
                filesToUpload.map((file, index) => (
                  <HStack alignItems="center">
                    <TouchableOpacity onPress={() => {
                      setFilesToUpload(filesToUpload.filter((_, fileIndex) => fileIndex !== index));
                    }}>
                      <Icon as={Ionicons} name="trash-bin-outline" size={5} color="appSecondary" />
                    </TouchableOpacity>
                    <View mx={1}/>
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                      {file.name} - {formatBytes(file.size)}
                    </Text>
                  </HStack>
                ))
              }
            </View>
          ) : null
        }

        <View mt={32}>
          <Button
            borderRadius={10}
            isDisabled={!paymentProof}
            onPress={handleIPaidButton}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={22} fontWeight={500} color="primary.50">
              {translate('screens.ccp.IPayed')}
            </Text>
          </Button>
        </View>

        {/* Confirm Payment Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.ccp.confirmPayment')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                   <View>
                      <Text mt={3} fontSize={20} textAlign="center">
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mx={1}>
                          {translate('screens.ccp.didYouPaid')}
                        </Text>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mx={1}>
                          {` `}
                        </Text>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mx={1} fontSize={20} fontWeight={500} color="appPrimary">
                          {price} {translate('misc.currency')}
                        </Text>
                        <View mx={1} />
                      </Text>
                     <Text fontSize={20} textAlign="center">
                       {translate('misc.to')}: {!!ccpAccount ? ccpAccount.full_name : ''} ?
                     </Text>
                    </View>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={submitOffer}>
                    {
                      isSending ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('screens.ccp.YesIPaid')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}
