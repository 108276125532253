import React, {useEffect, useState} from 'react';

import {
  View, Text, ScrollView,
  HStack, Switch, Radio, useColorModeValue, useColorMode, Button, Center, Modal, Spinner, Icon, Input, Divider, useTheme
} from "native-base";
import StorageManager from "../../utils/StorageManager";
import { Restart } from "../../utils/Restart";
import {I18nManager, Platform} from "react-native";
import * as Localization from 'expo-localization';
import { List } from 'react-native-paper';
import {localInfo, translate} from "../../utils/Translate";
import apiClient from "../../utils/apiClient";
import {AuthContext} from "../../utils/context/auth";
import {MaterialIcons} from "@expo/vector-icons";
import AuthUserManager from "../../utils/AuthUserManager";
import AlertModal from "../../components/AlertModal";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize={32} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="primary.50" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default () => {
  const [currentLanguage, setCurrentLanguage] = useState('1');
  const [canTextUser, setCanTextUser] = useState(true);
  const [canTextCompany, setCanTextCompany] = useState(true);
  const [canReceiveNotifications, setCanReceiveNotifications] = useState(true);
  const [isCompany, setIsCompany] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [deleteCompany, setDeleteCompany] = useState(true);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const { signOut } = React.useContext(AuthContext);

  const {colorMode, toggleColorMode} = useColorMode();
  const { colors } = useTheme();
  const isDark = colorMode === 'dark';

  const accordionTheme = {
    colors: {
      background: isDark ? colors.accent["800"] : colors.appWhite["100"],
      text: isDark ? colors.appWhite["900"] : colors.black["900"],
      primary: isDark ? colors.appPrimaryDark : colors.appPrimary,
    }
  }

  function getPreferences(){
    apiClient.get('users').then(  (response) => {
      let user = response.data

      setCanReceiveNotifications(!!user.receive_notifications)
      if (!!user.company_id){
        setHasAccess(isOwnerOrHaveAccess(user.permissions))
        setIsOwner(isTheOwner(user.permissions))
        setIsCompany(true)
        setCanTextCompany(!!user.company.text_me)
        return;
      }

      setCanTextUser(!!user.text_me);

    }).catch(error => {
      console.log(error.response.data);
    })

    function isOwnerOrHaveAccess(permissions){
      let index = permissions.findIndex(e => e.name === 'owner' || e.name === 'access settings')

      return index !== -1;
    }

    function isTheOwner(permissions){
      let index = permissions.findIndex(e => e.name === 'owner')

      return index !== -1;
    }
  }

  useEffect(() => {
    (async () => {
      let auth = await AuthUserManager.get()
      setCurrentUser(auth)

      let language = await StorageManager.getString('language');
      if (!!language){
        if (language === 'ar') {
          setCurrentLanguage('2')
        } else if (language === 'en') {
          setCurrentLanguage('3')
        } else if (language === 'fr') {
          setCurrentLanguage('4')
        }
      }
    })()
  }, [currentLanguage])

  useEffect(() => {
    getPreferences()
  }, [])

  const handleMessagesToggle = () => {

    if (isCompany && hasAccess){
      const data = new FormData()
      data.append("_method", "PUT")
      data.append('data', JSON.stringify({
        textMe: !canTextCompany
      }))

      apiClient.post(`companies`, data, {isFormData: true}).then( () => {
        setCanTextCompany(!canTextCompany)
      }).catch(error => {
        console.log(error.response.data)
      });
      return;
    }

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify({
      textMe: !canTextUser
    }))

    apiClient.post(`users`, data, {isFormData: true}).then( () => {
      setCanTextUser(!canTextUser)
    }).catch(error => {
      console.log(error.response.data)
    });
  }

  const handleNotificationsToggle = () => {

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify({
      receiveNotifications: !canReceiveNotifications
    }))

    apiClient.post(`users`, data, {isFormData: true}).then( () => {
      setCanReceiveNotifications(!canReceiveNotifications)
    }).catch(error => {
      console.log(error.response.data)
    });
  }

  const handleDeleteButton = () => {
    // If this is a demo account return alert message
    if (currentUser.id === 10193){
      setShowAlertModal(true);
      return;
    }
    setIsDeleting(true)

    let deleteCompanyToo = (isCompany && isOwner) ? deleteCompany : false

    const data = { password, deleteCompanyToo }
    apiClient.post(`users/delete`, data).then( () => {
      signOut()
    }).catch(error => {
      setIsDeleting(false)
      // show server errors
      if (error.response.status === 400){
        if (error.response.data === 'pass-incorrect'){
          setPassError(translate('screens.myProfile.passwordError'))
        }
      }
    });
  }

  const handleDeleteCompanySwitch = () => {
    setDeleteCompany(!deleteCompany)
  }

  const handlePassword = (text) => {
    setPassword(text)
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>
        <View bg={isCompany ? colors.appPrimary : colors.particular} borderBottomRightRadius={600}>
          <View m={4}>
            <View flexDirection="row">
              <Title title={translate('drawer.screens.settings.title')} />
            </View>
            <View flexDirection="row">
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} my={2} color="primary.50" fontSize={16}>
                {translate('drawer.screens.settings.description')}
              </Text>
            </View>
          </View>
        </View>

        <SpacerMY space={4}/>

        <List.AccordionGroup>
          {
            (isCompany && hasAccess) || !isCompany ? (
              <List.Accordion
                title={translate('drawer.screens.settings.messaging')} id="1"
                left={props => <List.Icon {...props} icon="chat-processing-outline" />}
                theme={accordionTheme}
              >
                <HStack px={7} py={6} alignItems="center" justifyContent="space-between" space={4}>
                  <View maxW="90%">
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
                      {translate('drawer.screens.settings.receiveMessages')}
                    </Text>
                  </View>
                  <Switch
                    onToggle={handleMessagesToggle}
                    size={ Platform.OS === 'android' ? "lg" : "md" }
                    isChecked={ isCompany ? canTextCompany : canTextUser}
                  />
                </HStack>
              </List.Accordion>
            ) : null
          }
          <List.Accordion
            title={translate('drawer.screens.settings.appearance')} id="2"
            left={props => <List.Icon {...props} icon="format-color-fill" />}
            theme={accordionTheme}
          >
            <HStack
              bg={useColorModeValue("white", "coolGray.800")}
              justifyContent="space-between" alignItems="center"
              pr={3} py={6}
            >
              <Text fontSize={18}>
                {translate('misc.darkMode')}
              </Text>
              <Switch
                size={ Platform.OS === 'android' ? "lg" : "md" } defaultIsChecked colorScheme="emerald"
                isChecked={isDark}
                onToggle={() => {
                  toggleColorMode()
                }}
              />
            </HStack>
          </List.Accordion>
          <List.Accordion
            title={translate('drawer.screens.settings.language')} id="3"
            left={props => <List.Icon {...props} icon="web" />}
            theme={accordionTheme}
          >
            <View px={7} py={6}>
              <Radio.Group
                value={currentLanguage} name="myRadioGroup" accessibilityLabel="chose language"
                onChange={ async (value) => {
                  if (value === '1'){
                    let isRTL = Localization.locale.indexOf('ar') === 0;
                    I18nManager.forceRTL(isRTL)
                    I18nManager.allowRTL(isRTL)
                    await StorageManager.remove('language');
                    await Restart()
                  } else if (value === '2'){
                    I18nManager.forceRTL(true)
                    I18nManager.allowRTL(true)
                    await StorageManager.storeString('language', 'ar');
                    await Restart()
                  } else if (value === '3'){
                    I18nManager.forceRTL(false)
                    I18nManager.allowRTL(false)
                    await StorageManager.storeString('language', 'en');
                    await Restart()
                  } else if (value === '4'){
                    I18nManager.forceRTL(false)
                    I18nManager.allowRTL(false)
                    await StorageManager.storeString('language', 'fr');
                    await Restart()
                  }
                }}
              >
                <Radio value="1" my={1}>
                  {translate('drawer.screens.settings.deviceLanguage')}
                </Radio>
                <Radio value="2" my={1}>
                  {translate('drawer.screens.settings.arabic')}
                </Radio>
                <Radio value="3" my={1}>
                  {translate('drawer.screens.settings.english')}
                </Radio>
                <Radio value="4" my={1}>
                  {translate('drawer.screens.settings.french')}
                </Radio>
              </Radio.Group>
            </View>
          </List.Accordion>
          <List.Accordion
            title={translate('drawer.screens.settings.notifications')} id="4"
            left={props => <List.Icon {...props} icon="bell-outline" />}
            theme={accordionTheme}
          >
            <HStack py={6} px={3} alignItems="center" justifyContent="space-between" space={4}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
                {translate('drawer.screens.settings.receiveNotifications')}
              </Text>
              <Switch
                onToggle={handleNotificationsToggle}
                isChecked={canReceiveNotifications}
                size="md"
              />
            </HStack>
          </List.Accordion>
          <List.Accordion
            title={translate('drawer.screens.settings.account')} id="5"
            left={props => <List.Icon {...props} icon="account" />}
            theme={accordionTheme}
          >
            <View mt={5} ml={-4}>
              <Center mb={16}>
                <Button
                  w="85%"
                  onPress={() => setShowConfirmModal(true)}
                  size="lg"
                  colorScheme="secondary"
                  shadow={5}
                  borderRadius={8}
                >
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="md" color="primary.50" fontWeight="700">
                    {translate('drawer.screens.settings.deleteAccount')}
                  </Text>
                </Button>
              </Center>
            </View>
          </List.Accordion>

        </List.AccordionGroup>

        {/* Confirm Account deletion Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.screens.settings.deleteAccount')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={18} textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                    {translate('drawer.screens.settings.deleteAccountConfirm')}
                  </Text>
                </Center>

                {
                  isCompany && isOwner ? (
                    <View mt="6" mb={1} mx="8">
                      <Divider thickness="1" bg="appPrimary" />
                    </View>
                  ) : null
                }

                {
                  isCompany && isOwner ? (
                    <HStack px={1} pt={4} alignItems="center" justifyContent="space-between" space={4}>
                      <View maxW="85%">
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
                          {translate('drawer.screens.settings.deleteCompany')}
                        </Text>
                      </View>
                      <Switch
                        onToggle={handleDeleteCompanySwitch}
                        size={ Platform.OS === 'android' ? "lg" : "md" }
                        isChecked={deleteCompany}
                      />
                    </HStack>
                  ) : null
                }

                <Input
                  mt={6} mb={6}
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  placeholder={translate('screens.auth.signIn.password')} rounded={10}
                  size="xl" borderColor="gray.500" pl={4} py={3}
                  autoCapitalize="none" bg={useColorModeValue("white", "coolGray.800")}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  InputRightElement={<Icon as={<MaterialIcons name={showPassword ? "visibility" : "visibility-off"} />} size={7} mr="2" color="muted.400" onPress={() => setShowPassword(!showPassword)} />}
                  onChangeText={handlePassword}
                />
                {
                  passError.length > 0 ? (
                    <View mt={1} mb={2}>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="appSecondary">
                        {passError}
                      </Text>
                    </View>
                  ) : null
                }
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button
                    isDisabled={password.length < 5} onPress={handleDeleteButton} colorScheme="secondary"
                    isLoading={isDeleting}
                  >
                    {
                      translate('drawer.screens.teamManagement.delete')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        <AlertModal alertText={translate('demoAlert.message')} isOpen={showAlertModal} onClose={() => setShowAlertModal(false)}/>

      </ScrollView>

    </View>
  );
}
