import {Box, View, useColorModeValue, Skeleton} from "native-base";

export default () => {

  return (
    <Box
      width={["100%", "100%", "33%"]}
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      borderRadius={8} shadow={3} mt={3} ml={[0, 0, 3]}
    >
      <View
        minH={["100", "100", "288"]}
        justifyContent="space-between"
        _dark={{ bg: "coolGray.700"}} m={2}
      >
        <View>
          <Skeleton.Text lines={1} mt={2} w="90%"/>

          <View
            mt={1} px={2} borderRadius={20} alignSelf="flex-start">

            <Skeleton.Text lines={1} mt={2} w={48} />
          </View>

          <Skeleton.Text lines={3} px={2} mt={5} w={48} />
        </View>

        <View _dark={{ bg: "coolGray.700"}} flexDirection="row" my={2}>
          <View px={2} alignSelf="flex-start">
            <Skeleton.Text lines={1} mt={2} w={48} />
          </View>
          <View ml={2} px={2} alignSelf="flex-start">
            <Skeleton.Text lines={1} mt={2} w={32} />
          </View>
        </View>

      </View>
    </Box>
  )
}
