
const UserModel = {
  id: null,
  full_name: null,
  birth_date: null,
  avatar: null,
  company_role: null,
  phone_number: null,
  email: null,
  status_id: null,
  company_id: null,
  roles: null,
  address: null,
  company: {
    name: ''
  }
}

export default UserModel
