import React, {useEffect, useState, useRef} from "react";

import {
  ScrollView, Checkbox, Text, Pressable, View, FormControl, Input, HStack,
  Center, Modal, Button, useColorMode, Radio, useColorModeValue
} from "native-base";
import SearchInput from "./SearchInput";
import {localInfo, translate} from "../utils/Translate";
import UserWithTitle from "./UserWithTitle";
import {Platform} from "react-native";
import {RadioButton} from "react-native-paper";

export default (
  {
    items, alreadySelectedItems, selectText, isMultiSelect = true, showSelectAll = true,
    hintText, modalHeaderText, onDone, doneText, cancelText,
    roundedSize = 10, size = 'xl', borderColor = "gray.500",
    inputBg = "white", isUsersList = false, selectedId = null
  }) => {

  const [openModal, setOpenModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([])
  const [itemList, setItemList] = useState([])
  const [inputSelectText, setInputSelectText] = useState('')
  const [searchText, setSearchText] = useState('')
  const [paginatedItems, setPaginatedItems] = useState([])
  const [radioValue, setRadioValue] = React.useState();

  const isLoadingMore = useRef(false);
  const paginationCurrentPage = useRef(1);

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  function paginate (arr, size) {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)

      return acc
    }, [])
  }
  let pages = paginate(items, 12)

  function handleCheck(value, id) {
    if (value === true){
      setSelectedItems(oldArray => [...oldArray, id])
    }
    // Remove existing id
    if (value === false) {
      setSelectedItems(oldArray => oldArray.filter(item => item !== id))
    }
  }

  const handleSelectAll = (value) => {
    let itemsToCheck = !!searchText ? itemList : items;

      const allIds = itemsToCheck.map(({id}) => id)
      setSelectedItems(allIds)
    // Remove existing id
    if (value === false) {
      setSelectedItems([])
    }
  }

  const handleSearch = (text) => {

    if (isUsersList){
      if (text.length > 0){
        setSearchText(text)
        setItemList( items.filter(({full_name}) => {
          return full_name.toUpperCase().includes(text.toUpperCase())
        }))
      } else (
        setItemList(items)
      )

      return;
    }

    if (text.length > 0){
      setSearchText(text)
      setItemList( items.filter(({name}) => {
        return name.toUpperCase().includes(text.toUpperCase())
      }))
    } else (
      setItemList(items)
    )
  }

  useEffect(() => {

    setPaginatedItems(pages[0])
    setItemList(items)
    setInputSelectText(selectText)

    if (!!alreadySelectedItems && alreadySelectedItems.length > 0){
      setSelectedItems(alreadySelectedItems)
      setInputSelectText(`${selectText} (${alreadySelectedItems.length})`)
    }

  }, [alreadySelectedItems])

  let itemsToRender = !!searchText ? itemList : paginatedItems;

  let selectedIdName;
  if (!!selectedId) {
    selectedIdName = getNameById(selectedId, itemsToRender)
  }

  useEffect(() => {
    if (!!selectedId){
      setRadioValue(selectedId);

      onDone(selectedId)
      setInputSelectText(selectedIdName)
    }
  }, [selectedIdName])

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  function loadMore(){
    if (paginationCurrentPage.current > pages.length-1) return;
    if (isLoadingMore.current) return;

    isLoadingMore.current = true
    setPaginatedItems(old => [...old, ...pages[paginationCurrentPage.current]])
    setTimeout(() => {
      isLoadingMore.current = false
      paginationCurrentPage.current++
    }, 200)
  }

  function resetModal(){
    setPaginatedItems(pages[0])
    paginationCurrentPage.current = 1
  }
  function getNameById(id, array) {
    const obj = array.find(item => item.id === id);
    return obj ? obj.name : null;
  }

  const SingleSelect = ({list}) => {

    return <RadioButton.Group
      onValueChange={
        newValue => {
          setRadioValue(newValue);
          const name = getNameById(newValue, list)
          resetModal()
          onDone(newValue)
          setOpenModal(false)
          setInputSelectText(name)
        }
      }
      value={radioValue}
    >
      {
        list.map(item => (
          <RadioButton.Item label={item.name} value={item.id} />
        ))
      }
    </RadioButton.Group>

    // return <Radio.Group
    //   name="myRadioGroup"
    //   accessibilityLabel="select"
    //   value={radioValue}
    //   onChange={nextValue => {
    //     setRadioValue(nextValue);
    //     const name = getNameById(nextValue, list)
    //     resetModal()
    //     onDone(nextValue)
    //     setOpenModal(false)
    //     setInputSelectText(name)
    //   }}
    // >
    //   {
    //     list.map(item => (
    //       <Radio key={item.id} value={item.id} my={1}>
    //         {item.name}
    //       </Radio>
    //     ))
    //   }
    // </Radio.Group>;
  };

  return (
    <View>
      <Pressable onPress={() => setOpenModal(true)}>
        <FormControl>
          <Input
            textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
            value={inputSelectText} isReadOnly pointerEvents="none"
            rounded={roundedSize} size={size}  py={3}
            borderColor={borderColor}
            bg={inputBg === 'white' ? isDark ? "coolGray.700" : "white" : inputBg }
          />
          {
            !!hintText && hintText.length > 0 ? (
              <FormControl.HelperText>
                <Text textAlign="left" ml={2} mt={-1}>
                  {hintText}
                </Text>
              </FormControl.HelperText>
            ) : null
          }
        </FormControl>
      </Pressable>

      {/* Modal */}
      <Center>
        <Modal size={["xl", "xl", "md"]} isOpen={openModal}>
          <Modal.Content>
            <Modal.Header>
              <Text textAlign="left" fontSize={20} fontWeight={500}>
                {modalHeaderText}
              </Text>
            </Modal.Header>

            <View mx={3}>
              <SearchInput
                onChange={handleSearch}
                onSubmit={handleSearch}
              />
            </View>

              <View mt={5}/>

              <ScrollView
                pl={2} pr={1}
                onScroll={({nativeEvent}) => {
                  if (isCloseToBottom(nativeEvent)) {
                    loadMore()
                  }
                }}
                scrollEventThrottle={400}
              >
                {
                  items.length && !isUsersList && !isMultiSelect ? (
                    <SingleSelect
                      list={itemsToRender}
                    />
                  ) : null
                }
                {
                  items.length && !isUsersList && isMultiSelect ? (
                    <View>
                      {
                        isMultiSelect && showSelectAll ? (
                          <Checkbox
                            shadow={2} my={1}
                            accessibilityLabel="select all items"
                            onChange={handleSelectAll}
                          >
                            {translate('components.selectList.selectAll')}
                          </Checkbox>
                        ) : null
                      }
                    {
                      itemsToRender.map(item => (
                        <View
                          bg={useColorModeValue("gray.100", "coolGray.600")}
                          key={item.id} mt={1} pt={1} pb={2} px={2} borderRadius={6}>
                          <Checkbox
                            shadow={2} mt={1}
                            value={item.id}
                            accessibilityLabel="items"
                            isChecked={!!selectedItems ? selectedItems.includes(item.id) : undefined}
                            onChange={(value) => {
                              if (isMultiSelect){
                                handleCheck(value, item.id)
                                return;
                              }
                              resetModal()
                              onDone(item.id)
                              setOpenModal(false)
                              setInputSelectText(item.name)
                            }}
                          >
                            {item.name}
                          </Checkbox>
                        </View>
                      ))
                    }
                    </View>
                  ) : null
                }

                {
                  items.length && isUsersList ? (
                    <View>
                      {
                        isMultiSelect && showSelectAll ? (
                          <Checkbox
                            shadow={2} my={1}
                            accessibilityLabel="select all items"
                            onChange={handleSelectAll}
                          >
                            {translate('components.selectList.selectAll')}
                          </Checkbox>
                        ) : null
                      }
                    {
                      itemsToRender.map(item => (
                        <HStack
                          justifyContent="space-between"
                          bg={useColorModeValue("gray.100", "coolGray.600")}
                          key={item.id} mt={1} pt={1} pb={2} px={2} borderRadius={6}
                        >
                          <UserWithTitle
                            index={item.id}
                            userId={item.id}
                            avatar={item.avatar}
                            name={item.full_name}
                            title={item.company_role}
                            onlyUser={true}
                          />
                          <Checkbox
                            shadow={2} mt={1}
                            value={item.id}
                            accessibilityLabel="items"
                            isChecked={!!selectedItems ? selectedItems.includes(item.id) : undefined}
                            onChange={(value) => {
                              if (isMultiSelect){
                                handleCheck(value, item.id)
                                return;
                              }
                              onDone(item.id)
                              setOpenModal(false)
                              setInputSelectText(item.full_name)
                              resetModal()
                            }}
                          >
                          </Checkbox>
                        </HStack>
                      ))
                    }
                    </View>
                  ) : null
                }
              </ScrollView>


            <Modal.Footer>
              <Button.Group space={2}>
                <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                  setOpenModal(false)
                  resetModal()
                }}>
                  {cancelText}
                </Button>
                <Button onPress={() => {
                  resetModal()
                  if (selectedItems.length === 0){
                    setOpenModal(false)
                    onDone(selectedItems)
                    setInputSelectText(`${selectText}`)
                    return
                  }

                  onDone(selectedItems)
                  setInputSelectText(`${selectText} (${selectedItems.length})`)
                  setOpenModal(false)
                }}>
                  {doneText}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Center>
    </View>
  )
}
