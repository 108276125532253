
class TimeFormatter {

    static getFormattedDate(date) {
        let newDate = new Date(date);

        // Check if the date is valid
        if (isNaN(newDate.getTime())) {
            // Invalid date, try parsing it manually
            let dateParts;
            if (date.includes("-")) {
                // Format 1: 2022-8-11
                dateParts = date.split("-");
            } else if (date.includes("T")) {
                // Format 2: 2022-08-05T21:21:14.000000Z
                dateParts = date.split("T")[0].split("-");
            }

            if (dateParts && dateParts.length === 3) {
                // Manually create the date object
                newDate = new Date(
                  parseInt(dateParts[0]),
                  parseInt(dateParts[1]) - 1,
                  parseInt(dateParts[2])
                );
            } else {
                // Unable to parse the date, return an empty string or handle the error as desired
                return "";
            }
        }

        // Format the date as desired
        const formattedDay = String(newDate.getDate()).padStart(2, '0');
        const formattedMonth = String(newDate.getMonth() + 1).padStart(2, '0');
        const formattedYear = newDate.getFullYear();

        return `${formattedDay}-${formattedMonth}-${formattedYear}`;
    }

}

export default TimeFormatter;
