import { AntDesign } from "@expo/vector-icons";

import {
  View, Text,
  Icon, ScrollView,
  Image, HStack, useColorModeValue
} from "native-base";
import {localInfo} from "../utils/Translate";
import {Platform} from "react-native";

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const Info = (props) => {
  return <HStack>
    <HStack>
      <Icon as={<AntDesign name="clockcircleo"/>} size={5} />
      <View mx={1}/>
      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>{ props.date }</Text>
    </HStack>
  </HStack>
}

const Title = (props) => {
  return <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={600}>
    { props.title }
  </Text>
}

const Body = (props) => {
  return <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
    { props.body }
  </Text>
}

function formatDate(date){
  return new Intl.DateTimeFormat('en-US').format(new Date(date));
}

export default ({ route }) => {

  const { article } = route.params;

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>

        {
          !!article ? (
            <View>
              <Image
                h={220} w="100%" alt="article image"
                source={{ uri: article.image }}
              />

              <View mx={4} mt={4}>
                <Info date={formatDate(article.created_at)}/>

                <SpacerMY space={3}/>

                <Title title={article.title} />

                <SpacerMY space={2}/>

                <Body body={article.content}/>

                <SpacerMY space={10} />

              </View>
            </View>
          ) : null
        }

      </ScrollView>
    </View>
  );
}
