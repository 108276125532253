import {
  Box,
  Center,
  HStack,
  Pressable,
  Text,
  useColorModeValue, useTheme,
  View
} from "native-base";
import React from "react";
import {Dimensions} from "react-native";
import {translate} from "../utils/Translate";

function getTitle(period, isTitle = true){

  if (period === "MONTHLY" && isTitle){
    return translate('components.subscription.oneMonth')
  } else if (period === "MONTHLY" && !isTitle){
    return translate('components.subscription.billedMonthly')
  } else if (period === "THREE_MONTH" && isTitle){
    return translate('components.subscription.threeMonths')
  } else if (period === "THREE_MONTH" && !isTitle){
    return translate('components.subscription.billedTrimsterly')
  } else if (period === "SIX_MONTH" && isTitle){
    return translate('components.subscription.sixMonths')
  } else if (period === "SIX_MONTH" && !isTitle){
    return translate('components.subscription.billedSemesterly')
  } else if (period === "ANNUAL" && isTitle){
    return translate('components.subscription.oneYear')
  } else if (period === "ANNUAL" && !isTitle){
    return translate('components.subscription.billedYearly')
  }

}

export default ({ period, price, showSave, subscriptionPackage, onClicked }) => {
  const width = Dimensions.get('screen').width
  const { colors } = useTheme();

  return (
    <Pressable
      width={[width * 0.95, width * 0.95, width * 0.35]}
      onPress={() => onClicked(subscriptionPackage)}>
      <Box
        bg={useColorModeValue(colors.appWhite["200"], "coolGray.700")}
        borderRadius={8} shadow={3} px={3} py={2} mt={5} mb={1} ml={[0, 0, 3]}
      >
        <HStack justifyContent="space-between">
          <Text textAlign="left" fontWeight={500} fontSize={22}>
            {getTitle(period, true)}
          </Text>

          <Center>
            {
              showSave ? (
                <View
                  px={1} mt={"-5"} borderRadius={5}
                  bg={useColorModeValue("coolGray.700", colors.appPrimary)}
                >
                  <Text textAlign="left" fontSize={16} color="primary.50">
                    {translate('components.subscription.save')} +30%
                  </Text>
                </View>
              ) : null
            }
            <Text mt={6} textAlign="left" fontSize={24} fontWeight={500}>
              {price}
            </Text>
          </Center>
        </HStack>

        <View>
          <Text textAlign="left" fontSize={20}>
            {`${price} ${getTitle(period, false)}`}
          </Text>
        </View>
      </Box>
    </Pressable>
  )
}
