import React, {useEffect, useRef, useState} from 'react';
import OutgoingOfferCard from "../../components/OutgoingOfferCard";

import apiClient from "../../utils/apiClient";
import {localInfo, translate} from "../../utils/Translate";

import {
  View, Text, Center, ScrollView, useColorModeValue, Button, Spinner, useTheme
} from "native-base";
import TimeFormatter from "../../utils/TimeFormatter";
import {AuthContext} from "../../utils/context/auth";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize={32} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="primary.50" w={60}/>
  </View>
}

export default ({ navigation, userId }) => {

  const { isCompany } = React.useContext(AuthContext);

  const [outgoingOffers, setOutgoingOffers] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(false)

  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  const { colors } = useTheme();

  function getOutgoingOffers(isLoadMore = false){
    let nextP  = nextPage.current.length ? `?${nextPage.current}` : '';

    let query = `users/${userId}/offers${nextP}`

    apiClient.get(query).then((response) => {

      if (isLoadMore && nextPage.current.length){
        getNextPage(response.data.next_page_url)
        setOutgoingOffers(old => [...old, ...response.data.data])

        setIsLoadingData(false)
        isLoadingMoreRef.current = false
        return;
      }

      getNextPage(response.data.next_page_url)
      setOutgoingOffers(response.data.data)
      setIsLoadingData(false)
      isLoadingMoreRef.current = false
    }).catch(error => console.log(error.response.data));
  }

  useEffect(() => {
    getOutgoingOffers()

    navigation.addListener('focus', () => {
      getOutgoingOffers()
    });
  },[navigation])

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  function loadMore(){
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    setIsLoadingData(true)
    isLoadingMoreRef.current = true

    getOutgoingOffers(true)
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            loadMore()
          }
        }}
        scrollEventThrottle={400}
      >

        <View bg={isCompany ? colors.appPrimary : colors.particular} borderBottomRightRadius={600}>
          <View m={4}>
            <View flexDirection="row">
              <Title title={translate('drawer.screens.outgoingOffers.title')} />
            </View>
            <View flexDirection="row">
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} my={2} color="primary.50" fontSize={16}>
                {translate('drawer.screens.outgoingOffers.description')}
              </Text>
            </View>
          </View>
        </View>

        <View m={4}>

          {
            outgoingOffers.length ? (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {outgoingOffers.map((offer, index) => {
                  return (
                    <OutgoingOfferCard
                      key={index}
                      tenderId={offer.tender.id}
                      location={offer.tender.state.name[localInfo.local]}
                      title={offer.tender.title}
                      branch={offer.tender.branch.name[localInfo.local]}
                      avatar={offer.tender.tenderable.avatar}
                      offerPoints={offer.points}
                      tenderPoints={offer.tender.required_points}
                      bidAmount={!!offer.bid_amount ? offer.bid_amount : !!offer.price ? offer.price : null}
                      currency={!!offer.currency ? offer.currency.code[localInfo.local] : ''}
                      date={TimeFormatter.getFormattedDate(offer.created_at)}
                    />
                  );
                })}
              </View>
            ) : (
              <Center
                bg={useColorModeValue(colors.appWhite["200"], "coolGray.700")}
                py={4} mt={10} borderRadius={8}
              >
                <Text textAlign="center" fontSize={20}>
                  {translate('drawer.screens.outgoingOffers.noOffers')}
                </Text>
              </Center>
            )
          }

          {
            isLoadingData ? (
              <View mt={10}>
                <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
              </View>
            ) : null
          }

        </View>

        <Center mt={10}>
          <Button
            w="85%" size="lg" color="appPrimary" borderRadius={8}
            onPress={() => navigation.navigate('Tenders', {userId : null, isUserTenders: false})}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('drawer.screens.outgoingOffers.browseTenders')}
            </Text>
          </Button>
        </Center>

        <View mb={20}/>

      </ScrollView>
    </View>
  );
}
