import React, {useEffect, useState} from "react";

import {
  Checkbox, Text, Pressable, View, FormControl, Input,
  Center, Modal, Button, useColorMode, useColorModeValue
} from "native-base";
import SearchInput from "./SearchInput";
import {localInfo, translate} from "../utils/Translate";

export default (
  {
    items, alreadySelectedItems, selectText, isMultiSelect = true,
    modalHeaderText, onDone, onCancel, doneText, cancelText, visible = false,
    showSelectAll = true
  }) => {

  const [selectedItems, setSelectedItems] = useState([])
  const [itemList, setItemList] = useState([])
  const [searchText, setSearchText] = useState('')

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  function handleCheck(value, id) {
    if (value === true){
      setSelectedItems(oldArray => [...oldArray, id])
    }
    // Remove existing id
    if (value === false) {
      setSelectedItems(oldArray => oldArray.filter(item => item !== id))
    }
  }

  const handleSelectAll = (value) => {
    let itemsToCheck = !!searchText ? itemList : items;

      const allIds = itemsToCheck.map(({id}) => id)
      setSelectedItems(allIds)
    // Remove existing id
    if (value === false) {
      setSelectedItems([])
    }
  }

  const handleSearch = (text) => {
    if (text.length > 0){
      setSearchText(text)
      setItemList( items.filter(({name}) => {
        return name.toUpperCase().includes(text.toUpperCase())
      }))
    } else (
      setItemList(items)
    )
  }

  useEffect(() => {
    setItemList(items)

    if (!!alreadySelectedItems && alreadySelectedItems.length > 0){
      setSelectedItems(alreadySelectedItems)
    }

  }, [alreadySelectedItems])

  let itemsToRender = !!searchText ? itemList : items;

  return (
    <View>

      <Center>
        <Modal size={["xl", "xl", "md"]} isOpen={visible}>
          <Modal.Content maxWidth="100%">
            <Modal.Header>
              <Text textAlign="left" fontSize={20} fontWeight={500}>
                {modalHeaderText}
              </Text>
            </Modal.Header>
            <Modal.Body>
              <SearchInput
                onChange={handleSearch}
                onSubmit={handleSearch}
              />

              <View mt={5}/>

                {
                  items.length ? (
                    <View>
                      {
                        isMultiSelect && showSelectAll ? (
                          <Checkbox
                            shadow={2} my={1}
                            accessibilityLabel="select all items"
                            onChange={handleSelectAll}
                          >
                            {translate('components.selectList.selectAll')}
                          </Checkbox>
                        ) : null
                      }
                    {
                      itemsToRender.map(item => (
                        <View
                          bg={useColorModeValue("gray.100", "coolGray.600")}
                          key={item.id} mt={1} pt={1} pb={2} px={2} borderRadius={6}>
                          <Checkbox
                            shadow={2} mt={1}
                            value={item.id}
                            accessibilityLabel="items"
                            isChecked={!!selectedItems ? selectedItems.includes(item.id) : undefined}
                            onChange={(value) => {
                              if (isMultiSelect){
                                handleCheck(value, item.id)
                                return;
                              }
                              onDone(item.id)
                            }}
                          >
                            {item.name}
                          </Checkbox>
                        </View>
                      ))
                    }
                    </View>
                  ) : null
                }

            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                  onCancel()
                }}>
                  {cancelText}
                </Button>
                <Button onPress={() => {
                  if (selectedItems.length === 0){
                    onDone(selectedItems)
                    return
                  }

                  onDone(selectedItems)
                }}>
                  {doneText}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Center>
    </View>
  )
}
