import React, {useEffect, useRef, useState} from 'react';
import apiClient from "../../../utils/apiClient";
import { useIsFocused } from '@react-navigation/native';

import {
  View, Text, ScrollView, Divider, useColorModeValue,
  HStack, Icon, Spinner
} from "native-base";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {formatBytes} from "../../../utils/StringTool";
import {TouchableOpacity} from "react-native";
import {AuthContext} from "../../../utils/context/auth";
import {translate} from "../../../utils/Translate";
import UserRequestCard from "../../../components/UserRequestCard";
import NoItemYet from "../../../components/NoItemYet";
import TenderCardSkeleton from "../../../components/skeleton/TenderCardSkeleton";
import TimeFormatter from "../../../utils/TimeFormatter";


const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

export default ({ route, navigation }) => {

  const { authUser } = React.useContext(AuthContext);

  const [userId, setUserId] = useState(0);
  const [groupsRequests, setGroupsRequests] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false)

  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)
  const searchText = useRef('')

  const isFocused = useIsFocused();

  function getMyRequests(userID, isLoadMore){

    let nextP  = nextPage.current.length ? `?${nextPage.current}` : '';

    apiClient.get(`users/${userID}/groups-requests${nextP}`).then( response => {

      if (isLoadMore && nextPage.current.length){
        getNextPage(response.data.next_page_url)
        setGroupsRequests(old => [...old, ...response.data.data])

        setIsLoadingData(false)
        isLoadingMoreRef.current = false
        return;
      }

      getNextPage(response.data.next_page_url)
      setGroupsRequests(response.data.data)

    }).catch(error => console.log('getMyRequests', error.response.data));
  }

  useEffect(() => {
    (async() => {
      const user = await authUser()

      setUserId(user.id);

      getMyRequests(user.id, false)
    })()

    navigation.addListener('focus', () => {
      if (userId !== 0){
        getMyRequests(userId, false)
      }
    });
  }, [navigation])

  useEffect(() => {
    if (userId !== 0){
      getMyRequests(userId, false)
    }
  }, [isFocused])



  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  function loadMore(){
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    setIsLoadingData(true)
    isLoadingMoreRef.current = true

    getMyRequests(userId, true)
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            loadMore()
          }
        }}
        scrollEventThrottle={400}
      >

        <View mt={5}/>

        <View mx={2}>
          <Title title={
            translate('drawer.groupBuying.tabs.myRequests')
          } />

          <View mt={10}/>

          {
            !!groupsRequests && groupsRequests.length ? (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {
                  groupsRequests.map((groupRequest, index) => {
                    return (
                      <UserRequestCard
                        key={index}
                        id={groupRequest.id}
                        groupBuyingId={groupRequest.groupbuying.id}
                        title={groupRequest.groupbuying.title}
                        date={TimeFormatter.getFormattedDate(groupRequest.created_at)}
                        isPendingRequest={groupRequest.status.name === 'pending'}
                        isAcceptedRequest={groupRequest.status.name === 'accepted'}
                        isRejectedRequest={groupRequest.status.name === 'rejected'}
                      />
                    );
                  })
                }
              </View>
            ) : null
          }

          {
            !!groupsRequests && !groupsRequests.length ? (
              <NoItemYet text={translate('components.userNoGroupsYet.noRequestsYet')}/>
            ) : null
          }
          {
            !groupsRequests && groupsRequests === null ? (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {[1,2,3].map((tender, index) => {
                  return (
                    <TenderCardSkeleton key={index}/>
                  );
                })}
              </View>
            ) : null
          }

          {
            isLoadingData ? (
              <View mt={10}>
                <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
              </View>
            ) : null
          }

        </View>

        <View mb={38}/>

      </ScrollView>
    </View>
  );
}
