import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, {useEffect, useState} from 'react';
import TabsNavigation from "./Tabs";
import AppIndex from "../screens/auth/Index";
import {TouchableOpacity, Platform, useWindowDimensions} from 'react-native';
import {Ionicons, MaterialIcons, AntDesign} from "@expo/vector-icons";
import Notifications from "../screens/Notifications";
import MyProfile from "../screens/MyProfile";
import Tenders from "../screens/Tenders";
import Tender from "../screens/Tender";
import Article from "../screens/Article";
import PublishTenderInfo from "../screens/publishTender/Info";
import PublishTenderCriteria from "../screens/publishTender/Criteria";
import PublishTenderReviewSteps from "../screens/publishTender/ReviewSteps";
import ReceivedOffers from "../screens/ReceivedOffers";
import Offer from "../screens/Offer";
import TenderPublished from "../screens/TenderPublished";
import OfferReview from "../screens/OfferReview";
import OfferSent from "../screens/OfferSent";
import PublishTenderPointsTable from "../screens/publishTender/PointsTable";
import PublishTenderPriceTable from "../screens/publishTender/PriceTable";
import RequiredDocuments from "../screens/publishTender/RequiredDocuments";
import ReviewStepsPreview from "../screens/ReviewStepsPreview";
import ReviewSteps from "../screens/ReviewSteps";
import TenderClosed from "../screens/TenderClosed";
import Chat from "../screens/Chat";
import PaymentMethods from "../screens/PaymentMethods";
import CCP from "../screens/CCP";
import PaymentReview from "../screens/PaymentReview";
import CompanyProfile from "../screens/CompanyProfile";
import UserProfile from "../screens/UserProfile";
import Companies from "../screens/Companies";
import CallLines from "../screens/CallLines";
import TenderApply from "../screens/TenderApply";

import {
  Icon, Pressable, View, HStack, Button, Badge, useColorMode, Text,
  Divider, useTheme
} from "native-base";
import {localInfo, translate} from "../utils/Translate";
import apiClient from "../utils/apiClient";
import {AuthContext} from "../utils/context/auth";
import theme from "../constants/tab/Theme";
import JoinRequest from "../screens/drawer/groupBuying/JoinRequest";
import Group from "../screens/drawer/groupBuying/Group";
import ReceivedRequests from "../screens/drawer/groupBuying/ReceivedRequests";
import JournalTender from "../screens/drawer/journal/JournalTender";
import Journal from "../screens/drawer/journal/Journal";

const Stack  = createNativeStackNavigator();

export default function StackNavigation({ navigation }) {

  const { isCompany, hasPermissionTo } = React.useContext(AuthContext);

  const [unreadCount, setUnreadCount] = useState(0);
  const [canPublishTender, setCanPublishTender] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const {colorMode} = useColorMode();
  const { colors }  = useTheme();

  const isDark = colorMode === 'dark';

  function getUnreadCount(){
    apiClient.get(`users/1/notifications/unread/count`).then( response => {
      setUnreadCount(response.data)
    }).catch(error => console.log(error.response.data));
  }
  function getUnreadMessagesCount(){
    apiClient.get('messages/unread').then((res) => {
      setUnreadMessagesCount(res.data)
    })
  }

  useEffect(() => {
    (async() => {
      const canManageMembers = await hasPermissionTo('publish tenders')

      setCanPublishTender(canManageMembers)
    })()

    getUnreadCount()
    getUnreadMessagesCount()

    const Interval = setInterval(() => {
      getUnreadCount()
      getUnreadMessagesCount()
    }, 30000)

    return function clean (){
      clearInterval(Interval);
    }
  }, [])

  const isWebPlatform = Platform.OS === 'web';
  const dimensions    = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;

  const headerLeft = () => {
    if (isWebPlatform && isLargeScreen){
      return <></>
    }
    return <TouchableOpacity onPress={() => navigation.openDrawer()}>
      <Icon as={<MaterialIcons name="menu" />} color={isWebPlatform ? "#fff" : isDark ? theme.colors.white : theme.colors.dark} />
    </TouchableOpacity>
  }

  const WebMenu = () => {

    const Menu = () => {
      return <HStack>
        <Button
          mx={0} px={2}
          colorScheme="appWhite"
          leftIcon={<Icon as={<MaterialIcons name="home" />} size={6} />}
          variant="ghost"
          onPress={() => navigation.navigate('Home')}
        >
          {
            isLargeScreen ? translate('stack.webMenu.home') : null
          }
        </Button>
        {/*<Divider bg="primary.50" thickness="2" mx="2" orientation="vertical" />*/}
        {
          !isCompany ? (
            <Button
              mx={0} px={2}
              colorScheme="appWhite"
              leftIcon={<Icon as={<AntDesign name="pluscircle" />} size={6} />}
              variant="ghost"
              onPress={() => navigation.navigate('PublishTenderInfo')}
            >
              {
                isLargeScreen ? translate('stack.webMenu.createTender') : null
              }
            </Button>
          ) : null
        }
        {
          isCompany && canPublishTender ? (
            <Button
              mx={0} px={2}
              colorScheme="appWhite"
              leftIcon={<Icon as={<AntDesign name="pluscircle" />} size={6} />}
              variant="ghost"
              onPress={() => navigation.navigate('PublishTenderInfo')}
            >
              {
                isLargeScreen ? translate('stack.webMenu.createTender') : null
              }
            </Button>
          ) : null
        }
        {/*<Divider bg="primary.50" thickness="2" mx="2" orientation="vertical" />*/}
        <View>
          {
            unreadMessagesCount !== 0 ? (
              <Badge
                colorScheme="secondary" rounded="full" mb={-4} mr={-2} zIndex={1}
                variant="solid" alignSelf="flex-end"
                _text={{
                  fontSize: 11
                }}
              >
                {unreadMessagesCount}
              </Badge>
            ) : null
          }
          <Button
            mx={0} px={2}
            colorScheme="appWhite"
            variant="ghost"
            leftIcon={<Icon as={<MaterialIcons name="mail" />} size={6} />}
            onPress={() => navigation.navigate('Inbox')}
          >
            {
              isLargeScreen ? translate('stack.webMenu.inbox') : null
            }
          </Button>
        </View>
        {/*<Divider bg="primary.50" thickness="2" mx="2" orientation="vertical" />*/}
        <Button
          mx={0} px={2}
          colorScheme="appWhite"
          leftIcon={<Icon as={<MaterialIcons name="article" />} size={6} />}
          variant="ghost"
          onPress={() => navigation.navigate('Articles')}
        >
          {
            isLargeScreen ? translate('stack.webMenu.articles') : null
          }
        </Button>
        {/*<Divider bg="primary.50" thickness="2" mx="2" orientation="vertical" />*/}
        <Button
          mx={0} px={2}
          colorScheme="appWhite"
          leftIcon={<Icon as={<MaterialIcons name="help" />} size={6} />}
          variant="ghost"
          onPress={() => navigation.navigate('Help')}
        >
          {
            isLargeScreen ? translate('stack.webMenu.help') : null
          }
        </Button>
        {/*<Divider bg="primary.50" thickness="2" mx="2" orientation="vertical" />*/}
      </HStack>
    }

    return isWebPlatform ? <Menu/> : <></> ;
  }

  const headerRight = () => {
    return <HStack>

      <WebMenu/>

      <View mr={isWebPlatform ? 5 : 0}>
        {
          unreadCount !== 0 ? (
            <Badge
              colorScheme="secondary" rounded="full" mb={-4} mr={-2} zIndex={1}
              variant="solid" alignSelf="flex-end" _text={{
              fontSize: 11
            }}>
              <Text color="white">
                {unreadCount}
              </Text>
            </Badge>
          ) : null
        }
        <TouchableOpacity onPress={() => navigation.navigate('Notifications')}>
          <Icon as={<MaterialIcons name="notifications-none" />} color={isWebPlatform ? "#fff" : isDark ? theme.colors.white : theme.colors.dark} />
        </TouchableOpacity>
      </View>
    </HStack>
  }

  const backButton = () => {
    let iconName =  localInfo.isRTL ? "chevron-forward-outline" : "chevron-back-outline";

    return <Pressable onPress={() => navigation.goBack()}>
      <Icon as={<Ionicons name={iconName} />} size={8} color={isWebPlatform ? "#fff" : isDark ? theme.colors.white : theme.colors.dark} />
    </Pressable>
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle:{
          backgroundColor: isWebPlatform ? colors.appPrimary : isDark ? theme.colors.dark : theme.colors.white
        },
        title: '',
        headerLeft,
        headerRight
      }}
    >
      <Stack.Screen name="tenders" component={TabsNavigation} options={{ ...(isWebPlatform && {headerLeft: headerLeft}) }} />
      <Stack.Screen name="Index" component={AppIndex} options={{ headerShown: false }} />
      <Stack.Screen name="Notifications" component={Notifications} options={{ headerLeft: backButton }} />
      <Stack.Screen name="MyProfile" component={MyProfile} options={{ headerLeft: backButton }} />
      <Stack.Screen name="Tenders" component={Tenders} options={{ headerLeft: backButton }} />
      <Stack.Screen name="Tender" component={Tender} options={{ headerLeft: backButton }} getId={({ params }) => params.tenderId} />
      <Stack.Screen name="TenderApply" component={TenderApply} options={{ headerLeft: backButton }} />
      <Stack.Screen name="Article" component={Article} options={{ headerLeft: backButton }} />
      <Stack.Screen name="PublishTenderInfo" component={PublishTenderInfo} options={{ headerLeft: backButton }} />
      <Stack.Screen name="RequiredDocuments" component={RequiredDocuments} options={{ headerLeft: backButton }} />
      <Stack.Screen name="PublishTenderCriteria" component={PublishTenderCriteria} options={{ headerLeft: backButton }} />
      <Stack.Screen name="PublishTenderPointsTable" component={PublishTenderPointsTable} options={{ headerLeft: backButton }} />
      <Stack.Screen name="PublishTenderPriceTable" component={PublishTenderPriceTable} options={{ headerLeft: backButton }} />
      <Stack.Screen name="PublishTenderReviewSteps" component={PublishTenderReviewSteps} options={{ headerLeft: backButton }} />
      <Stack.Screen name="ReviewStepsPreview" component={ReviewStepsPreview} options={{ headerLeft: backButton }} />
      <Stack.Screen name="ReceivedOffers" component={ReceivedOffers} options={{ headerLeft: backButton }} />
      <Stack.Screen name="Offer" component={Offer} options={{ headerLeft: backButton }} />
      <Stack.Screen name="TenderPublished" component={TenderPublished} options={{ headerLeft: () => <View/> }} />
      <Stack.Screen name="TenderClosed" component={TenderClosed} options={{ headerLeft: backButton }} />
      <Stack.Screen name="OfferReview" component={OfferReview} options={{ headerLeft: backButton }} />
      <Stack.Screen name="OfferSent" component={OfferSent} options={{ headerLeft: () => <View/> }} />
      <Stack.Screen name="ReviewSteps" component={ReviewSteps} options={{ headerLeft: backButton }} />
      <Stack.Screen name="Chat" component={Chat} options={{ headerLeft: backButton }} getId={({ params }) => params.typeId} />
      <Stack.Screen name="PaymentMethods" component={PaymentMethods} options={{ headerLeft: backButton }} />
      <Stack.Screen name="CCP" component={CCP} options={{ headerLeft: backButton }} />
      <Stack.Screen name="PaymentReview" component={PaymentReview} options={{ headerLeft: () => <View/> }} />
      <Stack.Screen name="CompanyProfile" component={CompanyProfile} options={{ headerLeft: backButton }} />
      <Stack.Screen name="UserProfile" component={UserProfile} options={{ headerLeft: backButton }} />
      <Stack.Screen name="Companies" component={Companies} options={{ headerLeft: backButton }} />
      <Stack.Screen name="CallLines" component={CallLines} options={{ headerLeft: backButton }} />
      <Stack.Screen name="JoinRequest" component={JoinRequest} options={{ headerLeft: backButton, headerRight: () => <View/> }} />
      <Stack.Screen name="Group" component={Group} options={{ headerLeft: backButton, headerRight: () => <View/> }} getId={({ params }) => params.groupId} />
      <Stack.Screen name="ReceivedRequests" component={ReceivedRequests} options={{ headerLeft: backButton, headerRight: () => <View/> }} />
      <Stack.Screen name="Journal" component={Journal} options={{ headerLeft: backButton, headerRight: () => <View/> }} />
      <Stack.Screen name="JournalTender" component={JournalTender} options={{ headerLeft: backButton, headerRight: () => <View/> }} />
    </Stack.Navigator>
  );
}
