import {Avatar, Box, Center, Icon, Text, View, Pressable, useColorModeValue, useColorMode} from "native-base";
import React from "react";
import {AntDesign} from "@expo/vector-icons";
import { useNavigation } from '@react-navigation/native';
import {translate} from "../utils/Translate";
import {shorten} from "../utils/StringTool";

export default (props) => {
  const navigation  = useNavigation();
  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const pendingText   = translate('components.offerCard.pending');
  const acceptedText  = translate('components.offerCard.accepted');
  const rejectedText  = translate('components.offerCard.rejected');

  function getStatusBgColor(){
    let bgColor = ""

    if (!!props.isPendingRequest && isDark){
      bgColor = "appAccentDark";
    } else if (!!props.isPendingRequest && !isDark){
      bgColor = "appAccent";
    } else if (!!props.isRejectedRequest){
      bgColor = "appSecondary";
    } else if (!!props.isAcceptedRequest) {
      bgColor = "appPrimary";
    }

    return bgColor
  }

  const Tag = () => !!props.isPendingRequest || !!props.isAcceptedRequest || !!props.isRejectedRequest ?
    <Box
      bg={ getStatusBgColor()}
      pt={4} p={2} borderTopLeftRadius={30} borderBottomRightRadius={8}
    >
      <Center>
        <Text textAlign="left" fontWeight={500} fontSize={18} color="white">
          { !!props.isPendingRequest ? pendingText : !!props.isAcceptedRequest ? acceptedText : rejectedText }
        </Text>
      </Center>
    </Box> : <View p={9}/>

  return (
    <Pressable
      width={["100%", "100%", "33%"]}
      onPress={() => {
        navigation.navigate('Group', {groupId: props.groupBuyingId})
        navigation.navigate('JoinRequest', {requestId: props.id})
      }}
    >
      <Box
        bg={useColorModeValue("#F7F7F7", "coolGray.700")}
        pl={3} pt={2} shadow={2} my={2} borderRadius={8}
        ml={[0, 0, 1]}
      >
        <View flexDirection="row">
          <View>
            <Text textAlign="left" fontWeight={600} fontSize={23}>
              {shorten(props.title, 50)}
            </Text>
          </View>
        </View>
        <Center mt={4} flexDirection="row" justifyContent="space-between">
          <View>
            <Text textAlign="left" fontWeight={500} fontSize={16}>
              {translate('components.offerCard.submissionDate')}: {props.date}
            </Text>
          </View>

          <Tag/>
        </Center>
      </Box>
    </Pressable>
  )
}
