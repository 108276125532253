import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

const Restart = async () => {
  if(Platform.OS === "web")
  {
    window.location.reload()
  }
  else{
    await Updates.reloadAsync();
  }
}
export { Restart }
