import {Center, Text, useColorModeValue} from "native-base";
import React from "react";
import {translate} from "../utils/Translate";

export default ({ text }) => {

  return (
    <Center
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      py={4} px={2} mb={10} mt={5} borderRadius={8}
    >
      <Text textAlign="center" fontSize={20}>
        {text}
      </Text>

    </Center>
  )
}
