import {Box, Image, Pressable, Text, useColorModeValue, View} from "native-base";
import React from "react";
import {useNavigation} from "@react-navigation/native";
import {shorten} from "../utils/StringTool";
import {Dimensions} from "react-native";


export default ({ article }) => {
  const navigation = useNavigation();
  const width = Dimensions.get('screen').width
  return (
    <Pressable
      width={[width * 0.99, width * 0.99, width * 0.35]}
      onPress={() => navigation.navigate('Article', { article })}>
      <Box
        bg={useColorModeValue("#F8F8F8", "coolGray.700")}
        borderRadius={8} shadow={3} mt={3} mb={1} ml={[0, 0, 1]}
      >
        <View flexDirection="row">
          <View width={["33%", "33%", "28%"]}>
            <Image
              alt={"Image"}
              borderRadius={8}
              w={120} h={110}
              source={{ uri: article.image}}
            />
          </View>
          <View ml={2} w="63%">
            <Text textAlign="left" fontWeight={500} fontSize={19}>
              {shorten(article.title, 37)}
            </Text>
            <Text textAlign="left" fontSize={17}>
              {shorten(article.content, 57)}
            </Text>
          </View>
        </View>
      </Box>
    </Pressable>
  )
}
