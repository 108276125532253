import React, {useEffect, useRef, useState} from "react";

import {
  Button,
  Center,
  Checkbox,
  Modal,
  ScrollView,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
  View
} from "native-base";
import SearchInput from "./SearchInput";
import {localInfo, translate} from "../utils/Translate";
import apiClient from "../utils/apiClient";
import SelectList from "../components/SelectList";
import {RadioButton} from 'react-native-paper';

// For using as Modal
export default (
  {
    alreadySelectedItems, selectText, isMultiSelect = true,
    modalHeaderText, onDone, onCancel, doneText, cancelText,
    selectedId = null, visible = false,
    showSelectAll = true
  }) => {

  const [openModal, setOpenModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([])
  const [inputSelectText, setInputSelectText] = useState('')
  const [searchText, setSearchText] = useState('')
  const [paginatedItems, setPaginatedItems] = useState([])
  const [radioValue, setRadioValue] = React.useState();
  const [states, setStates] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [itemsToRender, setItemsToRender] = React.useState([]);
  const [isLoadingStates, setIsLoadingStates] = React.useState(false);

  const isLoadingMore = useRef(false);
  const paginationCurrentPage = useRef(1);
  const countryID = useRef(4);
  const pages = useRef([]);
  const itemsToRenderRef = useRef([]);

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  function getCountries(){
    apiClient.get(`countries`).then(response => {
      setCountriesORStatesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error.response.data));
  }
  function getCountryStates(){
    setIsLoadingStates(true)

    apiClient.get(`countries/${countryID.current}/states`).then(response => {
      setIsLoadingStates(false)
      setCountriesORStatesOfLanguage(response.data, localInfo.local, 'states')
    }).catch(error => {
      setIsLoadingStates(false)
      console.log(error.response.data)
    });
  }

  function setCountriesORStatesOfLanguage(countriesORStates, language, type = 'countries'){

    let getCountriesStates = countriesORStates.map(item => ({
      id: item.id,
      name: language === 'ar' ? item.name.ar :
        language === 'fr' ? item.name.fr : item.name.en
    }))

    if (type === 'countries'){
      setCountries(getCountriesStates);
    } else {
      // Reset selected items - only select states of the current country
      setSelectedItems([])
      // Set pagination pages 12 items per page
      pages.current = paginate(getCountriesStates, 12)

      // setPaginatedItems(pages.current[0])
      setStates(getCountriesStates);
      // setItemList(getCountriesStates);
      setItemsToRender(getCountriesStates);
      // use itemsToRenderRef for select-all
      itemsToRenderRef.current = getCountriesStates;
    }
  }

  function paginate(arr, size) {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)

      return acc
    }, [])
  }

  function handleCheck(value, id) {
    if (value === true){
      setSelectedItems(oldArray => [...oldArray, id])
    }
    // Remove existing id
    if (value === false) {
      setSelectedItems(oldArray => oldArray.filter(item => item !== id))
    }
  }

  const handleSelectAll = (value) => {
    const allIds = itemsToRenderRef.current.map(({id}) => id)
      setSelectedItems(allIds)
    // Remove existing id
    if (value === false) {
      setSelectedItems([])
    }
  }

  const handleSearch = (text) => {

    if (text.length > 0){
      setSearchText(text)
      let data2 = states.filter(({name}) => {
        return name.toUpperCase().includes(text.toUpperCase())
      })
      // setItemList(data2)
      setItemsToRender(data2); // TODO
    } else {
      // setItemList(items)
      setItemsToRender(paginatedItems); // TODO
    }

  }

  useEffect(() => {
    // setPaginatedItems(pages.current[0])
    // setItemList(items)
    setInputSelectText(selectText)

    if (!!alreadySelectedItems && alreadySelectedItems.length > 0){
      setSelectedItems(alreadySelectedItems)
      setInputSelectText(`${selectText} (${alreadySelectedItems.length})`)
    }

  }, [alreadySelectedItems])

  // let itemsToRender = !!searchText ? itemList : paginatedItems;

  let selectedIdName;
  if (!!selectedId) {
    selectedIdName = getNameById(selectedId, itemsToRender)
  }

  useEffect(() => {
    getCountries()
    // getCountryStates()

    if (!!selectedId){
      setRadioValue(selectedId);

      onDone(selectedId, countryID.current)
      setInputSelectText(selectedIdName)
    }
  }, [selectedIdName])

  useEffect(() => {
    // if the modal is open then get Country States
    if (!!visible){
      getCountryStates()
    }
  }, [visible])

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  function loadMore(){
    if (paginationCurrentPage.current > pages.current.length-1) return;
    if (isLoadingMore.current) return;

    isLoadingMore.current = true
    setPaginatedItems(old => [...old, ...pages.current[paginationCurrentPage.current]])
    setTimeout(() => {
      isLoadingMore.current = false
      paginationCurrentPage.current++
    }, 200)
  }

  function resetModal(){
    setPaginatedItems(pages.current[0])
    paginationCurrentPage.current = 1
  }
  function getNameById(id, array) {
    const obj = array.find(item => item.id === id);
    return obj ? obj.name : null;
  }

  const SingleSelect = ({list}) => {

    return <RadioButton.Group
      onValueChange={
        newValue => {
          setRadioValue(newValue);
          const name = getNameById(newValue, list)
          resetModal()
          onDone(newValue, countryID.current)
          setOpenModal(false)
          setInputSelectText(name)
        }
      }
      value={radioValue}
    >
      {
        list.map(item => (
          <RadioButton.Item label={item.name} value={item.id} />
        ))
      }
    </RadioButton.Group>

    // return <Radio.Group
    //   name="myRadioGroup"
    //   accessibilityLabel="select"
    //   value={radioValue}
    //   onChange={nextValue => {
    //     setRadioValue(nextValue);
    //     const name = getNameById(nextValue, list)
    //     resetModal()
    //     onDone(nextValue)
    //     setOpenModal(false)
    //     setInputSelectText(name)
    //   }}
    // >
    //   {
    //     list.map(item => (
    //       <Radio key={item.id} value={item.id} my={1}>
    //         {item.name}
    //       </Radio>
    //     ))
    //   }
    // </Radio.Group>;
  };

  return (
    <View>

      {/* Modal */}
      <Center>
        <Modal size={["xl", "xl", "md"]} isOpen={visible}>
          <Modal.Content>
            <Modal.Header>
              <Text textAlign="left" fontSize={20} fontWeight={500}>
                {modalHeaderText}
              </Text>
            </Modal.Header>

            {
              countries.length ? (
                <View mt={4} mx={3}>
                  <SelectList
                    selectText={"Select Country"}
                    modalHeaderText={"Select Country"}
                    doneText={translate('misc.done')}
                    cancelText={translate('misc.cancel')}
                    isMultiSelect={false}
                    selectedId={4}
                    onDone={(selectedCountryID) => {
                      countryID.current = selectedCountryID;
                      if (!!visible){
                        getCountryStates()
                      }
                    }}
                    items={countries}
                  />
                </View>
              ) : null
            }

            <View mx={3}>
              <Text mt={4} fontSize={20} fontWeight={500}>
                States:
              </Text>

              <SearchInput
                onChange={handleSearch}
                onSubmit={handleSearch}
              />
            </View>

              <View mt={4}/>

            {
              isLoadingStates ? (
                <View mt={3} mb={4}>
                  <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading.." />
                </View>
              ) : null
            }

              <ScrollView
                pl={2} pr={1}
                onScroll={({nativeEvent}) => {
                  if (isCloseToBottom(nativeEvent)) {
                    loadMore()
                  }
                }}
                scrollEventThrottle={400}
              >
                {
                  !isLoadingStates && states.length && itemsToRender.length && !isMultiSelect ? (
                    <SingleSelect
                      list={itemsToRender}
                    />
                  ) : null
                }
                {
                  !isLoadingStates && states.length && itemsToRender.length && isMultiSelect ? (
                    <View>
                      {
                        isMultiSelect && showSelectAll ? (
                          <Checkbox
                            shadow={2} my={1}
                            accessibilityLabel="select all items"
                            onChange={handleSelectAll}
                          >
                            {translate('components.selectList.selectAll')}
                          </Checkbox>
                        ) : null
                      }
                    {
                      itemsToRender.map(item => (
                        <View
                          bg={useColorModeValue("gray.100", "coolGray.600")}
                          key={item.id} mt={1} pt={1} pb={2} px={2} borderRadius={6}>
                          <Checkbox
                            shadow={2} mt={1}
                            value={item.id}
                            accessibilityLabel="items"
                            isChecked={!!selectedItems ? selectedItems.includes(item.id) : undefined}
                            onChange={(value) => {
                              if (isMultiSelect){
                                handleCheck(value, item.id)
                                return;
                              }
                              resetModal()
                              onDone(item.id, countryID.current)
                              setOpenModal(false)
                              setInputSelectText(item.name)
                            }}
                          >
                            {item.name}
                          </Checkbox>
                        </View>
                      ))
                    }
                    </View>
                  ) : null
                }

              </ScrollView>


            <Modal.Footer>
              <Button.Group space={2}>
                <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                  onCancel()
                }}>
                  {cancelText}
                </Button>
                <Button onPress={() => {
                  resetModal()
                  if (selectedItems.length === 0){
                    setOpenModal(false)
                    onDone(selectedItems, countryID.current)
                    setInputSelectText(`${selectText}`)
                    return
                  }

                  onDone(selectedItems, countryID.current)
                  setInputSelectText(`${selectText} (${selectedItems.length})`)
                  setOpenModal(false)
                }}>
                  {doneText}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Center>
    </View>
  )
}
