import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';

import {translate} from "../../../utils/Translate";
import MyRequests from "./MyRequests";
import MyGroups from "./UserGroups";
import {MaterialCommunityIcons, Ionicons} from "@expo/vector-icons";
import {useTheme} from "native-base";

const Tab = createBottomTabNavigator();

export default function TabsNavigation() {

  const { colors } = useTheme();

  return (
    <Tab.Navigator screenOptions={{
      tabBarActiveTintColor: colors.appPrimary,
      tabBarInactiveTintColor: colors.appAccent,
      tabBarLabelStyle: {
        fontSize: 16,
        fontWeight: 500
      }
    }}>
      <Tab.Screen
        name="ExploreGroups" component={MyGroups}
        initialParams={{ isUserGroups: false, searchValue: null }}
        options={{
          headerShown: false,
          tabBarLabel: translate('drawer.groupBuying.tabs.explore'),
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="text-box-search" color={color} size={size} />
            )
        }}
      />
      <Tab.Screen
        name="MyGroups" component={MyGroups}
        initialParams={{ isUserGroups: true, searchValue: null }}
        options={{
          headerShown: false,
          tabBarLabel: translate('drawer.groupBuying.tabs.myGroups'),
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="file-document-edit" color={color} size={size} />
          )
        }}
      />
      <Tab.Screen
        name="myRequests" component={MyRequests}
        options={{
          headerShown: false,
          tabBarLabel: translate('drawer.groupBuying.tabs.myRequests'),
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="document-attach" color={color} size={size} />
          )
        }}
      />
    </Tab.Navigator>
  );

}
