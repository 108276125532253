import React, {useEffect, useState} from 'react';

import {
  View, Text, Icon, Center, Button, ScrollView, Box,
  Input, Checkbox, HStack, FormControl, Select, Pressable, useColorModeValue
} from "native-base";
import {StyleSheet} from "react-native";
import apiClient from "../../../../utils/apiClient";
import AuthUserManager from "../../../../utils/AuthUserManager";
import datePickerTheme from "../../../../theme/datePickerTheme";
import {DatePickerModal} from "react-native-paper-dates";
import {Provider as PaperProvider} from "react-native-paper";
import {localInfo, translate} from "../../../../utils/Translate";
import {Ionicons} from "@expo/vector-icons";
import {Platform} from "react-native";
import TimeFormatter from "../../../../utils/TimeFormatter";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const CriteriaCard = (props) => {
  return (
    <Box bg="#F3F3F3" borderRadius={8} p={4}>
      <HStack justifyContent="space-between" alignItems="center">
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={21} fontWeight={500}>
          {props.title}
        </Text>
        <Center>
          <Checkbox
            shadow={1} isChecked={props.isChecked} value="points-table" accessibilityLabel="points-table checkbox"
            isDisabled={!!props.isDisabled} onChange={props.onChecked}
          />
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
            {translate('misc.select')}
          </Text>
        </Center>
      </HStack>

      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
        {props.description}
      </Text>

      {props.children}

    </Box>
  )
}

const CurrencyInput = ({ currencies, selectedValue, onValueChange, inputErrors }) => {

  return <FormControl mt={4} w="85%" isInvalid={'currency' in inputErrors}>
    <Select
      textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
      selectedValue={selectedValue}
      accessibilityLabel="Currency input"
      placeholder={translate('screens.publishTender.criteria.placeholder.currency')}
      _selectedItem={{bg: "primary.100"}}
      borderRadius={10} size="xl" borderColor="gray.500" pl={4} py={3}
      onValueChange={onValueChange}
      dropdownIcon={
        <Icon
          as={Ionicons} name={"chevron-down-sharp"} size="5" color="darkgray"
          key={
            Math.random()
          }
        />
      }
    >
      {
        currencies.map((currency, index) => {
          return (
            <Select.Item key={index} label={currency.code[localInfo.local]} value={`${currency.id}`} />
          )
        })
      }
    </Select>
    {
      'currency' in inputErrors ? (
        <FormControl.ErrorMessage>
          {inputErrors.currency}
        </FormControl.ErrorMessage>
      ) : null
    }
  </FormControl>
}

export default ({ route, navigation }) => {

  const { tenderData } = route.params

  const [currencies, setCurrencies]   = React.useState([]);
  const [priceTable, setPriceTable]   = React.useState(true);
  const [pointsTable, setPointsTable] = React.useState(false);
  const [bid, setBid]                 = React.useState(false);
  const [tva, setTva]                 = React.useState(0);
  const [initPrice, setInitPrice]     = React.useState('');

  const [date, setDate] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [formattedDate, setFormattedDate] = React.useState(null);

  const [selectedCurrency, setSelectedCurrency] = React.useState(null);
  const [inputErrors, setInputErrors]           = React.useState({});

  const handlePriceTableCheck = (value) => {

  }

  const handlePointsTableCheck = (value) => {

  }

  const handleBidCheck = (value) => {

  }
  const handleCurrencySelect = (currencyId) => {
    setSelectedCurrency(currencyId)
    tenderData.tender.currency_id = currencyId;
    tenderData.tender.currency = getObjectById (currencies, currencyId);
  }
  const handleInitPrice = (price) => {

    if ('initPrice' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.initPrice
      setInputErrors(errors);
    }

    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    price = price.replace(regex, '')

    setInitPrice(price)
    if (!!tenderData.criteria.isBid){
      tenderData.tender.bid_initial_price = price;
    }
  }
  const handleTva = (tva) => {
    if (!!tenderData.criteria.hasPriceTable){

      let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

      tenderData.tender.tva = tva.replace(regex, '');
      setTva(tva.replace(regex, ''))
    }
  }

  function getObjectById (array, id){
    return array.find(x => x.id === parseInt(id));
  }

  useEffect(() => {

    (async () => {
      let user = await AuthUserManager.get();

      if (user.company === null){
        tenderData.tenderable = user
        tenderData.tenderable_type = 'App\\Models\\User'
      } else {
        tenderData.tenderable = user.company
        tenderData.tenderable_type = 'App\\Models\\Company'
      }
    })();

    getCurrencies()
    // Set TVA to 19%
    tenderData.tender.tva = 19;
    setTva(19);

    if (!!tenderData.criteria.isBid){
      setBid(true)
      setSelectedCurrency(tenderData.tender.currency_id)
    }
    if (!!tenderData.criteria.hasPointsTable){
      setPointsTable(true)
    }
    if (!!tenderData.criteria.hasPriceTable){
      setPriceTable(true)
      setSelectedCurrency(tenderData.tender.currency_id)
    }
  }, []);

  function getCurrencies(){
    apiClient.get(`currencies`).then( response => {
      setCurrencies(response.data)
    }).catch(error => console.log(error));
  }

  const handleNextButton = () => {

    if (!validate()) return;

    let nextRoute, data;

    if (tenderData.tenderable_type === "App\\Models\\Company"){
      nextRoute = 'PublishTenderReviewSteps';
      data = {tenderData};
    } else {
      nextRoute = 'Tender';
      data = { previewData: tenderData };
    }

    return navigation.navigate(nextRoute, data)
  }


  const onDismissSingle = () => {
    setOpen(false);
  };
  const onConfirmSingle = (params) => {
    setOpen(false);
    setDate(params.date);
    setFormattedDate(TimeFormatter.getFormattedDate(params.date));
    tenderData.tender.closing_date = getFormattedDate(params.date)
  }

  function getFormattedDate(date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  function isNextButtonDisabled(){
    if (!priceTable && !pointsTable && !bid){
      return true
    } else if ((priceTable || pointsTable) && formattedDate === null){
      return true;
    }
    return false;
  }

  const validate = () => {
    if (bid && initPrice.length === 0) {
      setInputErrors({ ...inputErrors,
        initPrice: translate('screens.publishTender.criteria.validation.initPrice')
      });
      return false;
    } else if (bid && selectedCurrency === null) {
      setInputErrors({ ...inputErrors,
        currency: translate('screens.publishTender.criteria.validation.currency')
      });
      return false;
    } else if (priceTable && selectedCurrency === null) {
      setInputErrors({ ...inputErrors,
        currency: translate('screens.publishTender.criteria.validation.currency')
      });
      return false;
    }

    return true;
  };

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4} contentContainerStyle={styles.contentContainerStyle}>

        <View>
          <View mt={4}/>
          <Title title={translate('screens.publishTender.title')} />
          <SpacerMY space={3}/>

          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={26}>
            {translate('screens.publishTender.criteria.title')}
          </Text>
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
            {translate('screens.publishTender.criteria.description')}
          </Text>

          <SpacerMY space={8}/>

          <CriteriaCard
            title={translate('screens.publishTender.criteria.pointsTable')}
            description={translate('screens.publishTender.criteria.addPointsTable')}
            isDisabled={priceTable}
            isChecked={pointsTable}
            onChecked={handlePointsTableCheck}
          />

          <SpacerMY space={1}/>

          <CriteriaCard
            title={translate('screens.publishTender.criteria.priceTable')}
            description={translate('screens.publishTender.criteria.addPriceTable')}
            isDisabled={bid}
            isChecked={priceTable}
            onChecked={handlePriceTableCheck}
          >
            {
              !priceTable ? <></> : <View>
                <FormControl mt={4} mb={-2} w="85%">
                  <Input
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    keyboardType="number-pad"
                    placeholder={`${translate('screens.publishTender.criteria.placeholder.TVA')} (%)`}
                    rounded={10} size="xl"
                    borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleTva}
                    value={tenderData.tender.tva}
                  />
                  <FormControl.HelperText>
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} ml={2} mt={-1}>
                      {translate('screens.publishTender.criteria.placeholder.TVA')} {tva}%
                    </Text>
                  </FormControl.HelperText>
                </FormControl>
                <CurrencyInput
                  key={1}
                  currencies={currencies}
                  selectedValue={selectedCurrency}
                  onValueChange={handleCurrencySelect}
                  inputErrors={inputErrors}
                />
              </View>
            }
          </CriteriaCard>

          <SpacerMY space={1}/>

          <CriteriaCard
            title={translate('screens.publishTender.criteria.bid')}
            description={translate('screens.publishTender.criteria.totalCost')}
            isDisabled={pointsTable || priceTable}
            isChecked={bid}
            onChecked={handleBidCheck}
          >
            {
              !bid ? <></> : <View>
                <FormControl mt={4} mb={-2} w="85%" isInvalid={'initPrice' in inputErrors}>
                  <Input
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={translate('screens.publishTender.criteria.placeholder.initialPrice')}
                    rounded={10} size="xl"
                    borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleInitPrice}
                    keyboardType="number-pad"
                    value={tenderData.tender.bid_initial_price}
                  />
                  {
                    'initPrice' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.initPrice}
                      </FormControl.ErrorMessage>
                    ) : null
                  }
                </FormControl>
                <CurrencyInput
                  currencies={currencies}
                  selectedValue={selectedCurrency}
                  onValueChange={handleCurrencySelect}
                  inputErrors={inputErrors}
                />
              </View>
            }
          </CriteriaCard>
        </View>

        {
          !bid ? (
            <Pressable w="100%" onPress={() => setOpen(true)}>
              <FormControl mt={4}>
                <Input
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  placeholder={translate('screens.publishTender.criteria.placeholder.pickDate')}
                  value={formattedDate} isReadOnly rounded={10} size="xl"
                  pointerEvents="none" borderColor="gray.500" pl={4} py={3}
                />
                <FormControl.HelperText>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} ml={2} mt={-1}>
                    {translate('screens.publishTender.criteria.closingDate')}
                  </Text>
                </FormControl.HelperText>
              </FormControl>
            </Pressable>
          ) : null
        }

        <PaperProvider theme={datePickerTheme}>
          <DatePickerModal
            locale={localInfo.local}
            mode="single"
            visible={open}
            onDismiss={onDismissSingle}
            date={date}
            onConfirm={onConfirmSingle}
            validRange={{
              startDate: new Date(),  // optional
              endDate: new Date(2999, 1, 1),
              disabledDates: [new Date()] // optional
            }}
          />
        </PaperProvider>

        <Center mb={16} mt={10}>
          <Button
            w="85%" size="lg" color="appPrimary" borderRadius={8}
            onPress={ handleNextButton }
            isDisabled={ isNextButtonDisabled() }
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.next')}
            </Text>
          </Button>
        </Center>

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    justifyContent: "space-between",
    flexGrow: 1
  },
});
