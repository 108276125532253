import React, {useState, useEffect, useRef} from 'react';
import {Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import Stepper from 'react-native-stepper-ui';

import {
  View, Text, Icon, Center, Button,
  ScrollView, FormControl,
  TextArea, Divider, Modal, HStack, Avatar, useColorModeValue, Spinner
} from "native-base";
import * as DocumentPicker from "expo-document-picker";
import apiClient from "../utils/apiClient";
import {Platform, TouchableOpacity} from "react-native";
import {formatBytes} from "../utils/StringTool";
import * as WebBrowser from "expo-web-browser";
import {localInfo, translate} from "../utils/Translate";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const SectionTitle = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}

const Reviewer = ({ name, avatar }) => {
  return <Center px={1}>
    <Avatar size="lg" source={{ uri: avatar }} />
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={16} >{name}</Text>
  </Center>
}

const Description = ({ text }) => {
  return <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
    { text }
  </Text>
}


export default ({ route, navigation }) => {

  const { offerId, authId } = route.params;

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [reason, setReason] = useState();
  const [reply, setReply] = useState();
  const [currentStepReplyId, setCurrentStepReplyId] = useState();
  const [currentStepId, setCurrentStepId] = useState();
  const [isUpdateReply, setIsUpdateReply] = useState();
  const [steps, setSteps] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isSendingAction, setIsSendingAction] = useState(false);

  const webFiles = useRef(null);

  const ReviewStep = ({ id, reviewers, title, description, action, reviewstepreply, isTenderOwner }) => {
    const [pickedFiles, setPickedFiles] = useState([]);

    let canSendReply = !!reviewstepreply ? reviewstepreply.status.name === "refused" : true;

    const pickDocument = async () => {
      let file = await DocumentPicker.getDocumentAsync({
        multiple: true
      });

      if (!!file.type && file.type === 'cancel'){
        return;
      }

      if(Platform.OS === 'web'){
        const files = file.output;

        webFiles.current = files;

        Promise.all(
          Object.keys(files).map(
            (_key, i) =>
              new Promise((resolve) => {
                const reader      = new FileReader();
                const currentFile = files[i];

                reader.onload = () => {
                  resolve({
                    uri: reader.result,
                    type: currentFile.type,
                    name: currentFile.name,
                    size: currentFile.size
                  });
                };
                reader.readAsDataURL(currentFile);
              })
          )
        ).then(result => {
          setPickedFiles(result)
        })

        return;
      }

      let filesToUp = [];

      for (let fileToUp of file.assets){
        filesToUp.push({
          uri: fileToUp.uri,
          type: fileToUp.mimeType,
          name: fileToUp.name,
          size: fileToUp.size
        })
      }

      setPickedFiles([...filesToUp, ...pickedFiles])
    };

    function saveDownloadHistory(attachmentId){
      return apiClient.post(`downloads`, {attachmentId})
    }

    return <ScrollView mx={4} key={id}>

      <SpacerMY space={3}/>

      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
        color="appPrimary"
        fontWeight={500}
        fontSize={22}
      >
        {title}
      </Text>

      <SpacerMY space={3}/>

      {
        !!reviewstepreply && reviewstepreply.status.name === "pending"
        && !(isReviewer(reviewers) || isTenderOwner) && action !== 'none' ? (
          <View borderWidth={1} borderRadius={8} borderColor="appSecondary" p={2}>
            <Text fontSize={19} color="appSecondary" textAlign="center">
              {translate('screens.reviewSteps.replyUnReview')}
            </Text>
          </View>
        ) : null
      }

      {
        !!reviewstepreply && reviewstepreply.status.name === "refused" && !(isReviewer(reviewers) || isTenderOwner) ? (
          <View borderWidth={1} borderRadius={8} borderColor="appSecondary" p={2}>
            <Text fontSize={19} color="appSecondary" textAlign="center">
              {translate('screens.reviewSteps.replyRefused')}
            </Text>
           <Text fontSize={19} color="appSecondary" textAlign="center">
              {translate('screens.reviewSteps.reason')}: {reviewstepreply.reason}
            </Text>
          </View>
        ) : null
      }

      <SpacerMY space={3}/>
      <Divider thickness="2" />
      <SpacerMY space={1}/>
      {
        !!reviewers ? (
          <View>
            <SectionTitle title={`${translate('screens.reviewSteps.reviewers')} : ${reviewers.length}`} />

            <HStack flexWrap="wrap" mt={2} >
              {
                reviewers.map((reviewer) => (
                  <Reviewer
                    key={reviewer.user.id}
                    name={reviewer.user.full_name}
                    avatar={reviewer.user.avatar}
                  />
                ))
              }
            </HStack>
          </View>
        ) : null
      }

      <SpacerMY space={2}/>
      <Divider thickness="2" />

      <SpacerMY space={2}/>

      <Description
        text={description}
      />

      <SpacerMY space={3}/>
      {
        (!!action && !!reviewers) && action === 'files-upload' && !(isReviewer(reviewers) || isTenderOwner) && canSendReply ? (
          <View>
            <Divider thickness="2" />
            <FormControl mt={5}>
              <FormControl.Label _text={{ fontSize: 18 }} >
                {translate('screens.reviewSteps.uploadFiles')}
              </FormControl.Label>
              <Button
                variant="outline"
                leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" size="sm" />}
                onPress={pickDocument}
              >
                {translate('screens.reviewSteps.clickToUpload')}
              </Button>
            </FormControl>

            {
              pickedFiles.length > 0 ? (
                <View mt={2}>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500}>
                    {translate('misc.filesToUpload')}:
                  </Text>
                  {
                    pickedFiles.map((file, index) => (
                      <HStack alignItems="center" key={index}>
                        <TouchableOpacity onPress={() => {
                          setPickedFiles(pickedFiles.filter((_, fileIndex) => fileIndex !== index));
                        }}>
                          <Icon as={Ionicons} name="trash-bin-outline" size={5} color="appSecondary" />
                        </TouchableOpacity>
                        <View mx={1}/>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                          {file.name} - {formatBytes(file.size)}
                        </Text>
                      </HStack>
                    ))
                  }
                </View>
              ) : null
            }

            <SpacerMY space={2}/>
          </View>
        ) : null
      }
      {
        !!action && action === 'text' && !!reviewers
        && !isReviewer(reviewers) && !isTenderOwner
        && action !== 'none' && canSendReply ? (
          <View>
            <Divider thickness="2" />
            <FormControl mt={3}>
              <TextArea
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                placeholder={translate('screens.reviewSteps.reply')} h={150}
                rounded={10} size="xl" borderColor="gray.500"
                onChangeText={handleReplyText}
              />
            </FormControl>

            <SpacerMY space={2}/>
          </View>
        ) : null
      }
      <Divider thickness="2" />

      {
        action !== 'none' ? (
          <View mt={4}>
            <View>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={25} fontWeight={500}>
                {translate('screens.reviewSteps.reply')}
              </Text>
              <View height={1} backgroundColor="appPrimary" w={30}/>
            </View>

            {
              !!reviewstepreply ? (
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} fontSize={20} fontWeight={500}>
                  {translate('screens.reviewSteps.status')} : {getStatus(reviewstepreply.status.name)}
                </Text>
              ) : null
            }

            {
              !!reviewstepreply === false ? (
                <View mt={4}>
                  <View borderWidth={1} borderRadius={8} borderColor="appSecondary" p={2}>
                   <Text fontSize={19} color="appSecondary" textAlign="center">
                      {translate('screens.reviewSteps.noReply')}
                    </Text>
                  </View>
                </View>
              ) : null
            }

            {
              !!reviewstepreply && action === 'text' ? (
                <View mt={4}>
                  <Description
                    text={reviewstepreply.reply}
                  />
                </View>
              ) : null
            }

            {
              !!reviewstepreply && action === 'files-upload' && reviewstepreply.attachments.length > 0 ? (
                <View mt={4}>
                  <SectionTitle title={translate('screens.reviewSteps.documents')} />
                </View>
              ) : null
            }

            {
              !!reviewstepreply && action === 'files-upload' && reviewstepreply.attachments.length > 0 ? (

                reviewstepreply.attachments.map((file, index) => (
                  <View key={index} mt={2}>
                    <TouchableOpacity
                      onPress={async () => {
                        await saveDownloadHistory(file.id)
                        await WebBrowser.openBrowserAsync(encodeURI(file.path));
                      }}
                    >
                      <HStack alignItems="center">
                        <Icon as={MaterialCommunityIcons} name="folder-download-outline" size="md" color="appPrimary" />
                        <View mx={1}/>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} color="appPrimary">
                          {`${file.name} - ${formatBytes(file.size)}`}
                        </Text>
                      </HStack>
                    </TouchableOpacity>
                  </View>
                ))
              ) : null
            }

          </View>

        ) : null
      }

      <SpacerMY space={6}/>

      {
        !!reviewstepreply && (isReviewer(reviewers) || isTenderOwner) ? (
          <HStack flexWrap="wrap" justifyContent="space-evenly">
            <Button
              px={10}
              borderRadius={8}
              _text={{
                fontSize: 20
              }}
              onPress={() => {
                setShowAcceptModal(true)
                setCurrentStepReplyId(reviewstepreply.id)
              }}
            >
              {translate('screens.reviewSteps.accept')}
            </Button>
            <Button
              px={10}
              borderRadius={8}
              colorScheme="secondary"
              _text={{
                fontSize: 20
              }}
              onPress={() => {
                setShowRejectModal(true)
                setCurrentStepReplyId(reviewstepreply.id)
              }}
            >
              {translate('screens.reviewSteps.reject')}
            </Button>
          </HStack>
        ) : null
      }

      {
        !!reviewers && !isReviewer(reviewers) && !isTenderOwner && action !== 'none' && canSendReply ? (
          <Center mb={1} mt={10}>
            <Button
              w="85%"
              onPress={ () => {
                setFilesToUpload(pickedFiles)
                setCurrentStepReplyId(!!reviewstepreply ? reviewstepreply.id : null)
                setCurrentStepId(id)
                setIsUpdateReply(!!reviewstepreply);
                setConfirmModal(true)
              }}
              size="lg"
              color="appPrimary"
              borderRadius={8}
            >
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                {translate('screens.reviewSteps.send')}
              </Text>
            </Button>
          </Center>
        ) : null
      }

    </ScrollView>
  }

  const StepNumbers = ({ numbers, active }) => (
    <View flexDirection="row" justifyContent="space-evenly">
      {
        [...Array(numbers)].map((number, index) => (
          <View key={index} bg={ (index) === active ? "appPrimary" : "appAccent" } borderRadius={200} px={2}>
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="white" fontWeight={500} fontSize={24}> {index + 1} </Text>
          </View>
        ))
      }
    </View>
  )

  const [active, setActive] = useState(0);
  const [content, setContent] = useState([
    <ReviewStep
      index={0}
      id={0}
      title="title"
      description="description"
      action="action"
    />
  ]);

  function getReviewSteps(){
    apiClient.get(`offers/${offerId}/review-steps`).then((response) => {
      setSteps(response.data.data)
      setStepsContent(response.data.data, response.data.is_tender_owner)
      setCurrentStep(response.data.data)
    }).catch((error) => console.log(error.response.data))
  }

  function setCurrentStep(steps){

    let hasNoRefuses = false;

    for (let n = 0; n < steps.length; n++){
      if (!!steps[n].reviewstepreply && steps[n].reviewstepreply.status.name === 'refused'){
        setActive(n)
        break;
      }
      if (n === steps.length -1) hasNoRefuses = true;
    }

    let hasNoPending = false;

    if (hasNoRefuses){
      for (let n = 0; n < steps.length; n++){
        if (!!steps[n].reviewstepreply && steps[n].reviewstepreply.status.name === 'pending' && steps[n].reviewstepreply.status.name !== 'pending'){
          setActive(n)
          break;
        }
        if (n === steps.length -1) hasNoPending = true;
      }
    }

    let acceptedIndexes = [];

    if (hasNoRefuses && hasNoPending){
      for (let n = 0; n < steps.length; n++){
        if (!!steps[n].reviewstepreply && steps[n].reviewstepreply.status.name === 'accepted'){
          acceptedIndexes.push(n)
        }
      }

      if (acceptedIndexes.length !== 0){
        let lastIndex = acceptedIndexes.length -1;
        let maxStepsIndex = steps.length - 1;

        let nextStep = acceptedIndexes[lastIndex] + 1 > maxStepsIndex ? maxStepsIndex : acceptedIndexes[lastIndex] + 1;
        setActive(nextStep)
      }
    }

  }

  function stepAction(replyId, action){
    setIsSendingAction(true)
    apiClient.put(`offers/${offerId}/review-steps/${replyId}/action`, {
      reply: {
        status: action,
        reason: action === 'refused' ? reason : null ,
      }
    }).then((response) => {
      setIsSendingAction(false)

      if (response.data === 'offer accepted'){
        navigation.navigate('TenderClosed')
      }
      getReviewSteps()
      setShowRejectModal(false);
      setShowAcceptModal(false);
    }).catch((error) => console.log(error.response.data))
  }

  function sendReply(stepId){
    setIsSendingAction(true)

    let data = new FormData()

    if (!!reply){
      data.append('reply', reply)
    }
    if (filesToUpload.length > 0 && Platform.OS !== 'web'){
      for (let n = 0; n < filesToUpload.length; n++){
        data.append('files[]', filesToUpload[n])
      }
    }
    if (Platform.OS === 'web' && !!webFiles.current){
      for (let n = 0; n < webFiles.current.length; n++){
        data.append('files[]', webFiles.current[n])
      }
    }

    apiClient.post(`offers/${offerId}/review-steps/${stepId}/reply`, data, {isFormData: true})
      .then(() => {
        setIsSendingAction(false)
        getReviewSteps()
        setConfirmModal(false)
    }).catch((error) => console.log(error.response.data))
  }

  function updateReply(replyId){
    setIsSendingAction(true)

    let data = new FormData()

    if (!!reply){
      data.append('reply', reply)
    }
    if (filesToUpload.length > 0 && Platform.OS !== 'web'){
      for (let n = 0; n < filesToUpload.length; n++){
        data.append('files[]', filesToUpload[n])
      }
    }
    if (Platform.OS === 'web' && !!webFiles.current){
      for (let n = 0; n < webFiles.current.length; n++){
        data.append('files[]', webFiles.current[n])
      }
    }

    apiClient.post(`offers/${offerId}/review-steps/${replyId}`, data, {isFormData: true}).then(() => {
      setIsSendingAction(false)
      getReviewSteps()
      setConfirmModal(false)
    }).catch((error) => console.log(error.response.data))
  }

  function setStepsContent(reviewSteps, isTenderOwner){
    let reviewStepsContent = reviewSteps.map((step, index) => (
      <ReviewStep
        key={index}
        index={index}
        isTenderOwner={isTenderOwner}
        id={step.id}
        title={step.title}
        description={step.description}
        action={step.action}
        reviewers={step.reviewstepreviewers}
        reviewstepreply={step.reviewstepreply}
      />
    ))

    setContent(reviewStepsContent);
  }
  const handleReasonText = (text) => {
    setReason(text)
  }

  const handleReplyText = (text) => {
    setReply(text)
  }

  function isReviewer(reviewers){
    return reviewers.some(el => el.user_id.toString() === authId.toString());
  }

  function getStatus(statusName){
    if (statusName === 'pending'){
      return translate('screens.reviewSteps.statuses.pending')
    } else if (statusName === 'refused'){
      return translate('screens.reviewSteps.statuses.refused')
    } else if (statusName === 'accepted'){
      return translate('screens.reviewSteps.statuses.accepted')
    }
  }

  useEffect(() => {
    getReviewSteps()
  }, [])

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4}>

        <View mt={4}/>
        <Title title={translate('screens.reviewSteps.title')} />
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} mt={2}>
          {translate('screens.reviewSteps.note')}
        </Text>
        <SpacerMY space={6}/>

        <StepNumbers
          numbers={content.length} active={active}
        />

        <View mb={5}>
          <Stepper
            active={active}
            content={content}
            onBack={() => setActive((p) => p - 1)}
            onFinish={() => alert('Finish')}
            onNext={() => setActive((p) => p + 1)}
            showButton={false}
            stepStyle={{ width: 800, height: 0 }}
          />
        </View>

        <HStack mt={10} flexWrap="wrap" justifyContent="space-between">
          {
            active > 0 ? (
              <Button
                px={10}
                borderRadius={8}
                colorScheme="blueGray"
                _text={{
                  fontSize: 20
                }}
                onPress={() => setActive((p) => p - 1)}
              >
                {translate('screens.reviewSteps.back')}
              </Button>
            ) : null
          }
          {
            steps.length !== 0 && !!steps[active].reviewstepreply && steps[active].reviewstepreply.status.name === "accepted" && active !== content.length - 1 ? (
              <Button
                px={10}
                borderRadius={8}
                colorScheme="accent"
                _text={{
                  fontSize: 20
                }}
                onPress={() => setActive((p) => p + 1)}
              >
                {translate('screens.reviewSteps.next')}
              </Button>
            ) : null
          }
        </HStack>

        <View mb={20} />

        {/* Reject Modal */}
        <Center>
          <Modal avoidKeyboard size={["xl", "xl", "md"]} isOpen={showRejectModal} onClose={() => setShowRejectModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.reviewSteps.rejectStep')}
              </Modal.Header>
              <Modal.Body>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {translate('screens.reviewSteps.whyRejectStep')}
                </Text>

                <FormControl mt={3}>
                  <TextArea
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder="Reason" h={150}
                    rounded={10} size="xl" borderColor="gray.500"
                    onChangeText={handleReasonText}
                  />
                </FormControl>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowRejectModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={isSendingAction ? null : () => {
                    stepAction(currentStepReplyId, 'refused')
                  }}>
                    {
                      isSendingAction ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('screens.reviewSteps.reject')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Confirm Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={confirmModal} onClose={() => setConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.reviewSteps.sendingReply')}
              </Modal.Header>
              <Modal.Body>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {translate('screens.reviewSteps.confirmSendingReply')}
                </Text>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={isSendingAction ? null : () => {
                    if (isUpdateReply){
                      updateReply(currentStepReplyId)
                    } else {
                      sendReply(currentStepId)
                    }
                  }}>
                    {
                      isSendingAction ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('screens.reviewSteps.send')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Confirm Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showAcceptModal} onClose={() => setShowAcceptModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.reviewSteps.acceptReply')}
              </Modal.Header>
              <Modal.Body>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {translate('screens.reviewSteps.confirmAcceptReply')}
                </Text>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowAcceptModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={isSendingAction ? null : () => {
                    stepAction(currentStepReplyId, 'accepted')
                  }}>
                    {
                      isSendingAction ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('screens.reviewSteps.accept')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}
