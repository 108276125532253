
import {
  View, Text, Icon, ScrollView,
  useColorModeValue, Center, Input, FormControl, Button, Spinner
} from "native-base";
import React from "react";
import {FontAwesome} from "@expo/vector-icons";
import {localInfo, translate} from "../../utils/Translate";
import apiClient from "../../utils/apiClient";
import {Platform} from "react-native";

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ navigation }) => {

  const [errors, setErrors] = React.useState({});
  const [email, setEmail] = React.useState();
  const [isSending, setIsSending] = React.useState(false);

  function sendCode(){
    setIsSending(true)
    apiClient.post(`passwords/request-reset`, {email}).then(() => {
      navigation.navigate('ResetPage');
    }).catch(() => {
      setIsSending(false)
    })
  }

  const handleEmailInput = (text) => {
    setErrors({ ...errors,
      email: null
    });
    setEmail(text)
  }

  const validate = () => {

    if (!validateEmail(email)){
      setErrors({ ...errors,
        email: translate('validation.invalidEmail')
      });
      return false;
    }

    return true;
  };

  const validateEmail = (email) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    return reg.test(email)
  };

  const handleSend = () => {
    if (validate()){
      sendCode()
    }
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>

        <SpacerMY space={16}/>
        <Center>
          <Text
            fontSize={20} fontWeight={500}
          >
            {translate('screens.forgetPassword.emailPage.title')}
          </Text>

          <SpacerMY space={3}/>

          <Text
            textAlign="center"
            fontSize={18}
          >
            {translate('screens.forgetPassword.emailPage.description')}
          </Text>

          <SpacerMY space={6}/>

          <Icon color="black" as={<FontAwesome name="envelope-o"/>} size={32}/>

          <SpacerMY space={4}/>

          <FormControl mt={4} w="85%" isInvalid={'email' in errors}>
            <Input
              textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.auth.signIn.email')}
              rounded={10}
              size="2xl" borderColor="gray.500" pl={4} py={[4, 4, 2]}
              autoCapitalize="none"
              value={email}
              onChangeText={handleEmailInput}
            />
            {
              'email' in errors ? <FormControl.ErrorMessage>
                {errors.email}
              </FormControl.ErrorMessage> : null
            }
          </FormControl>

          <SpacerMY space={3}/>

          <Button
            w="85%"
            onPress={handleSend}
            size="lg"
            color="appPrimary"
            borderRadius={8}
          >
            {
              !isSending ? (
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                  {translate('misc.send')}
                </Text>
              ) : null
            }
            {
              isSending ? (
                <Spinner size="sm" color="white" accessibilityLabel="Loading Sign in" />
              ) : null
            }
          </Button>

          <SpacerMY space={3}/>

          <Text fontSize={18}>
            {translate('screens.forgetPassword.emailPage.rememberPassword')}
            <Text
              color="appPrimary"
              onPress={() => navigation.navigate('Sign-in')}
            >
              {` ${translate('misc.signIn')}`}
            </Text>
          </Text>

        </Center>



      </ScrollView>
    </View>
  );
}
