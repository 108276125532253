import React, {useState} from 'react';
import {Feather, MaterialIcons} from "@expo/vector-icons";

import {
  View, Text, Box, Center, Button, ScrollView,
  HStack, Avatar, Icon, Modal, useColorModeValue, Spinner
} from "native-base";

import { TouchableOpacity, Platform } from "react-native";
import apiClient from "../utils/apiClient";
import {localInfo, translate} from "../utils/Translate";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const Requirement = ({ title, content, key }) => {
  return <HStack key={key} px={4} py={2} justifyContent="space-between">
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={18}>
      {title}
    </Text>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={18} color="gray.400">
      {content}
    </Text>
  </HStack>
}

export default ({ route, navigation }) => {

  const { tenderId, avatar, offerData, tenderRequiredDocs, isPaidTender, price } = route.params

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const offerTotalPoints     = offerData.offerPointsTables.reduce((total, item) => item.total + total,0)
  const tenderRequiredPoints = offerData.offerPointsTables.reduce((total, item) => (item.requirement * item.points) + total,0)

  const result = offerTotalPoints < tenderRequiredPoints ? translate('screens.offerReview.failed')
    : translate('screens.offerReview.passed')

  const submitOffer = () => {
    if (isPaidTender){
      setShowConfirmModal(false);
      navigation.navigate("PaymentMethods", {price, offerData, tenderId})
      return;
    }

    setIsPublishing(true)

    const data = new FormData()
    data.append('data', JSON.stringify({
      ...offerData,
      isPaid: 0
    }))

    for (let n = 0; n < tenderRequiredDocs.length; n++){
      if (!!tenderRequiredDocs[n].file){
        data.append('docs[]', tenderRequiredDocs[n].file)
        data.append('docsNames[]', tenderRequiredDocs[n].name)
      }
    }

    apiClient.post(`tenders/${tenderId}/offers`, data, {isFormData: true}).then(() => {
      setIsPublishing(false)
      setShowConfirmModal(false);
      navigation.replace('OfferSent')
    }).catch((error) => console.log(error.response.data))
  }

  const handleSubmitOfferButton = () => {
    setShowConfirmModal(true);
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4}>

        <SpacerMY space={4}/>
        <Title title={translate('screens.offerReview.title')}/>

        <SpacerMY space={10}/>

        <Center>
          <Box bg="#F7F7F7" w="100%" borderRadius={10} shadow={4}>

            <HStack justifyContent="space-evenly" alignItems="center">
              <View/>
              <Avatar
                mt={-10}
                size="xl"
                source={{ uri: avatar }}
              />
              <TouchableOpacity>
                <Icon
                  onPress={() => navigation.goBack()}
                  as={<Feather name="edit" />} color="appSecondary" size={6}
                />
              </TouchableOpacity>
            </HStack>

            <SpacerMY space={4}/>

            {
              offerData.offerPointsTables.map((item, index) => (
                <Requirement
                  key={index}
                  title={`${item.item} (${item.offer})`}
                  content={`${item.total} ${translate('screens.offerReview.points')}`}
                />
              ))
            }

            <SpacerMY space={2}/>

            <View px={4}>
              <HStack>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={600} >
                  {translate('screens.offerReview.yourOfferPoints')}:
                </Text>
                <Text ml={2} fontSize={20} fontWeight={600} color="appPrimary" textAlign="center">
                  { offerTotalPoints }
                </Text>
              </HStack>

              <HStack>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={600} >
                  {translate('screens.offerReview.requiredPoints')}:
                </Text>
                <Text ml={2} fontSize={20} fontWeight={600} color="appPrimary" textAlign="center">
                  { tenderRequiredPoints }
                </Text>
              </HStack>

              <HStack>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={600} >
                  {translate('screens.offerReview.result')}:
                </Text>
                <Text
                  ml={2} fontSize={20} fontWeight={600} textAlign="center"
                  color={result === 'Passed' ? 'appPrimary' : 'appSecondary'}
                >
                  { result }
                </Text>
              </HStack>
            </View>

            <SpacerMY space={2}/>
          </Box>

        </Center>

        <View mt={50}>
          <Button
            borderRadius={10}
            onPress={handleSubmitOfferButton}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={22} fontWeight={500} color="primary.50">
              {
                isPaidTender ? (
                  <HStack alignItems="center">
                    <Icon color="white" as={<MaterialIcons name="payment"/>} size={7}/>
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="white" mx={2} fontSize={20} fontWeight={500}>
                      {translate('screens.offerReview.payAndSubmit')}
                    </Text>
                  </HStack>
                ) : translate('misc.submit')
              }
            </Text>
          </Button>
        </View>

        {/* Confirm Publish Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.offerReview.sendOffer')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  {
                    offerTotalPoints < tenderRequiredPoints
                      ? <View>
                          <Text textAlign="center" fontWeight={600} fontSize={26} color="appSecondary">
                            {translate('screens.offerReview.warning')}
                          </Text>
                          <Text mt={3} fontSize={20} textAlign="center">
                            {translate('screens.offerReview.minimumPoints')}
                          </Text>
                          <Text mt={3} fontSize={18} textAlign="center">
                            {translate('screens.offerReview.submitAnyway')}
                          </Text>
                        </View>
                      : <Text mt={3} fontSize={18} textAlign="center">
                        {translate('screens.offerReview.submitOffer')}
                      </Text>
                  }

                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={submitOffer} disabled={isPublishing}>
                    {
                      isPaidTender ? translate('screens.offerReview.payAndSubmit') :
                        isPublishing ? (
                          <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                        ) : translate('misc.submit')
                    }

                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}
