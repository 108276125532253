import {Box, Image, NativeBaseProvider, Pressable, Text, View} from "native-base";
import React, {useState, useEffect} from "react";
import axios from "axios";
import WebMapView from "react-native-web-maps";
import MapView, {Callout, Marker} from "react-native-maps";
import {Platform} from "react-native";
import {localInfo} from "../utils/Translate";

export default ({ onPress, isPreview, tenderCoordinate }) => {

  const initialRegionCoordinate = {
    latitude: isPreview ? tenderCoordinate.latitude : 36.46809620054313,
    longitude: isPreview ? tenderCoordinate.longitude : 3.0760182619064746
  }

  async function geocoder(lat, lng, language){

    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC5HxR2IAiiLhXIuCQxctsKq7AVp1CaGmI&address=${lat},${lng}&language=${language}`

    return new Promise((resolve, reject) => {
      axios.get(url).then(res => {
        let neighborhoodIndex;
        let administrativeIndex;

        for(let index in res.data.results){
          if (res.data.results[index].types.includes("neighborhood")){
            neighborhoodIndex = index
          }
          if (res.data.results[index].types.includes("administrative_area_level_1")){
            administrativeIndex = index
          }
        }

        let address = neighborhoodIndex !== undefined ?
          res.data.results[neighborhoodIndex].formatted_address
          :
          res.data.results[administrativeIndex].formatted_address

        resolve(address);
      })
    })

  }

  const WebMap = () => {

    const [marker, setMarker] = useState()
    const [webMapLatLng, setWebMapLatLng] = useState({ latitude: 36.46809620054313, longitude: 3.0760182619064746 })
    const [selectedAddress, setSelectedAddress] = useState("")

    let webSelectedLocation;
    let webMapViewRef;

    useEffect(() => {
      if (isPreview){
        setMarkerCoordinate(tenderCoordinate)
      }
    })

    function setMarkerCoordinate(latLng){

      let lat = isPreview ? latLng.latitude : latLng.lat()
      let lng = isPreview ? latLng.longitude : latLng.lng()

      geocoder(lat, lng, localInfo.local).then( (address) => {

        const coordinate = {
          latitude: lat,
          longitude: lng
        }

        setSelectedAddress(address)

        if (!marker){
          setMarker(coordinate)
        }

        onPress({ address, coordinate })
      })

    }

    return <View w="100%" h={500}>
      <WebMapView
        ref={mapView => { webMapViewRef = mapView }}
        defaultZoom={8}
        initialRegion={{
          latitude: isPreview ? initialRegionCoordinate.latitude : webMapLatLng.latitude,
          longitude: isPreview ? initialRegionCoordinate.longitude : webMapLatLng.longitude
        }}
        onPress={isPreview ? () => null : (e) => setMarkerCoordinate(e.latLng) }
      >
        {
          // if state contains marker variable with a valid value, render the marker
          marker &&
          <WebMapView.Marker
            ref={marker => { webSelectedLocation = marker }}
            onPress={() => {
              webSelectedLocation.showCallout();
            }} coordinate={marker}>
            <WebMapView.Callout onPress={null}>
              <NativeBaseProvider>
                <View style={{ padding: 10 }}>
                  <Text textAlign="left">{selectedAddress}</Text>
                </View>
              </NativeBaseProvider>
            </WebMapView.Callout>
          </WebMapView.Marker>
        }
      </WebMapView>
    </View>
  }

  const MobileMap = () => {
    const [marker, setMarker] = useState()
    const [selectedAddress, setSelectedAddress] = useState("")

    useEffect(() => {
      if (isPreview){
        handleMobileMapPress(tenderCoordinate)
      }
    }, [])

    function handleMobileMapPress(coordinate){

      geocoder(coordinate.latitude, coordinate.longitude, localInfo.local).then( (address) => {

        setSelectedAddress(address)
        setMarker(coordinate)

        onPress({ address, coordinate })
      })
    }

    return <View w="100%">
      <MapView
        style={{ width: '100%', height: 400 }}
        onPress={isPreview ? () => null : (e) => handleMobileMapPress(e.nativeEvent.coordinate) }
        initialRegion={{
          ...initialRegionCoordinate,
          latitudeDelta: 0.9922,
          longitudeDelta: 0.4421,
        }}
      >
        {
          marker &&
          <Marker coordinate={marker} >
            <Callout>
              <View>
                <Text textAlign="left">
                  {selectedAddress}
                </Text>
              </View>
            </Callout>
          </Marker>
        }
      </MapView>
    </View>
  }

  const AppMap = () => Platform.OS === 'web' ? <WebMap/> : <MobileMap/>

  return <AppMap/>;

}
