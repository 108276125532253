import React, { useState, useEffect  } from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { useNavigationState, useNavigation } from '@react-navigation/native';

import FaIcon from 'react-native-vector-icons/Feather';

import theme from '../../constants/tab/Theme';
import SIZES from '../../constants/tab/Sizes';
import {ROUTES} from "../../constants/tab/Routes"

import {createBox} from "@shopify/restyle";
import {MaterialIcons} from "@expo/vector-icons";
import {Icon, View, Badge, useColorMode, useTheme} from "native-base";
import apiClient from "../../utils/apiClient";

const { NAVIGATION_BOTTOM_TABS_HEIGHT } = SIZES;
const Box = createBox();

function TabHandler({ tabs, tabWidth }) {

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const {colorMode} = useColorMode();
  const { colors } = useTheme();
  const isDark = colorMode === 'dark';

  useEffect(() => {
    getUnreadMessagesCount()

    const Interval = setInterval(() => getUnreadMessagesCount(), 30000)

    return function clean (){
      clearInterval(Interval);
    }

  }, [])

  function getUnreadMessagesCount(){
    apiClient.get('messages/unread').then((res) => {
      setUnreadMessagesCount(res.data)
    })
  }

  const routeName  = null;
  const navigation = useNavigation();
  const index      = useNavigationState(state => state);

  let currentIndex = 0;

  if (index.routes[0].state !== undefined){
    currentIndex = index.routes[0].state.index
  }

  function getIcon(name, key) {
    switch (key) {
      case 4: {

        return <Icon as={<MaterialIcons name="home" />}
                     color={currentIndex === 0 ? "appPrimary" : isDark ? 'white' : "appAccent"} />;
      }
      case 3: {

        return <View>
          {
            unreadMessagesCount !== 0 ? (
              <Badge
                colorScheme="secondary" rounded="full" mb={-4} mr={-4} zIndex={1}
                variant="solid" alignSelf="flex-end" _text={{
                fontSize: 11
              }}>
                {unreadMessagesCount}
              </Badge>
            ) : null
          }
          <Icon as={<MaterialIcons name="mail" />}
                color={currentIndex === 1 ? "appPrimary" : isDark ? 'white' : "appAccent"} />
        </View>;
      }
      case 1: {
        return <Icon as={<MaterialIcons name="article" />}
                     color={currentIndex === 3 ? "appPrimary" : isDark ? 'white' : "appAccent"} />;

      }
      case 0: {
        return <Icon as={<MaterialIcons name="help" />}
                     color={currentIndex === 4 ? "appPrimary" : isDark ? 'white' : "appAccent"} />;
      }
      default:
        break;
    }

    return null;
  }

  function getText(name) {
    if (name === routeName) {
      return <Text textAlign="left" style={{ color: theme.colors.main }}>{name}</Text>;
    }

    return <Text style={{ color: isDark ? theme.colors.white : theme.colors.black }} textAlign="left">{name}</Text>;
  }

  return (
    <Box flexDirection="row-reverse">
      {tabs.map((tab, key) => {
        if (key === Math.floor(tabs.length / 2)) {
          return (
            <TouchableOpacity
              style={{ top: -2 }}
              key={key} onPress={() => navigation.navigate('PublishTenderInfo')}
            >
              <Box
                width={tabWidth}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height={NAVIGATION_BOTTOM_TABS_HEIGHT}
              >
                <TouchableOpacity
                  onPress={() => navigation.navigate('PublishTenderInfo')}
                  position="absolute"
                  style={{
                    top: -NAVIGATION_BOTTOM_TABS_HEIGHT / 2,
                    backgroundColor: colors.appPrimary,
                    borderRadius: 100,
                    padding: 12,
                    marginTop: -2
                  }}>
                  <Icon
                    as={<FaIcon name="plus" />}
                    size={8} color="primary.50"
                  />
                </TouchableOpacity>
              </Box>
            </TouchableOpacity>

          );
        }

        return (
          <TouchableOpacity key={key} {...{ key }} onPress={tab.action}>
            <Box
              key={key}
              width={tabWidth}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height={NAVIGATION_BOTTOM_TABS_HEIGHT}>
              {getIcon(tab.name, key)}
              {getText(tab.name)}
            </Box>
          </TouchableOpacity>
        );
      })}
    </Box>
  );
}

export default TabHandler;
