import React, {useEffect, useState} from 'react';
import { MaterialIcons, MaterialCommunityIcons, Feather } from "@expo/vector-icons";
import * as WebBrowser from 'expo-web-browser';

import TenderModel from "../../../models/TenderModel";
import apiClient from "../../../utils/apiClient";

import {
  View, Text, Icon, Center, ScrollView, HStack, useColorMode,
} from "native-base";
import { TouchableOpacity } from "react-native";
import {formatBytes} from "../../../utils/StringTool";
import {localInfo, translate} from "../../../utils/Translate";
import JournalTenderSkeleton from "../../../components/skeleton/JournalTenderSkeleton";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}

const SectionTitle = ({ title, suffix }) => {
  return <View>
    <HStack alignContent="center">
      <Text textAlign="left" fontSize={28} fontWeight={500}>
        {title}
      </Text>
      {
        !!suffix ? <Text textAlign="left" ml={2} mt={2} fontSize={18} fontWeight={400}>
          ({suffix})
        </Text> : null
      }
    </HStack>
    <View height={1} backgroundColor="appPrimary" w={60}/>

  </View>
}

export default ({ route, navigation }) => {

  const { journalTenderId } = route.params;

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const [tender, setTender] = useState(TenderModel[0]);

  useEffect(() => {
    getTender()
  }, []);

  function getTender(){
    apiClient.get(`journal/${journalTenderId}`).then( response => {
      setTender(response.data)
    }).catch(error => console.log(error));
  }

  function getFormattedDate(date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  function saveDownloadHistory(attachmentId){
    return apiClient.post(`downloads`, {attachmentId})
  }

  if (!tender.title){
    return (
      <JournalTenderSkeleton/>
    )
  }

  return (
    <View
      bg={isDark ? "coolGray.800" : "white"}
      px={3} h="100%"
    >

      <ScrollView>

        <View mt={10}>
          <Title
            title={tender.title} />
        </View>

        <View mt={10} flexDirection="row" justifyContent="space-between">
          <SectionTitle title={translate('screens.tender.description')} />

          <Center h={9} px={2} flexDirection="row" justifyContent="space-between" bg="appAccent" borderRadius={8}>
            <Icon color="primary.50" as={<Feather name="eye" />} size={5} />
            <Text textAlign="left" fontWeight={500} color="primary.50" mx={1}>
              {tender.views_count}
            </Text>
            <Text textAlign="left" fontWeight={500} color="primary.50">
              {translate('screens.tender.views')}
            </Text>
          </Center>
        </View>

        <HStack mt={4}>

          <View maxW="60%" mt={1} px={2} py={1} bg="appAccent" borderRadius={20} alignSelf="flex-start">
            <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
              {tender.branch.name[localInfo.local]}
            </Text>
          </View>

          <HStack w="40%" alignItems="center" justifyContent="center">
            <Icon w="18%" as={<MaterialIcons name="location-pin"/>} size={6} ml="2"/>
            <Text w="82%" textAlign="left" fontWeight={500} fontSize={18}>
              {tender.state.name[localInfo.local]}
            </Text>
          </HStack>

        </HStack>

        <Text textAlign="left" mt={4} fontSize={20}>
          {tender.content}
        </Text>

        {
          !!tender.attachments && tender.attachments.length > 0 ? (
            <View mt={4} mb={6}>
              <SectionTitle title={translate('screens.tender.documents')} />

              {
                tender.attachments.map((file, index) => (
                  <View key={index} mt={2}>
                    <TouchableOpacity
                      onPress={async () => {
                        await saveDownloadHistory(file.id)
                        await WebBrowser.openBrowserAsync(encodeURI(file.path));
                      }}
                    >
                      <HStack alignItems="center">
                        <Icon as={MaterialCommunityIcons} name="folder-download-outline" size="md" color="appPrimary" />
                        <View mx={1}/>
                        <Text textAlign="left" fontSize={16} color="appPrimary">
                          {`${file.name} - ${formatBytes(file.size)}`}
                        </Text>
                      </HStack>
                    </TouchableOpacity>
                  </View>
                ))
              }
            </View>
          ) : null
        }

        <View mt={48} />

      </ScrollView>
    </View>
  );
}
