import React from "react";

import {
  Box, useColorModeValue, View,
  Center, HStack, Skeleton
} from "native-base";
import {Dimensions} from "react-native";

export default () => {
  const width = Dimensions.get('screen').width

  return (
    <Box
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      borderRadius={8} shadow={3} mt={3} ml={2} mb={1} pb={2}
      width={[width * 0.95, width * 0.95, width * 0.35]}
    >
      <View mt={5}/>

        <View>
          <HStack justifyContent="space-between" px={2}>
            <View maxW="50%" px={2} borderRadius={20} alignSelf="flex-start">
              <Skeleton.Text lines={1} w={20} />
            </View>
            <View maxW="50%">
              <Center flexDirection="row">
                <Skeleton.Text lines={1} />
              </Center>
            </View>
          </HStack>

          <HStack mt={7} justifyContent="space-between" px={2}>
            <View maxW="50%" px={2} borderRadius={20} alignSelf="flex-start">
              <Skeleton.Text lines={1} w={20} />
            </View>
            <View maxW="50%">
              <Center flexDirection="row">
                <Skeleton.Text lines={1} />
              </Center>
            </View>
          </HStack>
        </View>

      <View mb={5}/>

    </Box>
  )
}
