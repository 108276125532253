import {Button, Center, Icon, Text, useColorModeValue} from "native-base";
import React from "react";
import {useNavigation} from "@react-navigation/native";
import FaIcon from "react-native-vector-icons/Feather";
import {translate} from "../utils/Translate";

export default () => {
  const navigation = useNavigation();

  return (
    <Center
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      py={4} mt={10} borderRadius={8}
    >
      <Text textAlign="center" fontSize={20}>
        {translate('components.userNoGroupsYet.noGroups')}
      </Text>

      <Button
        w="85%" mt={4} size="lg" color="appPrimary" borderRadius={8}
        leftIcon={<Icon
          as={<FaIcon name="plus" />}
          size={5} color="primary.50"
        />}
        onPress={() => navigation.navigate("GroupInfo")}
      >
        <Text fontSize="lg" color="primary.50" fontWeight="700">
          {translate('components.userNoGroupsYet.createGroup')}
        </Text>
      </Button>

    </Center>
  )
}
