import React, {useState, useEffect} from 'react';

import {
  View, Text, Center, Button, ScrollView,
  Input, FormControl, Modal, HStack, Icon, useColorModeValue, Checkbox, useTheme
} from "native-base";
import {Cell, Row, Table, TableWrapper} from "react-native-table-component-2";
import {StyleSheet, TouchableOpacity} from "react-native";
import {Ionicons} from "@expo/vector-icons";
import {AuthContext} from "../../utils/context/auth";
import {localInfo, translate} from "../../utils/Translate";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>
      {title}
    </Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const tableHead = [
  translate('screens.tender.pointsTableHeader.item'),
  translate('screens.tender.pointsTableHeader.requirement'),
  translate('screens.tender.pointsTableHeader.points'),
  translate('screens.tender.pointsTableHeader.offer'),
  translate('screens.tender.pointsTableHeader.total')
]

const widthArr  = [150, 150, 150, 150, 150]

export default ({ route, navigation }) => {

  const { tenderData } = route.params

  const { isCompany } = React.useContext(AuthContext);
  const { colors }    = useTheme();

  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [pointsTableData, setPointsTableData]   = useState([
    ['', '', '', '', '']
  ]);

  let tableRow = [];
  const [itemName, setItemName] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [itemPoint, setItemPoint] = useState("");
  const [maxPoints, setMaxPoints] = React.useState("");
  const [inputErrors, setInputErrors] = React.useState({});

  const handleItemName   = (name) => {
  if ('itemName' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.itemName
      setInputErrors(errors);
    }

    setItemName(name)
  }
  const handleItemNumber = (number) => {
    if ('itemNumber' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.itemNumber
      setInputErrors(errors);
    }

    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;
    setItemNumber(number.replace(regex, ''))
  }
  const handleItemPoints = (points) => {
    if ('itemPoint' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.itemPoint
      setInputErrors(errors);
    }

    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;
    setItemPoint(points.replace(regex, ''))
  }
  const handleMaxPoints = (points) => {
    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;
    setMaxPoints(points.replace(regex, ''))
  }

  const validate = () => {
    if (itemName.length === 0) {
      setInputErrors({ ...inputErrors,
        itemName: translate('screens.publishTender.pointsTable.validation.itemName')
      });
      return false;
    } else if (itemNumber.length === 0) {
      setInputErrors({ ...inputErrors,
        itemNumber: translate('screens.publishTender.pointsTable.validation.itemNumber')
      });
      return false;
    } else if (itemPoint.length === 0) {
      setInputErrors({ ...inputErrors,
        itemPoint: translate('screens.publishTender.pointsTable.validation.itemPoint')
      });
      return false;
    }

    return true;
  };

  const handleAddItem = () => {

    if (!validate()) return;

    tableRow[0] = itemName;
    tableRow[1] = itemNumber;
    tableRow[2] = itemPoint;
    tableRow[3] = '';
    tableRow[4] = '';
    tableRow[5] = maxPoints;

    setPointsTableData([tableRow, ...pointsTableData])

    setShowAddItemModal(false);
    setItemName('')
    setItemNumber('')
    setItemPoint('')
    setMaxPoints('')
  }

  const handleNextButton = () => {
    tenderData.pointTables = [];
    saveCurrentTable()

    if (pointsTableData.length > 1){
      for (let i = 0; i < pointsTableData.length-1; i++){
        tenderData.pointTables.push({
          item: pointsTableData[i][0],
          requirement: pointsTableData[i][1],
          points: pointsTableData[i][2],
          offer: pointsTableData[i][3],
          total: pointsTableData[i][4],
          maxPoints: pointsTableData[i][5]
        })
      }
    }

    let nextRoute;

    if (tenderData.criteria.hasPriceTable){
      nextRoute = 'PublishTenderPriceTable';
    } else {
      if (isCompany){
        nextRoute = 'PublishTenderReviewSteps';
      } else {
        navigation.navigate('Tender', { previewData: tenderData })
        return;
      }
    }

    return navigation.navigate(nextRoute, {tenderData})
  }

  function saveCurrentTable(){
    if (pointsTableData.length > 1){
      tenderData.pointTablePreview = pointsTableData;
    }
  }

  useEffect(() => {
    if (tenderData.pointTablePreview.length !== 0){
      setPointsTableData(tenderData.pointTablePreview)
    }
  }, [])

  const AddItemButton = (data, index) => {
    // save current table when add new row
    saveCurrentTable()

    return (
      (
        <View m={3}>
          <Button borderRadius={12} onPress={() => setShowAddItemModal(true)}>
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={17} color="white">
              {translate('screens.publishTender.pointsTable.addItem')}
            </Text>
          </Button>
        </View>
      )
    )
  };

  const itemNameWithDeleteButton = (data, index) => {
    return (
      <HStack m={3} justifyContent="center">
        <View mr={2}>
          <TouchableOpacity onPress={() => {
            setPointsTableData(pointsTableData.filter((row, rowIndex) => rowIndex !== index));
          }}>
            <Icon as={Ionicons} name="trash-bin-outline" size={7} color="appSecondary" />
          </TouchableOpacity>
        </View>
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} justifyContent="flex-start" fontWeight={600} fontSize={18}>
          {data}
        </Text>
      </HStack>
    )
  };

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4} contentContainerStyle={styles.contentContainerStyle}>

        <View>
          <View mt={4}/>
          <Title title={translate('screens.publishTender.title')} />
          <SpacerMY space={3}/>

          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={26}>
            {translate('screens.publishTender.pointsTable.title')}
          </Text>
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
            {translate('screens.publishTender.pointsTable.description')}
          </Text>

          <View mt={5}>
            <ScrollView horizontal={true}>
              <View style={styles.container}>
                <Table style={{  }} borderStyle={{ borderWidth: 2, borderColor: colors.appPrimary }}>
                  <Row widthArr={widthArr} data={tableHead} style={styles.head} textStyle={styles.headText} />

                  {pointsTableData.map((rowData, index) => (
                    <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                      {rowData.map((cellData, cellIndex) => (
                        cellIndex !== 5 ? <Cell
                          width={150}
                          key={cellIndex}
                          data={
                            (index === pointsTableData.length - 1 && cellIndex === 0)
                              ?
                              AddItemButton(cellData, cellIndex)
                              :
                              cellIndex === 0
                                ?
                                itemNameWithDeleteButton(cellData, index)
                                : cellData
                          }
                          textStyle={styles.text}
                        /> : null
                      ))}
                    </TableWrapper>
                  ))}

                </Table>
              </View>
            </ScrollView>
          </View>


          {/* Add Item Modal */}
          <Center>
            <Modal avoidKeyboard size={["xl", "xl", "md"]} isOpen={showAddItemModal} onClose={() => setShowAddItemModal(false)}>
              <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>
                  {translate('screens.publishTender.pointsTable.addToTable')}
                </Modal.Header>
                <Modal.Body>
                  <FormControl mt={4} isInvalid={'itemName' in inputErrors}>
                    <Input
                      textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                      placeholder={translate('screens.publishTender.pointsTable.placeholder.itemName')}
                      rounded={10}
                      size="xl" borderColor="gray.500" pl={4} py={3}
                      onChangeText={handleItemName}
                      value={itemName}
                    />
                    {
                    'itemName' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.itemName}
                      </FormControl.ErrorMessage>
                    ) : null
                    }
                  </FormControl>

                  <FormControl mt={4} isInvalid={'itemNumber' in inputErrors}>
                    <Input
                      textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                      placeholder={translate('screens.publishTender.pointsTable.placeholder.requirementNumber')}
                      rounded={10}
                      size="xl" borderColor="gray.500" pl={4} py={3}
                      onChangeText={handleItemNumber}
                      keyboardType="number-pad"
                      value={itemNumber}
                    />
                    {
                    'itemNumber' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.itemNumber}
                      </FormControl.ErrorMessage>
                    ) : null
                    }
                  </FormControl>

                  <FormControl mt={4} isInvalid={'itemPoint' in inputErrors}>
                    <Input
                      textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                      placeholder={translate('screens.publishTender.pointsTable.placeholder.numberOfPoints')}
                      rounded={10}
                      size="xl" borderColor="gray.500" pl={4} py={3}
                      onChangeText={handleItemPoints}
                      keyboardType="number-pad"
                      value={itemPoint}
                    />
                    {
                    'itemPoint' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.itemPoint}
                      </FormControl.ErrorMessage>
                    ) : null
                    }
                  </FormControl>

                  <FormControl mt={4}>
                    <Input
                      textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                      placeholder={translate('screens.publishTender.pointsTable.placeholder.maxPoints')}
                      rounded={10}
                      size="xl" borderColor="gray.500" pl={4} py={3}
                      onChangeText={handleMaxPoints}
                      keyboardType="number-pad"
                      value={maxPoints}
                    />
                  </FormControl>

                </Modal.Body>
                <Modal.Footer>
                  <Button.Group space={2}>
                    <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                      setShowAddItemModal(false);
                      setItemName('')
                      setItemNumber('')
                      setItemPoint('')
                    }}>
                      {translate('misc.cancel')}
                    </Button>
                    <Button onPress={handleAddItem}>
                      {translate('misc.add')}
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </Center>
        </View>

        <Center mb={16} mt={10}>
          <Button
            w="85%"
            isDisabled={pointsTableData.length < 2}
            size="lg" color="appPrimary" borderRadius={8}
            onPress={ handleNextButton }
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.next')}
            </Text>
          </Button>
        </Center>

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    justifyContent: "space-between",
    flexGrow: 1
  },
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center', paddingVertical: 10
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
