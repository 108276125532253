import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, {useEffect, useState} from 'react';
import {TouchableOpacity, Platform} from 'react-native';
import {Ionicons, MaterialIcons, AntDesign} from "@expo/vector-icons";

// import Tender from "../../Tender";
import Journal from "./Journal";
import JournalTender from "./JournalTender";

import {
  Icon, Pressable, View, HStack, Button, Badge, useColorMode, Text, useTheme
} from "native-base";
import {localInfo, translate} from "../../../utils/Translate";
import apiClient from "../../../utils/apiClient";
import {AuthContext} from "../../../utils/context/auth";
import theme from "../../../constants/tab/Theme";

const Stack  = createNativeStackNavigator();

export default function StackNavigation({ navigation }) {

  const { isCompany, hasPermissionTo } = React.useContext(AuthContext);

  const [unreadCount, setUnreadCount] = useState(0);
  const [canPublishTender, setCanPublishTender] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const {colorMode} = useColorMode();
  const { colors }  = useTheme();

  const isDark = colorMode === 'dark';

  function getUnreadCount(){
    apiClient.get(`users/1/notifications/unread/count`).then( response => {
      setUnreadCount(response.data)
    }).catch(error => console.log(error.response.data));
  }
  function getUnreadMessagesCount(){
    apiClient.get('messages/unread').then((res) => {
      setUnreadMessagesCount(res.data)
    })
  }

  useEffect(() => {
    // (async() => {
    //   const canManageMembers = await hasPermissionTo('publish tenders')
    //
    //   setCanPublishTender(canManageMembers)
    // })()
    //
    // getUnreadCount()
    // getUnreadMessagesCount()
    //
    // const Interval = setInterval(() => {
    //   getUnreadCount()
    //   getUnreadMessagesCount()
    // }, 30000)
    //
    // return function clean (){
    //   clearInterval(Interval);
    // }
  }, [])

  const isWebPlatform = Platform.OS === 'web';

  const headerLeft = () => {
    return <TouchableOpacity onPress={() => navigation.openDrawer()}>
      <Icon as={<MaterialIcons name="menu" />} color={isWebPlatform ? "#fff" : isDark ? theme.colors.white : theme.colors.dark} />
    </TouchableOpacity>
  }

  const WebMenu = () => {

    const Menu = () => {
      return <HStack>
        <Button
          colorScheme="appWhite"
          leftIcon={<Icon as={<MaterialIcons name="home" />} size={6} />}
          variant="ghost"
          onPress={() => navigation.navigate('Home')}
        >
          {translate('stack.webMenu.home')}
        </Button>
        {
          !isCompany ? (
            <Button
              colorScheme="appWhite"
              leftIcon={<Icon as={<AntDesign name="pluscircle" />} size={6} />}
              variant="ghost"
              onPress={() => navigation.navigate('PublishTenderInfo')}
            >
              {translate('stack.webMenu.createTender')}
            </Button>
          ) : null
        }
        {
          isCompany && canPublishTender ? (
            <Button
              colorScheme="appWhite"
              leftIcon={<Icon as={<AntDesign name="pluscircle" />} size={6} />}
              variant="ghost"
              onPress={() => navigation.navigate('PublishTenderInfo')}
            >
              {translate('stack.webMenu.createTender')}
            </Button>
          ) : null
        }
        <View>
          {
            unreadMessagesCount !== 0 ? (
              <Badge
                colorScheme="secondary" rounded="full" mb={-4} mr={-2} zIndex={1}
                variant="solid" alignSelf="flex-end"
                _text={{
                  fontSize: 11
                }}
              >
                {unreadMessagesCount}
              </Badge>
            ) : null
          }
          <Button
            colorScheme="appWhite"
            variant="ghost"
            leftIcon={<Icon as={<MaterialIcons name="mail" />} size={6} />}
            onPress={() => navigation.navigate('Inbox')}
          >
            {translate('stack.webMenu.inbox')}
          </Button>
        </View>
        <Button
          colorScheme="appWhite"
          leftIcon={<Icon as={<MaterialIcons name="article" />} size={6} />}
          variant="ghost"
          onPress={() => navigation.navigate('Articles')}
        >
          {translate('stack.webMenu.articles')}
        </Button>
        <Button
          colorScheme="appWhite"
          leftIcon={<Icon as={<MaterialIcons name="help" />} size={6} />}
          variant="ghost"
          onPress={() => navigation.navigate('Help')}
        >
          {translate('stack.webMenu.help')}
        </Button>
      </HStack>
    }

    return isWebPlatform ? <Menu/> : <></> ;
  }

  const headerRight = () => {
    return <HStack>

      <WebMenu/>

      <View mr={isWebPlatform ? 5 : 0}>
        {
          unreadCount !== 0 ? (
            <Badge
              colorScheme="secondary" rounded="full" mb={-4} mr={-2} zIndex={1}
              variant="solid" alignSelf="flex-end" _text={{
              fontSize: 11
            }}>
              <Text color="white">
                {unreadCount}
              </Text>
            </Badge>
          ) : null
        }
        <TouchableOpacity onPress={() => navigation.navigate('Notifications')}>
          <Icon as={<MaterialIcons name="notifications-none" />} color={isWebPlatform ? "#fff" : isDark ? theme.colors.white : theme.colors.dark} />
        </TouchableOpacity>
      </View>
    </HStack>
  }

  const backButton = () => {
    let iconName =  localInfo.isRTL ? "chevron-forward-outline" : "chevron-back-outline";

    return <Pressable onPress={() => navigation.goBack()}>
      <Icon as={<Ionicons name={iconName} />} size={8} color={isWebPlatform ? "#fff" : isDark ? theme.colors.white : theme.colors.dark} />
    </Pressable>
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle:{
          backgroundColor: isWebPlatform ? colors.appPrimary : isDark ? theme.colors.dark : theme.colors.white
        },
        title: '',
        headerLeft,
        headerRight
      }}
    >
      <Stack.Screen name="Journal" component={Journal} options={{ headerShown: true, headerRight: () => <View/> }} />
      <Stack.Screen name="JournalTender" component={JournalTender} options={{ headerLeft: backButton, headerRight: () => <View/> }} />
    </Stack.Navigator>
  );
}
