import React from "react";
import {Box, Text, View, Pressable, useColorModeValue, Center, Icon, HStack} from "native-base";
import {useNavigation} from "@react-navigation/native";
import {shorten} from "../utils/StringTool";
import {MaterialIcons} from "@expo/vector-icons";

export default (props) => {
  const navigation = useNavigation();

  return (
    <Pressable
      width={["100%", "100%", "33%"]}
      onPress={() => navigation.navigate('JournalTender', {journalTenderId: props.id})}
    >
      <Box
        bg={useColorModeValue("#F8F8F8", "coolGray.700")}
        borderRadius={8} shadow={3} mt={3} ml={[0, 0, 3]}
      >
        <View
          minH={["100", "100", "288"]}
          justifyContent="space-between"
          _dark={{ bg: "coolGray.700"}} m={2}
        >
          <View>
            <Text textAlign="left" fontSize={24} fontWeight={500}>
              {shorten(props.title, 50)}
            </Text>
            <HStack>
              <View
                maxW="60%"
                bg={useColorModeValue("appAccent", "appAccentDark")}
                mt={1} px={2} borderRadius={20} alignSelf="flex-start"
              >
                <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                  {shorten(props.branch, 30)}
                </Text>
              </View>
              <Center maxW="40%" flexDirection="row">
                <Icon as={<MaterialIcons name="location-pin"/>} size={6} ml="2"/>
                <Text textAlign="left" fontWeight={500} fontSize={16}>
                  {props.state}
                </Text>
              </Center>
            </HStack>

            <Text textAlign="left" mt={2} fontSize={17}>
              {shorten(props.description, 100)}
            </Text>
          </View>


        </View>
      </Box>
    </Pressable>
  )
}
