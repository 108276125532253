import {Button, Text, Modal} from "native-base";
import React, {useEffect, useState} from "react";
import {localInfo, translate} from "../utils/Translate";

import apiClient from "../utils/apiClient";

export default ({isOpen, onClose}) => {

  const [showModal, setShowModal] = useState(false)
  const [Privacy, setPrivacy] = useState('App Privacy')

  function getPrivacy(){
    apiClient.get(`privacy`).then( (res) => {
      setPrivacy(res.data.content[localInfo.local])
    }).catch(error => {
      console.log(error)
    });
  }

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen])

  useEffect(() => {
    getPrivacy()
  }, [])

  return (
    <Modal size={["xl", "xl", "lg"]} isOpen={showModal}>
      <Modal.Content maxWidth="100%">
        <Modal.Header>
          <Text textAlign="left" fontSize={20} fontWeight={500}>
            {translate('screens.auth.index.privacyPolicy')}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text textAlign="left" fontSize={17}>
            {Privacy}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button onPress={() => {
              onClose()
              setShowModal(false)
            }}>
              {translate('misc.Ok')}
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
