
const OfferModel = {
  id: null,
  price: null,
  points: null,
  bid_amount: null,
  delivery_date: null,
  reason: null,
  tva: null,
  total_ht: null,
  total_tva: null,
  required_points: null,
  offerpointstables: null,
  offerpricetables: null,
  is_selected: null,
  can_select: false,
  company_id: null,
  created_at: null,
  user: null,
  company: null,
  currency: null,
  is_tender_manager: false,
  is_tender_owner: false,
  is_offer_owner: false,
  has_reviews: false,
}

export default OfferModel
