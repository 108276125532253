import {Avatar, Box, Center, Icon, Text, View, Pressable, useColorModeValue, useColorMode} from "native-base";
import React from "react";
import {AntDesign} from "@expo/vector-icons";
import { useNavigation } from '@react-navigation/native';
import {translate} from "../utils/Translate";

export default (props) => {
  const navigation = useNavigation();
  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const bestOffer = translate('components.offerCard.bestOffer');
  const selected  = translate('components.offerCard.selected');
  const accepted  = translate('components.offerCard.accepted');

  const Tag = () => !!props.isBestOffer || !!props.isSelectedOffer || !!props.isAcceptedOffer ?
   <Box bg={ !!props.isSelectedOffer ? isDark ? "appAccentDark" : "appAccent" : "appPrimary"} pt={4} p={2} borderTopLeftRadius={60} borderBottomRightRadius={8}>
    <Center>
      <Icon as={<AntDesign name="star"/>} color="white" size={5} />
      <Text textAlign="left" fontWeight={500} fontSize={18} color="white">
        { !!props.isBestOffer ? bestOffer : !!props.isSelectedOffer ? selected : !!props.isAcceptedOffer ? accepted : null }
      </Text>
    </Center>
  </Box> : <View p={9}/>

  return (
    <Pressable
      width={["100%", "100%", "33%"]}
      onPress={() => navigation.navigate('Offer', {offerId: props.id})}
    >
      <Box
        bg={useColorModeValue("#F7F7F7", "coolGray.700")}
        pl={3} pt={2} shadow={2} my={2} borderRadius={8}
        ml={[0, 0, 1]}
      >
        <View flexDirection="row">
          <Avatar size="lg" source={{ uri: props.avatar }} />
          <View ml={3}>
            <Text textAlign="left" fontWeight={600} fontSize={23}>
              {props.by !== "[deleted]" ? props.by : translate('misc.deletedUser')}
            </Text>
            {
              !!props.points ? (
                <View mt={2} bg={ props.points > props.tenderPoints ? 'appPrimary' : 'appSecondary'} borderRadius={200} px={2}>
                  <Text textAlign="left" color="white" fontWeight={500} fontSize={16}>
                    {translate('components.offerCard.points')}: {props.points} / {props.tenderPoints}
                  </Text>
                </View>
              ) : null
            }
          </View>
        </View>
        <Center mt={4} flexDirection="row" justifyContent="space-between">
          <View>
            {
              !!props.bidAmount ? (
                <Text textAlign="left" fontWeight={500} fontSize={16}>
                  {translate('components.offerCard.bidAmount')}: {props.bidAmount} {props.currency}
                </Text>
              ) : null
            }

            <Text textAlign="left" fontWeight={500} fontSize={16}>
              {translate('components.offerCard.submissionDate')}: {props.date}
            </Text>
          </View>

          <Tag/>
        </Center>
      </Box>
    </Pressable>
  )
}
