import { createDrawerNavigator, DrawerToggleButton } from '@react-navigation/drawer';
import React, {useEffect, useState} from 'react';
import DrawerContent from "../screens/DrawerContent"
import StackNavigation from "./Stack";
import GroupStackNavigation from "../screens/drawer/groupBuying/Stack";
import JournalStackNavigation from "../screens/drawer/journal/StackNavigation";
import { useWindowDimensions, Platform } from 'react-native';
import { MaterialIcons, Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import {getFocusedRouteNameFromRoute, useNavigation} from '@react-navigation/native';

import Statistics from "../screens/drawer/Statistics";
import Settings from "../screens/drawer/Settings";
import TeamManagement from "../screens/drawer/TeamManagement";
import SavedTenders from "../screens/drawer/SavedTenders";
import Tenders from "../screens/Tenders";
import OutgoingOffers from "../screens/drawer/OutgoingOffers";
import Downloads from "../screens/drawer/Downloads";
import Subscriptions from "../screens/drawer/Subscriptions";

import {AuthContext} from "../utils/context/auth";
import {translate} from "../utils/Translate";

import {Icon, useColorMode, View, HStack, Text, useTheme} from "native-base";

const Drawer = createDrawerNavigator();


export default function DrawerNavigation() {
  const navigation    = useNavigation();
  const dimensions    = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;
  const isSmallScreen = dimensions.width < 768;
  const {colorMode}   = useColorMode();
  const isDark        = colorMode === 'dark';
  const isWebPlatform = Platform.OS === 'web';

  const { authUser, isCompany, hasPermissionTo } = React.useContext(AuthContext);
  const [userId, setUserId] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [canManageTeam, setCanManageTeam] = useState(false);
  const [canAccessStatistics, setCanAccessStatistics] = useState(false);
  const [canManageGroupBuying, setCanManageGroupBuying] = useState(false);

  const { colors } = useTheme();

  const drawerScreenOption = {
    gestureEnabled: false,
    overlayColor: 'transparent',
    drawerLabelStyle: {
      marginLeft: -25, fontSize: isWebPlatform ? 15 : 17,
      fontWeight: "bold", ...( isDark && {color: "white"})
    },
    drawerActiveTintColor: 'rgba(70, 85, 247, 1)',
    drawerInactiveTintColor: '#333',
    headerTitle: '',
    headerTintColor: '#FFFFFF',
    headerBackground: () => (
      <View bg={isCompany ? colors.appPrimary : colors.particular} w="100%" h="100%" />
    ),
  }

  useEffect(() => {
    (async() => {
      const user = await authUser()
      const canManageMembers     = await hasPermissionTo('manage members')
      const canSeeStatistics     = await hasPermissionTo('access statistics')
      const canAccessGroupBuying = await hasPermissionTo('manage group buying')

      setCanManageTeam(canManageMembers)
      setCanAccessStatistics(canSeeStatistics)
      setCanManageGroupBuying(canAccessGroupBuying)
      setUserId(user.id);
      if (isCompany){
        setCompanyId(user.company_id)
      }
    })()
  }, [])


  const YourTendersScreen = ({ userId, navigation }) => {
    return <Tenders navigation={navigation} authId={userId} myTenders={true}/>
  }

  const homeIcon = (color) => {
    return <Icon as={<MaterialIcons name="home"/>} size={6} ml="2" color={colors.appPrimary}/>
  }

  const plusIcon = (color) => {
    return <Icon as={<Feather name="plus-circle" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const peopleIcon = (color) => {
    return <Icon as={<MaterialIcons name="people-alt" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const archiveIcon = (color) => {
    return <Icon as={<MaterialCommunityIcons name="archive" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const bookmarkIcon = (color) => {
    return <Icon as={<MaterialIcons name="bookmark" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const paperPlaneIcon = (color) => {
    return <Icon as={<Ionicons name="paper-plane" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const filesIcon = (color) => {
    return <Icon as={<MaterialCommunityIcons name="file-download" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const chartIcon = (color) => {
    return <Icon as={<Feather name="bar-chart-2" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const settingsIcon = (color) => {
    return <Icon as={<MaterialIcons name="settings" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const subscriptionsIcon = (color) => {
    return <Icon as={<MaterialIcons name="credit-card" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const cartIcon = (color) => {
    return <Icon as={<MaterialIcons name="shopping-cart" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const docIcon = (color) => {
    return <Icon as={<Ionicons name="document-text" />} size={6} ml="2" color={colors.appPrimary}/>
  }

  const LabelWithIcon = (label, icon) => {
    return (
      <HStack my={-1} direction={"row"}>
        {icon}
        <View mx={2}/>
        <Text fontSize={16} fontWeight={500}>
          {label}
        </Text>
      </HStack>
    )
  }

  return (
    <Drawer.Navigator
      backBehavior="none"
      drawerContent={(props) => <DrawerContent {...props} />}
      screenOptions={{...drawerScreenOption,
        drawerType: isLargeScreen && Platform.OS === 'web' ? 'permanent' : 'back',
        drawerStyle: {
          ...((Platform.OS === 'web' && isLargeScreen) && {width: "23%"})
        },
      }}
    >
      <Drawer.Screen
        name="app" component={StackNavigation}
        options={({ route }) => {
          const routeName = getFocusedRouteNameFromRoute(route) ?? 'Items'
          if (routeName == "Sign-up") {
            return ({swipeEnabled: false, headerShown: false, drawerStyle: {
                display: 'none',
              }})
          }else {
            return ({ drawerLabel: ({color}) => LabelWithIcon( translate('drawer.home'), homeIcon(color))  ,headerShown: false, })
          }
        }}
      />
      <Drawer.Screen
        name="GroupBuying" component={GroupStackNavigation}
        options={{ drawerLabel: ({color}) => LabelWithIcon( translate('drawer.groupBuying.title'), cartIcon(color)),headerShown: false }}
      />
      <Drawer.Screen
        name="JournalDrawer" component={JournalStackNavigation}
        options={{ drawerLabel: ({color}) => LabelWithIcon( translate('drawer.journal'), docIcon(color)), headerShown: false }}
      />
      <Drawer.Screen
        name="Subscriptions" component={Subscriptions}
        options={{ drawerLabel: ({color}) => LabelWithIcon( translate('drawer.subscriptions'), subscriptionsIcon(color)) }}
      />
      <Drawer.Screen
        name="YourTenders"
        options={{ drawerLabel: ({color}) => LabelWithIcon( translate('drawer.yourTenders'), archiveIcon(color)) }}
      >
        {props => <YourTendersScreen {...props} navigation={navigation} userId={userId}/>}
      </Drawer.Screen>

      {
        isCompany && canManageTeam ? (
          <Drawer.Screen
            name="TeamManagement" component={TeamManagement}
            options={{drawerLabel: ({color}) => LabelWithIcon( translate('drawer.teamManagement'), peopleIcon(color)) }}
          />
        ) : null
      }

      <Drawer.Screen
        name="SavedTenders"
        options={{drawerLabel: ({color}) => LabelWithIcon( translate('drawer.SavedTenders'), bookmarkIcon(color)) }}
      >
        {props => <SavedTenders {...props} userId={userId}/>}
      </Drawer.Screen>

      <Drawer.Screen
        name="OutgoingOffers"
        options={{drawerLabel: ({color}) => LabelWithIcon( translate('drawer.outgoingOffers'), paperPlaneIcon(color)) }}
      >
        {props => <OutgoingOffers {...props} userId={userId}/>}
      </Drawer.Screen>

      <Drawer.Screen
        name="Downloads"
        options={{drawerLabel: ({color}) => LabelWithIcon( translate('drawer.downloads'), filesIcon(color)) }}
      >
        {props => <Downloads {...props} />}
      </Drawer.Screen>

      {
        isCompany && canAccessStatistics ? (
          <Drawer.Screen
            name="Statistics"
            options={{ drawerLabel: ({color}) => LabelWithIcon( translate('drawer.statistics'), chartIcon(color)) }}
          >
            {props => <Statistics {...props} companyID={companyId}/>}
          </Drawer.Screen>
        ) : null
      }
      <Drawer.Screen
        name="Settings" component={Settings}
        options={{ drawerLabel: ({color}) => LabelWithIcon( translate('drawer.settings'), settingsIcon(color)) }}
      />

    </Drawer.Navigator>
  );
}
