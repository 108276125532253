import React from "react";
import {Avatar, Center, Checkbox, HStack, Icon, Text, View} from "native-base";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {translate} from "../utils/Translate";

export default (
  {
    avatar, name, title, onlyUser,
    isChecked, onChecked, onEdit, userId, index, avatarSize = 'md',
    nameFontSize = 18, titleFontSize = 16
  }) => {

  if (name === "[deleted]"){
    name = translate('misc.deletedUser')
  }

  return (
    <View>
      <HStack justifyContent="space-between">
        <HStack w="80%">
          <Avatar size={avatarSize} source={{ uri: avatar }} />

          <View ml={2}>
            <Text textAlign="left" fontWeight={500} fontSize={nameFontSize}>
              {name}
            </Text>
            <Text textAlign="left" fontSize={titleFontSize}>
              {title}
            </Text>
          </View>
        </HStack>

        {
          onlyUser ? null : (
            <Center>
              <Checkbox
                shadow={1} value="reviewer"
                accessibilityLabel="reviewer"
                isChecked={isChecked}
                onChange={(value) => {
                  onChecked(value, userId, index)
                }}
              />
              <Text textAlign="left">
                {translate('components.userWithTitle.select')}
              </Text>
            </Center>
          )
        }

        {
          !!onEdit ? (
            <Icon
              as={<MaterialCommunityIcons name="square-edit-outline"/>}
              color="appAccent" size={10}
              onPress={onEdit}
            />
          ) : null
        }

      </HStack>
    </View>
  )
}
