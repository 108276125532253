import {Box, Center, View, useColorModeValue, Skeleton} from "native-base";
import React from "react";

export default () => {

  return (
    <Box
      width={["100%", "100%", "33%"]}
      bg={useColorModeValue("#F7F7F7", "coolGray.700")}
      pl={3} pt={2} shadow={2} my={2} borderRadius={8}
      ml={[0, 0, 1]}
    >
      <View flexDirection="row">
        <Skeleton size="16" rounded="full" mt={2} />
        <View ml={3}>
          <Skeleton size="6" rounded={'md'} w={40} mt={4} />

          <Skeleton size="6" rounded={'md'} w={20} mt={3} />
        </View>
      </View>

      <Skeleton.Text lines={2} mt={10} mb={4} w={56} />


    </Box>
  )
}
