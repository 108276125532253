import React, {useEffect, useState} from 'react';
import {MaterialCommunityIcons, MaterialIcons} from "@expo/vector-icons";

import {
  View, Text, Icon, ScrollView, Center, useColorModeValue, Avatar, Button
} from "native-base";
import {localInfo, translate} from "../utils/Translate";
import apiClient from "../utils/apiClient";
import {AuthContext} from "../utils/context/auth";
import TokenManager from "../utils/TokenManager";
import TenderCard from "../components/TenderCard";
import * as WebBrowser from "expo-web-browser";

const Title = ({title}) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

export default ({ route, navigation }) => {

  const { profileType, profileId } = route.params

  const [company, setCompany] = useState({
    name: null,
    avatar: null,
    website: null,
    text_me: null
  });
  const [userData, setUserData] = useState({
    full_name: null,
    avatar: null,
    text_me: null
  });
  const [belongsToCompany, setBelongsToCompany] = useState(false);
  const [isUserProfile, setIsUserProfile] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [currentUserToken, setCurrentUserToken] = useState({});
  const { authUser, isCompany } = React.useContext(AuthContext);
  const [userTenders, setUserTenders] = useState([]);
  const [companyTenders, setCompanyTenders] = useState([]);


  function getCompany(){
    apiClient.get(`companies/${profileId}`).then( async (res) => {

      const user = await getCurrentUser()
      const token = await getCurrentUserToken()
      setCurrentUser(user);
      setCurrentUserToken(token)

      if (!!user && user.company_id === res.data.id){
        setBelongsToCompany(true)
      }
      setCompany(res.data)
    })
  }

  function getUser(){
    apiClient.get(`users/${profileId}`).then( async (res) => {

      const user = await getCurrentUser()
      const token = await getCurrentUserToken()
      setCurrentUser(user);
      setCurrentUserToken(token)

      if (!!user && user.id === res.data.id){
        setIsUserProfile(true)
      }

      setUserData(res.data)
    })
  }

  function getCompanyTenders(){
    apiClient.get(`companies/${profileId}/tenders?lang=${localInfo.local}`).then( response => {
      setCompanyTenders(response.data.data)
    }).catch(error => console.log(error));
  }

  function getUserTenders(){
    apiClient.get(`users/${profileId}/tenders?lang=${localInfo.local}`).then( response => {
      setUserTenders(response.data.data)
    }).catch(error => console.log(error));
  }

  async function getCurrentUser(){
    return await authUser()
  }

  async function getCurrentUserToken(){
    return await TokenManager.get()
  }

  useEffect(() => {
    if (profileType === 'company'){
      getCompany()
    } else if (profileType === 'user') {
      getUser()
    }
  }, []);

  useEffect(() => {
    if (profileType === 'company'){
      getCompanyTenders()
    } else if (profileType === 'user') {
      getUserTenders()
    }
  }, []);

  return (

      <ScrollView bg={useColorModeValue('white', "coolGray.800")}>
        <View
          bg={profileType === 'company' ? "appPrimary" : 'particular'}
          flexDirection="row" py={12} px={3}
        >

          <View>
            <Avatar
              mt={2}
              size="xl"
              source={{ uri : profileType === 'company' ? company.avatar : userData.avatar }}
            />
          </View>

          <View flex={1} ml={4} pt={2} >
            <Text
              color="white"
              textAlign="left" fontSize={18} fontWeight={500}
            >
              {
                profileType === 'company' ? company.name : userData.full_name
              }
              <View>
                <Icon
                  as={MaterialIcons}
                  size="4"
                  name="check-circle"
                  color="white"
                />
              </View>
            </Text>

            <View flexDirection="row" mt={3}>
              {
                !belongsToCompany && !isUserProfile && (!!userData.text_me || !!company.text_me) ? (
                  <Button
                    mr={2} bg="white"
                    _pressed={{ backgroundColor: '#e3e3e3' }}
                    leftIcon={<Icon as={<MaterialCommunityIcons name="chat-processing-outline" />} color="appPrimary" size={5} />}
                    onPress={() => {
                      navigation.navigate('Chat', {
                        type: profileType === 'company' ? 'companies' : 'users',
                        typeId: profileId,
                        currentSenderId: !!currentUser.company_id ? currentUser.company_id : currentUser.id,
                        token: currentUserToken
                      })
                    }}
                  >
                    <Text textAlign="left" color="appPrimary" fontSize={15} fontWeight={500}>
                      {translate('screens.tender.message')}
                    </Text>
                  </Button>
                ) : null
              }

              {
                !!company.website ? (
                  <Button
                    mr={2} bg="white"
                    _pressed={{ backgroundColor: '#e3e3e3' }}
                    leftIcon={<Icon as={<MaterialCommunityIcons name="web" />} color="appPrimary" size={5} />}
                    onPress={async () => {
                      if (company.website.startsWith('http')){
                        await WebBrowser.openBrowserAsync(encodeURI(company.website));
                      } else {
                        await WebBrowser.openBrowserAsync(encodeURI(`http://${company.website}`));
                      }
                    }}
                  >
                    <Text textAlign="left" color="appPrimary" fontSize={15} fontWeight={500}>
                      {translate('screens.companyProfile.website')}
                    </Text>
                  </Button>
                ) : null
              }

            </View>

          </View>
        </View>

        <View px={3}>
          <View mt={10} mb={3} flexDirection="row" justifyContent="space-between">
            <Title title={translate('screens.userProfile.publishedTenders')} />
          </View>

          <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
            {
              userTenders.length ? (
                userTenders.map((tender, index) => {
                  return (
                    <TenderCard
                      key={index}
                      id={tender.id}
                      postTime={tender.created_at}
                      location={tender.state.name[localInfo.local]}
                      title={tender.title}
                      branch={tender.branch.name[localInfo.local]}
                      description={tender.description}
                      date={tender.closing_date}
                      offers={tender.offers_count}
                      tenderableType={tender.tenderable_type}
                      avatar={tender.tenderable.avatar}
                    />
                  );
                })
              ) : profileType === 'user' ? (
                <Center
                  bg={useColorModeValue("#F8F8F8", "coolGray.700")}
                  py={4} mt={10} borderRadius={8}
                >
                  <Text textAlign="center" fontSize={20}>
                    {translate('screens.userProfile.noTenders')}
                  </Text>
                </Center>
              ) : null
            }
            {
              companyTenders.length ? (
                companyTenders.map((tender, index) => {
                  return (
                    <TenderCard
                      key={index}
                      id={tender.id}
                      postTime={tender.created_at}
                      location={tender.state.name[localInfo.local]}
                      title={tender.title}
                      branch={tender.branch.name[localInfo.local]}
                      description={tender.description}
                      date={tender.closing_date}
                      offers={tender.offers_count}
                      tenderableType={tender.tenderable_type}
                      avatar={tender.tenderable.avatar}
                    />
                  );
                })
              ) : profileType === 'company' ? (
                <Center
                  bg={useColorModeValue("#F8F8F8", "coolGray.700")}
                  py={4} mt={10} borderRadius={8}
                >
                  <Text textAlign="center" fontSize={20}>
                    {translate('screens.userProfile.noTenders')}
                  </Text>
                </Center>
              ) : null
            }
          </View>
        </View>

        <View mb={24}/>

      </ScrollView>
  );
}
