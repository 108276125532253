module.exports = {
  API_URL: "https://server.safakat.net/api",
  UNIVERSAL_LINK_PREFIX: "https://app.safakat.net/",
  PUSHER_APP_ID: 1415546,
  PUSHER_APP_KEY: "543dcc3551f6b4eb6859",
  PUSHER_APP_SECRET: "3751162ca0cf902cfc4f",
  PUSHER_APP_CLUSTER: "eu",
  BROADCASTING_AUTH: "https://server.safakat.net/broadcasting/auth",
  FIREBASE_API_KEY: "AIzaSyAI1MEpuVGkw6lBPps_QJuMrRfYuaLGU_M",
  FIREBASE_AUTH_DOMAIN: "safakat-1a4b9.firebaseapp.com",
  FIREBASE_PROJECT_ID: "safakat-1a4b9",
  FIREBASE_STORAGE_BUCKET: "safakat-1a4b9.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "31720374296",
  FIREBASE_APP_ID: "1:31720374296:web:774e6cf270c31b08ba836d",
  FIREBASE_MEASUREMENT_ID: "G-6ZL79MWBMW",
  REVENUECAT_APPLE_KEY: "appl_UlOSXIdLZwsoOIzzAKOKqPqddjl",
  REVENUECAT_GOOGLE_KEY: "goog_iIbDLkLxXajeryufefHjfmaPZyk",
};
