import React from "react";

import {
  Box, Text, useColorModeValue, View,
  Center, HStack, Skeleton
} from "native-base";
import {Dimensions} from "react-native";


export default () => {

  const width = Dimensions.get('screen').width

  return (
    <Box
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      borderRadius={8} shadow={3} mt={3} ml={2} mb={1} pb={2}
      w={[width * 0.65, width * 0.25, width * 0.25]}
    >
      <Center>
        <Skeleton size="20" rounded="full" mt={2} />
      </Center>

      <View my={3}/>

      <View px={2}>
        <Skeleton.Text lines={1} px={8}/>
      </View>

      <View my={2}/>

      <HStack justifyContent="space-between" px={2}>
        <View maxW="50%" px={2} borderRadius={20} alignSelf="flex-start">
          <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
            <Skeleton.Text lines={1} w={20} />
          </Text>
        </View>
        <View maxW="50%">
          <Center flexDirection="row">
            <Skeleton.Text lines={1} />
          </Center>
        </View>
      </HStack>
    </Box>
  )
}
