import {Center, Text, useColorModeValue} from "native-base";
import React from "react";
import {translate} from "../utils/Translate";

export default () => {

  return (
    <Center
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      py={4} mb={10} mt={5} borderRadius={8} shadow={3}
    >
      <Text textAlign="center" fontSize={20}>
        {translate('components.noTendersYet')}
      </Text>

    </Center>
  )
}
