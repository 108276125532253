import axios from "axios";
import TokenManager from "./TokenManager";
import {API_URL} from "../environment"

const instance = axios.create({ baseURL: API_URL });

instance.interceptors.request.use( async config => {

        const token = await TokenManager.get()

        config.headers['Content-Type'] = 'application/json';
        if (!!config.isFormData){
          config.headers['Content-Type'] = 'multipart/form-data';
        }

        if (token){
            config.headers['Authorization'] = 'Bearer ' + token;
            return config;
        }

        return config;
    },
    error => {
        Promise.reject(error)
    });

export default instance;
