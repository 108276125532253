import React, {useEffect, useState} from 'react';

import {Platform, StyleSheet} from 'react-native';
import {
  Pressable, View, ScrollView, Image, Text, Button, Center, VStack, Modal, Box, useColorMode, useTheme
} from "native-base";
import { AuthContext } from "../../../utils/context/auth";
import {ActivityIndicator} from "react-native-paper";
import apiClient from "../../../utils/apiClient";
import SelectList from "../../../components/SelectList";
import {localInfo, translate} from "../../../utils/Translate";
import CountryStatesSelectList from "../../../components/CountryStatesSelectList";

export default function InterestedBranches({ route, navigation }) {
  const { signUp } = React.useContext(AuthContext);

  const { userData } = route.params;

  const [selectedStates, setSelectedStates] = useState([])
  const [selectedBranch, setSelectedBranch] = useState([])
  const [signUpLoading, setSignUpLoading] = useState(false)
  const [signUpSuccess, setSignUpSuccess] = useState(false)
  const [signUpError, setSignUpError] = useState(false)
  const [branches, setBranches] = useState([])
  const [errors, setErrors] = React.useState({});
  const [serverErrors, setServerErrors] = React.useState([]);

  const {colorMode} = useColorMode();
  const { colors }  = useTheme();

  const isDark = colorMode === 'dark';

  let onStateChanged = (item) => {
    setSelectedStates(item)

    let error = {...errors}
    delete error.state
    setErrors(error);
  }

  let onBranchChanged = (item) => {
    setSelectedBranch(item)

    let error = {...errors}
    delete error.branches
    setErrors(error);
  }

  let handleSignUp = () => {

    if (!validate()){
      return
    }

    setSignUpLoading(true);
    userData.interestedStates = selectedStates;
    userData.interestedBranches = selectedBranch;
    delete userData.user.isProfessional

    const data = new FormData();
    data.append('user', JSON.stringify(userData.user))
    data.append('company', JSON.stringify(userData.company))
    data.append('companyBranches', JSON.stringify(userData.companyBranches))
    data.append('interestedStates', JSON.stringify(userData.interestedStates))
    data.append('interestedBranches', JSON.stringify(userData.interestedBranches))

    if (Platform.OS === "web"){
      data.append('commercialRegister[]', userData.webCommercialRegisterFront)
      data.append('commercialRegister[]', userData.webCommercialRegisterBack)
      data.append('commercialRegister[]', userData.webStartupLabel)
    } else {
      data.append('commercialRegister[]', userData.commercialRegisterFront)
      data.append('commercialRegister[]', userData.commercialRegisterBack)
      data.append('commercialRegister[]', userData.startupLabel)
    }

    signUp(data).then(() => {
      setSignUpSuccess(true);
    }).catch((err) => {
      if (err.status === 400){
        getServerErrors(err.data)
      }
      setSignUpError(true)
    })
  }

  function getServerErrors(errors){

    let currentErrors = []

    for (const property in errors) {
      for(const err of errors[property]){
        currentErrors = [...currentErrors, err]
      }
    }

    setServerErrors(currentErrors)
  }

  function getBranches(){
    apiClient.get(`branches`).then(response => {
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error.response.data));
  }

  function setBranchesOfLanguage(branches, language){
    let getBranches = branches.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
            language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(getBranches)
  }

  const validate = () => {
    if (selectedStates.length === 0) {
      setErrors({ ...errors,
        state: translate('validation.states')
      });
      return false;
    }
    if (selectedBranch.length === 0) {
      setErrors({ ...errors,
        branches: translate('validation.branches2')
      });
      return false;
    }

    return true;
  };

  useEffect(() => {
    getBranches()
  }, [])


  const styles = StyleSheet.create({
    contentContainerStyle: {
      justifyContent: "space-between",
      flexDirection: "column",
      backgroundColor: isDark ? "#1f2937" :'#fff',
      flexGrow: 1
    },
  })


  return (
    <ScrollView
      px={[0, 0, 32, 64]}
      contentContainerStyle={styles.contentContainerStyle}
    >
      <VStack mt={12}>
        <Pressable
          m={5}
          onPress={() => navigation.goBack()}>
          <Image
            alt="back icon"
            source={require('../../../assets/icons/back_arrow.png')}
          />
        </Pressable>

        <View mt={5}>

          {
            userData.user.company_role !== null ? (
              <Box ml={4} mr={10}>
                <View alignItems='flex-end' flexDirection="row">
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24">4/4 </Text>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={1}>
                    {translate('misc.complete')}
                  </Text>
                </View>

                <View mt={2} flexDirection="row">
                  <View m={1} h={1} w="25%" bg="appPrimary"></View>
                  <View m={1} h={1} w="25%" bg="appPrimary"></View>
                  <View m={1} h={1} w="25%" bg="appPrimary"></View>
                  <View m={1} h={1} w="25%" bg="appPrimary"></View>
                </View>
              </Box>
            ) : (
              <Box ml={4} mr={10}>
                <View alignItems='flex-end' flexDirection="row">
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24">3/3 </Text>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={1}>
                    {translate('misc.complete')}
                  </Text>
                </View>

                <View mt={2} flexDirection="row">
                  <View m={1} h={1} w="33%" bg="appPrimary"></View>
                  <View m={1} h={1} w="33%" bg="appPrimary"></View>
                  <View m={1} h={1} w="33%" bg="appPrimary"></View>
                </View>
              </Box>
            )
          }

          <Center ml={4} mt={8}>
          </Center>

          <View ml={4} mt={8}>
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="18" fontWeight={500}>
              {translate('screens.auth.interests.selectInterests')}
            </Text>

            <View mt={5} mr={4}>
              <CountryStatesSelectList
                selectText={translate('misc.states')}
                modalHeaderText={translate('screens.auth.interests.selectCountryStates')}
                doneText={translate('misc.done')}
                cancelText={translate('misc.cancel')}
                isMultiSelect={true}
                showSelectAll={false}
                onDone={onStateChanged}
              />
              {
                'state' in errors ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={2} color="appSecondary">
                    {errors.state}
                  </Text>
                ) : null
              }
            </View>

            <View my={1}/>

            <View mr={4}>
              {
                branches.length ? (
                  <SelectList
                    selectText={translate('misc.branches')}
                    modalHeaderText={translate('screens.auth.interests.selectBranches')}
                    doneText={translate('misc.done')}
                    cancelText={translate('misc.cancel')}
                    isMultiSelect={true}
                    showSelectAll={false}
                    onDone={onBranchChanged}
                    items={branches}
                  />
                ) : null
              }
              {
                'branches' in errors ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                    {errors.branches}
                  </Text>
                ) : null
              }
            </View>

          </View>

        </View>
      </VStack>

      <Center mb={10}>
        <Button
          w="85%"
          onPress={ handleSignUp }
          size="lg"
          color="appPrimary"
          borderRadius={8}
        >
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
            {translate('misc.next')}
          </Text>
        </Button>
      </Center>

      {/* Sign up Modal */}
      <Center>
        <Modal size={["xl", "xl", "md"]} isOpen={signUpLoading} >
          <Modal.Content>

            <Modal.Header>
              {translate('screens.auth.interests.signUpModal.headerTitle')}
            </Modal.Header>
            <Modal.Body>
              {
                !signUpSuccess && !signUpError ?
                  (
                    <View>
                      <ActivityIndicator size="large" color={colors.appPrimary} />
                      <View mt={4}/>
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                          {translate('screens.auth.interests.signUpModal.creatingText')}
                        </Text>
                      </Center>
                      <View mt={4}/>
                    </View>
                  )
                  :
                  signUpSuccess && !signUpError ? (
                    <View>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                        {translate('screens.auth.interests.signUpModal.accountCreated')}
                      </Text>
                      <View mt={4}/>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                        {translate('screens.auth.interests.signUpModal.passSent')} ({userData.user.email})
                      </Text>
                      <View mt={4}/>
                      <Button
                        w="100%"
                        onPress={ () => {
                          setSignUpLoading(false);
                          navigation.navigate('Sign-in', {email: userData.user.email})
                        } }
                        size="lg"
                        color="appPrimary"
                        borderRadius={8}
                      >
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                          {translate('misc.signIn')}
                        </Text>
                      </Button>
                    </View>
                  )
                    : signUpError ?
                    (
                      <View>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                          {translate('misc.errorTryAgain')}
                        </Text>
                        <View mt={4}/>

                        {
                          serverErrors.length ? (
                            <View mt={2} mb={5}>
                              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>
                                Error:
                              </Text>
                              {
                                serverErrors.map((err, index) => (
                                  <Text my={1} key={index} textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17} color="appSecondary">
                                    {err}
                                  </Text>
                                ))
                              }
                            </View>
                          ) : null
                        }
                        <Button
                          w="100%"
                          onPress={ () => {
                            setSignUpLoading(false);
                            navigation.navigate('Sign-up')
                          } }
                          size="lg"
                          color="appPrimary"
                          borderRadius={8}
                        >
                          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                            {translate('misc.tryAgain')}
                          </Text>
                        </Button>
                      </View>
                  ) : null
              }

            </Modal.Body>
          </Modal.Content>
        </Modal>
      </Center>

    </ScrollView>
  );
}

