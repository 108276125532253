import { RSA } from 'hybrid-crypto-js';
import apiClient from '../utils/apiClient';
import StorageManager from '../utils/StorageManager';

const rsa = new RSA({
    keySize: 1024,
});

class UserKeyManager {

    static async storePrivateKey(){
        return rsa.generateKeyPairAsync().then(async keyPair => {
            await StorageManager.storeString('userPrivateKey', keyPair.privateKey);
            // Save public key in the server
            await this.storePublicKey(keyPair.publicKey)
        });
    }

    static async storePublicKey(publicKey){
        // Save the public key in the server
        await apiClient.post("keys", {key: publicKey}).catch(console.log)
    }

    static async getPrivateKey(){
        return await StorageManager.getString('userPrivateKey');
    }

    static async destroyPrivateKey(){
        await StorageManager.remove('userPrivateKey');
    }

    static async hasPrivateKey(){
        const key = await StorageManager.getString('userPrivateKey');

        return !!key;
    }

}

export default UserKeyManager;
