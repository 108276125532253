const GroupModel = [
  {
    id: null,
    title: null,
    description: null,
    latitude: null,
    longitude: null,
    closing_date: null,
    bid_initial_price: null,
    participation_cost: null,
    required_points: null,
    views_count: null,
    offers_count: null,
    groupbuyingable_type: null,
    groupbuyingable_id: null,
    language_id: null,
    status_id: null,
    branch_id: null,
    state_id: null,
    currency_id: null,
    participationtype_id: null,
    created_at: null,
    updated_at: null,
    groupbuyingable: {
      id: null,
      name: null,
      address: null,
      avatar: null,
      website: null,
      balance: null,
      state_id: null,
      status_id: null,
      payoutinfo_id: null,
      created_at: null,
      updated_at: null,
    },
    branch:{
      name: {
        en: '',
        ar: '',
        fr: '',
      }
    },
    state:{
      name: {
        en: '',
        ar: '',
        fr: '',
      }
    },
    pointtables: [
      {
        id: null,
        item: null,
        requirement: null,
        points: null,
        offer: null,
        total: null,
        tender_id: null,
        created_at: null,
        updated_at: null,
      },
      {
        id: null,
        item: null,
        requirement: null,
        points: null,
        offer: null,
        total: null,
        tender_id: null,
        created_at: null,
        updated_at: null,
      }
    ],
    itemtables: [
      {
        id: null,
        item: null,
        unit: null,
        quantity: null,
        price: null,
        total: null,
        tender_id: null,
        created_at: null,
        updated_at: null,
      },
      {
        id: null,
        item: null,
        unit: null,
        quantity: null,
        price: null,
        total: null,
        tender_id: null,
        created_at: null,
        updated_at: null,
      },
      {
        id: null,
        item: null,
        unit: null,
        quantity: null,
        price: null,
        total: null,
        tender_id: null,
        created_at: null,
        updated_at: null,
      }
    ],
    reviewsteps: [
      {
        id: null,
        title: null,
        description: null,
        action: null,
        tender_id: null,
        created_at: null,
        updated_at: null,
      }
    ],
    currency: {
      id: null,
      code: {
        en: null,
        ar: null,
        fr: null,
      },
      created_at: null,
      updated_at: null,
    },
    attachments: [],
    is_owner: null,
    has_participate: null
  }
]

export default GroupModel
