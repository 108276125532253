import React, {useEffect, useState} from "react";
import Octicons from 'react-native-vector-icons/Octicons';
import { AuthContext } from "../utils/context/auth";

import {
  Text, View, Center, Pressable, Avatar, Icon, HStack,
  useColorModeValue, Modal, Button, useTheme,
} from "native-base";
import {
  DrawerContentScrollView,
  DrawerItemList
} from '@react-navigation/drawer';
import {useNavigation} from "@react-navigation/native";
import {localInfo, translate} from "../utils/Translate";

export default function CustomDrawerContent(props) {

  const navigation = useNavigation();

  const { signOut, authUser, isCompany } = React.useContext(AuthContext);
  const [showSignOutModal, setShowSignOutModal] = useState(false)
  const [user, setUser] = useState({
    full_name: '',
    company: {
      name: ''
    }
  })

  const { colors } = useTheme();

  useEffect(() => {
    (async() => {
      const user = await authUser()
      setUser(user);
    })()
  }, []);

    return (
      <View flex={1} bg={useColorModeValue("white", "coolGray.800")}>
        <DrawerContentScrollView {...props}
          contentContainerStyle={{ backgroundColor: isCompany ? colors.appPrimary : colors.particular }}
        >
          <HStack ml={3} mt={4} mb={5}>
            <Pressable onPress={() => navigation.navigate('MyProfile')}>
              <Avatar
                source={{uri: user.avatar}}
                size="lg"
              />
            </Pressable>
            <View w={'70%'} ml={2}>
              <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                {user.full_name}
              </Text>
              {
                user.company_id === null ? null
                  :
                  <View>
                    {
                      localInfo.isRTL ? (
                        <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                          {user.company.name} {translate('screens.home.at')} {user.company_role}
                        </Text>
                      ) : (
                        <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                          {user.company_role} {translate('screens.home.at')} {user.company.name}
                        </Text>
                      )
                    }

                  </View>
              }
            </View>
          </HStack>

          <View pb={16} bg={useColorModeValue("white", "coolGray.800")}>
            <DrawerItemList {...props} />
          </View>

        </DrawerContentScrollView>

          <Pressable onPress={() => setShowSignOutModal(true)} >
            <View bg="appSecondary" mx={4} mb={[6, 6, 2]} borderRadius={15} p={1}>
              <Center flexDirection="row">
                <Icon as={<Octicons name="sign-out" />} color="primary.50" size={6} ml="2" />
                <Text textAlign="left" ml={2} fontSize={18} fontWeight={600} color="primary.50">
                  {translate('drawer.content.signOut')}
                </Text>
              </Center>
            </View>
          </Pressable>

        {/* Sign-out Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showSignOutModal} onClose={() => setShowSignOutModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.content.signOut')}
              </Modal.Header>
              <Modal.Body>

                <Center>
                  <Text>
                    {translate('drawer.content.signOutQuestion')}
                  </Text>
                </Center>

              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowSignOutModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={() => {
                    signOut()
                  }}>
                    {translate('drawer.content.signOut')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </View>
    );
}
