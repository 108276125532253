import React, { useEffect, useState, useRef } from 'react';

import {TouchableWithoutFeedback, Keyboard, KeyboardAvoidingView, Platform} from 'react-native';
import {
  Pressable, View, Image, Text, Button, Center, Box, Spinner, useColorModeValue,
} from "native-base";
import OtpInput from "./OtpInput";
import Resend from "../../../components/Resend";

import firebase from "../../../utils/firebase";
import {localInfo, translate} from "../../../utils/Translate";

import auth from '@react-native-firebase/auth';

export default function VerifyPhone({ route, navigation }) {

  const { userData, recaptchaVerifier } = route.params;

  const [OTP, setOTP] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)
  const [invalidOTP, setInvalidOTP] = useState(false)
  const [captchaError, setCaptchaError] = useState(false)

  // @react-native-firebase/auth for IOS & Android
  const [confirm, setConfirm] = useState(null);
  // for Firebase web
  const webConfirmationResult = useRef(null)

  async function sendVerification() {
    console.log('sendVerification...')

    if (Platform.OS !== 'web'){
      try {
        let confirmation = await auth().signInWithPhoneNumber(userData.user.phone_number);
        setConfirm(confirmation);
        console.log('code sent!')
      } catch (error) {
        console.log('confirmation e:', error)
      }
    }

    if (Platform.OS === 'web'){
      firebase.auth().signInWithPhoneNumber(userData.user.phone_number, recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          webConfirmationResult.current = confirmationResult;

        }).catch((error) => {
        console.log('confirmation e:', error)
      });
    }

  }

  const confirmOTP = async () => {
    setIsConfirming(true)

    if (Platform.OS !== 'web'){
      try {
        await confirm.confirm(OTP);
        navigateToNext()
      } catch (error) {
        console.log(error)
        setIsConfirming(false)
        setInvalidOTP(true)
      }
    }

    if (Platform.OS === 'web'){
      webConfirmationResult.current.confirm(OTP).then((result) => {
        // User signed in successfully.
        navigateToNext()
        // ...
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.log(error)
        setIsConfirming(false)
        setInvalidOTP(true)
      });
    }

  };

  function onAuthStateChanged(user) {
    if (!!user && user.phoneNumber == userData.user.phone_number ) {

      console.log('user phone has been verified!')
      navigateToNext()
      // Some Android devices can automatically process the verification code (OTP) message, and the user would NOT need to enter the code.
      // Actually, if he/she tries to enter it, he/she will get an error message because the code was already used in the background.
      // In this function, make sure you hide the component(s) for entering the code and/or navigate away from this screen.
      // It is also recommended to display a message to the user informing him/her that he/she has successfully logged in.
    }
  }

  useEffect(() => {
    if (Platform.OS !== 'web'){
      const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
      return subscriber; // unsubscribe on unmount
    }
  }, []);

  useEffect(() => {
    ( async () => {
       await sendVerification()
    })()
  }, [])

  useEffect(() => {
    ( async () => {
      if (Platform.OS !== 'web'){
        await auth().signOut()
      }
    })()
  }, [])

  function navigateToNext(){
    if (userData.user.isProfessional){
      navigation.replace('CompanyInfo', {userData})
    } else {
      navigation.replace('InterestedBranches', {userData})
    }
  }

  return (
      <Box
        px={[0, 0, 32, 64]}
        flex={1} flexDirection="column" justifyContent="space-between"
        bg={useColorModeValue("white", "coolGray.800")}
      >

        <View mt={12}>
          <Pressable
            m={5}
            onPress={() => navigation.goBack()}>
            <Image
              alt="back icon"
              source={require('../../../assets/icons/back_arrow.png')}
            />
          </Pressable>

          <View mt={12}>

            {
              userData.user.isProfessional ? (
                <Box ml={4} mr={10}>
                  <View alignItems='flex-end' flexDirection="row">
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24">2/4 </Text>
                    <Text mb={1}>
                      {translate('misc.complete')}
                    </Text>
                  </View>

                  <View mt={2} flexDirection="row">
                    <View m={1} h={1} w="25%" bg="appPrimary"></View>
                    <View m={1} h={1} w="25%" bg="appPrimary"></View>
                    <View m={1} h={1} w="25%" bg="appSecondary"></View>
                    <View m={1} h={1} w="25%" bg="appSecondary"></View>
                  </View>
                </Box>
              ) : (
                <Box ml={4} mr={10}>
                  <View alignItems='flex-end' flexDirection="row">
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24">2/3 </Text>
                    <Text mb={1}>
                      {translate('misc.complete')}
                    </Text>
                  </View>

                  <View mt={2} flexDirection="row">
                    <View m={1} h={1} w="33%" bg="appPrimary"></View>
                    <View m={1} h={1} w="33%" bg="appPrimary"></View>
                    <View m={1} h={1} w="33%" bg="appSecondary"></View>
                  </View>
                </Box>
              )
            }

            <View ml={4} mt={8}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="2xl" fontWeight={700}>
                {translate('screens.auth.verifyPhone.verifyPhone')}
              </Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="xl" fontWeight={300}>
                {translate('screens.auth.verifyPhone.enterCode')}
              </Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="xl" fontWeight={300}>
                {translate('screens.auth.verifyPhone.weSentCode')} {userData.user.phone_number}
              </Text>
            </View>

            <View>
              <View style={{ direction: 'ltr' }}>
                <OtpInput onOTP={(otp) => {
                  setOTP(otp)
                  setInvalidOTP(false)
                }} />
              </View>
              {
                invalidOTP ? (
                 <Text textAlign="center" fontSize={20} color="appSecondary">
                    {translate('screens.auth.verifyPhone.invalidCode')}
                  </Text>
                ) : null
              }
              {
                captchaError ? (
                 <Text textAlign="center" fontSize={20} color="appSecondary">
                    {translate('screens.auth.verifyPhone.captchaError')}
                  </Text>
                ) : null
              }
            </View>
          </View>
        </View>

        {/*<Resend time={90} onResend={async () => await sendVerification()}/>*/}

        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <Center mb={10}>
              <Button
                isDisabled={OTP.length !== 6}
                disabled={isConfirming}
                w="85%"
                onPress={confirmOTP}
                size="lg"
                color="appPrimary"
                borderRadius={8}
              >
                {
                  isConfirming ? (
                    <Spinner size="sm" color="white" accessibilityLabel="Loading Sign in" />
                  ) : (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                      {translate('misc.confirm')}
                    </Text>
                  )
                }
              </Button>
            </Center>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </Box>
    );
}
