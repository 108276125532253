import React from "react";
import {Box, Text, View, Pressable, useColorModeValue} from "native-base";
import {useNavigation} from "@react-navigation/native";
import {translate} from "../utils/Translate";
import {shorten} from "../utils/StringTool";
import DateFormatter from "../utils/TimeFormatter";

export default (props) => {
  const navigation = useNavigation();

  return (
    <Pressable
      width={["100%", "100%", "33%"]}
      onPress={() => navigation.navigate('Group', {groupId: props.id})}
    >
      <Box
        bg={useColorModeValue("#F8F8F8", "coolGray.700")}
        borderRadius={8} shadow={3} mt={3} ml={[0, 0, 3]}
      >
        <View
          minH={["100", "100", "288"]}
          justifyContent="space-between"
          _dark={{ bg: "coolGray.700"}} m={2}
        >
          <View>
            <Text textAlign="left" fontSize={24} fontWeight={500}>
              {shorten(props.title, 50)}
            </Text>
            <View
              bg={useColorModeValue("appAccent", "appAccentDark")}
              mt={1} px={2} borderRadius={20} alignSelf="flex-start">
              <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                {shorten(props.branch, 30)}
              </Text>
            </View>

            <Text textAlign="left" mt={2} fontSize={17}>
              {shorten(props.description, 100)}
            </Text>
          </View>

          <View _dark={{ bg: "coolGray.700"}} flexDirection="row" my={2}>
            {
              !!props.date ? (
                <View px={2} bg="appSecondary" borderRadius={20} alignSelf="flex-start">
                  <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                    {translate('components.tenderCard.closingDate')}: {DateFormatter.getFormattedDate(props.date)}
                  </Text>
                </View>
              ) : null
            }
            <View ml={2} px={2} bg="appPrimary" borderRadius={20} alignSelf="flex-start">
              <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                {translate('drawer.groupBuying.requests')}: {props.requests}
              </Text>
            </View>
          </View>

        </View>
      </Box>
    </Pressable>
  )
}
