import React, {useEffect, useState} from 'react';
import {AntDesign, FontAwesome5} from "@expo/vector-icons";
import {Platform} from "react-native";

import {
  View, Text, Box, Icon, Center, HStack, Spacer, ScrollView, useColorModeValue,
  Pressable
} from "native-base";
import {localInfo, translate} from "../utils/Translate";
import apiClient from "../utils/apiClient";
import AuthUserManager from "../utils/AuthUserManager";
import TokenManager from "../utils/TokenManager";
import * as WebBrowser from "expo-web-browser";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const QuestionAnswer = ({ question, answer }) => {
  return <View my={2}>

    <HStack ml={5}>
      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600} fontSize={20} color="white">• </Text>
      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600} fontSize={20} color="white"> {question} </Text>
    </HStack>

    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} color="white" ml={3}>{answer}</Text>
  </View>
}


export default ({ navigation }) => {

  const [faqs, setFaqs] = useState([]);
  const [token, setToken] = useState()
  const [currentUser, setCurrentUser] = useState(null);

  function getFAQs(){
    apiClient.get(`faqs?lang=${localInfo.local}`).then((response) => {
      setFaqs(response.data)
    }).catch(error => console.log(error.response.data))
  }

  useEffect(() => {
    (async () => {
      let user      = await AuthUserManager.get();
      let authToken = await TokenManager.get()

      setToken(authToken)
      setCurrentUser(user)
    })();

    getFAQs()
  }, [])

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>
        <View mt={4} mx={4}>
          <Title title={translate('screens.help.title')} />
          <SpacerMY space={3}/>

          <Box bg="black.800" borderRadius={10}>

            {/*<View  w={50} h={50} bg="#426380" borderBottomRightRadius={999999} />*/}

            <View py={4}>

              <Center>
                <Icon as={<FontAwesome5 name="question-circle" />} size={10} ml="2" color="primary.50" />
                <Text color="primary.50" textAlign="center" fontWeight={500} fontSize={30}>
                  {translate('screens.help.FAQ')}
                </Text>
              </Center>

              <SpacerMY space={3}/>

              {
                faqs.length ? (
                  <View>
                    {
                      faqs.map((faq, index) => (
                        <View key={index}>
                          <QuestionAnswer
                            question={faq.question}
                            answer={faq.answer}
                          />
                        </View>
                      ))
                    }
                  </View>
                ) : null
              }

            </View>

          </Box>

          <SpacerMY space={3}/>

          <HStack>

            <Pressable
              w="31%" bg="black.800" borderRadius={14}
              onPress={() => {
                navigation.navigate('Chat', {
                  isSupportChat: true,
                  currentSenderId: !!currentUser.company_id ? currentUser.company_id : currentUser.id,
                  token: token
                })
              }}
            >
              <Box>
                <View py={4} px={4}>
                  <Center>
                    <Icon as={<AntDesign name="wechat" />} size={9} ml="2" color="primary.50" />
                    <Text mt={2} color="primary.50" textAlign="center" fontWeight={600} fontSize={18}>
                      {translate('screens.help.chat')}
                    </Text>
                  </Center>
                </View>
              </Box>
            </Pressable>

            <Spacer/>

            <Pressable
              w="31%" bg="black.800" py={4} px={4} borderRadius={14}
              onPress={() => navigation.navigate('CallLines')}
            >
              <Box >
                <Center>
                  <Icon as={<FontAwesome5 name="phone-alt" />} size={9} ml="2" color="primary.50" />
                  <Text mt={2} color="primary.50" textAlign="center" fontWeight={600} fontSize={18}>
                    {translate('screens.help.call')}
                  </Text>
                </Center>
              </Box>
            </Pressable>

            <Spacer/>

            <Pressable
              w="31%" bg="black.800" py={4} px={4} borderRadius={14}
              onPress={async () => {
                await WebBrowser.openBrowserAsync(`https://www.facebook.com/Safakat.Dz/`);
              }}
            >
              <Box>
                <Center>
                  <Icon as={<FontAwesome5 name="facebook" />} size={9} ml="2" color="primary.50" />
                  <Text mt={2} color="primary.50" textAlign="center" fontWeight={600} fontSize={18}>
                    {translate('screens.help.facebookPage')}
                  </Text>
                </Center>
              </Box>
            </Pressable>
          </HStack>

          <View mt={130}/>
        </View>
      </ScrollView>

    </View>
  );
}
