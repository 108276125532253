import React, {useEffect, useState} from 'react';

import {
  View, Text, Icon, Center, Button, ScrollView,
  Input, Checkbox, FormControl, useColorModeValue,
  Modal, Divider
} from "native-base";
import {StyleSheet, TouchableOpacity} from "react-native";
import {localInfo, translate} from "../../../../utils/Translate";
import {Ionicons} from "@expo/vector-icons";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ route, navigation }) => {

  const { tenderData } = route.params

  const [showAddItemModal, setShowAddItemModal]   = React.useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentNote, setDocumentNote] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = React.useState([]);
  const [inputErrors, setInputErrors] = React.useState({});


  useEffect(() => {
    if (tenderData.requiredDocuments.length){
      setRequiredDocuments(tenderData.requiredDocuments)
    }
  }, []);

  function saveRequiredDocuments(){
    if (requiredDocuments.length){
      tenderData.requiredDocuments = requiredDocuments
    }
  }

  const handleNextButton = () => {
    saveRequiredDocuments()
    return navigation.navigate("GroupTenderCriteria", {tenderData})
  }

  const handleDocumentName = (text) => {
    if ('docName' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.docName
      setInputErrors(errors);
    }

    setDocumentName(text)
  }

  const handleDocumentNote = (text) => {
    setDocumentNote(text)
  }

  const handleDocumentIsRequired = (value) => {
    setIsRequired(value)
  }

  const handleAddButton = () => {
    if (!validate()) return;

    let requirement = {
      documentName,
      documentNote,
      isRequired,
    }

    setRequiredDocuments([...requiredDocuments, requirement])
    saveRequiredDocuments()

    setShowAddItemModal(false)
    setDocumentName("")
    setDocumentNote("")
    setIsRequired(false)
  }

  const validate = () => {
    if (documentName.length === 0) {
      setInputErrors({ ...inputErrors,
        docName: translate('validation.nameRequired')
      });
      return false;
    }

    return true;
  };

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4} contentContainerStyle={styles.contentContainerStyle}>

        <View>
          <View mt={4}/>
          <Title title={translate('screens.publishTender.title')} />
          <SpacerMY space={3}/>

          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={26}>
            {translate('screens.publishTender.requiredDocuments.title')}
          </Text>
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
            {translate('screens.publishTender.requiredDocuments.description')}
          </Text>

          <SpacerMY space={3}/>

          {
            tenderData.requiredDocuments.length ? (
              <View mt={9} mb={3} mx="8">
                <Divider thickness="1" bg="appPrimary" />
              </View>
            ) : null
          }

          {
            requiredDocuments.map((doc, docIndex) => (
              <View mt={3}>
                <Center flexDirection="row" justifyContent="space-between">
                  <View flexDirection="row" alignItems="center">
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={24} >
                      {translate('screens.publishTender.requiredDocuments.document')} : </Text>
                    <View bg="appPrimary" borderRadius={200} px={2}>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="white" fontWeight={500} fontSize={24}> {docIndex + 1} </Text>
                    </View>
                  </View>

                  <View mt={2} jualignItems="flex-end" alignItems="flex-end">
                    <TouchableOpacity onPress={() => {
                      setRequiredDocuments(requiredDocuments.filter((e, index) => index !== docIndex));
                      saveRequiredDocuments()
                    }}>
                      <Icon as={Ionicons} name="trash-bin-outline" size={7} color="appSecondary" />
                    </TouchableOpacity>
                  </View>
                </Center>

                <View ml={2} mt={3}>
                  <Text fontSize={20}>
                    {translate('screens.publishTender.requiredDocuments.name')}: {doc.documentName}
                  </Text>
                  <Text fontSize={20}>
                    {translate('screens.publishTender.requiredDocuments.add.documentNote')}: {doc.documentNote}
                  </Text>
                  <Text fontSize={20}>
                    {translate('screens.publishTender.requiredDocuments.add.required')}: {doc.isRequired ? translate('misc.yes') : translate('misc.no')}
                  </Text>
                </View>

                <View mt={9} mb={3} mx="8">
                  <Divider thickness="1" bg="appPrimary" />
                </View>
              </View>
            ))
          }


          <Center mb={16} mt={10}>
            <Button
              w="45%"
              leftIcon={<Icon as={Ionicons} name="add" size="sm" color="white" />}
              onPress={ () => setShowAddItemModal(true)}
              size="lg"
              colorScheme="accent"
              borderRadius={8}

            >
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                {translate('misc.add')}
              </Text>
            </Button>
          </Center>

        </View>


        <Center mb={16} mt={10}>
          <Button
            w="85%" size="lg" color="appPrimary" borderRadius={8}
            onPress={ handleNextButton }
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.next')}
            </Text>
          </Button>
        </Center>

        {/* Add Item Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showAddItemModal} onClose={() => setShowAddItemModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.publishTender.pointsTable.addToTable')}
              </Modal.Header>
              <Modal.Body>
                <FormControl mt={4} isInvalid={'docName' in inputErrors}>
                  <Input
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={
                      translate('screens.publishTender.requiredDocuments.add.documentName')
                    }
                    rounded={10}
                    size="xl" borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleDocumentName}
                    value={documentName}
                  />
                  {
                    'docName' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.docName}
                      </FormControl.ErrorMessage>
                    ) : null
                  }
                </FormControl>

                <FormControl mt={4}>
                  <Input
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={
                      translate('screens.publishTender.requiredDocuments.add.documentNote')
                    }
                    rounded={10}
                    size="xl" borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleDocumentNote}
                    value={documentNote}
                  />
                </FormControl>

                <SpacerMY space={1}/>

                <Checkbox
                  shadow={2} my={1}
                  accessibilityLabel="is Required"
                  onChange={handleDocumentIsRequired}
                  isChecked={isRequired}
                >
                  {translate('screens.publishTender.requiredDocuments.add.required')}
                </Checkbox>

                <SpacerMY space={2}/>

              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowAddItemModal(false)
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={handleAddButton}>
                    {translate('misc.add')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>

    </View>
  );
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    justifyContent: "space-between",
    flexGrow: 1
  },
});
