

const Outfit = {
  100: {
    normal : "Outfit_100Thin",
  },
  200: {
    normal : "Outfit_200ExtraLight",
  },
  300: {
    normal : "Outfit_300Light",
  },
  400: {
    normal : "Outfit_400Regular",
  },
  500: {
    normal : "Outfit_500Medium",
  },
  600: {
    normal : "Outfit_600SemiBold",
  },
  700: {
    normal : "Outfit_700Bold",
  },
  800: {
    normal : "Outfit_800ExtraBold",
  },
  900: {
    normal : "Outfit_900Black",
  }
}

const Roboto = {
  100: {
    normal: 'Roboto-Light',
    italic: 'Roboto-LightItalic',
  },
  200: {
    normal: 'Roboto-Light',
    italic: 'Roboto-LightItalic',
  },
  300: {
    normal: 'Roboto-Light',
    italic: 'Roboto-LightItalic',
  },
  400: {
    normal: 'Roboto-Regular',
    italic: 'Roboto-Italic',
  },
  500: {
    normal: 'Roboto-Medium',
  },
  600: {
    normal: 'Roboto-Medium',
    italic: 'Roboto-MediumItalic',
  },
}

const fontConfig = {
  Roboto,
  Outfit
}

const fonts = {
  heading: 'Outfit',
    body: 'Outfit',
    mono: 'Outfit',
}

export default {
  fontConfig,
  fonts
}
