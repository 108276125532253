import Purchases from 'react-native-purchases';
import {REVENUECAT_APPLE_KEY, REVENUECAT_GOOGLE_KEY} from "../environment"
import {Platform} from "react-native";

const APIKeys = {
  apple: REVENUECAT_APPLE_KEY,
  google: REVENUECAT_GOOGLE_KEY,
};

export const initializeRevenueCat = async (appUserId) => {
  await Purchases.setLogLevel(Purchases.LOG_LEVEL.DEBUG);

  if (Platform.OS == "android") {
    await Purchases.configure({ apiKey: APIKeys.google, appUserID: `${appUserId}` });
  } else {
    await Purchases.configure({ apiKey: APIKeys.apple, appUserID: `${appUserId}` });
  }
};

export const getOfferingsPackages = async (offeringsID) => {
  try {
    const offerings = await Purchases.getOfferings();

    return offerings.all[offeringsID].availablePackages

  } catch (e) {
    console.error('Error getting offerings:', e);
    return null;
  }
};

export const getCustomerInfo = async () => {
  try {
    return await Purchases.getCustomerInfo();
  } catch (e) {
    console.error('Error getting Customer Info:', e);
    return null;
  }
};

export const purchasePackage = async (pack) => {
  try {
    console.log("purchasing..:")
    await Purchases.purchasePackage(pack)
    return true;
  } catch (e) {
    console.log("purchase error: ",e)
    return false;
  }
};

export const hasActiveSubscription = async () => {
    const customerInfo = await Purchases.getCustomerInfo();

    return typeof customerInfo.entitlements.active["pro"] !== "undefined";
};

export const customerInfoUpdateListener = Purchases.addCustomerInfoUpdateListener;
