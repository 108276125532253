import React, {useState, useEffect} from 'react';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import JoinRequestModel from "../../../models/JoinRequestModel";
import TimeFormatter from "../../../utils/TimeFormatter";

import {
  View, Text, Icon, Divider, Avatar, HStack, TextArea,
  Button, ScrollView, Center, Modal, FormControl, useColorMode, Spinner, Box, useTheme
} from "native-base";
import apiClient from "../../../utils/apiClient";
import TableConverter from "../../../utils/TableConverter";
import {Cell, Row, Table, TableWrapper} from "react-native-table-component-2";
import {StyleSheet} from "react-native";
import AuthUserManager from "../../../utils/AuthUserManager";
import {localInfo, translate} from "../../../utils/Translate";
import TokenManager from "../../../utils/TokenManager";
import OfferSkeleton from "../../../components/skeleton/OfferSkeleton";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const SectionTitle = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}


export default ({ route, navigation }) => {

  const { requestId } = route.params;

  const {colorMode} = useColorMode();
  const { colors }  = useTheme();

  const isDark = colorMode === 'dark';

  const [joinRequest, setJoinRequest] = useState(JoinRequestModel)
  const [token, setToken] = useState()
  const [currentUser, setCurrentUser] = useState(null);

  const [requestItemsTableData, setRequestItemsTableData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // Tables Data
  const widthArr = [150, 150, 150]

  const itemsTableHead = [
    translate('screens.tender.priceTableHeader.item'),
    translate('screens.tender.priceTableHeader.unit'),
    translate('screens.tender.priceTableHeader.quantity')
  ]

  const pendingText   = translate('components.offerCard.pending');
  const acceptedText  = translate('components.offerCard.accepted');
  const rejectedText  = translate('components.offerCard.rejected');

  const [reason, setReason] = useState("");

  useEffect(() => {
    (async () => {
      let user      = await AuthUserManager.get();
      let authToken = await TokenManager.get()

      setToken(authToken)
      setCurrentUser(user)
    })();

    getJoinRequest()

    navigation.addListener('focus', () => {
      getJoinRequest()
    });
  }, [navigation]);

  function getJoinRequest(){
    apiClient.get(`requests/${requestId}`).then( response => {
      setJoinRequest(response.data)

      if (response.data.grouprequestitemtables.length > 0){
        getJoinRequestItemsTable(response.data.grouprequestitemtables)
      }

    }).catch(error => console.log(error));
  }

  function getJoinRequestItemsTable(priceTables){
    let tableArr = TableConverter.itemsToArray(priceTables)
    setRequestItemsTableData(tableArr);
  }

  const acceptRequest = () => {
    setIsSending(true)

    apiClient.post(`requests/${requestId}/action`, {
      action: 'accepted',
    }).then((response) => {
      getJoinRequest()
      setShowConfirmModal(false)
      setIsSending(false)
    }).catch((error) => console.log(error.response.data))
  }

  const rejectOffer = () => {
    setIsSending(true)

    apiClient.post(`requests/${requestId}/action`, {
      action: 'rejected',
      reason: reason
    }).then((response) => {
      getJoinRequest()
      setShowRejectModal(false)
      setIsSending(false)
    }).catch((error) => console.log(error.response.data))
  }

  const handleReasonText = (text) => {
    setReason(text)
  }

  function goToUserProfile(){
    navigation.navigate('UserProfile', {
      profileId: !!joinRequest.company_id ? joinRequest.company_id : joinRequest.user_id,
      profileType: !!joinRequest.company_id ? 'company' : 'user'
    })
  }

  function getUserName(){
    let userName = !!joinRequest.company_id ? joinRequest.company.name : !!joinRequest.user ? joinRequest.user.full_name : null

    if (userName === "[deleted]"){
      userName = translate('misc.deletedUser')
    }
    return userName
  }

  function isDeletedUser(){
    let userName = !!joinRequest.company_id ? joinRequest.company.name : !!joinRequest.user ? joinRequest.user.full_name : null
    return userName === "[deleted]"
  }

  function getStatusBgColor(statusName){
    let bgColor = ""

    if (statusName === "pending" && isDark){
      bgColor = "appAccentDark";
    } else if (statusName === "pending" && !isDark){
      bgColor = "appAccent";
    } else if (statusName === "rejected"){
      bgColor = "appSecondary";
    } else {
      bgColor = "appPrimary";
    }

    return bgColor
  }

  const MessageButton = () => {

    let canTextOwner = !!joinRequest && !!joinRequest.company_id ? !!joinRequest.company.text_me : !!joinRequest.user.text_me;

    if (!!joinRequest && !joinRequest.is_request_owner && canTextOwner){
      return (
        <Button
          mr={2} leftIcon={<Icon as={<MaterialCommunityIcons name="chat-processing-outline" />} size={5} />}
          onPress={() => {
            navigation.navigate('Chat', {
              typeId: !!joinRequest.company_id ? joinRequest.company_id : joinRequest.user_id,
              type: !!joinRequest.company_id ? 'companies' : 'users',
              currentSenderId: !!currentUser.company_id ? currentUser.company_id : currentUser.id,
              token: token
            })
          }}
        >
          <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
            {translate('screens.tender.message')}
          </Text>
        </Button>
      )
    }

    return null;
  }

  if (!joinRequest.id){
    return (
      <OfferSkeleton/>
    )
  }

  return (
    <View bg={isDark ? "coolGray.800" : "white"} h="100%">
      <ScrollView mx={4}>

        <View flexDirection="row" mt={2} mb={4} >

          <View>
            <Avatar
              mt={2}
              size="xl"
              source={{ uri: !!joinRequest.company_id ? joinRequest.company.avatar : !!joinRequest.user ? joinRequest.user.avatar : null }}
            />
          </View>

          <View flex={1} ml={4} pt={2} >
            <Text textAlign="left" fontSize={18} fontWeight={500} >
              {getUserName()}
            </Text>

            {
              !isDeletedUser() ? (
                <View flexDirection="row" mt={3}>

                  <MessageButton/>

                </View>
              ) : null
            }

          </View>
        </View>

        <Divider thickness="2" />

        {/*{*/}
        {/*  (joinRequest.has_reviews && joinRequest.is_selected) && (joinRequest.is_group_owner || joinRequest.is_request_owner ) ? (*/}
        {/*    <View py={4}>*/}
        {/*      <SectionTitle title={translate('screens.offer.reviewOptions')} />*/}

        {/*      <HStack*/}
        {/*        flexWrap="wrap" mt={3}*/}
        {/*        justifyContent={joinRequest.is_group_manager && joinRequest.status.name !== 'accepted' ? "space-evenly" : "flex-start" }*/}
        {/*      >*/}
        {/*        <Button*/}
        {/*          borderRadius={8}*/}
        {/*          _text={{*/}
        {/*            fontSize: 20*/}
        {/*          }}*/}
        {/*          onPress={() => navigation.navigate('ReviewSteps', {requestId, authId: currentUser.id})}*/}
        {/*        >*/}
        {/*          {translate('screens.offer.reviewSteps')}*/}
        {/*        </Button>*/}
        {/*        {*/}
        {/*          joinRequest.is_group_manager && joinRequest.status.name !== 'accepted' ? (*/}
        {/*            <Button*/}
        {/*              onPress={() => setShowRejectModal(true)}*/}
        {/*              borderRadius={8}*/}
        {/*              colorScheme="secondary"*/}
        {/*              _text={{*/}
        {/*                fontSize: 20*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              {translate('screens.offer.cancelSelection')}*/}
        {/*            </Button>*/}
        {/*          ) : null*/}
        {/*        }*/}
        {/*      </HStack>*/}
        {/*    </View>*/}
        {/*  ) : null*/}
        {/*}*/}

        <Divider thickness="2" />

        <View>
          <SpacerMY space={2}/>


          <View mt={3}>

            <Text textAlign="left" fontWeight={500} fontSize={18}>
              {translate('screens.offer.submissionDate')} : {!!joinRequest.created_at ? TimeFormatter.getFormattedDate(joinRequest.created_at) : null}
            </Text>

            <HStack mt={4}>
              <Text textAlign="left" fontWeight={500} fontSize={18}>
                {translate('misc.status')} :
              </Text>
              <Box
                bg={getStatusBgColor(joinRequest.status.name)}
                ml={2} py={1} px={2} borderRadius={5}>
                <Center>
                  <Text textAlign="left" fontWeight={500} fontSize={18} color="white">
                    { joinRequest.status.name === "pending" ? pendingText : joinRequest.status.name === "accepted" ?
                      acceptedText : joinRequest.status.name === "rejected" ? rejectedText : null }
                  </Text>
                </Center>
              </Box>
            </HStack>
            {
              joinRequest.status.name === "rejected" && !!joinRequest.reason ? (
                <Text mt={3} textAlign="left" fontWeight={500} fontSize={18}>
                  {translate('misc.reason')} : {joinRequest.reason}
                </Text>
              ) : null
            }
          </View>
        </View>

        <SpacerMY space={2}/>

        {
          requestItemsTableData.length === 0 ? null : <View mt={8}>
            <SectionTitle title={translate('drawer.groupBuying.itemsTable.title')}/>

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={itemsTableHead} style={styles.head} textStyle={styles.headText}/>

                    {requestItemsTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={cellData}
                            textStyle={styles.text}
                          />
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>

          </View>
        }

        <View mt={5} />

        {/*{*/}
        {/*  joinRequest.offer_required_docs.length > 0 ? (*/}
        {/*    <View mt={4} mb={6}>*/}
        {/*      <SectionTitle title={translate('screens.tender.documents')} />*/}
        {/*      <View height={1} backgroundColor="appPrimary" w={60}/>*/}

        {/*      {*/}
        {/*        joinRequest.offer_required_docs.map(doc => (*/}
        {/*          <>*/}
        {/*            <View mt={4} />*/}
        {/*            <Text fontWeight={600} fontSize={18}>*/}
        {/*              {doc.required_doc_name} :*/}
        {/*            </Text>*/}
        {/*            {*/}
        {/*              doc.attachments.map((file, index) => (*/}
        {/*                <View key={index} mt={2}>*/}
        {/*                  <TouchableOpacity*/}
        {/*                    onPress={async () => {*/}
        {/*                      await saveDownloadHistory(file.id)*/}
        {/*                      await WebBrowser.openBrowserAsync(encodeURI(file.path));*/}
        {/*                    }}*/}
        {/*                  >*/}
        {/*                    <HStack alignItems="center">*/}
        {/*                      <Icon as={MaterialCommunityIcons} name="folder-download-outline" size="md" color="appPrimary" />*/}
        {/*                      <View mx={1}/>*/}
        {/*                      <Text textAlign="left" fontSize={16} color="appPrimary">*/}
        {/*                        {`${file.name} - ${formatBytes(file.size)}`}*/}
        {/*                      </Text>*/}
        {/*                    </HStack>*/}
        {/*                  </TouchableOpacity>*/}
        {/*                </View>*/}
        {/*              ))*/}
        {/*            }*/}
        {/*          </>*/}
        {/*        ))*/}
        {/*      }*/}
        {/*    </View>*/}
        {/*  ) : null*/}
        {/*}*/}

        <View mt={50} />
        {
          !joinRequest.is_request_owner && joinRequest.status.name === 'pending' && joinRequest.is_group_manager ? (
           <View>
             <Button borderRadius={10} onPress={() => setShowConfirmModal(true)}>
               <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                 {translate('drawer.groupBuying.acceptRequest')}
               </Text>
             </Button>
           </View>
         ) : null
        }
        {
          !joinRequest.is_request_owner && (joinRequest.status.name === 'accepted' || joinRequest.status.name === 'pending') && joinRequest.is_group_manager ? (
           <View mt={4}>
             <Button
               borderRadius={10}
               onPress={() => setShowRejectModal(true)}
               colorScheme={"secondary"}
             >
               <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                 {translate('drawer.groupBuying.rejectRequest')}
               </Text>
             </Button>
           </View>
         ) : null
        }
        {
         joinRequest.is_request_owner ? (
           <View>
             <Button
               isDisabled={joinRequest.is_group_closed} borderRadius={10}
               onPress={() => navigation.navigate(
                 "UpdateRequest",
                 {groupbuyingId: joinRequest.groupbuying_id, joinRequest}
                 )}
             >
               <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                 {translate('drawer.groupBuying.updateRequest')}
               </Text>
             </Button>
           </View>
         ) : null
        }
        <View mb={20} />

        {/* Confirm Selecting Offer Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.acceptRequest')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={18} textAlign="center">
                    {translate('drawer.groupBuying.acceptRequestMsg')}
                  </Text>

                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={acceptRequest} disabled={isSending}>
                    {
                      isSending ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.yes')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Confirm Rejecting Offer Modal */}
        <Center>
          <Modal avoidKeyboard size={["xl", "xl", "md"]} isOpen={showRejectModal} onClose={() => setShowRejectModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.rejectRequest')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  {/*<Text mt={3} fontSize={18} textAlign="center">*/}
                  {/*  {translate('drawer.groupBuying.rejectRequestMsg')}*/}
                  {/*</Text>*/}
                </Center>

                <Text mt={3} fontSize={18} textAlign="left">
                  {translate('misc.reason')} :
                </Text>

                <FormControl mt={3}>
                  <TextArea
                    textAlign="left"
                    placeholder={translate('misc.reason')}
                    h={150} rounded={10} size="xl" borderColor="gray.500"
                    onChangeText={handleReasonText}
                  />
                </FormControl>

              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowRejectModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={rejectOffer} isDisabled={reason.length < 10} disabled={reason.length < 10 || isSending}>
                    {
                      isSending ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.reject')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  wrapper: { flexDirection: 'row' },
  title: { flex: 1, backgroundColor: '#f6f8fa', width: 200 },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center'
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
