import React, {useEffect, useRef, useState} from 'react';
import {MaterialIcons} from "@expo/vector-icons";
import { TouchableOpacity } from "react-native"
import MyTenderCard from "../components/MyTenderCard";

import {
  View, Text, Icon, ScrollView, useColorModeValue, Spinner
} from "native-base";

import apiClient from "../utils/apiClient";
import TenderCard from "../components/TenderCard";
import {localInfo, translate} from "../utils/Translate";
import SelectListModal from "../components/SelectListModal";
import SearchInput from "../components/SearchInput";
import UserNoTendersYet from "../components/UserNoTendersYet";
import NoItemYet from "../components/NoItemYet";
import TenderCardSkeleton from "../components/skeleton/TenderCardSkeleton";
import MyTenderCardSkeleton from "../components/skeleton/MyTenderCardSkeleton";
import CountryStatesModalList from "../components/CountryStatesModalList";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

export default ({ route, myTenders, authId, navigation }) => {

  const { userId, isUserTenders, searchValue } = !!route ? route.params : {isUserTenders: myTenders, userId: authId, searchValue: null};
  const [userTenders, setUserTenders] = useState(null);
  const [showSelectBranchesModal, setShowSelectBranchesModal] = useState(false);
  const [showSelectStatesModal, setShowSelectStatesModal] = useState(false);
  const [branches, setBranches] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(false)

  const filteredStates = useRef([])
  const filteredBranches = useRef([])
  const searchText = useRef('')
  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  function getTenders(){

    let userTender = isUserTenders ? `users/${userId}/` : '';

    apiClient.get(`${userTender}tenders?lang=${localInfo.local}`).then( response => {
      getNextPage(response.data.next_page_url)
      setUserTenders(response.data.data)

    }).catch(error => console.log(error));
  }

  function getFilteredTenders(isLoadMore = false){

    let branchFilter = `filter[branch_id]=${filteredBranches.current}`;
    let stateFilter  = `filter[state_id]=${filteredStates.current}`;
    let titleFilter  = `filter[title]=${searchText.current}`
    let userTender = isUserTenders ? `users/${userId}/` : '';
    let nextP      = nextPage.current.length ? `&${nextPage.current}` : '';

    let query = `${userTender}tenders?lang=${localInfo.local}&${branchFilter}&${stateFilter}&${titleFilter}${nextP}`;

    apiClient.get(query).then( response => {

      if (isLoadMore && nextPage.current.length){
        getNextPage(response.data.next_page_url)
        setUserTenders(old => [...old, ...response.data.data])

        setIsLoadingData(false)
        isLoadingMoreRef.current = false
        return;
      }

      getNextPage(response.data.next_page_url)
      setUserTenders(response.data.data)

      setIsLoadingData(false)
      isLoadingMoreRef.current = false
    }).catch(error => console.log(error));
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  function getBranches(){
    apiClient.get(`branches`).then(response => {
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error.response.data));
  }
  function setBranchesOfLanguage(branches, language){
    let branchesOfLanguage = branches.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
        language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(branchesOfLanguage)
  }

  const onBranchesSelected = (item) => {
    filteredBranches.current = item;
    setShowSelectBranchesModal(false)

    getFilteredTenders()
  }
  const onStatesSelected = (item) => {
    filteredStates.current = item;
    setShowSelectStatesModal(false)

    getFilteredTenders()
  }
  const onSelectBranchesModalCanceled = () => {
    setShowSelectBranchesModal(false)
  }
  const onSelectStatesModalCanceled = () => {
    setShowSelectStatesModal(false)
  }
  const onSearch = (text) => {
    searchText.current = text;
    getFilteredTenders();
  }

  function loadMore(){
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    setIsLoadingData(true)
    isLoadingMoreRef.current = true

    getFilteredTenders(true)
  }

  useEffect(() => {
    if (!!searchValue){
      searchText.current = searchValue;
      getFilteredTenders();

      return;
    }

    getTenders();
    getBranches()

    navigation.addListener('focus', () => {
      getTenders()
    });

  }, [navigation]);

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  return (
    <View px={3} bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            loadMore()
          }
        }}
        scrollEventThrottle={400}
      >
        <View mt={4}>
          <Title title={
            isUserTenders ? translate('screens.tenders.userTendersTitle') : translate('screens.tenders.tendersTitle')
          } />
        </View>

        <View mt={5} mb={3}>

          <SearchInput
            onSubmit={onSearch}
            value={!!searchText.current ? searchText.current : searchValue}
            onChange={(text) => {
              searchText.current = text
            }}
            onEmpty={() => {
              searchText.current = '';
              getFilteredTenders();
            }}
          />

          <View mt={4}/>

          <View flexDirection="row" justifyContent="space-between">

            {/*<TouchableOpacity>*/}
            {/*  <Icon as={<SimpleLineIcons name="equalizer" />}*/}
            {/*        color={"appPrimary"} size={6}*/}
            {/*        style={{*/}
            {/*          transform: [{ rotate: '90deg'}]*/}
            {/*        }}*/}
            {/*  />*/}
            {/*</TouchableOpacity>*/}

            <View flexDirection="row">
              <TouchableOpacity
                onPress={() => setShowSelectBranchesModal(true)}
              >
                <View flexDirection="row">
                  <Icon as={<MaterialIcons name="filter-list" />} color={"appPrimary"} size={7}/>
                  <Text textAlign="left" fontWeight={500} fontSize={16}>
                    {translate('screens.tenders.Branches')}
                  </Text>
                </View>
              </TouchableOpacity>

              <View mx={2} />

              <TouchableOpacity
                onPress={() => setShowSelectStatesModal(true)}
              >
                <View flexDirection="row">
                  <Icon as={<MaterialIcons name="filter-list" />} color={"appPrimary"} size={7}/>
                  <Text textAlign="left" fontWeight={500} fontSize={16}>
                    {translate('screens.tenders.states')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        {
          isUserTenders ? (
            <View>
              {
                !!userTenders && userTenders.length ? (
                  <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                    {
                      userTenders.map((tender, index) => {
                        return (
                          <MyTenderCard
                            key={index}
                            id={tender.id}
                            title={tender.title}
                            branch={tender.branch.name[localInfo.local]}
                            description={tender.description}
                            date={tender.closing_date}
                            offers={tender.offers_count}
                          />
                          );
                      })
                    }
                  </View>
                ) : null
              }
              {
                userTenders === null ? (
                  <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                    {[1,2,3].map((tender, index) => {
                      return (
                        <MyTenderCardSkeleton key={index}/>
                      );
                    })}
                  </View>
                ) : null
              }
              {
                !!userTenders && !userTenders.length ? (
                  <UserNoTendersYet/>
                ) : null
              }

            </View>
          ) : (
            <View>
              {
                !!userTenders && userTenders.length ? (
                  <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                    {
                      userTenders.map((tender, index) => {
                        return (
                          <TenderCard
                            key={index}
                            id={tender.id}
                            postTime={tender.created_at}
                            location={tender.state.name[localInfo.local]}
                            title={tender.title}
                            branch={tender.branch.name[localInfo.local]}
                            description={tender.description}
                            date={tender.closing_date}
                            offers={tender.offers_count}
                            tenderableType={tender.tenderable_type}
                            avatar={tender.tenderable.avatar}
                          />
                        );
                      })
                    }
                  </View>
                ) : null
              }
              {
                !!userTenders && !userTenders.length ? (
                  <NoItemYet text={translate('components.noTendersYet')}/>
                ) : null
              }
            </View>
          )
        }

        {
          !isUserTenders && userTenders === null ? (
            <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
              {[1,2,3].map((tender, index) => {
                return (
                  <TenderCardSkeleton key={index}/>
                );
              })}
            </View>
          ) : null
        }

        {
          isLoadingData ? (
            <View mt={10}>
              <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
            </View>
          ) : null
        }

        <View mb={20}/>

        {
          branches.length ? (
            <SelectListModal
              visible={showSelectBranchesModal}
              items={branches}
              selectText={translate('misc.branches')}
              modalHeaderText={translate('screens.auth.interests.selectBranches')}
              doneText={translate('misc.done')}
              cancelText={translate('misc.cancel')}
              showSelectAll={false}
              onDone={onBranchesSelected}
              onCancel={onSelectBranchesModalCanceled}
            />
          ) : null
        }

        <CountryStatesModalList
          visible={showSelectStatesModal}
          modalHeaderText={translate('screens.auth.interests.selectCountryStates')}
          doneText={translate('misc.done')}
          cancelText={translate('misc.cancel')}
          showSelectAll={true}
          onDone={onStatesSelected}
          onCancel={onSelectStatesModalCanceled}
        />

      </ScrollView>
    </View>
  );
}
