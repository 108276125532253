import {Box, View, Skeleton, HStack, Spacer} from "native-base";
import React from "react";

export default () => {

  return (
    <Box pr={2} borderRadius={7} borderBottomWidth="1" _dark={{
      borderColor: "gray.600",
      bg: "coolGray.700",
      padding: 2,
    }} borderColor="coolGray.200" py="2">
      <HStack space={3} justifyContent="space-between">

        <Skeleton size="16" rounded="full" mt={2} />

        <View w={["80%", "80%", "90%"]}>
          <HStack justifyContent="space-between">

            <View>
              <Skeleton size="3" rounded={'md'} w={40} mt={4} />
              <Skeleton size="3" rounded={'md'} w={24} mt={4} />
            </View>

            <View mx={1}>
              <Skeleton size="3" rounded={'md'} w={20} mt={4} />
            </View>

          </HStack>

          <Spacer />

        </View>

      </HStack>
    </Box>
  )
}
