import React, {useEffect, useRef, useState} from 'react';

import {
  View, Text, ScrollView, Center, Box, Modal,
  ZStack, Avatar, Icon, HStack, FormControl, Input, Button, Checkbox, useColorModeValue, useColorMode, Spinner, useTheme
} from "native-base";
import {AntDesign} from "@expo/vector-icons";
import {TouchableOpacity} from "react-native";
import UserWithTitle from "../../components/UserWithTitle";
import UserModel from "../../models/UserModel";
import {AuthContext} from "../../utils/context/auth";
import apiClient from "../../utils/apiClient";
import TokenManager from "../../utils/TokenManager";
import {localInfo, translate} from "../../utils/Translate";
import {Platform} from "react-native";
import AlertModal from "../../components/AlertModal";

const Title = ({ title, white }) => {
  if (white){
    return <View>
      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
      <View height={1} backgroundColor="appPrimary" w={60}/>
    </View>
  }
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize={32} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="primary.50" w={60}/>
  </View>
}

const PeopleStack = ({ members }) => {
  return (
    <View>
      <ZStack>
        {
          members.length === 0 ? null : members.map((member, index) => (
            <View key={index}>
              <Avatar size="lg" ml={index * 10} source={{ uri : member.avatar }} />
            </View>
          ))
        }
      </ZStack>
    </View>
  );
};

const Member = ({ id, currentUserId, userName, userTitle, avatar, onChat, onEdit, onDelete, permissions, isDark }) => {

  if (userName === "[deleted]"){
    userName = translate('misc.deletedUser')
  }

  return (
    <HStack
      px={3} pt={1} my={1} justifyContent="space-between"
      _dark={{
        bg: "coolGray.700"
      }}
    >
      <UserWithTitle
        name={userName}
        title={userTitle}
        avatar={avatar}
        onlyUser={true}
      />
      {
        currentUserId !== id ? (
          <HStack alignItems="center">
            <TouchableOpacity onPress={() => onChat(id)}>
              <Icon as={<AntDesign name="wechat"/>} color={isDark ? "white" : "appAccent"} size={6} />
            </TouchableOpacity>
            <View mx={1}/>
            <TouchableOpacity onPress={() => onEdit(id, userTitle, permissions)}>
              <Icon as={<AntDesign name="edit"/>} color={isDark ? "white" : "appAccent"} size={6} />
            </TouchableOpacity>
            <View mx={1}/>
            <TouchableOpacity onPress={() => onDelete(id, userName)}>
              <Icon as={<AntDesign name="delete"/>} color={isDark ? "white" : "appSecondary"} size={6} />
            </TouchableOpacity>
          </HStack>
        ) : (
          <TouchableOpacity onPress={() => onEdit(id, userTitle, permissions)}>
            <Icon as={<AntDesign name="edit"/>} color={isDark ? "white" : "appAccent"} size={6} />
          </TouchableOpacity>
        )
      }

    </HStack>
  );
};

const PermissionsList = (props) => {
  return (
    <View>
      {
        props.permissions.map((permission, index) => (
          <HStack key={index} justifyContent="space-between" my={1}>
            <HStack>

              <View ml={2}>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500}>
                  {permission.name.en}
                </Text>
              </View>
            </HStack>

            <Center>
              <Checkbox
                shadow={1} value={permission.value}
                accessibilityLabel="permission"
                isChecked={!!props.currentPermissions ? props.currentPermissions.includes(permission.value) : undefined}
                onChange={(value) => {
                  props.onChecked(value, permission.value, index)
                }}
              />
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                {translate('drawer.screens.teamManagement.select')}
              </Text>
            </Center>

          </HStack>
        ))
      }
    </View>
  );
};

export default ({ navigation }) => {

  const { authUser, isCompany } = React.useContext(AuthContext);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [newMemberName, setNewMemberName] = useState(null);
  const [newMemberEmail, setNewMemberEmail] = useState(null);
  const [newMemberRole, setNewMemberRole] = useState(null);
  const [newMemberPermissions, setNewMemberPermissions] = useState([]);
  const [user, setUser] = useState(UserModel)
  const [token, setToken] = useState(UserModel)
  const [members, setMembers] = useState([])
  const [tempUserId, setTempUserId] = useState(null)
  const [tempUserRole, setTempUserRole] = useState(null)
  const [tempUserName, setTempUserName] = useState(null)
  const [tempUserPermissions, setTempUserPermissions] = useState([])
  const [showAlertModal, setShowAlertModal] = useState(false);

  const {colorMode} = useColorMode();
  const isDark      = colorMode === 'dark';
  const { colors }  = useTheme();

  const permissions = [
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.owner'),
      },
      value: 'owner'
    },
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.manageMembers'),
      },
      value: 'manage members'
    },
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.publishTenders'),
      },
      value: 'publish tenders'
    },
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.manageGroupBuying'),
      },
      value: 'manage group buying'
    },
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.sendOffers'),
      },
      value: 'send offers'
    },
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.readReplyMessages'),
      },
      value: 'read reply messages'
    },
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.accessStatistics'),
      },
      value: 'access statistics'
    },
    {
      name: {
        en: translate('drawer.screens.teamManagement.permissions.accessCompanySettings'),
      },
      value: 'access settings'
    },
  ]

  const handleAddMember = () => {
    // If this is a demo account return alert message
    if (user.id === 10193){
      setShowAlertModal(true);
      return;
    }
    setIsSending(true)

    let data = {
      member: {
        full_name : newMemberName,
        company_role : newMemberRole,
        email : newMemberEmail
      },
      permissions: newMemberPermissions
    }

    apiClient.post(`companies/${user.company_id}/members`, data).then(() => {
      getMembers(user.company_id)
      setShowAddModal(false);
      setIsSending(false)
    }).catch(error => {
      setIsSending(false)
      console.log(error.response.data)
    })
  }

  const handleSaveEdit = () => {
    // If this is a demo account return alert message
    if (user.id === 10193){
      setShowAlertModal(true);
      return;
    }

    setIsSending(true)

    let data = {
      companyRole : tempUserRole,
      permissions: tempUserPermissions
    }

    apiClient.put(`companies/${user.company_id}/members/${tempUserId}`, data).then(() => {
      getMembers(user.company_id)
      setTempUserId(null)
      setShowEditModal(false);
      setIsSending(false)
    }).catch(error => {
      console.log(error.response.data)
      setIsSending(false)
    })
  }

  const handleDeleteMember = () => {
    // If this is a demo account return alert message
    if (user.id === 10193){
      setShowAlertModal(true);
      return;
    }
    apiClient.delete(`companies/${user.company_id}/members/${tempUserId}`).then(() => {
      getMembers(user.company_id)
      setShowDeleteModal(false);
      setTempUserId(null)
    }).catch(error => console.log(error.response.data))
  }

  function getMembers(companyId){
    apiClient.get(`companies/${companyId}/members?full=true`).then((res) => {
      setMembers(res.data)
      // TODO add pagination
    }).catch(error => console.log(error.response.data))
  }

  const handleNewMemberName = (name) => {
    setNewMemberName(name)
  }

  const handleNewMemberEmail = (email) => {
    setNewMemberEmail(email)
  }

  const handleNewMemberRole = (role) => {
    setNewMemberRole(role)
  }

  const handleTempMemberRole = (role) => {
    setTempUserRole(role)
  }

  function emptyFields(){
    setNewMemberName(null)
    setNewMemberEmail(null)
    setNewMemberRole(null)
  }


  useEffect(() => {
    (async() => {
      const user = await authUser()
      getMembers(user.company_id)
      setUser(user);

      const authToken = await TokenManager.get()
      setToken(authToken)
    })()

  }, []);


  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>
        <View bg={isCompany ? colors.appPrimary : colors.particular} borderBottomRightRadius={600}>
          <View m={4}>
            <View flexDirection="row">
              <Title title={translate('drawer.screens.teamManagement.title')} />
            </View>
            <View flexDirection="row">
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} my={2} color="primary.50" fontSize={16}>
                {translate('drawer.screens.teamManagement.description')}
              </Text>
            </View>
          </View>
        </View>

        <View mt={2} ml={3} mb={2}>
          <View flexDirection="row">
            <Title white={true} title={`${translate('drawer.screens.teamManagement.members')}: ${members.length}`}/>
          </View>
        </View>

        <HStack ml={3} mt={4} justifyContent="space-between">
          <PeopleStack
            members={members.slice(0, 5)}
          />

          <TouchableOpacity
            onPress={() => setShowAddModal(true)}
          >
            <Box
              mr={3} borderStyle="dashed" borderWidth={2}
              borderRadius={100} w={65} h={65}
              justifyContent="center" alignContent="center"
              _dark={{
                borderColor: "white",
                borderWidth: 3
              }}
            >
              <Center>
                <Icon as={<AntDesign name="plus"/>} size={6} />
              </Center>
            </Box>
          </TouchableOpacity>
        </HStack>

        <View mt={10} />

        {
          members.length !== 0 ? members.map((member, index) => (
            <View key={index} pr={2}>
              <Member
                id={member.id}
                currentUserId={user.id}
                userName={member.full_name}
                userTitle={member.company_role}
                avatar={member.avatar}
                permissions={member.permissions}
                isDark={isDark}
                onDelete={(userId, userName) => {
                  setTempUserName(userName)
                  setTempUserId(userId)
                  setShowDeleteModal(true)
                }}
                onEdit={(userId, role, permissions) => {
                  let memberPermissions = [];
                  permissions.map(permission => memberPermissions.push(permission.name))
                  setTempUserPermissions(memberPermissions)

                  setTempUserId(userId)
                  setTempUserRole(role)
                  setShowEditModal(true)
                }}
                onChat={(userId) => {
                  navigation.navigate('Chat', {
                    type: 'users',
                    typeId: userId,
                    currentSenderId: user.id,
                    token: token
                  })
                }}
              />
            </View>
          )) : null
        }

        {/* Add Member Modal */}
        <Center>
          <Modal avoidKeyboard size={["xl", "xl", "lg"]} isOpen={showAddModal} onClose={() => setShowAddModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.screens.teamManagement.addMember')}
              </Modal.Header>
              <Modal.Body>

                <FormControl mt={4}>
                  <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={translate('drawer.screens.teamManagement.email')} rounded={10} size="xl"
                    autoCapitalize="none"
                    borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleNewMemberEmail}
                    value={newMemberEmail}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={translate('drawer.screens.teamManagement.fullName')} rounded={10} size="xl"
                    borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleNewMemberName}
                    value={newMemberName}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={translate('drawer.screens.teamManagement.memberRole')} rounded={10} size="xl"
                    borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleNewMemberRole}
                    value={newMemberRole}
                  />
                </FormControl>

                <View mt={5}/>

                <Title white={true} title={translate('drawer.screens.teamManagement.userPermissions')}/>

                <View mt={5} />

                <PermissionsList
                  permissions={permissions}
                  onChecked={(value, permission) => {
                    if (value === true){
                      setNewMemberPermissions(oldArray => [...oldArray, permission])
                    }
                    if (value === false) {
                      setNewMemberPermissions(oldArray => oldArray.filter(item => item !== permission))
                    }

                  }}
                />

                <View mt={20} />

              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    emptyFields()
                    setShowAddModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={handleAddMember}>
                    {
                      isSending ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('drawer.screens.teamManagement.sendInvitation')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Edit Member Modal */}
        <Center>
          <Modal avoidKeyboard size={["xl", "xl", "lg"]} isOpen={showEditModal} onClose={() => {
            setShowEditModal(false);
          }}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.screens.teamManagement.editMember')}
              </Modal.Header>
              <Modal.Body>

                <FormControl mt={4}>
                  <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={translate('drawer.screens.teamManagement.memberRole')} rounded={10} size="xl"
                    borderColor="gray.500" pl={4} py={3}
                    onChangeText={handleTempMemberRole}
                    value={tempUserRole}
                  />
                </FormControl>

                <View mt={5}/>

                <Title white={true} title={translate('drawer.screens.teamManagement.userPermissions')} />

                <View mt={5} />

                <PermissionsList
                  permissions={permissions}
                  currentPermissions={tempUserPermissions}
                  onChecked={(value, permission) => {
                    if (value === true){
                      setTempUserPermissions(oldArray => [...oldArray, permission])
                    }
                    if (value === false) {
                      setTempUserPermissions(oldArray => oldArray.filter(item => item !== permission))
                    }

                  }}
                />

                <View mt={20} />

              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowEditModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={handleSaveEdit}>
                    {
                      isSending ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.save')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Delete Member Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showDeleteModal} onClose={() => {
            setShowDeleteModal(false);
          }}>
            <Modal.Content>
              <Modal.Header>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {translate('drawer.screens.teamManagement.deleteMember')}
                </Text>
              </Modal.Header>
              <Modal.Body>
                <Center>

                  <HStack>

                    <Text textAlign="center" fontSize={18}>
                      {translate('drawer.screens.teamManagement.deleting')}
                    </Text>

                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                      mx={1} textAlign="center"
                      fontSize={18} fontWeight={600}
                      color="appSecondary"
                    >
                      "{tempUserName}"
                    </Text>

                  </HStack>
                  <Text textAlign="center" fontSize={18}>
                    {translate('drawer.screens.teamManagement.confirmDelete')}
                  </Text>

                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowDeleteModal(false);
                    setTempUserId(null)
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={handleDeleteMember}>
                    {translate('drawer.screens.teamManagement.delete')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        <AlertModal alertText={translate('demoAlert.message')} isOpen={showAlertModal} onClose={() => setShowAlertModal(false)}/>

      </ScrollView>

    </View>
  );
}
