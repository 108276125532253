import React from "react";
import {useNavigation} from "@react-navigation/native";

import {
  Box, Image, Pressable, Text, useColorModeValue, View,
  Center, Avatar, Icon, HStack
} from "native-base";
import {translate} from "../utils/Translate";
import {MaterialIcons} from "@expo/vector-icons";
import {Dimensions} from "react-native";


export default ({id, companyName, avatar, tendersCount, location}) => {
  const navigation = useNavigation();
  const width = Dimensions.get('screen').width

  return <Pressable onPress={() => {
    navigation.navigate('UserProfile', {
      profileId: id,
      profileType: 'company'
    })
  }}>
    <Box
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      borderRadius={8} shadow={3} mt={3} ml={2} mb={1} pb={2}
      w={[width * 0.65, width * 0.25, width * 0.25]}
    >
      <Center>
        <Avatar
          mt={2}
          size="xl"
          source={{ uri : avatar }}
        />
      </Center>

      <View my={3}/>

      <View px={2}>
        <Text fontSize={22} fontWeight={500}>
          {companyName}
        </Text>
      </View>

      <View my={2}/>

      <HStack justifyContent="space-between" px={2}>
        <View maxW="50%" px={2} bg="appPrimary" borderRadius={20} alignSelf="flex-start">
          <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
            {tendersCount} {translate('misc.tenders')}
          </Text>
        </View>
        <View maxW="50%">
          <Center flexDirection="row">
            <Icon as={<MaterialIcons name="location-pin"/>} size={6}/>
            <Text textAlign="left" fontWeight={500} fontSize={16}>
              {location}
            </Text>
          </Center>
        </View>
      </HStack>
    </Box>
  </Pressable>
}
