import React, {useState} from 'react';
import { AntDesign } from "@expo/vector-icons";

import {
  View, Text, Icon, Center, Button, ScrollView, useColorModeValue,
} from "native-base";
import {localInfo, translate} from "../utils/Translate";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ navigation }) => {

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4}>

        <SpacerMY space={4}/>
        <Title title={translate('screens.paymentReview.title')}/>
        <SpacerMY space={4}/>

        <Center mt={10}>
          <Icon as={<AntDesign name="checkcircleo"/>} color="appPrimary" size={100} />
          <Text fontSize={24} textAlign="center" mt={10}>
            {translate('screens.paymentReview.text')}
          </Text>
        </Center>


        <View mt={20}>
          <Button
            borderRadius={10}
            onPress={() => navigation.navigate('tenders')}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={22} fontWeight={500} color="primary.50">
              {translate('screens.paymentReview.buttonText')}
            </Text>
          </Button>
        </View>

      </ScrollView>
    </View>
  );
}
