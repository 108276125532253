
class TableConverter {

    static pointsToArray(pointObjectTables){
        let tableArr = []

        for (const table of pointObjectTables){
            tableArr.push([
                table.item,
                table.requirement,
                table.points,
                table.offer,
                table.total
            ])
        }

        return tableArr;
    }

    static priceToArray(priceObjectTables){
        let tableArr = []

        for (const table of priceObjectTables){
            tableArr.push([
                table.item,
                table.unit,
                table.quantity,
                table.price,
                table.total
            ])
        }

        return tableArr;
    }

    static itemsToArray(priceObjectTables){
        let tableArr = []

        for (const table of priceObjectTables){
            tableArr.push([
                table.item,
                table.unit,
                table.quantity
            ])
        }

        return tableArr;
    }

}

export default TableConverter;
