import StorageManager from './StorageManager'

class TokenManager {

    static async store(token){
        await StorageManager.storeString('token', token);
    }

    static async get(){
       return await StorageManager.getString('token');
    }

    static async remove(){
        await StorageManager.remove('token');
    }

}

export default TokenManager;
