import React, { useState, useEffect } from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Menu, MenuItem } from 'react-native-material-menu';

import {
  View, Text, Icon, Box, Avatar, Center, ScrollView, Pressable, useColorModeValue, Image
} from "native-base";
import apiClient from "../utils/apiClient";
import {TimeFormatter} from "../utils/TimeAgo";
import {localInfo, translate} from "../utils/Translate";
import {AuthContext} from "../utils/context/auth";
import UserModel from "../models/UserModel";
import NoItemYet from "../components/NoItemYet";
import bellIcon from "../assets/icons/notification-bell.png";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const NotificationCard = (props) => {

  const [visible, setVisible] = useState(false);
  const [isSeen, setIsSeen] = useState(props.isSeen);

  const hideMenu = () => setVisible(false);
  const showMenu = () => setVisible(true);

  function markAsRead(notificationId){
    props.onMarkAsRead(notificationId)
    setIsSeen(true);
  }

  return (
    <Pressable onPress={() => {
      !!props.onClicked ?
        !!props.onClicked(props.dataId, props.dataType, props.notificationId)
        : null
    }}>
      <Box
        bg={useColorModeValue("gray.100", "coolGray.700")}
        flexDirection="row" pl={4} py={2} mb={2}
        justifyContent="space-between"
      >
        <Center>
          <Avatar
            source={{ uri: props.avatar }}
            size="lg"
          />
        </Center>
        <View w={["80%", "80%", "90%"]}>

          <View alignItems="flex-end">
            <View flexDirection="row">
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                {TimeFormatter(props.time, localInfo.local)}
              </Text>

              <Menu
                visible={visible}
                anchor={
                  <Icon onPress={showMenu} as={<MaterialCommunityIcons name="dots-vertical" />} size={6} ml="2" />
                }
                onRequestClose={hideMenu}
              >
                <MenuItem onPress={() => {
                  setVisible(false);
                  !!props.onMarkAsRead ?
                    markAsRead(props.notificationId)
                    : null
                }}>
                  {translate('screens.notifications.markAsRead')}
                </MenuItem>
              </Menu>

            </View>

          </View>
          <View flexDirection="row">
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600} fontSize={18} w="90%">
              {
                !!props.tag ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appPrimary">{props.tag} </Text>
                ) : null
              }
              {props.title}
            </Text>
            {
              !isSeen ? (
                <Pressable onPress={() => {
                  !!props.onMarkAsRead ?
                    markAsRead(props.notificationId)
                    : null
                }}>
                  <Center>
                    <View w={5} h={5} bg="appSecondary" borderRadius={100} />
                  </Center>
                </Pressable>
              ) : null
            }
          </View>
        </View>
      </Box>
    </Pressable>
  )
}

const NoNotificationsYet = () => {
  return (
    <Center>
      <Image alt="notification icon" size={150} resizeMode={"contain"} source={bellIcon} />

      <NoItemYet text={translate('screens.notifications.noNotificationsYet')} />
    </Center>
  )
}

export default ({ navigation }) => {
  const { authUser } = React.useContext(AuthContext);

  const [user, setUser] = useState(UserModel)
  const [notifications, setNotifications] = useState(null)

  function getNotifications(id){
    apiClient.get(`users/${id}/notifications`).then( response => {
      setNotifications(response.data)
    }).catch(error => console.log(error.response.data));
  }

  const handleMarkAsRead = (id) => {
    apiClient.post(`notifications/${id}/read`).then( response => {
      getNotifications()
    }).catch(error => console.log(error.response.data));
  }

  async function markAsRead(id){
    return apiClient.post(`notifications/${id}/read`)
  }

  useEffect(() => {
    (async() => {
      const user = await authUser()
      setUser(user)
      getNotifications(user.id)
    })()

    navigation.addListener('focus', () => {
      (async() => {
        const user = await authUser()
        setUser(user)
        getNotifications(user.id)
      })()
    });
  }, [navigation]);

  function getTag(notification){
    if (notification.data.type === 'new-tender'){
      return translate('screens.notifications.newTender')
    }
    else if (notification.data.type === 'new-offer'){
      return translate('screens.notifications.newOffer')
    }
    else if (notification.data.type === 'account-activated'){
      return translate('screens.notifications.congrats')
    } else {
      return null;
    }
  }

  function getContent(notification){
    if (notification.data.type === 'new-tender' || notification.data.type === 'new-offer'){
      return `${translate('screens.notifications.from')} ${notification.data.from}`
    }
    else if (notification.data.type === 'account-activated'){
      return translate('screens.notifications.congrats')
    }
    else if (notification.data.type === 'withdraw-accepted'){
      return translate('screens.notifications.withdrawAccepted')
    }
    else if (notification.data.type === 'withdraw-rejected'){
      return translate('screens.notifications.withdrawRejected')
    }
    else if (notification.data.type === 'payment-accepted'){
      return translate('screens.notifications.paymentAccepted')
    }
    else if (notification.data.type === 'payment-rejected'){
      return translate('screens.notifications.paymentRejected')
    }
    else if (notification.data.type === 'your-tender-offers'){
      return `${translate('screens.notifications.yourTenderOffers')} ${notification.data.offers_count} ${translate('screens.notifications.yourTenderOffers2')}`
    }
    else if (notification.data.type === 'tender-closed'){
      return translate('screens.notifications.tenderClosed')
    }
    else if (notification.data.type === 'your-offer-accepted'){
      return translate('screens.notifications.yourOfferAccepted')
    }
    else if (notification.data.type === 'your-offer-selected'){
      return translate('screens.notifications.yourOfferSelected')
    }
    else if (notification.data.type === 'your-offer-canceled'){
      return translate('screens.notifications.yourOfferCanceled')
    }
    else if (notification.data.type === 'offer-reply-accepted'){
      return translate('screens.notifications.yourOfferReplyAccepted')
    }
    else if (notification.data.type === 'offer-reply-rejected'){
      return translate('screens.notifications.yourOfferReplyRejected')
    }
    else if (notification.data.type === 'your-request-accepted'){
      return translate('screens.notifications.yourRequestAccepted')
    }
    else if (notification.data.type === 'your-request-rejected'){
      return translate('screens.notifications.yourRequestRejected')
    }
    else if (notification.data.type === 'your-group-requests'){
      return `${translate('screens.notifications.yourGroupRequests')} ${notification.data.requests_count} ${translate('screens.notifications.yourGroupRequests2')}`
    }
  }

  const handleNotificationPress = async (id, type, notificationId) => {

    await markAsRead(notificationId)

    if (type === 'new-tender'){
      navigation.navigate('Tender', { tenderId: id })
    }
    else if (type === 'new-offer'){
      navigation.navigate('Offer', { offerId: id })
    }
    else if (type === 'account-activated'){
      return null;
    }
    else if (type === 'withdraw-accepted'){
      return null;
    }
    else if (type === 'withdraw-rejected'){
      return null;
    }
    else if (type === 'payment-accepted'){
      navigation.navigate('Tender', { tenderId: id })
    }
    else if (type === 'payment-rejected'){
      navigation.navigate('Tender', { tenderId: id })
    }
    else if (type === 'your-tender-offers'){
      navigation.navigate('Tender', { tenderId: id })
    }
    else if (type === 'tender-closed'){
      navigation.navigate('Tender', { tenderId: id })
    }
    else if (type === 'your-offer-accepted'){
      navigation.navigate('Offer', { offerId: id })
    }
    else if (type === 'your-offer-selected'){
      navigation.navigate('Offer', { offerId: id })
    }
    else if (type === 'your-offer-canceled'){
      navigation.navigate('Offer', { offerId: id })
    }
    else if (type === 'offer-reply-accepted' || type === 'offer-reply-rejected'){
      navigation.navigate('Offer', { offerId: id })
    }
    else if (type === 'your-request-accepted' || type === 'your-request-rejected'){
      navigation.navigate('JoinRequest', { requestId: id })
    }
    else if (type === 'your-group-requests'){
      navigation.navigate('Group', { groupId: id })
    }
  }

  return (
      <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

        <ScrollView bg={useColorModeValue("white", "coolGray.800")} h="100%">
          <View m={4}>
            <Title title={translate('screens.notifications.title')} />
          </View>

          {
            !!notifications ? (
              <View>
                {
                  notifications.new.length !== 0 ? (
                    <View>
                      <View m={7} mb={2}>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500}>
                          {translate('screens.notifications.new')} <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary"> {notifications.new.length}</Text>
                        </Text>
                      </View>
                      {
                        notifications.new.map(notification => (
                          <View key={notification.id}>
                            <NotificationCard
                              notificationId={notification.id}
                              dataType={notification.data.type}
                              dataId={notification.data.id}
                              time={notification.created_at}
                              title={getContent(notification)}
                              tag={getTag(notification)}
                              avatar={notification.data.avatar}
                              isSeen={!!notification.read_at}
                              onClicked={handleNotificationPress}
                              onMarkAsRead={handleMarkAsRead}
                            />
                          </View>
                        ))
                      }
                    </View>
                  ) : null
                }

                {
                  notifications.earlier.length !== 0 ? (
                    <View>
                      <View m={7} mb={2}>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500}>
                          {translate('screens.notifications.earlier')} <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary"> {notifications.earlier.length}</Text>
                        </Text>
                      </View>
                      {
                        notifications.earlier.map(notification => (
                          <View key={notification.id}>
                            <NotificationCard
                              notificationId={notification.id}
                              dataType={notification.data.type}
                              dataId={notification.data.id}
                              time={notification.created_at}
                              title={getContent(notification)}
                              tag={getTag(notification)}
                              avatar={notification.data.avatar}
                              isSeen={!!notification.read_at}
                              onClicked={handleNotificationPress}
                              onMarkAsRead={handleMarkAsRead}
                            />
                          </View>
                        ))
                      }
                    </View>
                  ) : null
                }

              </View>
            ) : null
          }

          {
            !!notifications && !notifications.new.length && !notifications.earlier.length ? (
              <View my={16}>
                <NoNotificationsYet/>
              </View>
            ) : null
          }

          <View my={20}/>
        </ScrollView>
      </View>
  );
}
