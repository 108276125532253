import React, {useEffect, useState} from "react";
import {
  hasActiveSubscription
} from '../utils/RevenueCatService';

import {
  Text, View,
  Center, Modal, Button, useColorMode, useColorModeValue
} from "native-base";
import {localInfo, translate} from "../utils/Translate";
import {useNavigation} from "@react-navigation/native";
import apiClient from "../utils/apiClient";

export default ({ featureToAccess }) => {

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';
  const navigation = useNavigation();

  const [showModal, setShowModal] = useState(false);

  function canAccessSubscriptionFeature() {
    apiClient.get(`subscription/check-feature?feature=${featureToAccess}`).then((res) => {
      setShowModal(!res.data)
    })
  }

  useEffect(() => {
    canAccessSubscriptionFeature()
  }, [])

  return (
    <View>

      <Center>
        <Modal size={["xl", "xl", "md"]} isOpen={showModal}>
          <Modal.Content maxWidth="100%">
            <Modal.Header>
              <Text textAlign="left" fontSize={20} fontWeight={500}>
                {translate('components.subscriptionExpired.modalHeader')}
              </Text>
            </Modal.Header>
            <Modal.Body>

              <View mt={3}/>

                <Text textAlign={"center"} fontSize={18}>
                  {translate('components.subscriptionExpired.modalText')}
                </Text>

                <Text mt={4} fontSize={18}>
                  {translate('components.subscriptionExpired.modalText2')}
                </Text>

            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant="ghost" colorScheme="blueGray"
                  onPress={() => {
                    setShowModal(false)
                    navigation.goBack()
                  }}
                >
                  {translate('components.subscriptionExpired.back')}
                </Button>
                <Button onPress={() => {
                  setShowModal(false)
                  navigation.goBack()
                  navigation.navigate("Subscriptions")
                }}>
                  {translate('components.subscriptionExpired.renewSubscription')}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Center>
    </View>
  )
}
