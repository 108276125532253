
import {
  Box, Center, HStack, useColorModeValue, View, Skeleton
} from "native-base";

export default () => {

  return (
    <Box width={["100%", "100%", "33%"]}
         bg={useColorModeValue("#F8F8F8", "coolGray.700")}
         borderRadius={8} shadow={3} mt={3} mb={1} ml={[0, 0, 3]}
    >
      <Skeleton h="16" borderTopRadius={8} />

      <View
        m={2}
        minH={["100", "100", "288"]}
        justifyContent="space-between"
      >
        <View>
          <View flexDirection="row">
            <View>
              <Skeleton size="16" rounded="full" ml={2} />
            </View>

            <View w="78%" ml={2}>
              <Skeleton.Text lines={1} px={2} mt={2} w={48} />
              <HStack>
                <View
                  w="60%"
                  mt={1} px={2} borderRadius={20} alignSelf="flex-start"
                >
                  <Skeleton.Text lines={1} mt={4} pr={4} />
                </View>
                <Center w="40%" flexDirection="row">
                  <Skeleton.Text lines={1} mt={4} pr={2} />
                </Center>
              </HStack>
            </View>
          </View>

          <Skeleton.Text lines={3} mt={8} />
        </View>

        <View flexDirection="row" my={2} mt={5}>

          <Skeleton.Text lines={1} w={20} />

          <Skeleton.Text lines={1} w={20} ml={4} />
        </View>

      </View>

    </Box>
  )
}
