import {DefaultTheme} from "react-native-paper";
import themeColors from "./colors";

const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: themeColors.appPrimary,
  },
}

export default theme
