import React, {useEffect, useState} from "react";
import apiClient from "../utils/apiClient";
import {localInfo, translate} from "../utils/Translate";

import {
  View, Text, ScrollView,
  Image, HStack, useColorModeValue, Divider
} from "native-base";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const CountryCallLines = ({ country }) => {
  return (
    <View py={4}>
      <HStack>
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={24} fontWeight={500}>
          {country.name}
        </Text>
        <Image ml={2} alt="country flag" size={'xs'} resizeMode={"contain"} source={{ uri: country.flag}} />
      </HStack>

      {
        country.call_lines.map((line, index) => (
          <View key={index}>
            <HStack mt={2}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500}>
                {line.name}:
              </Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
                {` ${line.phone}`}
              </Text>
            </HStack>
          </View>
        ))
      }

      <View mt={9}/>

      <Divider thickness="2" />
    </View>
  )
}

export default () => {

  const [countries, setCountries] = useState([]);

  function getCallCountriesWithLines(){
    apiClient.get(`call-lines?lang=${localInfo.local}`).then((response) => {
      setCountries(response.data)
    }).catch(error => console.log(error.response.data))
  }

  useEffect(() => {
    getCallCountriesWithLines()
  }, [])


  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>

        <View mt={3} mx={3}>
          <Title title="Call Lines" />

          <Text mt={4} fontSize={18}>
            {translate('screens.callLines.paragraph1')}
          </Text>

          <Text mt={5} fontSize={18}>
            {translate(('screens.callLines.paragraph2'))}
          </Text>

          <View mt={6}/>

          {
            countries.length ? (
              <View>
                {
                  countries.map((country, index) => (
                    <View key={index}>
                      <CountryCallLines country={country}/>
                    </View>
                  ))
                }
              </View>
            ) : null
          }

        </View>

      </ScrollView>
    </View>
  );
}
