import {Avatar, Box, HStack, Spacer, Text, VStack, Pressable, View, Icon, Center} from "native-base";
import React from "react";
import {Entypo} from "@expo/vector-icons";
import {shorten} from "../utils/StringTool";
import { TimeFormatter } from "../utils/TimeAgo";
import {localInfo, translate} from "../utils/Translate";

export default ({ item, navigation, currentUser, token }) => {

  return <Pressable
    onPress={() => {
      navigation.navigate('Chat', {
        currentConversation: item.id,
        type: item.messenger.type === "App\\Models\\Company" ? 'companies' : 'users',
        typeId: item.messenger.id,
        currentSenderId: item.is_team_member ? currentUser.id : !!currentUser.company_id ? currentUser.company_id : currentUser.id,
        token: token
      })
    }}
  >
    <Box pr={2} borderRadius={7} borderBottomWidth="1" _dark={{
      borderColor: "gray.600",
      bg: "coolGray.700",
      padding: 2,
    }} borderColor="coolGray.200" py="2">
      <HStack space={3} justifyContent="space-between">

        <Avatar size="lg" source={{ uri: item.messenger.avatar }} />

        <View w={["80%", "80%", "90%"]}>
          <HStack justifyContent="space-between">

            <Text w="70%" textAlign="left" _dark={{
              color: "warmGray.50"
            }} color="coolGray.800" fontWeight={500} fontSize={18}>
              {item.messenger.name !== "[deleted]" ? item.messenger.name : translate('misc.deletedUser')}
            </Text>

            <View mx={1}>
              <Text textAlign="left" fontSize="xs" _dark={{
                color: "warmGray.50"
              }} color="coolGray.800" alignSelf="flex-start">
                {TimeFormatter(item.created_at, localInfo.local)}
              </Text>
              {
                !item.is_seen ? (
                  <Center mt={2}>
                    <View w={5} h={5} bg="appSecondary" borderRadius={100} />
                  </Center>
                ) : null
              }
            </View>

          </HStack>

          <Spacer />

          <HStack>
            {
              item.is_sender ? (
                <View mr={1} w="6%">
                  <Icon as={<Entypo name="reply"/>} color="appPrimary" size={5} />
                </View>
              ) : null
            }
            {
              !item.is_seen ? (
                  <Text textAlign="left" w="94%" fontSize={17} fontWeight={500} color="appPrimary" _dark={{
                    color: "warmGray.200"
                  }}>
                    {shorten(item.last_message, 57)}
                  </Text>
              ) : (
                <Text textAlign="left" w="94%" fontSize={16} color="coolGray.600" _dark={{
                  color: "warmGray.200"
                }}>
                  {shorten(item.last_message, 57)}
                </Text>
              )
            }
          </HStack>
        </View>

      </HStack>
    </Box>
  </Pressable>
}
