import React, {useState, useEffect, useRef} from 'react';
import {Ionicons} from "@expo/vector-icons";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import {
  View, Text, Icon, FlatList, useColorModeValue, Spinner, Center, Image, useTheme,
} from "native-base";

import InboxItem from "../components/InboxItem";
import apiClient from "../utils/apiClient";
import AuthUserManager from "../utils/AuthUserManager";
import TokenManager from "../utils/TokenManager";
import {localInfo, translate} from "../utils/Translate";
import SearchInput from "../components/SearchInput";
import chatBubble from "../assets/icons/chat-bubble.png";
import NoItemYet from "../components/NoItemYet";
import InboxThreadSkeleton from "../components/skeleton/InboxThreadSkeleton";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const InboxEmpty = () => {
  return (
    <Center mt={24}>
      <Image alt="chat bubble icon" size={120} resizeMode={"contain"} source={chatBubble} />

      <NoItemYet text={translate('screens.inbox.inboxEmpty')} />
    </Center>
  )
}

const Tab = createMaterialTopTabNavigator();

export default ({ navigation }) => {

  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [particularInbox, setParticularInbox] = useState(null);
  const [professionalInbox, setProfessionalInbox] = useState(null);
  const [isLoadingData, setIsLoadingData]       = useState(false)

  const searchText = useRef('')
  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  const { colors }  = useTheme();

  const screenOptions = {
    tabBarLabelStyle: { fontSize: 16, fontWeight: "bold" },
    tabBarItemStyle: {
      flexDirection: 'row'
    },
    tabBarActiveTintColor: "#fff",
    tabBarInactiveTintColor: "#000",
    tabBarStyle: {
      backgroundColor: "#ccc",
      height: 46,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    tabBarIndicatorStyle: {
      backgroundColor: colors.appPrimary, height: 46,
      marginRight: 4,

      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }

  const onSearch = (text, type) => {
    searchText.current = text;
    getFilteredInbox(type);
  }

  const InboxList = ({ inboxData, type, loadMore }) => {

    return <View h="100%" bg={useColorModeValue("white", "coolGray.800")} px={4}>
      <FlatList
        data={inboxData}
        renderItem={({item}) => {
          return <InboxItem
            item={item}
            navigation={navigation}
            currentUser={currentUser}
            token={token}
          />
        }}
        keyExtractor={item => item.id}
        ListHeaderComponent={() => (
          <View my={4}>
            <SearchInput
              bg="appPrimary" textColor="white" iconsColor="white" isHovered={true}
              onSubmit={(text) => onSearch(text, type)}
              value={searchText.current}
              onChange={(text) => {
                searchText.current = text
              }}
              onEmpty={() => {
                onSearch('', type)
              }}
            />
          </View>
        )}
        ListFooterComponent={() => <View mb={120}>
          {
            isLoadingData ? (
              <View mt={10}>
                <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
              </View>
            ) : null
          }
        </View>}
        onEndReachedThreshold={0.4}
        onEndReached={() => loadMore(type)}
      />

    </View>;
  };

  const Particular = () => {
    if (!particularInbox) {
      return <InboxThreadSkeleton/>
    }
    else if (!particularInbox.length) {
      return <InboxEmpty/>
    }
    return <InboxList inboxData={particularInbox} type={'user'} loadMore={(type) => loadMore(type)} />
  }

  const Professional = () => {
    if (!professionalInbox) {
      return <InboxThreadSkeleton/>
    }
    else if (!professionalInbox.length) {
      return <InboxEmpty/>
    }
    return <InboxList inboxData={professionalInbox} type={'company'} loadMore={(type) => loadMore(type)} />
  }

  function getInbox(type, isLoadMore){
    let nextP = nextPage.current.length ? `&${nextPage.current}` : '';

    apiClient.get(`conversations?type=${type}${nextP}`).then((response) => {
      if (type === 'user'){
        if (isLoadMore && nextPage.current.length){
          getNextPage(response.data.next_page_url)
          setParticularInbox(old => [...old, ...response.data.data])
          setIsLoadingData(false)
          isLoadingMoreRef.current = false
          return;
        }
        getNextPage(response.data.next_page_url)
        setParticularInbox(response.data.data)
      }
      if (type === 'company'){
        if (isLoadMore && nextPage.current.length){
          getNextPage(response.data.next_page_url)
          setProfessionalInbox(old => [...old, ...response.data.data])
          setIsLoadingData(false)
          isLoadingMoreRef.current = false
          return;
        }
        getNextPage(response.data.next_page_url)
        setProfessionalInbox(response.data.data)
      }
    }).catch(error => console.log(error.response.data))
  }

  const loadMore = (type) => {
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    isLoadingMoreRef.current = true
    setIsLoadingData(true)

    getInbox(type, true)
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  function getFilteredInbox(type){

    let nameFilter = `name=${searchText.current}`

    apiClient.get(`conversations?${nameFilter}&type=${type}`).then((response) => {
      if (type === 'user'){
        setParticularInbox(response.data.data)
      }
      if (type === 'company'){
        setProfessionalInbox(response.data.data)
      }
    }).catch(error => console.log(error.response.data))
  }

  useEffect(() => {
    (async () => {
      let auth      = await AuthUserManager.get()
      let authToken = await TokenManager.get()
      setCurrentUser(auth)
      setToken(authToken)
    })();

    navigation.addListener('focus', () => {
      getAllInbox()
    });
  }, [navigation])

  function getAllInbox(){
    getInbox('user')
    getInbox('company')
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <View mt={4} mx={4}>
        <Title title={translate('screens.inbox.title')} />
        <SpacerMY space={3}/>
      </View>

      <Tab.Navigator screenOptions={screenOptions} style={{ direction: localInfo.isRTL && Platform.OS !== "web" ? 'rtl' : "ltr" }}>
        <Tab.Screen
          name={translate('misc.particular')}
          component={Particular}
          options={{
            tabBarIcon: ({color }) => <Icon as={<Ionicons name="person"/>} color={color} size={5} />,
          }} />
        <Tab.Screen
          name={translate('misc.professional')}
          component={Professional}
          options={{
            tabBarIcon: ({color }) => <Icon as={<Ionicons name="briefcase"/>} color={color} size={5} />,
          }} />
      </Tab.Navigator>

    </View>
  );
}
