import {
  View, Divider, ScrollView, useColorModeValue, Skeleton
} from "native-base";

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default () => {

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4}>
        <View mt={4}>
          <Skeleton size="7" w={20} />
          <Skeleton size="2" w={16} mt={3} />
        </View>

        <View flexDirection="row" mt={8} mb={4} >

          <View>
            <Skeleton size="24" rounded="full" mt={2} />
          </View>

          <View flex={1} ml={4} pt={2} >
            <Skeleton.Text lines={1} w={32} />

            <View flexDirection="row" mt={6}>
              <Skeleton size="10" w={32} />
              <Skeleton size="10" w={32} ml={4}/>
            </View>

          </View>
        </View>

        <View>
          <SpacerMY space={2}/>
          {/* Summary */}
          <Skeleton size="7" w={32} />
          <Skeleton size="5" rounded='md' w={24} mt={3} />

          <View mt={3}>
            <Skeleton.Text lines={3} mt={3} />
          </View>
        </View>

      </ScrollView>
    </View>
  );
}
