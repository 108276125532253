import React, {useEffect, useState} from 'react';

import {
  View, Text, Center, Button, ScrollView,
  Input, FormControl, Modal, HStack, Icon, useColorModeValue, useTheme
} from "native-base";
import {Cell, Row, Table, TableWrapper} from "react-native-table-component-2";
import {StyleSheet, TouchableOpacity} from "react-native";
import {Ionicons} from "@expo/vector-icons";
import {AuthContext} from "../../../../utils/context/auth";
import {localInfo, translate} from "../../../../utils/Translate";
import {Platform} from "react-native";
import AuthUserManager from "../../../../utils/AuthUserManager";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const tableHead = [
  translate('screens.tender.priceTableHeader.item'),
  translate('screens.tender.priceTableHeader.unit'),
  translate('screens.tender.priceTableHeader.quantity')
]

const widthArr  = [150, 150, 150]

export default ({ route, navigation }) => {

  const { groupData } = route.params

  const { colors }    = useTheme();

  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [itemsTableData, setItemsTableData]     = useState([
    ['', '', '']
  ]);

  let tableRow = [];
  const [itemName, setItemName] = useState("");
  const [itemUnit, setItemUnit] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [inputErrors, setInputErrors] = React.useState({});

  const handleItemName = (name) => {
    if ('itemName' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.itemName
      setInputErrors(errors);
    }

    setItemName(name)
  }
  const handleItemUnit = (unit) => {
    if ('itemUnit' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.itemUnit
      setInputErrors(errors);
    }

    setItemUnit(unit)
  }
  const handleItemQuantity = (quantity) => {
    if ('itemQuantity' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.itemQuantity
      setInputErrors(errors);
    }

    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    setItemQuantity(quantity.replace(regex, ''))
  }

  const validate = () => {
    if (itemName.length === 0) {
      setInputErrors({ ...inputErrors,
        itemName: translate('screens.publishTender.priceTable.validation.itemName')
      });
      return false;
    } else if (itemUnit.length === 0) {
      setInputErrors({ ...inputErrors,
        itemUnit: translate('screens.publishTender.priceTable.validation.itemUnit')
      });
      return false;
    }

    return true;
  };

  const handleAddItem = () => {

    if (!validate()) return;

    tableRow[0] = itemName;
    tableRow[1] = itemUnit;
    tableRow[2] = '';

    setItemsTableData([tableRow, ...itemsTableData])

    setShowAddItemModal(false);
    setItemName('')
    setItemUnit('')
  }

  const handleNextButton = () => {
    groupData.itemsTable = [];
    saveCurrentTable()

    if (itemsTableData.length > 1){
      for (let i = 0; i < itemsTableData.length-1; i++){
        groupData.itemsTable.push({
          item: itemsTableData[i][0],
          unit: itemsTableData[i][1],
          quantity: itemsTableData[i][2],
        })
      }
    }

    navigation.navigate('Group', { previewData: groupData })
  }

  function saveCurrentTable(){
    if (itemsTableData.length > 1) {
      groupData.itemsTablePreview = itemsTableData;
    }
  }

  useEffect(() => {
    (async () => {
      let user = await AuthUserManager.get();

      if (user.company === null){
        groupData.groupbuyingable = user
        groupData.groupbuyingable_type = 'App\\Models\\User'
      } else {
        groupData.groupbuyingable = user.company
        groupData.groupbuyingable_type = 'App\\Models\\Company'
      }
    })();

    if (groupData.itemsTablePreview.length !== 0){
      setItemsTableData(groupData.itemsTablePreview)
    }
  }, [])

  const AddItemButton = (data, index) => {
    // save current table when add new row
    saveCurrentTable()

    return (
      <View m={3}>
        <Button borderRadius={12} onPress={() => setShowAddItemModal(true)}>
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={17} color="white">
            {translate('screens.publishTender.pointsTable.addItem')}
          </Text>
        </Button>
      </View>
    )
  };

  const itemNameWithDeleteButton = (data, index) => {
    return (
      <HStack m={3} justifyContent="center">
        <View mr={2}>
          <TouchableOpacity onPress={() => {
            setItemsTableData(itemsTableData.filter((row, rowIndex) => rowIndex !== index));
          }}>
            <Icon as={Ionicons} name="trash-bin-outline" size={7} color="appSecondary" />
          </TouchableOpacity>
        </View>
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} justifyContent="flex-start" fontWeight={600} fontSize={18}>
          {data}
        </Text>
      </HStack>
    )
  };

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4} contentContainerStyle={styles.contentContainerStyle}>

        <View>
          <View mt={4}/>
          <Title title={translate('drawer.groupBuying.create')} />
          <SpacerMY space={3}/>

          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={26}>
            {translate('drawer.groupBuying.itemsTable.title')}
          </Text>
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
            {translate('drawer.groupBuying.itemsTable.description')}
          </Text>

          <View mt={5}>
            <ScrollView horizontal={true}>
              <View style={styles.container}>
                <Table style={{  }} borderStyle={{ borderWidth: 2, borderColor: colors.appPrimary }}>
                  <Row widthArr={widthArr} data={tableHead} style={styles.head} textStyle={styles.headText} />

                  {itemsTableData.map((rowData, index) => (
                    <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                      {rowData.map((cellData, cellIndex) => (
                        <Cell
                          width={150}
                          key={cellIndex}
                          data={(index === itemsTableData.length - 1 && cellIndex === 0)
                            ?
                            AddItemButton(cellData, cellIndex)
                            :
                            cellIndex === 0
                              ?
                              itemNameWithDeleteButton(cellData, index)
                              : cellData
                          }
                          textStyle={styles.text}
                        />
                      ))}
                    </TableWrapper>
                  ))}

                </Table>
              </View>
            </ScrollView>
          </View>


          {/* Add Item Modal */}
          <Center>
            <Modal avoidKeyboard size={["xl", "xl", "md"]} isOpen={showAddItemModal} onClose={() => setShowAddItemModal(false)}>
              <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>
                  {translate('screens.publishTender.priceTable.addToTable')}
                </Modal.Header>
                <Modal.Body>
                  <FormControl mt={4} isInvalid={'itemName' in inputErrors}>
                    <Input
                      textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                      placeholder={translate('screens.publishTender.priceTable.placeholder.itemName')}
                      rounded={10} size="xl"
                      borderColor="gray.500" pl={4} py={3}
                      onChangeText={handleItemName}
                      value={itemName}
                    />
                    {
                    'itemName' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.itemName}
                      </FormControl.ErrorMessage>
                    ) : null
                    }
                  </FormControl>

                  <FormControl mt={4} isInvalid={'itemUnit' in inputErrors}>
                    <Input
                      textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                      placeholder={translate('screens.publishTender.priceTable.placeholder.unit')}
                      rounded={10} size="xl"
                      borderColor="gray.500" pl={4} py={3}
                      onChangeText={handleItemUnit}
                      value={itemUnit}
                    />
                    {
                    'itemUnit' in inputErrors ? (
                      <FormControl.ErrorMessage>
                        {inputErrors.itemUnit}
                      </FormControl.ErrorMessage>
                    ) : null
                    }
                  </FormControl>

                </Modal.Body>
                <Modal.Footer>
                  <Button.Group space={2}>
                    <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                      setShowAddItemModal(false);
                    }}>
                      {translate('misc.cancel')}
                    </Button>
                    <Button onPress={handleAddItem}>
                      {translate('misc.add')}
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </Center>
        </View>

        <Center mb={16} mt={10}>
          <Button
            w="85%"
            onPress={ handleNextButton }
            isDisabled={itemsTableData.length < 2}
            size="lg"
            color="appPrimary"
            borderRadius={8}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.next')}
            </Text>
          </Button>
        </Center>

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    justifyContent: "space-between",
    flexGrow: 1
  },
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center', paddingVertical: 10
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
