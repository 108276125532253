
const OfferModel = {
  id: null,
  reason: null,
  is_selected: null,
  can_select: false,
  company_id: null,
  created_at: null,
  user: null,
  company: null,
  is_group_closed: false,
  is_group_manager: false,
  is_group_owner: false,
  is_request_owner: false,
}

export default OfferModel
