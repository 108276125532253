import React, {useState, useEffect, useRef} from 'react';
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import apiClient from "../../../utils/apiClient";
import OfferModel from "../../../models/OfferModel";
import TimeFormatter from "../../../utils/TimeFormatter";
import { Foundation } from '@expo/vector-icons';

import {
  View, Text, Icon, FlatList, Box, Center, useColorModeValue, Spinner, useTheme
} from "native-base";

import { Platform } from "react-native";

import RequestCard from "../../../components/RequestCard";
import {localInfo, translate} from "../../../utils/Translate";
import OfferCardSkeleton from "../../../components/skeleton/OfferCardSkeleton";
import NoItemYet from "../../../components/NoItemYet";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const Tab = createMaterialTopTabNavigator();

const OffersList = ({ groupId, status, navigation }) => {

  const [pendingRequests, setPendingRequests]         = useState(null)
  const [acceptedRequests, setAcceptedRequests]     = useState([])
  const [selectedOffers, setSelectedOffers]     = useState([])
  const [showCantSeeAlert, setShowCantSeeAlert] = useState(false)
  const [closingDate, setClosingDate]           = useState(null)
  const [isLoadingData, setIsLoadingData]       = useState(false)

  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  useEffect(() => {
    getAllRequests()


    navigation.addListener('focus', () => {
      getAllRequests()
    });

  }, [navigation])

  function getAllRequests(){
    getRequests('pending')
    getRequests('accepted')
  }

  function getRequests(requestStatus, isLoadMore){

    let nextP = nextPage.current.length ? `&${nextPage.current}` : '';

    apiClient.get(`groups/${groupId}/requests?status=${requestStatus}${nextP}`).then( response => {

      if (requestStatus === 'pending'){
        if (isLoadMore && nextPage.current.length){
          getNextPage(response.data.next_page_url)
          setPendingRequests(old => [...old, ...response.data.data])
          setIsLoadingData(false)
          isLoadingMoreRef.current = false
          return;
        }
        getNextPage(response.data.next_page_url)
        setPendingRequests(response.data.data)
        setIsLoadingData(false)
        isLoadingMoreRef.current = false
      }
      else if (requestStatus === 'accepted'){
        if (isLoadMore && nextPage.current.length){
          getNextPage(response.data.next_page_url)
          setAcceptedRequests(old => [...old, ...response.data.data])
          setIsLoadingData(false)
          isLoadingMoreRef.current = false
          return;
        }
        getNextPage(response.data.next_page_url)
        setAcceptedRequests(response.data.data)
        setIsLoadingData(false)
        isLoadingMoreRef.current = false
      }

    }).catch(error => console.log(error));
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  const loadMore = () => {
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    isLoadingMoreRef.current = true
    setIsLoadingData(true)

    getRequests(status, true)
  }

  const ClosingDateNotice = () => {
    return <Box bg="appSecondary" mx={2} borderRadius={8} mt={3}>
      <Center my={4}>
        <Icon as={<Foundation name="alert"/>} size={9} color="white" />
        <Text textAlign="center" fontWeight={500} fontSize={25} color="white">
          {translate('screens.receivedOffers.closingDateMsg')}
        </Text>
        <Text textAlign="left" fontWeight={500} fontSize={25} color="white">
          ({closingDate})
        </Text>
      </Center>
    </Box>
  }

  const FlatListHeader = () => {
    return (
      <>
        {
          selectedOffers.length === 0 ? null : !!selectedOffers[0].is_selected ? (
            <View>

              <RequestCard
                isSelectedOffer={true}
                id={selectedOffers[0].id}
                by={!!selectedOffers[0].company_id ? selectedOffers[0].company.name : !!selectedOffers[0].user ? selectedOffers[0].user.full_name : ''}
                avatar={selectedOffers[0].avatar}
                points={!!selectedOffers[0].points ? selectedOffers[0].points : null}
                tenderPoints={!!selectedOffers[0].tender.required_points ? selectedOffers[0].tender.required_points : null}
                bidAmount={!!selectedOffers[0].bid_amount ? selectedOffers[0].bid_amount : !!selectedOffers[0].price ? selectedOffers[0].price : null}
                currency={!!selectedOffers[0].currency ? selectedOffers[0].currency.code[localInfo.local] : ''}
                date={TimeFormatter.getFormattedDate(selectedOffers[0].created_at)}
              />

            </View>
          ) : null
        }
        {
          acceptedRequests.length === 0 ? null : !!acceptedRequests[0].is_selected ? (
            <View>

              <RequestCard
                isAcceptedOffer={true}
                id={acceptedRequests[0].id}
                by={!!acceptedRequests[0].company_id ? acceptedRequests[0].company.name : !!acceptedRequests[0].user ? acceptedRequests[0].user.full_name : ''}
                avatar={acceptedRequests[0].avatar}
                points={!!acceptedRequests[0].points ? acceptedRequests[0].points : null}
                tenderPoints={!!acceptedRequests[0].tender.required_points ? acceptedRequests[0].tender.required_points : null}
                bidAmount={!!acceptedRequests[0].bid_amount ? acceptedRequests[0].bid_amount : !!acceptedRequests[0].price ? acceptedRequests[0].price : null}
                currency={!!acceptedRequests[0].currency ? acceptedRequests[0].currency.code[localInfo.local] : ''}
                date={TimeFormatter.getFormattedDate(acceptedRequests[0].created_at)}
              />

            </View>
          ) : null
        }
      </>
    )
  }

  const ShowOffersList = () => {

    if (status === 'accepted' && pendingRequests === null && !( selectedOffers.length || acceptedRequests.length) ){
      return (
        <FlatList
          numColumns={Platform.OS === "web" ? 3 : 1 }
          data={[1,2,3]}
          renderItem={({item, index}) => (
            <OfferCardSkeleton/>
          )}
          keyExtractor={item => item}
        />
      );
    } else if (status === 'accepted' && !!pendingRequests && !pendingRequests.length && !( selectedOffers.length || acceptedRequests.length) ){
      return (
        <NoItemYet text={translate('drawer.groupBuying.noAcceptedRequests')}/>
      );
    } else if (status === 'pending' && pendingRequests === null ){
      return (
        <FlatList
          numColumns={Platform.OS === "web" ? 3 : 1 }
          data={[1,2,3]}
          renderItem={({item, index}) => (
            <OfferCardSkeleton/>
          )}
          keyExtractor={item => item}
        />
      );
    } else if (status === 'pending' && !!pendingRequests && !pendingRequests.length ){
      return (
        <NoItemYet text={translate('drawer.groupBuying.noPendingRequests')}/>
      );
    }

    return (
      <FlatList
        numColumns={Platform.OS === "web" ? 3 : 1 }
        data={
          status === 'accepted'
            ? acceptedRequests : pendingRequests
        }
        renderItem={({item, index}) => (
          <RequestCard
            id={item.id}
            isPendingRequest={status === 'pending'}
            isAcceptedRequest={status === 'accepted'}
            by={!!item.company_id ? item.company.name : !!item.user ? item.user.full_name : ''}
            avatar={item.avatar}
            date={TimeFormatter.getFormattedDate(item.created_at)}
          />
        )}
        keyExtractor={item => item.id}
        ListFooterComponent={() => <View mb={120}>
          {
            isLoadingData ? (
              <View mt={10}>
                <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
              </View>
            ) : null
          }
        </View>}
        ListHeaderComponent={FlatListHeader}
        onEndReachedThreshold={0.2}
        onEndReached={loadMore}
      />
    )
  }

  return (
    <View h="100%" bg={useColorModeValue("white", "coolGray.800")} px={2} pt={2}>

      {
        showCantSeeAlert ? (
          <ClosingDateNotice/>
        ) : null
      }
      {
        !showCantSeeAlert ? (
          (
            <ShowOffersList/>
          )
        ) : null
      }

    </View>
  );
};


export default ({ route, navigation }) => {

  const { groupId } = route.params;
  const { colors }  = useTheme();
  const [requestsCount, setRequestsCount] = useState({accepted: 0, pending: 0})



  const screenOptions = {
    tabBarLabelStyle: { fontSize: 16, fontWeight: "bold" },
    tabBarItemStyle: {
      flexDirection: 'row'
    },
    tabBarActiveTintColor: "#fff",
    tabBarInactiveTintColor: "#000",
    tabBarStyle: {
      backgroundColor: "#ccc",
      height: 46,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    tabBarIndicatorStyle: {
      backgroundColor: colors.appPrimary, height: 46,
      marginRight: 4,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }

  useEffect(() => {
    // getRequestsCount()
  }, [])

  function getRequestsCount(){
    apiClient.get(`groups/${groupId}/requests-count`).then( response => {
      setRequestsCount(response.data)
    }).catch(error => console.log(error));
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <View mt={4} mx={4}>
        <Title title={translate('drawer.groupBuying.receivedRequestsTitle')} />
        <SpacerMY space={3}/>
      </View>

      <Tab.Navigator screenOptions={screenOptions} style={{ direction: localInfo.isRTL && Platform.OS !== "web" ? 'rtl' : "ltr" }}>
        <Tab.Screen
          name={`${translate('components.offerCard.pending')}`}
          options={{
            tabBarIcon: ({color }) => <Icon as={<MaterialIcons name="pending-actions"/>} color={color} size={5} />,
          }} >
          {props => <OffersList navigation={navigation} groupId={groupId} status="pending" {...props} />}
        </Tab.Screen>
        <Tab.Screen
          name={`${translate('components.offerCard.accepted')}`}
          options={{
            tabBarIcon: ({color }) => <Icon as={<AntDesign name="checkcircle"/>} color={color} size={5} />,
          }} >
          {props => <OffersList navigation={navigation} groupId={groupId} status="accepted" {...props} />}
        </Tab.Screen>
      </Tab.Navigator>


    </View>
  );
}
