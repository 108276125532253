import TimeAgo from 'javascript-time-ago'
import ar from 'javascript-time-ago/locale/ar'
import fr from 'javascript-time-ago/locale/fr'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(ar)
TimeAgo.addLocale(fr)
TimeAgo.addLocale(en)
TimeAgo.addDefaultLocale(en)

function TimeFormatter(date, local, style = 'round-minute'){
  if (!date || !local) return '';

  return (new TimeAgo(local)).format(new Date(date), style)
}

export {TimeFormatter}

