import React from "react";
import {View, Image, Heading, Text, Center, Button} from "native-base";
import imgLogo from '../../assets/logo.png';
import {localInfo, translate} from "../../utils/Translate";
import {Platform} from "react-native";

export default function Index({ navigation }){

    return (
      <View>
      <Center>
        <Image alt="Logo" size={300} resizeMode={"contain"} source={imgLogo} />

        <View ml={4} mr={4}>
          <Heading>
            {translate('screens.auth.index.heading')}
          </Heading>
          <Heading mt={2} mb={9} color="appPrimary">
            {translate('screens.auth.index.heading2')}
          </Heading>

          <View mt={32}>
            <Button
              onPress={ () => navigation.navigate('Sign-up') }
              size="lg"
              color="appPrimary"
              borderRadius={10}
            >
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                {translate('screens.auth.index.CreateAccount')}
              </Text>
            </Button>
            <Button
              mt={3}
              size="lg"
              variant="outline"
              color="appPrimary"
              borderRadius={10}
              onPress={() => navigation.navigate('Sign-in') }
            >
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="appPrimary" fontWeight="700">
                {translate('misc.signIn')}
              </Text>
            </Button>
          </View>

          <Text textAlign="center" mt="3" m={10} fontWeight="400">
            {translate('screens.auth.index.note')}
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appPrimary"> {translate('screens.auth.index.termsAndCondition')}</Text>
            {translate('screens.auth.index.andOur')}{" "}
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appPrimary">
              {translate('screens.auth.index.privacyPolicy')}
            </Text>
          </Text>

        </View>
      </Center>
      </View>
    );
}
