import React, {useEffect, useRef, useState} from 'react';
import apiClient from "../../utils/apiClient";

import {
  View, Text, ScrollView, Divider, useColorModeValue,
  HStack, Icon, Spinner, Center, useTheme
} from "native-base";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {formatBytes} from "../../utils/StringTool";
import {TouchableOpacity} from "react-native";
import * as WebBrowser from "expo-web-browser";
import {AuthContext} from "../../utils/context/auth";
import {translate} from "../../utils/Translate";

function formatDate(date){
  return new Intl.DateTimeFormat('en-US').format(new Date(date));
}

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" color="primary.50" fontSize={32} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="primary.50" w={60}/>
  </View>
}

export default ({ navigation }) => {

  const { isCompany } = React.useContext(AuthContext);

  const [downloads, setDownloads] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false)

  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  const { colors } = useTheme();


  function getDownloads(isLoadMore = false){

    let nextP  = nextPage.current.length ? `?${nextPage.current}` : '';

    apiClient.get(`downloads${nextP}`).then( response => {
      if (isLoadMore && nextPage.current.length){
        getNextPage(response.data.next_page_url)
        setDownloads(old => [...old, ...response.data.data])

        setIsLoadingData(false)
        isLoadingMoreRef.current = false
        return;
      }

      getNextPage(response.data.next_page_url)
      setDownloads(response.data.data)

      setIsLoadingData(false)
      isLoadingMoreRef.current = false
    }).catch(error => console.log(error));
  }

  useEffect(() => {
    getDownloads()

    navigation.addListener('focus', () => {
      getDownloads()
    });
  }, [navigation])

  function getFileSource(attachment) {
    if (!attachment) return undefined;

    return attachment.attachmentable_type === "App\\Models\\Tender" ?
      attachment.attachmentable_id : null
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  function loadMore(){
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    setIsLoadingData(true)
    isLoadingMoreRef.current = true

    getDownloads(true)
  }

  const FileItem = ({ fileName, size, date, onPress, source, navigation }) => {
    return (
      <View>
        <HStack py={4} alignItems="center">
          <View w="13%">
            <TouchableOpacity onPress={!!onPress ? onPress : null}>
              <Icon
                size={10} color={colors.appPrimary}
                as={<MaterialCommunityIcons name="file-download" />}
              />
            </TouchableOpacity>
          </View>

          <View w="45%" pr={2}>
            <TouchableOpacity onPress={!!onPress ? onPress : null}>
              <Text fontSize={18} fontWeight={500}>
                {fileName}
              </Text>
            </TouchableOpacity>
            <Text fontSize={16}>
              {formatBytes(size)}
            </Text>
          </View>

          <View w="20%">
            {
              !!source ? (
                <View>
                  <Text fontSize={18} fontWeight={500}>
                    {translate('drawer.screens.downloads.fileItem.source')}
                  </Text>
                  <TouchableOpacity onPress={() => {
                    navigation.navigate('Tender', { tenderId: source })
                  }}>
                    <Text fontSize={16}>
                      {translate('drawer.screens.downloads.fileItem.here')}
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : null
            }

          </View>

          <View w="21%">
            <Text fontSize={18} fontWeight={500}>
              {translate('drawer.screens.downloads.fileItem.date')}
            </Text>
            <Text fontSize={16}>
              {formatDate(date)}
            </Text>
          </View>

        </HStack>

        <Divider thickness="2" />
      </View>
    )
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            loadMore()
          }
        }}
        scrollEventThrottle={400}
      >

        <View bg={isCompany ? colors.appPrimary : colors.particular} borderBottomRightRadius={600}>
          <View m={4}>
            <Title title={translate('drawer.screens.downloads.title')} />
            <Text textAlign="left" my={2} color="primary.50" fontSize={16}>
              {translate('drawer.screens.downloads.description')}
            </Text>
          </View>
        </View>

        <View mt={10}/>

        <View mx={2}>

          {
            downloads.length ? (
              downloads.map((download, index) => (
                <View key={index}>
                  <FileItem
                    fileName={download.attachment.name}
                    size={download.attachment.size}
                    date={download.created_at}
                    source={getFileSource(download.attachment)}
                    onPress={async () => {
                      await WebBrowser.openBrowserAsync(encodeURI(download.attachment.path));
                    }}
                    navigation={navigation}
                  />
                </View>
              ))
            ) : (
              <Center
                bg={useColorModeValue("#F8F8F8", "coolGray.700")}
                py={4} mt={10} borderRadius={8}
              >
                <Text textAlign="center" fontSize={20}>
                  {translate('drawer.screens.downloads.noDownloads')}
                </Text>
              </Center>
            )
          }
          {
            isLoadingData ? (
              <View mt={10}>
                <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
              </View>
            ) : null
          }

        </View>

        <View mb={38}/>

      </ScrollView>
    </View>
  );
}
