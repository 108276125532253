import {MaterialIcons} from "@expo/vector-icons";
import React, {useEffect, useRef, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import { TimeFormatter } from "../utils/TimeAgo";
import DateFormatter from "../utils/TimeFormatter";
import {localInfo, translate} from "../utils/Translate";
import {
  Box, Center, Icon, HStack, TextArea,
  Pressable, Text, useColorModeValue, View, useColorMode, Avatar, Modal, FormControl, Button, Spinner
} from "native-base";
import apiClient from "../utils/apiClient";
import {shorten} from "../utils/StringTool";
import {AuthContext} from "../utils/context/auth";
import AlertModal from "./AlertModal";

export default (
  {
    id, tenderableType, postTime, avatar, title, branch, location, description,
    date, offers, isSaved
  }) => {

  const navigation = useNavigation();
  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const { authUser } = React.useContext(AuthContext);

  const [toggleSaveButton, setToggleSaveButton] = useState(isSaved)
  const [showReportModal, setShowReportModal] = useState(false)
  const [isReporting, setIsReporting] = useState(false)
  const [reasonError, setReasonError] = useState({})
  const [responseMessage, setResponseMessage] = useState('')
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const reportReason = useRef('');

  function isCompanyTender(tenderableType){
    return tenderableType === 'App\\Models\\Company';
  }

  const handleSave = () => {
    // If this is a demo account return alert message
    if (currentUser.id === 10193){
      setShowAlertModal(true);
      return;
    }

    apiClient.post(`tenders/${id}/favorites`).then(() => {
      setToggleSaveButton(!toggleSaveButton)
    }).catch(error => console.log(error.response.data));
  }

  const handleReportPress = () => {
    // If this is a demo account return alert message
    if (currentUser.id === 10193){
      setShowAlertModal(true);
      return;
    }

    setShowReportModal(true)
    setResponseMessage('')
  }

  const validate = () => {
    if (reportReason.current.length < 10) {
      setReasonError({ reason : 'Reason is too short'});
      return false;
    }

    return true;
  };

  const sendReport = () => {

    if (!validate()) return

    setIsReporting(true)
    apiClient.post(`tenders/${id}/reports`, {reason: reportReason.current}).then(() => {
      setIsReporting(false)
      setResponseMessage('Report sent, Thanks')
    }).catch(error => {
      setIsReporting(false)
      if (error.response.status === 400 && error.response.data === 'already-reported'){
        setResponseMessage('You already reported this tender.')
      } else if (error.response.status === 400 && error.response.data === 'reason-required'){
        setResponseMessage('Please write the reason')
      }
    });
  }

  useEffect(() => {
    if (isSaved !== undefined){
      setToggleSaveButton(isSaved)
    }
  }, [isSaved])

  useEffect(() => {
    (async() => {
      const user = await authUser()
      setCurrentUser(user)
    })()
  }, [])

  return (
    <Pressable
      width={["100%", "100%", "33%"]}
      onPress={() => navigation.navigate('Tender', { tenderId: id })}>
      <Box bg={useColorModeValue("#F8F8F8", "coolGray.700")} borderRadius={8} shadow={3} mt={3} mb={1} ml={[0, 0, 3]}>
        <View
          borderTopRadius={8}
          bg={isCompanyTender(tenderableType) ? 'appPrimary' : isDark ? 'appAccentDark' : 'particular'}
          flexDirection="row" justifyContent="space-between">
          <Center p={2}>
            <Text textAlign="left" fontSize={18} fontWeight={500} color="primary.50">
              {translate('components.tenderCard.posted')} {TimeFormatter(postTime, localInfo.local)}
            </Text>
          </Center>
          <View flexDirection="row" pt={1} pr={2} py={1}>
            <View mr={3}>
              <Pressable onPress={handleSave}>
                <Icon as={<MaterialIcons name={toggleSaveButton ? "bookmark" : "bookmark-outline"}/>} size={7} ml="2" color="primary.50"/>
                <Text textAlign="left" fontWeight={500} fontSize={16} color="primary.50">
                  {translate('components.tenderCard.save')}
                </Text>
              </Pressable>
            </View>
            <View>
              <Pressable onPress={handleReportPress}>
                <Icon as={<MaterialIcons name="outlined-flag"/>} size={7} ml="2" color="primary.50"/>
                <Text textAlign="left" fontWeight={500} fontSize={16} color="primary.50">
                  {translate('components.tenderCard.report')}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>

        <View
          m={2}
          minH={["100", "100", "288"]}
          justifyContent="space-between"
        >
          <View>
            <View flexDirection="row">
              <View>
                <Avatar
                  mt={2} size="lg"
                  source={{ uri : avatar}}
                />
              </View>

              <View w="78%" ml={2}>
                <Text textAlign="left" fontSize={24} fontWeight={500} >
                  {shorten(title, 40)}
                </Text>
                <HStack>
                  <View
                    maxW="60%"
                    bg={useColorModeValue("appAccent", "appAccentDark")}
                    mt={1} px={2} borderRadius={20} alignSelf="flex-start"
                  >
                    <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                      {shorten(branch, 30)}
                    </Text>
                  </View>
                  <Center maxW="40%" flexDirection="row">
                    <Icon as={<MaterialIcons name="location-pin"/>} size={6} ml="2"/>
                    <Text textAlign="left" fontWeight={500} fontSize={16}>
                      {location}
                    </Text>
                  </Center>
                </HStack>
              </View>
            </View>

            <Text textAlign="left" mt={2} fontSize={17}>
              {shorten(description, 100)}
            </Text>
          </View>

          <View flexDirection="row" my={2}>
            {
              !!date ? (
                <View px={2} bg="appAccent" borderRadius={20} alignSelf="flex-start">
                  <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                    {translate('components.tenderCard.closingDate')}: {DateFormatter.getFormattedDate(date)}
                  </Text>
                </View>
              ) : null
            }
            <View ml={2} px={2} bg="appAccent" borderRadius={20} alignSelf="flex-start">
              <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                {translate('components.tenderCard.offers')}: {offers}
              </Text>
            </View>
          </View>

        </View>

        {/* Report Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showReportModal} onClose={() => setShowReportModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('components.tenderCard.sendReport')}
              </Modal.Header>
              <Modal.Body>

                {
                  !isReporting && responseMessage.length === 0 ? (
                    <FormControl mt={3}>
                      <TextArea
                        textAlign="left"
                        placeholder={translate('components.tenderCard.reason')} h={100}
                        rounded={10} size="xl" borderColor="gray.500"
                        onChangeText={(text) => {
                          if ('reason' in reasonError) {
                            let errors = {...setReasonError}
                            delete errors.reason
                            setReasonError(errors);
                          }
                          reportReason.current = text
                        }}
                      />
                    </FormControl>
                  ) : null
                }
                {
                  isReporting ? (
                    <Center>
                      <Spinner size="lg" color="appPrimary" accessibilityLabel="Reporting..." />
                    </Center>
                  ) : null
                }
                {
                  !isReporting && responseMessage.length ? (
                    <Center>
                      <Text fontSize={18} fontWeight={500}>
                        {responseMessage}
                      </Text>
                    </Center>
                  ) : null
                }

                {
                  'reason' in reasonError ? (
                    <Text fontSize={18} color="appSecondary">
                      {reasonError.reason}
                    </Text>
                  ) : null
                }

              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowReportModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={() => {
                    sendReport()
                  }}>
                    {translate('components.tenderCard.sendReport')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>
      </Box>

      <AlertModal alertText={translate('demoAlert.message') } isOpen={showAlertModal} onClose={() => setShowAlertModal(false)}/>
    </Pressable>
  )
}
