import React from "react";

import {
  Box, useColorModeValue, View,
  Center, HStack, Skeleton
} from "native-base";
import {Dimensions} from "react-native";

export default () => {
  const width = Dimensions.get('screen').width

  return (
    <Box
      bg={useColorModeValue("#F8F8F8", "coolGray.700")}
      borderRadius={8} shadow={3} mt={3} ml={2} mb={1} pb={2}
      w={[width * 0.9, width * 0.24, width * 0.24]}
    >
      <View my={3}/>

      <HStack>
        <Skeleton size="16" rounded="full" ml={2} />

        <View w="70%">
          <View px={2}>
            <Skeleton.Text lines={1} px={2}/>
          </View>

          <View my={3}/>

          <HStack justifyContent="space-between" px={2}>
            <View maxW="50%" px={2} borderRadius={20} alignSelf="flex-start">
              <Skeleton.Text lines={1} w={20} />
            </View>
            <View maxW="50%">
              <Center flexDirection="row">
                <Skeleton.Text lines={1} />
              </Center>
            </View>
          </HStack>
        </View>

      </HStack>

      <View my={3}/>

    </Box>
  )
}
