import StorageManager from './StorageManager'

export default class AuthUserManager {

  static async store(userData){
    await StorageManager.storeObject('user', userData);
  }

  static async get(){
    return await StorageManager.getObject('user');
  }

  static async remove(){
    await StorageManager.remove('user');
  }

}
