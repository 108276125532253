
import React, { useMemo } from 'react';
import { View, Dimensions, StyleSheet } from 'react-native';
import TabHandler from "./TabHandler";
import SIZES from '../../constants/tab/Sizes';

import {
  createBox,
  ThemeProvider,
} from '@shopify/restyle';

const Box = createBox();

import TabShape from './TabShape';
import theme from '../../constants/tab/Theme';

const { NAVIGATION_BOTTOM_TABS_HEIGHT } = SIZES;
const { width: wWidth } = Dimensions.get('window');


function TabsUI({ tabs }) {
  const tabWidth = useMemo(() => wWidth / tabs.length, [tabs.length]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        {...{ height: NAVIGATION_BOTTOM_TABS_HEIGHT, wWidth }}
        >
        <TabShape {...{ tabWidth }}  />
        <Box  {...StyleSheet.absoluteFill}>
          <TabHandler {...{ tabs, tabWidth }} />
        </Box>
      </Box>
    </ThemeProvider>

  );
}

export default TabsUI;
