import React, {useEffect, useState, useContext, useRef} from 'react';
import { Ionicons } from "@expo/vector-icons";
import * as DocumentPicker from 'expo-document-picker';
import apiClient from "../../../../utils/apiClient";

import {
  View, Text, Icon, Center, Button,
  ScrollView, FormControl,
  Input, TextArea, Select, Modal, Pressable, Divider, useColorModeValue
} from "native-base";

import Map from "../../../../components/Map"
import {Platform} from "react-native";
import {AuthContext} from "../../../../utils/context/auth";
import {localInfo, translate} from "../../../../utils/Translate";
import SelectList from "../../../../components/SelectList";
import NoItemYet from "../../../../components/NoItemYet";
import SubscriptionExpiredModal from "../../../../components/SubscriptionExpiredModal";
import CountryStatesSelectList from "../../../../components/CountryStatesSelectList";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ navigation }) => {
  const { isCompany, hasPermissionTo } = useContext(AuthContext);

  const [branches, setBranches] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [participationTypes, setParticipationTypes] = useState([]);
  const [showMapModal, setShowMapModal] = useState(false);
  const [mapInfo, setMapInfo] = useState(translate('screens.publishTender.info.placeholder.location'));
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [participationType, setParticipationType] = useState();
  const [canPublishTender, setCanPublishTender] = useState(true);
  const [currencies, setCurrencies] = React.useState([]);

  const originalBranches = useRef([])
  const originalStates = useRef([])
  const webFiles = useRef({});

  let location;

  const groupData = useRef({
    group : {
      title : null,
      description : null,
      latitude : null,
      longitude : null,
      closing_date : null,
      bid_initial_price : null,
      participation_cost : '',
      tva : null,
      language_id : null,
      branch_id : null,
      branch : {},
      state_id : null,
      state : {},
      currency_id : null,
      currency : {},
      participationtype_id : null,
      is_owner : true,
      has_participate : false,
    },
    pointTables: [],
    pointTablePreview: [],
    itemsTable: [],
    itemsTablePreview: [],
    reviewSteps: [],
    criteria: {},
    requiredDocuments: [],
    attachments: []
  });

  const pickDocument = async () => {
    let file = await DocumentPicker.getDocumentAsync({
      multiple: true
    });

    if (!!file.type && file.type === 'cancel'){
      return;
    }

    if(Platform.OS === 'web'){
      // WEB
      const files = file.output;
      webFiles.current = files;

      Promise.all(
        Object.keys(files).map(
          (_key, i) =>
            new Promise((resolve) => {
              const reader      = new FileReader();
              const currentFile = files[i];

              reader.onload = () => {
                resolve({
                  uri: reader.result,
                  type: currentFile.type,
                  name: currentFile.name,
                  size: currentFile.size
                });
              };
              reader.readAsDataURL(currentFile);
            })
        )
      ).then(result => {
        setFilesToUpload(result)
      })

      return;
    }

    let filesToUp = [];

    for (let fileToUp of file.assets){
      filesToUp.push({
        uri: fileToUp.uri,
        type: fileToUp.mimeType,
        name: fileToUp.name,
        size: fileToUp.size
      })
    }

    setFilesToUpload([...filesToUp, ...filesToUpload])

  };

  const handleTitleText = (text) => {
    if ('title' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.title
      setInputErrors(errors);
    }

    groupData.current = {
      ...groupData.current,
      group: {
        ...groupData.current.group,
        title: text,
      }
    }
  }

  const handleCostText = (text) => {
    if ('participationCost' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.participationCost
      setInputErrors(errors);
    }
    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    groupData.current.group.participation_cost =  text.replace(regex, '')
  }

  const handleDescriptionText = (text) => {
    if ('description' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.description
      setInputErrors(errors);
    }

    groupData.current = {
      ...groupData.current,
      group: {
        ...groupData.current.group,
        description: text,
      }
    }
  }

  const handleMapPress = (mapInfo) => location = mapInfo;

  function saveMapLocation(){
    setMapInfo(`${location.address} (${location.coordinate.latitude.toFixed(5)}, ${location.coordinate.longitude.toFixed(5)})`)

    groupData.current = {
      ...groupData.current,
      group: {
        ...groupData.current.group,
        latitude: location.coordinate.latitude,
        longitude: location.coordinate.longitude,
      }
    }
    setShowMapModal(false);
  }

  const handleBranchSelect = (branchId) => {

    if ('branch' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.branch
      setInputErrors(errors);
    }

    groupData.current.group.branch_id = branchId
    groupData.current.group.branch = getObjectById(originalBranches.current, branchId)
  }

  const handleStateSelect = (stateID, countryID) => {
    if ('state' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.state
      setInputErrors(errors);
    }

    groupData.current.group.state_id   = stateID
    groupData.current.group.country_id = countryID

    getAndSetState(countryID, stateID)
  }

  const handleLanguageSelect = (itemValue) => {
    if ('language' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.language
      setInputErrors(errors);
    }
    groupData.current = {
      ...groupData.current,
      group: {
        ...groupData.current.group,
        language_id: itemValue,
      }
    }
  }


  const handleParticipationTypeSelect = (itemValue) => {
    if ('participation' in inputErrors) {
      let errors = {...inputErrors}
      delete errors.participation
      setInputErrors(errors);
    }
    groupData.current = {
      ...groupData.current,
      group: {
        ...groupData.current.group,
        participationtype_id: itemValue,
      }
    }

    let participationType = participationTypes.find(p => p.id === parseInt(itemValue))
    setParticipationType(participationType)
  }

  function getObjectById (array, id){
    return array.find(x => x.id === parseInt(id));
  }

  const validate = () => {

    if (!groupData.current.group.title) {
      setInputErrors({ ...inputErrors,
        title: translate('screens.publishTender.info.validation.title')
      });
      return false;
    } else if (!groupData.current.group.description) {
      setInputErrors({ ...inputErrors,
        description: translate('screens.publishTender.info.validation.description')
      });
      return false;
    } else if (!groupData.current.group.branch_id) {
      setInputErrors({ ...inputErrors,
        branch: translate('screens.publishTender.info.validation.branch')
      });
      return false;
    } else if (!groupData.current.group.state_id){
      setInputErrors({ ...inputErrors,
        state: translate('screens.publishTender.info.validation.state')
      });
      return false;
    } else if (!groupData.current.group.language_id){
      setInputErrors({ ...inputErrors,
        language: translate('screens.publishTender.info.validation.language')
      });
      return false;
    }

    return true;
  };

  function getBranches(){
    apiClient.get(`branches`).then( response => {
      originalBranches.current = response.data
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error));
  }

  function getCurrencies(){
    apiClient.get(`currencies`).then( response => {
      setCurrencies(response.data)
    }).catch(error => console.log(error));
  }

  function getAndSetState(countryID, stateID){
    apiClient.get(`countries/${countryID}/states/${stateID}`).then( response => {
      groupData.current.group.state = response.data
    }).catch(error => console.log(error));
  }

  function getLanguages(){
    apiClient.get(`languages`).then( response => {
      setLanguages(response.data)
    }).catch(error => console.log(error));
  }

  function getParticipationTypes(){
    apiClient.get(`participation-types`).then( response => {
      setParticipationTypes(response.data)
    }).catch(error => console.log(error));
  }

  function setBranchesOfLanguage(branchesList, language){
    let mapBranches = branchesList.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
        language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(mapBranches)
  }

  const handleNextButton = () => {

    if (!validate()) return;

    navigation.navigate('ItemsTable', {groupData: groupData.current})
  }

  useEffect(() => {
    (async() => {
      const canCreateTender = await hasPermissionTo('publish groups')

      setCanPublishTender(canCreateTender)
    })()

    getLanguages()
    getParticipationTypes()
    getBranches()
    getCurrencies()
  }, []);

  if (isCompany && !canPublishTender){
    return (
      <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
        <View mx={3}>
          <View mt={4}/>
          <Title title={translate('drawer.groupBuying.create')} />
          <SpacerMY space={3}/>

          <NoItemYet text={translate('screens.publishTender.noPermission')} />
        </View>
      </View>
    )
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4}>

        {/* Check user subscription */}
        <SubscriptionExpiredModal featureToAccess={"create-group-buying"}/>

        <View mt={4}/>
        <Title title={translate('drawer.groupBuying.create')} />
        <SpacerMY space={3}/>


        <Center>
          <FormControl mt={4} isInvalid={'title' in inputErrors}>
            <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                   placeholder={translate('screens.publishTender.info.placeholder.title')} rounded={10}
                   size="xl" borderColor="gray.500"
                   pl={4} py={3}
                   onChangeText={handleTitleText}
            />
            {
              'title' in inputErrors ? (
                <FormControl.ErrorMessage>
                  {inputErrors.title}
                </FormControl.ErrorMessage>
              ) : null
            }
          </FormControl>

          <FormControl mt={3} isInvalid={'description' in inputErrors}>
            <TextArea
              textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.publishTender.info.placeholder.description')} h={260}
              rounded={10} size="xl" borderColor="gray.500"
              onChangeText={handleDescriptionText}
            />
            {
              'description' in inputErrors ? (
                <FormControl.ErrorMessage>
                  {inputErrors.description}
                </FormControl.ErrorMessage>
              ) : null
            }
          </FormControl>

          <View mt={3} w="100%">
            <FormControl isInvalid={'language' in inputErrors}>
              <Select
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                selectedValue={groupData.current.group.language_id}
                accessibilityLabel="Field type"
                placeholder={translate('screens.publishTender.info.placeholder.language')}
                _selectedItem={{bg: "primary.100"}}
                borderRadius={10} size="xl" borderColor="gray.500" pl={4} py={3}
                onValueChange={handleLanguageSelect}
                dropdownIcon={
                  <Icon
                    as={Ionicons} name={"chevron-down-sharp"} size="5" color="darkgray"
                    key={
                      Math.random()
                    }
                  />
                }
              >
                {
                  languages.map((language, index) => {
                    return (
                      <Select.Item key={index} label={language.name[localInfo.local]} value={`${language.id}`} />
                    )
                  })
                }
              </Select>
              {
                'language' in inputErrors ? (
                  <FormControl.ErrorMessage>
                    {inputErrors.language}
                  </FormControl.ErrorMessage>
                ) : null
              }
            </FormControl>
          </View>

          <Pressable w="100%" onPress={() => setShowMapModal(true)}>
            <FormControl mt={4}>
              <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} placeholder={mapInfo} isReadOnly rounded={10} size="xl"
                     pointerEvents="none" borderColor="gray.500" pl={4} py={3}
              />
            </FormControl>
          </Pressable>

          {
            branches.length ? (
              <View mt={3} w="100%">
                <SelectList
                  selectText={translate('misc.branches')}
                  modalHeaderText={translate('screens.auth.interests.selectBranches')}
                  doneText={translate('misc.done')}
                  cancelText={translate('misc.cancel')}
                  isMultiSelect={false}
                  onDone={handleBranchSelect}
                  items={branches}
                />
                {
                  'branch' in inputErrors ? (
                    <Text fontSize={15} color="danger.400">
                      {inputErrors.branch}
                    </Text>
                  ) : null
                }
              </View>
            ) : null
          }

          <View mt={3} w="100%">
            <CountryStatesSelectList
              selectText={translate('misc.states')}
              modalHeaderText={translate('screens.auth.interests.selectCountryStates')}
              doneText={translate('misc.done')}
              cancelText={translate('misc.cancel')}
              isMultiSelect={false}
              onDone={handleStateSelect}
            />
            {
              'state' in inputErrors ? (
                <Text fontSize={16} color="danger.400">
                  {inputErrors.state}
                </Text>
              ) : null
            }
          </View>

        </Center>

        <View mt={9} mb={3} mx="8">
          <Divider thickness="1" bg="appPrimary" />
        </View>

        <Center mb={16} mt={10}>
          <Button
            w="85%" size="lg" color="appPrimary" borderRadius={8}
            onPress={ handleNextButton }
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.next')}
            </Text>
          </Button>
        </Center>

        {/* Map Modal */}
        <Center>
          <Modal size={["xl", "xl", "xl"]} isOpen={showMapModal} onClose={() => setShowMapModal(false)}>
            <Modal.Content maxWidth="100%">
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.publishTender.info.tenderLocation')}
              </Modal.Header>
              <Modal.Body>
                <Map onPress={handleMapPress} />
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowMapModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={() => {
                    saveMapLocation()
                  }}>
                    {translate('misc.save')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}
