import React, {useEffect, useState} from 'react';
import {Feather} from "@expo/vector-icons";

import GroupModel from "../../../models/GroupModel";
import apiClient from "../../../utils/apiClient";
import TableConverter from "../../../utils/TableConverter";

import { Table, TableWrapper, Row, Cell } from 'react-native-table-component-2';
import {
  View, Text, Icon, Center, ScrollView,
  Input, Button, Modal, FormControl, HStack, useColorMode, Spinner, Box, useTheme
} from "native-base";
import {Platform, StyleSheet} from "react-native";
import {localInfo, translate} from "../../../utils/Translate";
import TenderSkeleton from "../../../components/skeleton/TenderSkeleton";
import * as DocumentPicker from "expo-document-picker";
import TimeFormatter from "../../../utils/TimeFormatter";
import SubscriptionExpiredModal from "../../../components/SubscriptionExpiredModal";
import AuthUserManager from "../../../utils/AuthUserManager";
import AlertModal from "../../../components/AlertModal";

const SectionTitle = ({ title, suffix }) => {
  return <View>
    <HStack alignContent="center">
      <Text textAlign="left" fontSize={28} fontWeight={500}>
        {title}
      </Text>
      {
        !!suffix ? <Text textAlign="left" ml={2} mt={2} fontSize={18} fontWeight={400}>
          ({suffix})
        </Text> : null
      }
    </HStack>
    <View height={1} backgroundColor="appPrimary" w={60}/>

  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const RequestSum = ({ item, unit, quantity, key }) => {
  return <HStack key={key} px={4} py={2} justifyContent="space-between">
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={18}>
      {item}
    </Text>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={18}>
      {`${quantity}  ${unit}`}
    </Text>
  </HStack>
}

const widthArr = [150, 150, 150]

export default ({ route, navigation }) => {

  const { groupData, previewData } = route.params;

  const {colorMode} = useColorMode();
  const { colors } = useTheme();
  const isDark = colorMode === 'dark';


  const [showConfirmOfferModal, setShowConfirmOfferModal] = useState(false);
  const [isOffering, setIsOffering] = useState(false);
  const [group, setGroup] = useState(GroupModel[0]);
  const [pointsTableData, setPointsTableData] = useState([]);
  const [itemsTableData, setItemsTableData] = useState([]);
  const [totalTableData, setTotalTableData] = useState([ [0], [0], [0] ]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [groupRequiredDocs, setGroupRequiredDocs] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [requestSentModal, setRequestSentModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [inputErrors, setInputErrors] = React.useState({});
  const pointsTableIsValid = React.useRef({});

  const pointsTableHead = [
    translate('screens.tender.pointsTableHeader.item'),
    translate('screens.tender.pointsTableHeader.requirement'),
    translate('screens.tender.pointsTableHeader.points'),
    translate('screens.tender.pointsTableHeader.offer'),
    translate('screens.tender.pointsTableHeader.total')
  ]

  const itemsTableHead = [
    translate('screens.tender.priceTableHeader.item'),
    translate('screens.tender.priceTableHeader.unit'),
    translate('screens.tender.priceTableHeader.quantity')
  ]

  const [totalTableTitles, setTotalTableTitles] = useState([
    translate('screens.tender.totalTableTitles.TOTAL_HT'),
    translate('screens.tender.totalTableTitles.TVA'),
    translate('screens.tender.totalTableTitles.TOTAL_TTC')
  ]);

  // Offer state
  const [date, setDate] = useState();
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [hasReviewSteps, setHasReviewSteps] = React.useState(false);
  const [formattedDate, setFormattedDate] = React.useState(null);
  const [offerData, setOfferData] = useState({
    offer: {
      bid_amount : null,
      delivery_date : null,
      reason : null
    },
    offerPriceTables: []
  });

  useEffect(() => {

    let canOffer = !previewData || !group.is_owner || !group.has_participate;
    setIsOffering(canOffer)

    getGroup()
  }, []);

  useEffect(() => {
    (async () => {
      let auth = await AuthUserManager.get()
      setCurrentUser(auth)
    })();

  }, []);

  const validate = () => {
    // Validate offer max points input
    for (const index in pointsTableIsValid.current) {
      if (!pointsTableIsValid.current[index]){
        return false
      }
    }
    // Validate offer required documents
    for (const [index, doc] of groupRequiredDocs.entries()){
      if (doc.is_required && !doc.file){
        setInputErrors({ ...inputErrors,
          [`docRequired${index}`]: translate('screens.publishTender.requiredDocuments.requiredDocument')
        });

        return false;
      }
    }

    return true;
  };

  const pickDocument = async (index) => {
    let file = await DocumentPicker.getDocumentAsync({
      multiple: false
    });

    if (!!file.type && file.type === 'cancel'){
      return;
    }

    if (`docRequired${index}` in inputErrors) {
      let errors = {...inputErrors}
      delete errors[`docRequired${index}`]
      setInputErrors(errors);
    }

    if(Platform.OS === 'web'){
      const files = file.output;

      let requiredDocs = [...groupRequiredDocs]

      if (!!requiredDocs[index].file){
        delete requiredDocs[index].file
      }

      requiredDocs[index].file = files[0]
      setGroupRequiredDocs(requiredDocs)

      return;
    }

    let requiredDocs = [...groupRequiredDocs]

    if (!!requiredDocs[index].file){
      delete requiredDocs[index].file
    }

    requiredDocs[index].file = {
      uri: file.assets[0].uri,
      type: file.assets[0].mimeType,
      name: file.assets[0].name,
      size: file.assets[0].size
    }
    setGroupRequiredDocs(requiredDocs)
  };

  function getGroup(){

    if (!!previewData) {
      let preview = {...previewData};

      let previewGroup = {...preview.group}
      delete preview.group;
      let previewGroupData = {...previewGroup, ...preview}

      setTVA(previewGroupData.tva)
      setGroup(previewGroupData)
      setHasReviewSteps(!!previewData.reviewSteps.length)

      if (preview.itemsTablePreview.length > 0){
        let itemsTablePreview = preview.itemsTablePreview.filter((item, index) => index !== preview.itemsTablePreview.length -1 )
        setItemsTableData(itemsTablePreview);
      }

      return;
    }

    setGroup(groupData)
    // setTVA(groupData.tva)
    // setGroupRequiredDocs(groupData.required_docs)
    getGroupItemsTable(groupData.itemtables)
    resetTableQuantity(groupData)
  }

  function getGroupPointsTable(pointTables){
    let tableArr = TableConverter.pointsToArray(pointTables)
    setPointsTableData(tableArr);
  }

  function getGroupItemsTable(priceTables){
    let tableArr = TableConverter.itemsToArray(priceTables)
    setItemsTableData(tableArr);
  }

  function requestMaker(groupData = null){

    const data = !!groupData ? groupData : group

    const itemsWithNonEmptyQuantity = data.itemtables.filter(item => item.quantity !== "" && item.quantity !== null);

    setRequestData(itemsWithNonEmptyQuantity)
  }

  function resetTableQuantity(groupData = null){
    const data = !!groupData ? groupData : group

    data.itemtables = data.itemtables.map(item => {
      item.quantity = null
      return item;
    });
  }

  const handleSubmitOffer = () => {

    if (!validate()) return;

    if (group.bid_initial_price !== null){
      setShowConfirmOfferModal(true)
      return;
    }
    if (pointsTableData.length === 0){
      setShowConfirmOfferModal(true)
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: group.groupable.avatar,
      groupId: group.id,
      isPaidGroup: false,
      price: group.participation_cost,
      offerData: {
        ...offerData,
        offerPriceTables: group.itemtables,
      }
    })
  }

  const submitOffer = () => {
    // If this is a demo account return alert message
    if (currentUser.id === 10193){
      setShowAlertModal(true);
      return;
    }

    setIsPublishing(true)

    const data = new FormData()
    data.append('data', JSON.stringify({
      requestItems: group.itemtables,
    }))

    // for (let n = 0; n < groupRequiredDocs.length; n++){
    //   if (!!groupRequiredDocs[n].file){
    //     data.append('docs[]', groupRequiredDocs[n].file)
    //     data.append('docsNames[]', groupRequiredDocs[n].name)
    //   }
    // }

    apiClient.post(`groups/${group.id}/requests`, data, {isFormData: true}).then(() => {
      setShowConfirmOfferModal(false);
      setIsPublishing(false)
      setRequestSentModal(true)
    }).catch((error) => {
      setIsPublishing(false)
      console.log(error.response.data)
    })
  }

  function saveDownloadHistory(attachmentId){
    return apiClient.post(`downloads`, {attachmentId})
  }

  const handleBid = (value) => {
    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        bid_amount: value.replace(regex, '')
      }
    })
  }

  const onDismissSingle = () => {
    setOpenDatePicker(false);
  };

  const onConfirmSingle = (params) => {
    setOpenDatePicker(false);
    setDate(params.date);

    setFormattedDate(TimeFormatter.getFormattedDate(params.date));

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        delivery_date: getFormattedDate(params.date)
      }
    })
  }

  function getFormattedDate(date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  const OfferInput = (index, type) => {

    return <View>
      <FormControl>
        <Input value={group.itemtables[index].quantity} keyboardType="numeric" onChangeText={(value) => {
          let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

          let offerValue = value.replace(regex, '');

          if (type === 'price'){
            group.itemtables[index].quantity = offerValue;

            requestMaker()
          }
        }} textAlign='center' size="2xl" placeholder="- -"/>
        <FormControl.ErrorMessage>

        </FormControl.ErrorMessage>
      </FormControl>
    </View>
  };

  function setTVA(tva){
    let updateTVA = [...totalTableTitles];
    updateTVA[1] = `TVA ${tva}%`;
    setTotalTableTitles(updateTVA);
  }

  function calculatePriceTable(){

    let totalHT  = group.itemtables.reduce((total, item) => item.total + total,0);
    let TVA      = totalHT * group.tva/100;
    let totalTTC = totalHT + TVA;

    let updatePriceTotal = [...totalTableData];
    updatePriceTotal[0][0] = formatter.format(totalHT);
    updatePriceTotal[1][0] = formatter.format(TVA);
    updatePriceTotal[2][0] = formatter.format(totalTTC);
    setTotalTableData(updatePriceTotal);
  }

  let formatter = new Intl.NumberFormat(localInfo.local, {
    style: 'currency',
    currency: !!group.currency_id ? group.currency.code.en : 'DZD',
  });


  const handleSubmitPaidOffer = () => {

    if (pointsTableData.length === 0){
      navigation.navigate("PaymentMethods", {
        price: group.participation_cost,
        groupId: group.id,
        offerData: {
          ...offerData,
          offerPointsTables: group.pointtables,
          offerPriceTables: group.itemtables,
        }
      })
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: group.groupable.avatar,
      groupId: group.id,
      isPaidGroup: true,
      price: group.participation_cost,
      offerData: {
        ...offerData,
        offerPointsTables: group.pointtables,
        offerPriceTables: group.itemtables,
      }
    })
  }

  if (!group.title){
    return (
      <TenderSkeleton/>
    )
  }

  return (
    <View
      bg={isDark ? "coolGray.800" : "white"}
      px={3} h="100%"
    >
      {
        !previewData ? null : <View bg="appSecondary" py={1} px={10}>
          <Center>
            <View flexDirection="row">
              <Text textAlign="center" fontWeight={500} fontSize={24} color="primary.50">
                {translate('screens.tender.previewText')}
              </Text>
              <Center ml={10}>
                <Icon color="primary.50" as={<Feather name="eye" />} size={7} />
              </Center>
            </View>
          </Center>
        </View>
      }

      <ScrollView>

        {/* Check user subscription */}
        <SubscriptionExpiredModal featureToAccess={"participate-group-buying"}/>

        <View my={2}/>

        {/*{*/}
        {/*  group.attachments.length > 0 ? (*/}
        {/*    <View mt={4} mb={6}>*/}
        {/*      <SectionTitle title={translate('screens.tender.documents')} />*/}

        {/*      {*/}
        {/*        group.attachments.map((file, index) => (*/}
        {/*          <View key={index} mt={2}>*/}
        {/*            <TouchableOpacity*/}
        {/*              onPress={async () => {*/}
        {/*                if (!!previewData) return*/}
        {/*                await saveDownloadHistory(file.id)*/}
        {/*                await WebBrowser.openBrowserAsync(encodeURI(file.path));*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              <HStack alignItems="center">*/}
        {/*                <Icon as={MaterialCommunityIcons} name="folder-download-outline" size="md" color="appPrimary" />*/}
        {/*                <View mx={1}/>*/}
        {/*                <Text textAlign="left" fontSize={16} color="appPrimary">*/}
        {/*                  {`${file.name} - ${formatBytes(file.size)}`}*/}
        {/*                </Text>*/}
        {/*              </HStack>*/}
        {/*            </TouchableOpacity>*/}
        {/*          </View>*/}
        {/*        ))*/}
        {/*      }*/}
        {/*    </View>*/}
        {/*  ) : null*/}
        {/*}*/}
        {/*{*/}
        {/*  group.bid_initial_price === null ? null : <FormControl mt={4}>*/}
        {/*    <Input*/}
        {/*      textAlign="left"*/}
        {/*      placeholder={`${translate('screens.tender.bid')} (${group.currency.code[localInfo.local]})`} rounded={10}*/}
        {/*      size="xl" borderColor="gray.500" pl={4} py={3}*/}
        {/*      w={["100%", "100%", "42%"]}*/}
        {/*      keyboardType="number-pad"*/}
        {/*      isDisabled={!!previewData}*/}
        {/*      value={offerData.offer.bid_amount}*/}
        {/*      onChangeText={handleBid}*/}
        {/*    />*/}
        {/*    <FormControl.HelperText>*/}
        {/*      <Text textAlign="left" ml={2} mt={-1} fontSize={18}>*/}
        {/*        {translate('screens.tender.startingPrice')}: {group.bid_initial_price} {group.currency.code[localInfo.local]}*/}
        {/*      </Text>*/}
        {/*    </FormControl.HelperText>*/}
        {/*  </FormControl>*/}
        {/*}*/}
        {/*{*/}
        {/*  pointsTableData.length === 0 ? null : <View mt={8}>*/}
        {/*    <SectionTitle title={translate('screens.tender.pointsTable')}/>*/}

        {/*    <View mt={5}>*/}
        {/*      <ScrollView horizontal={true}>*/}
        {/*        <View style={styles.container}>*/}
        {/*          <Table style={{}} borderStyle={{borderWidth: 2, borderColor: '#2F7E78'}}>*/}
        {/*            <Row widthArr={widthArr} data={pointsTableHead} style={styles.head} textStyle={styles.headText}/>*/}

        {/*            {pointsTableData.map((rowData, index) => (*/}
        {/*              <TableWrapper widthArr={widthArr} key={index} style={styles.row}>*/}
        {/*                {rowData.map((cellData, cellIndex) => (*/}
        {/*                  cellIndex !== 5 ? <Cell*/}
        {/*                    style={index === 2 ? {backgroundColor: '#fff'} : null}*/}
        {/*                    width={150}*/}
        {/*                    key={cellIndex}*/}
        {/*                    data={*/}
        {/*                      (cellIndex === 3 && isOffering && !previewData && !group.is_owner && !group.has_participate)*/}
        {/*                        ? OfferInput(index, 'point') : cellData*/}
        {/*                    }*/}
        {/*                    textStyle={styles.text}*/}
        {/*                  /> : null*/}
        {/*                ))}*/}
        {/*              </TableWrapper>*/}
        {/*            ))}*/}

        {/*          </Table>*/}
        {/*        </View>*/}
        {/*      </ScrollView>*/}
        {/*    </View>*/}
        {/*  </View>*/}
        {/*}*/}

        {
          itemsTableData.length === 0 ? null : <View mt={0}>
            <SectionTitle title={translate('drawer.groupBuying.itemsTable.title')}/>

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={itemsTableHead} style={styles.head} textStyle={styles.headText}/>

                    {itemsTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={(cellIndex === 2 && isOffering && !previewData && !group.has_participate) ? OfferInput(index, 'price') : cellData}
                            textStyle={styles.text}
                          />
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>

            <View mt={6}>
              <SectionTitle title={translate('drawer.groupBuying.apply.yourRequest')} />
            </View>

          </View>
        }
        {/*{*/}
        {/*  group.bid_initial_price !== null ? null : <View mt={8}>*/}
        {/*    <SectionTitle title={translate('screens.tender.deliveryDate')} />*/}

        {/*    <Pressable*/}
        {/*      w={["100%", "100%", "42%"]}*/}
        {/*      onPress={() => !!previewData || group.is_owner || group.has_participate*/}
        {/*      || (!!group.is_the_closing_date && group.is_the_closing_date) ? null : setOpenDatePicker(true)}>*/}
        {/*      <FormControl mt={4}>*/}
        {/*        <Input*/}
        {/*          textAlign="left"*/}
        {/*          placeholder={translate('screens.tender.deliveryDate')}*/}
        {/*          value={formattedDate} isReadOnly rounded={10} size="xl"*/}
        {/*          pointerEvents="none" borderColor="gray.500" pl={4} py={3}*/}
        {/*        />*/}
        {/*        <FormControl.HelperText>*/}
        {/*          <Text textAlign="left" ml={2} mt={-1}>*/}
        {/*            {translate('screens.tender.deliveryDate')}*/}
        {/*          </Text>*/}
        {/*        </FormControl.HelperText>*/}
        {/*      </FormControl>*/}
        {/*    </Pressable>*/}

        {/*    <PaperProvider theme={datePickerTheme}>*/}
        {/*      <DatePickerModal*/}
        {/*        locale="en"*/}
        {/*        mode="single"*/}
        {/*        visible={openDatePicker}*/}
        {/*        onDismiss={onDismissSingle}*/}
        {/*        date={date}*/}
        {/*        onConfirm={onConfirmSingle}*/}
        {/*        validRange={{*/}
        {/*          startDate: new Date(),  // optional*/}
        {/*          endDate: new Date(2999, 1, 1),*/}
        {/*          disabledDates: [new Date()] // optional*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </PaperProvider>*/}
        {/*  </View>*/}
        {/*}*/}

        {/*{*/}
        {/*  groupRequiredDocs.length ? (*/}
        {/*    <View mt={8}>*/}
        {/*      <SectionTitle title={translate('screens.tender.requiredDocuments')} />*/}
        {/*    </View>*/}
        {/*  ) : null*/}
        {/*}*/}

        {/*{*/}
        {/*  groupRequiredDocs.map((doc, docIndex) => (*/}
        {/*    <View>*/}
        {/*      <FormControl mt={5} isInvalid={`docRequired${docIndex}` in inputErrors}>*/}
        {/*        <Text fontSize={20} fontWeight={600}>*/}
        {/*          {doc.name}*/}
        {/*        </Text>*/}
        {/*        <Text fontSize={17} mb={1}>*/}
        {/*          {*/}
        {/*            !doc.is_required ? translate('misc.optional') : null*/}
        {/*          } {doc.note}*/}
        {/*        </Text>*/}
        {/*        <Button*/}
        {/*          variant="outline"*/}
        {/*          leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" size="sm" />}*/}
        {/*          onPress={ async () => {*/}
        {/*            await pickDocument(docIndex)*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {translate('misc.clickToUpload')}*/}
        {/*        </Button>*/}

        {/*        {*/}
        {/*          `docRequired${docIndex}` in inputErrors ? (*/}
        {/*            <FormControl.ErrorMessage>*/}
        {/*              {inputErrors[`docRequired${docIndex}`]}*/}
        {/*            </FormControl.ErrorMessage>*/}
        {/*          ) : null*/}
        {/*        }*/}

        {/*      </FormControl>*/}

        {/*      {*/}
        {/*        !!doc.file ? (*/}
        {/*          <HStack alignItems="center">*/}
        {/*            <TouchableOpacity onPress={() => {*/}
        {/*              let requiredDocs = [...groupRequiredDocs]*/}
        {/*              delete requiredDocs[docIndex].file*/}
        {/*              setGroupRequiredDocs(requiredDocs)*/}
        {/*            }}>*/}
        {/*              <Icon as={Ionicons} name="trash-bin-outline" size={5} color="appSecondary" />*/}
        {/*            </TouchableOpacity>*/}
        {/*            <View mx={1}/>*/}
        {/*            <Text textAlign="left" fontSize={17}>*/}
        {/*              {doc.file.name} - {formatBytes(doc.file.size)}*/}
        {/*            </Text>*/}
        {/*          </HStack>*/}
        {/*        ) : null*/}
        {/*      }*/}


        {/*      <View mt={9} mb={3} mx="8">*/}
        {/*        <Divider thickness="1" bg="appPrimary" />*/}
        {/*      </View>*/}
        {/*    </View>*/}
        {/*  ))*/}
        {/*}*/}

        {/*{*/}
        {/*  !!previewData || !group.participation_cost || (group.is_owner || group.has_participate)*/}
        {/*  || (!!group.is_the_closing_date && group.is_the_closing_date) ? null :*/}
        {/*    <View mt={50}>*/}
        {/*      <Button*/}
        {/*        borderRadius={10}*/}
        {/*        onPress={handleSubmitPaidOffer}*/}
        {/*        isDisabled={!!group.bid_initial_price === false && !!offerData.offer.delivery_date === false}*/}
        {/*      >*/}
        {/*        <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">*/}
        {/*          {translate('misc.next')}*/}
        {/*        </Text>*/}
        {/*      </Button>*/}
        {/*    </View>*/}
        {/*}*/}

        <Center mt={4}>
          <Box bg="#F7F7F7" w="100%" borderRadius={10} shadow={4}>

            {
              requestData.map((item, index) => (
                <RequestSum
                  key={index}
                  item={item.item}
                  unit={item.unit}
                  quantity={item.quantity}
                />
              ))
            }

            <SpacerMY space={2}/>
          </Box>

        </Center>

        {
          !!previewData ? <View mt={50}>
              <Button
                borderRadius={10}
                isDisabled={true}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('misc.send')}
                </Text>
              </Button>
            </View>
            :
            null
        }
        {
          !!previewData || group.has_participate
          || !!group.participation_cost || (!!group.is_the_closing_date
            || group.is_closed && group.is_the_closing_date) ? null :
            <View mt={50}>
              <Button
                borderRadius={10}
                onPress={handleSubmitOffer}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('misc.send')}
                </Text>
              </Button>
            </View>
        }
        {
          (!!group.is_the_closing_date && group.is_the_closing_date) || group.is_closed ? (
            <View mt={50}>
              <Button
                borderRadius={10}
                isDisabled={true}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('screens.tender.closed')}
                </Text>
              </Button>
            </View>
          ) : null
        }

        {/* Confirm offer Modal */}
        <Center>
          <Modal isOpen={showConfirmOfferModal} onClose={() => setShowConfirmOfferModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.apply.modalHeader')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text textAlign="left" fontWeight={600} fontSize={26}>
                    {translate('screens.tender.publishModal.areYouSure')}
                  </Text>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.apply.modalNote')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmOfferModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={submitOffer}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.send')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Request Sent Modal */}
        <Center>
          <Modal
            isOpen={requestSentModal}
            closeOnOverlayClick={false}
            onClose={() => setRequestSentModal(false)}
          >
            <Modal.Content>
              <Modal.Header>
                {translate('drawer.groupBuying.apply.requestSent')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.apply.requestSentMsg')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button onPress={() => {
                    navigation.goBack()
                  }}
                  >
                    {translate('misc.Ok')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        <View mt={48} />

        <AlertModal alertText={translate('demoAlert.message')} isOpen={showAlertModal} onClose={() => setShowAlertModal(false)}/>

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  wrapper: { flexDirection: 'row' },
  title: { flex: 1, backgroundColor: '#f6f8fa', width: 200 },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center'
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
