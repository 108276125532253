import React, {useEffect, useRef, useState} from 'react';
import {MaterialIcons} from "@expo/vector-icons";
import { TouchableOpacity } from "react-native"

import {
  View, Text, Icon, ScrollView, useColorModeValue, Spinner
} from "native-base";

import apiClient from "../utils/apiClient";
import {localInfo, translate} from "../utils/Translate";
import CompanyCardH from "../components/CompanyCardH";
import SearchInput from "../components/SearchInput";
import SelectListModal from "../components/SelectListModal";
import CompanyCardHSkeleton from "../components/skeleton/CompanyCardHSkeleton";
import NoItemYet from "../components/NoItemYet";
import {Platform} from "react-native";
import CountryStatesModalList from "../components/CountryStatesModalList";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

export default () => {

  const [showSelectBranchesModal, setShowSelectBranchesModal] = useState(false);
  const [showSelectStatesModal, setShowSelectStatesModal] = useState(false);
  const [branches, setBranches] = useState([])
  const [searchInputText, setSearchInputText] = useState(undefined)
  const [companies, setCompanies] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false)

  const filteredStates = useRef([])
  const filteredBranches = useRef([])
  const searchText = useRef('')
  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  function getCompanies(){
    apiClient.get(`companies`).then( response => {
      getNextPage(response.data.next_page_url)
      setCompanies(response.data.data)
    }).catch(error => console.log(error));
  }

  function getFilteredCompanies(isLoadMore = false){
    let branchFilter = `filter[branches.id]=${filteredBranches.current}`;
    let stateFilter  = `filter[state_id]=${filteredStates.current}`;
    let nameFilter   = `filter[name]=${searchText.current}`
    let nextP        = nextPage.current.length ? `&${nextPage.current}` : '';

    let query = `companies?${branchFilter}&${stateFilter}&${nameFilter}${nextP}`;

    apiClient.get(query).then( response => {

      if (isLoadMore && nextPage.current.length){
        getNextPage(response.data.next_page_url)
        setCompanies(old => [...old, ...response.data.data])

        setIsLoadingData(false)
        isLoadingMoreRef.current = false
        return;
      }

      getNextPage(response.data.next_page_url)
      setCompanies(response.data.data)

      setIsLoadingData(false)
      isLoadingMoreRef.current = false
    }).catch(error => console.log(error));
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  function getBranches(){
    apiClient.get(`branches`).then(response => {
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error.response.data));
  }
  function setBranchesOfLanguage(branches, language){
    let branchesOfLanguage = branches.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
        language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(branchesOfLanguage)
  }

  const onBranchesSelected = (item) => {
    filteredBranches.current = item;
    setShowSelectBranchesModal(false)

    getFilteredCompanies()
  }
  const onStatesSelected = (item) => {
    filteredStates.current = item;
    setShowSelectStatesModal(false)

    getFilteredCompanies()
  }
  const onSelectBranchesModalCanceled = () => {
    setShowSelectBranchesModal(false)
  }
  const onSelectStatesModalCanceled = () => {
    setShowSelectStatesModal(false)
  }
  const onSearch = (text) => {
    searchText.current = text;
    setSearchInputText(text)
    getFilteredCompanies();
  }

  function loadMore(){
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    setIsLoadingData(true)
    isLoadingMoreRef.current = true

    getFilteredCompanies(true)
  }

  useEffect(() => {

    getCompanies()
    getBranches()

  }, []);

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 50;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  return (
    <View px={3} bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            loadMore()
          }
        }}
        scrollEventThrottle={400}
      >
        <View mt={4}>
          <Title title={translate('screens.companies.title')} />
        </View>

        <View mt={5} mb={3}>

          <SearchInput
            onSubmit={onSearch}
            value={searchInputText}
            onChange={(text) => {
              setSearchInputText(text)
              searchText.current = text
            }}
            onEmpty={() => {
              searchText.current = '';
              getFilteredCompanies();
            }}
          />

          <View mt={4}/>

          <View flexDirection="row" justifyContent="space-between">

            <View flexDirection="row">
              <TouchableOpacity
                onPress={() => setShowSelectBranchesModal(true)}
              >
                <View flexDirection="row">
                  <Icon as={<MaterialIcons name="filter-list" />} color={"appPrimary"} size={7}/>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={16}>
                    {translate('screens.tenders.Branches')}
                  </Text>
                </View>
              </TouchableOpacity>

              <View mx={2} />
              <TouchableOpacity
                onPress={() => setShowSelectStatesModal(true)}
              >
                <View flexDirection="row">
                  <Icon as={<MaterialIcons name="filter-list" />} color={"appPrimary"} size={7}/>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={16}>
                    {translate('screens.tenders.states')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        {
          !!companies ? (
            <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
              {
                companies.map((company, index) => {
                  return (
                    <View key={company.id}>
                      <CompanyCardH
                        companyName={company.name}
                        avatar={company.avatar}
                        tendersCount={company.tenders_count}
                        location={company.state.name[localInfo.local]}
                        id={company.id}
                      />
                    </View>
                  );
                })
              }
            </View>
          ) : null
        }
        {
          companies === null ? (
            (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {
                  [1,2,3].map((company, index) => {
                    return (
                      <CompanyCardHSkeleton key={index}/>
                    );
                  })
                }
              </View>
            )
          ) : null
        }
        {
          !!companies && !companies.length ? (
            <NoItemYet text={translate('components.noItemYet.noCompaniesYet')}/>
          ) : null
        }

        {
          isLoadingData ? (
            <View mt={10}>
              <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
            </View>
          ) : null
        }

        <View mb={20}/>

        {
          branches.length ? (
            <SelectListModal
              visible={showSelectBranchesModal}
              items={branches}
              selectText={translate('misc.branches')}
              modalHeaderText={translate('screens.auth.interests.selectBranches')}
              doneText={translate('misc.done')}
              cancelText={translate('misc.cancel')}
              showSelectAll={false}
              onDone={onBranchesSelected}
              onCancel={onSelectBranchesModalCanceled}
            />
          ) : null
        }

        <CountryStatesModalList
          visible={showSelectStatesModal}
          modalHeaderText={translate('screens.auth.interests.selectCountryStates')}
          doneText={translate('misc.done')}
          cancelText={translate('misc.cancel')}
          showSelectAll={true}
          onDone={onStatesSelected}
          onCancel={onSelectStatesModalCanceled}
        />

      </ScrollView>
    </View>
  );
}
