
import {
  View, Text, Icon, ScrollView,
  useColorModeValue, Center, Button
} from "native-base";
import React from "react";
import {AntDesign} from "@expo/vector-icons";
import {localInfo, translate} from "../../utils/Translate";
import {Platform} from "react-native";

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ navigation }) => {

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>

        <SpacerMY space={16}/>
        <Center>
          <Text
            fontSize={20} fontWeight={500}
          >
            {translate('screens.forgetPassword.successPage.title')}
          </Text>

          <SpacerMY space={7}/>

          <Icon as={<AntDesign name="checkcircleo"/>} color="appPrimary" size={100} />

          <SpacerMY space={3}/>

          <Text
            textAlign="center"
            fontSize={18}
          >
            {translate('screens.forgetPassword.successPage.description')}
          </Text>

          <SpacerMY space={8}/>

          <Button
            w="85%"
            onPress={() => navigation.navigate('Sign-in')}
            size="lg"
            color="appPrimary"
            borderRadius={8}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.signIn')}
            </Text>
          </Button>

        </Center>

      </ScrollView>
    </View>
  );
}
