import React, {useState, useEffect} from 'react';
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import OfferModel from "../models/OfferModel";
import TimeFormatter from "../utils/TimeFormatter";

import {
  View, Text, Icon, Divider, Avatar, HStack, TextArea,
  Pressable, Button, ScrollView, Center, Modal, FormControl, useColorMode, Spinner, useTheme
} from "native-base";
import apiClient from "../utils/apiClient";
import TableConverter from "../utils/TableConverter";
import {Cell, Col, Row, Rows, Table, TableWrapper} from "react-native-table-component-2";
import {StyleSheet, TouchableOpacity} from "react-native";
import AuthUserManager from "../utils/AuthUserManager";
import {localInfo, translate} from "../utils/Translate";
import TokenManager from "../utils/TokenManager";
import OfferSkeleton from "../components/skeleton/OfferSkeleton";
import * as WebBrowser from "expo-web-browser";
import {formatBytes} from "../utils/StringTool";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const SectionTitle = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}


export default ({ route, navigation }) => {

  const { offerId } = route.params;

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const [offer, setOffer] = useState(OfferModel)
  const [token, setToken] = useState()
  const [currentUser, setCurrentUser] = useState(null);

  const [pointsTableData, setPointsTableData] = useState([]);
  const [priceTableData, setPriceTableData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // Tables Data
  const widthArr = [150, 150, 150, 150, 150]
  const pointsTableHead = [
    translate('screens.tender.pointsTableHeader.item'),
    translate('screens.tender.pointsTableHeader.requirement'),
    translate('screens.tender.pointsTableHeader.points'),
    translate('screens.tender.pointsTableHeader.offer'),
    translate('screens.tender.pointsTableHeader.total')
  ]

  const priceTableHead = [
    translate('screens.tender.priceTableHeader.item'),
    translate('screens.tender.priceTableHeader.unit'),
    translate('screens.tender.priceTableHeader.quantity'),
    translate('screens.tender.priceTableHeader.price'),
    translate('screens.tender.priceTableHeader.total')
  ]

  const [totalTableTitles, setTotalTableTitles] = useState([
    translate('screens.tender.totalTableTitles.TOTAL_HT'),
    translate('screens.tender.totalTableTitles.TVA'),
    translate('screens.tender.totalTableTitles.TOTAL_TTC')
  ]);

  const [totalTableData, setTotalTableData] = useState([ [0], [0], [0] ]);
  const [reason, setReason] = useState();

  const { colors } = useTheme();

  useEffect(() => {
    (async () => {
      let user      = await AuthUserManager.get();
      let authToken = await TokenManager.get()

      setToken(authToken)
      setCurrentUser(user)
    })();
    getOffer()
  }, [])

  function getOffer(){
    apiClient.get(`/offers/${offerId}`).then( response => {
      setOffer(response.data)

      if (response.data.offerpointstables.length > 0){
        getTenderPointsTable(response.data.offerpointstables)
      }
      if (response.data.offerpricetables.length > 0){
        getTenderPriceTable(response.data.offerpricetables)
        setTVA(response.data.tva)
        calculatePriceTable(response.data)
      }

    }).catch(error => console.log(error));
  }

  function getTenderPointsTable(pointTables){
    let tableArr = TableConverter.pointsToArray(pointTables)
    setPointsTableData(tableArr);
  }

  function getTenderPriceTable(priceTables){
    let tableArr = TableConverter.priceToArray(priceTables)
    setPriceTableData(tableArr);
  }

  function setTVA(tva){
    let updateTVA = [...totalTableTitles];
    updateTVA[1] = `${translate('screens.tender.totalTableTitles.TVA')} ${tva}%`;
    setTotalTableTitles(updateTVA);
  }

  function calculatePriceTable(offerData){

    let updatePriceTotal = [...totalTableData];
    updatePriceTotal[0][0] = formatter.format(offerData.total_ht);
    updatePriceTotal[1][0] = formatter.format(offerData.total_tva);
    updatePriceTotal[2][0] = formatter.format(offerData.price);
    setTotalTableData(updatePriceTotal);
  }

  let formatter = new Intl.NumberFormat(localInfo.local, {
    style: 'currency',
    currency: !!offer.currency ? offer.currency.code.en : 'DZD',
  });

  const selectOffer = () => {
    setIsSending(true)

    apiClient.post(`offers/${offerId}/action`, {
      action: 'selected',
      reason: reason
    }).then((response) => {
      getOffer()
      setShowConfirmModal(false)
      setIsSending(false)
    }).catch((error) => console.log(error.response.data))
  }

  const rejectOffer = () => {
    setIsSending(true)

    apiClient.post(`offers/${offerId}/action`, {
      action: 'failed',
      reason: reason
    }).then((response) => {
      getOffer()
      setShowRejectModal(false)
      setIsSending(false)
    }).catch((error) => console.log(error.response.data))
  }

  const handleReasonText = (text) => {
    setReason(text)
  }

  function goToUserProfile(){
    navigation.navigate('UserProfile', {
      profileId: !!offer.company_id ? offer.company_id : offer.user_id,
      profileType: !!offer.company_id ? 'company' : 'user'
    })
  }

  function saveDownloadHistory(attachmentId){
    return apiClient.post(`downloads`, {attachmentId})
  }

  function getReasonTitle(status){
    if (status.name === 'accepted'){
      return translate('screens.offer.reasonOfAccepting')
    } else if (status.name === 'failed'){
      return translate('screens.offer.reasonOfCancelling')
    } else if (status.name === 'selected'){
      return translate('screens.offer.reasonOfSelecting')
    }
  }

  function getUserName(){
    let userName = !!offer.company_id ? offer.company.name : !!offer.user ? offer.user.full_name : null

    if (userName === "[deleted]"){
      userName = translate('misc.deletedUser')
    }
    return userName
  }

  function isDeletedUser(){
    let userName = !!offer.company_id ? offer.company.name : !!offer.user ? offer.user.full_name : null
    return userName === "[deleted]"
  }

  const MessageButton = () => {

    let canTextOwner = !!offer && !!offer.company_id ? !!offer.company.text_me : !!offer.user.text_me;

    if (!!offer && !offer.is_offer_owner && canTextOwner){
      return (
        <Button
          mr={2} leftIcon={<Icon as={<MaterialCommunityIcons name="chat-processing-outline" />} size={5} />}
          onPress={() => {
            navigation.navigate('Chat', {
              typeId: !!offer.company_id ? offer.company_id : offer.user_id,
              type: !!offer.company_id ? 'companies' : 'users',
              currentSenderId: !!currentUser.company_id ? currentUser.company_id : currentUser.id,
              token: token
            })
          }}
        >
          <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
            {translate('screens.tender.message')}
          </Text>
        </Button>
      )
    }

    return null;
  }

  if (!offer.id){
    return (
      <OfferSkeleton/>
    )
  }

  return (
    <View bg={isDark ? "coolGray.800" : "white"} h="100%">
      <ScrollView mx={4}>
        <View mt={4}>
          <Title title={translate('screens.offer.title')} />
          <SpacerMY space={3}/>
        </View>

        <View flexDirection="row" mt={2} mb={4} >

          <View>
            <Pressable
              onPress={() => {
                if (isDeletedUser()) return;
                goToUserProfile()
              }}
            >
              <Avatar
                mt={2}
                size="xl"
                source={{ uri: !!offer.company_id ? offer.company.avatar : !!offer.user ? offer.user.avatar : null }}
              />
            </Pressable>
          </View>

          <View flex={1} ml={4} pt={2} >
            <Text textAlign="left" fontSize={18} fontWeight={500} >
              {getUserName()}
            </Text>

            {
              !isDeletedUser() ? (
                <View flexDirection="row" mt={3}>
                  <Button
                    mr={2} leftIcon={<Icon as={<Feather name="briefcase" />} size={5} />}
                    onPress={() => goToUserProfile()}
                  >
                    <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
                      {translate('screens.tender.profile')}
                    </Text>
                  </Button>

                  <MessageButton/>

                </View>
              ) : null
            }

          </View>
        </View>

        <Divider thickness="2" />

        {
          (offer.has_reviews && offer.is_selected) && (offer.is_tender_owner || offer.is_offer_owner ) ? (
            <View py={4}>
              <SectionTitle title={translate('screens.offer.reviewOptions')} />

              <HStack
                flexWrap="wrap" mt={3}
                justifyContent={offer.is_tender_manager && offer.status.name !== 'accepted' ? "space-evenly" : "flex-start" }
              >
                <Button
                  borderRadius={8}
                  _text={{
                    fontSize: 20
                  }}
                  onPress={() => navigation.navigate('ReviewSteps', {offerId, authId: currentUser.id})}
                >
                  {translate('screens.offer.reviewSteps')}
                </Button>
                {
                  offer.is_tender_manager && offer.status.name !== 'accepted' ? (
                    <Button
                      onPress={() => setShowRejectModal(true)}
                      borderRadius={8}
                      colorScheme="secondary"
                      _text={{
                        fontSize: 20
                      }}
                    >
                      {translate('screens.offer.cancelSelection')}
                    </Button>
                  ) : null
                }
              </HStack>
            </View>
          ) : null
        }

        <Divider thickness="2" />

        <View>
          <SpacerMY space={2}/>
          <SectionTitle title={translate('screens.offer.summary')} />

          {
            !!offer.points ? (
              <View
                mt={2}
                bg={offer.points > offer.required_points ? 'appPrimary' : 'appSecondary'}
                borderRadius={200} px={2} alignSelf="flex-start"
              >
                <Text textAlign="left" color="white" fontWeight={500} fontSize={18}>
                  {translate('screens.offer.points')} : {offer.points} / {offer.required_points}
                </Text>
              </View>
            ) : null
          }
          <View mt={3}>
            {
              !!offer.price ? (
                <Text textAlign="left" fontWeight={500} fontSize={18}>
                  {translate('screens.offer.bidAmount')} : {offer.price} {offer.currency.code[localInfo.local]}
                </Text>
              ) : null
            }
            {
              !!offer.bid_amount ? (
                <Text textAlign="left" fontWeight={500} fontSize={18}>
                  {translate('screens.offer.bidAmount')} : {offer.bid_amount} {offer.currency.code[localInfo.local]}
                </Text>
              ) : null
            }
            {
              !!offer.delivery_date ? (
                <Text textAlign="left" fontWeight={500} fontSize={18}>
                  {translate('screens.tender.deliveryDate')} : {TimeFormatter.getFormattedDate(offer.delivery_date)}
                </Text>
              ) : null
            }

            <Text textAlign="left" fontWeight={500} fontSize={18}>
              {translate('screens.offer.submissionDate')} : {!!offer.created_at ? TimeFormatter.getFormattedDate(offer.created_at) : null}
            </Text>

            {
              !!offer.reason ? (
                <Text textAlign="left" fontWeight={500} fontSize={18}>
                  {getReasonTitle(offer.status)} : {offer.reason}
                </Text>
              ) : null
            }
          </View>
        </View>

        <SpacerMY space={2}/>

        {
          pointsTableData.length === 0 ? null : <View mt={8}>

            <HStack>
              <SectionTitle title={translate('screens.offer.pointsTableTitle')}/>

              <View
                mt={2} mx={2}
                bg={ offer.points > offer.required_points ? 'appPrimary' : 'appSecondary'}
                borderRadius={200} px={2} alignSelf="flex-start"
              >
                <Text textAlign="left" color="white" fontWeight={500} fontSize={18}>
                  {offer.points} / {offer.required_points}
                </Text>
              </View>
            </HStack>

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={pointsTableHead} style={styles.head} textStyle={styles.headText}/>

                    {pointsTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={cellData}
                            textStyle={styles.text}
                          />
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>
          </View>
        }

        {
          priceTableData.length === 0 ? null : <View mt={8}>
            <SectionTitle title={translate('screens.offer.priceTableTitle')} suffix={offer.currency.code[localInfo.local]}/>

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={priceTableHead} style={styles.head} textStyle={styles.headText}/>

                    {priceTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={cellData}
                            textStyle={styles.text}
                          />
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>

            <View my={4}>
              <SectionTitle title={translate('misc.total')} suffix={offer.currency.code[localInfo.local]}/>
            </View>

            <ScrollView horizontal={true}>
              <View style={styles.container}>
                <Table borderStyle={{ borderWidth: 1 }}>
                  <TableWrapper style={styles.row}>
                    <Col
                      style={styles.title}
                      textStyle={styles.text}
                      data={totalTableTitles}
                    />
                    <Rows data={totalTableData} widthArr={[170]} textStyle={styles.text} />
                  </TableWrapper>
                </Table>
              </View>
            </ScrollView>

          </View>
        }

        <View mt={5} />

        {
          offer.offer_required_docs.length > 0 ? (
            <View mt={4} mb={6}>
              <SectionTitle title={translate('screens.tender.documents')} />
              <View height={1} backgroundColor="appPrimary" w={60}/>

              {
                offer.offer_required_docs.map(doc => (
                  <>
                    <View mt={4} />
                    <Text fontWeight={600} fontSize={18}>
                      {doc.required_doc_name} :
                    </Text>
                    {
                      doc.attachments.map((file, index) => (
                        <View key={index} mt={2}>
                          <TouchableOpacity
                            onPress={async () => {
                              await saveDownloadHistory(file.id)
                              await WebBrowser.openBrowserAsync(encodeURI(file.path));
                            }}
                          >
                            <HStack alignItems="center">
                              <Icon as={MaterialCommunityIcons} name="folder-download-outline" size="md" color="appPrimary" />
                              <View mx={1}/>
                              <Text textAlign="left" fontSize={16} color="appPrimary">
                                {`${file.name} - ${formatBytes(file.size)}`}
                              </Text>
                            </HStack>
                          </TouchableOpacity>
                        </View>
                      ))
                    }
                  </>
                ))
              }
            </View>
          ) : null
        }

        <View mt={50} />
        {
         offer.can_select && offer.is_tender_manager ? (
           <View>
             <Button disabled={!!offer.is_selected} borderRadius={10} onPress={() => setShowConfirmModal(true)}>
               <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                 {translate('screens.offer.selectOffer')}
               </Text>
             </Button>
           </View>
         ) : null
        }
        <View mb={20} />

        {/* Confirm Selecting Offer Modal */}
        <Center>
          <Modal avoidKeyboard size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.offer.selectThisOffer')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={18} textAlign="center">
                    {translate('screens.offer.whyChooseOfferText')}
                  </Text>

                  <FormControl mt={3}>
                    <TextArea
                      textAlign="left"
                      placeholder={translate('misc.reason')}
                      h={150} rounded={10} size="xl" borderColor="gray.500"
                      onChangeText={handleReasonText}
                    />
                  </FormControl>

                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={selectOffer}>
                    {
                      isSending ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.select')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Confirm Rejecting Offer Modal */}
        <Center>
          <Modal avoidKeyboard size={["xl", "xl", "md"]} isOpen={showRejectModal} onClose={() => setShowRejectModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>{translate('screens.offer.rejectThisOffer')}</Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={18} textAlign="center">
                    {translate('screens.offer.whyRejectOfferText')}
                  </Text>

                  <FormControl mt={3}>
                    <TextArea
                      textAlign="left"
                      placeholder={translate('misc.reason')}
                      h={150} rounded={10} size="xl" borderColor="gray.500"
                      onChangeText={handleReasonText}
                    />
                  </FormControl>

                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowRejectModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={rejectOffer}>
                    {
                      isSending ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.reject')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  wrapper: { flexDirection: 'row' },
  title: { flex: 1, backgroundColor: '#f6f8fa', width: 200 },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center'
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
