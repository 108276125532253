import React, {useEffect, useRef, useState} from 'react';

import {
  View, Text, Center, ScrollView, useColorModeValue, Spinner, useTheme,
} from "native-base";
import apiClient from "../../utils/apiClient";
import TenderCard from "../../components/TenderCard";
import {localInfo, translate} from "../../utils/Translate";
import {AuthContext} from "../../utils/context/auth";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize={32} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="primary.50" w={60}/>
  </View>
}

export default ({ navigation, userId }) => {

  const { isCompany } = React.useContext(AuthContext);

  const [savedTenders, setSavedTenders] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(false)

  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  const { colors } = useTheme();

  const getSavedTenders = (isLoadMore = false) => {

    let nextP = nextPage.current.length ? `?${nextPage.current}` : '';

    apiClient.get(`users/${userId}/favorites${nextP}`).then((response) => {

      if (isLoadMore && nextPage.current.length){
        getNextPage(response.data.next_page_url)
        setSavedTenders(old => [...old, ...response.data.data])

        setIsLoadingData(false)
        isLoadingMoreRef.current = false
        return;
      }

      getNextPage(response.data.next_page_url)
      setSavedTenders(response.data.data)

      setIsLoadingData(false)
      isLoadingMoreRef.current = false
    }).catch(error => console.log(error.response.data));
  }

  function loadMore(){
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    setIsLoadingData(true)
    isLoadingMoreRef.current = true

    getSavedTenders(true)
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 50;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  useEffect(() => {
    getSavedTenders()

    navigation.addListener('focus', () => {
      getSavedTenders()
    });
  },[navigation])

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            loadMore()
          }
        }}
        scrollEventThrottle={400}
      >

        <View bg={isCompany ? colors.appPrimary : colors.particular} borderBottomRightRadius={600}>
          <View m={4}>
            <View flexDirection="row">
              <Title title={translate('drawer.screens.savedTenders.title')} />
            </View>
            <View flexDirection="row">
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} my={2} color="primary.50" fontSize={16}>
                {translate('drawer.screens.savedTenders.description')}
              </Text>
            </View>
          </View>
        </View>

        <View m={4}>

          {
            savedTenders.length ? (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {savedTenders.map((tender, index) => {
                  return (
                    <TenderCard
                      key={index}
                      id={tender.id}
                      postTime={tender.created_at}
                      location={tender.state.name[localInfo.local]}
                      title={tender.title}
                      branch={tender.branch.name[localInfo.local]}
                      description={tender.description}
                      date={tender.closing_date}
                      offers={tender.offers_count}
                      tenderableType={tender.tenderable_type}
                      avatar={tender.tenderable.avatar}
                      isSaved={tender.has_favorited}
                    />
                  );
                })}
              </View>
            ) : (
              <Center
                bg={useColorModeValue(colors.appWhite["200"], "coolGray.700")}
                py={4} mt={10} borderRadius={8}
              >
                <Text textAlign="center" fontSize={20}>
                  {translate('drawer.screens.savedTenders.noTenders')}
                </Text>
              </Center>
            )
          }

        </View>

        {
          isLoadingData ? (
            <View mt={10}>
              <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
            </View>
          ) : null
        }

        <View mb={20}/>

      </ScrollView>
    </View>
  );
}
