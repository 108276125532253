import React, {useEffect, useState} from 'react';
import {MaterialIcons, MaterialCommunityIcons, Feather, FontAwesome} from "@expo/vector-icons";

import Map from "../../../components/Map"
import GroupModel from "../../../models/GroupModel";
import apiClient from "../../../utils/apiClient";
import TableConverter from "../../../utils/TableConverter";

import { Table, TableWrapper, Row, Rows, Cell, Col } from 'react-native-table-component-2';
import {
  View, Text, Icon, Avatar, Center, ScrollView, Pressable, Divider,
  Input, Button, Modal, FormControl, HStack, useColorMode, Spinner, useTheme
} from "native-base";
import {Platform, Share, StyleSheet, TouchableOpacity} from "react-native";
import {AuthContext} from "../../../utils/context/auth";
import AuthUserManager from "../../../utils/AuthUserManager";
import TokenManager from "../../../utils/TokenManager";
import {localInfo, translate} from "../../../utils/Translate";
import TenderSkeleton from "../../../components/skeleton/TenderSkeleton";
import TimeFormatter from "../../../utils/TimeFormatter";
import * as SplashScreen from "expo-splash-screen";
import {UNIVERSAL_LINK_PREFIX} from "../../../environment";
import AlertModal from "../../../components/AlertModal";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}

const SectionTitle = ({ title, suffix }) => {
  return <View>
    <HStack alignContent="center">
      <Text textAlign="left" fontSize={28} fontWeight={500}>
        {title}
      </Text>
      {
        !!suffix ? <Text textAlign="left" ml={2} mt={2} fontSize={18} fontWeight={400}>
          ({suffix})
        </Text> : null
      }
    </HStack>
    <View height={1} backgroundColor="appPrimary" w={60}/>

  </View>
}

const widthArr = [150, 150, 150]
const isWebPlatform = Platform.OS === 'web';

export default ({ route, navigation }) => {

  const { groupId, previewData } = route.params;

  const { authUser, isCompany, hasPermissionTo } = React.useContext(AuthContext);

  const {colorMode} = useColorMode();
  const { colors }  = useTheme();

  const isDark = colorMode === 'dark';


  // Share button
  const onShare = async () => {
    try {
      const result = await Share.share({
        message: `${UNIVERSAL_LINK_PREFIX}GroupBuying/Group/${groupId}`
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (!isWebPlatform && !!!previewData){
      navigation.setOptions({
        headerRight: () => (
          <Button
            rounded={40} py={1} px={2} leftIcon={<Icon as={<FontAwesome name="share-square-o" />} size={5} />}
            onPress={onShare}
          >
            <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
              {translate('screens.tender.share')}
            </Text>
          </Button>
        ),
      });
    }

  }, [navigation]);


  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmOfferModal, setShowConfirmOfferModal] = useState(false);
  const [isOffering, setIsOffering] = useState(false);
  const [group, setGroup] = useState(GroupModel[0]);
  const [pointsTableData, setPointsTableData] = useState([]);
  const [itemsTableData, setItemsTableData] = useState([]);
  const [totalTableData, setTotalTableData] = useState([ [0], [0], [0] ]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [groupCreatedModal, setGroupCreatedModal] = useState(false);
  const [showCloseGroupModal, setShowCloseGroupModal] = useState(false);
  const [createTenderNoteModal, setCreateTenderNoteModal] = useState(false);
  const [noPermissionModal, setNoPermissionModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const canAccessGroupBuying = React.useRef(null)

  const pointsTableHead = [
    translate('screens.tender.pointsTableHeader.item'),
    translate('screens.tender.pointsTableHeader.requirement'),
    translate('screens.tender.pointsTableHeader.points'),
    translate('screens.tender.pointsTableHeader.offer'),
    translate('screens.tender.pointsTableHeader.total')
  ]

  const itemsTableHead = [
    translate('screens.tender.priceTableHeader.item'),
    translate('screens.tender.priceTableHeader.unit'),
    translate('screens.tender.priceTableHeader.quantity')
  ]

  const [totalTableTitles, setTotalTableTitles] = useState([
    translate('screens.tender.totalTableTitles.TOTAL_HT'),
    translate('screens.tender.totalTableTitles.TVA'),
    translate('screens.tender.totalTableTitles.TOTAL_TTC')
  ]);

  // Offer state
  const [date, setDate] = useState();
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [hasReviewSteps, setHasReviewSteps] = React.useState(false);
  const [offerData, setOfferData] = useState({
    offer: {
      bid_amount : null,
      delivery_date : null,
      reason : null
    },
    offerItemsTable: []
  });

  useEffect(() => {
    (async () => {
      await SplashScreen.hideAsync();

      let auth      = await AuthUserManager.get()
      let authToken = await TokenManager.get()

      canAccessGroupBuying.current = await hasPermissionTo('manage group buying')

      setCurrentUser(auth)
      setToken(authToken)
    })();

    let canOffer = !previewData || !group.is_owner || !group.has_participate;
    setIsOffering(canOffer)

    getGroup()

    navigation.addListener('focus', () => {
      getGroup()
    });
  }, []);

  function getGroup(){

    if (!!previewData) {
      let preview = {...previewData};

      let previewGroup = {...preview.group}
      delete preview.group;
      let previewGroupData = {...previewGroup, ...preview}

      setGroup(previewGroupData)
      setHasReviewSteps(!!previewData.reviewSteps.length)

      // Set preview table
      if (preview.itemsTablePreview.length > 0){
        let itemsTablePreview = preview.itemsTablePreview.filter((item, index) => index !== preview.itemsTablePreview.length -1 )
        setItemsTableData(itemsTablePreview);
      }

      return;
    }

    apiClient.get(`groups/${groupId}`).then( response => {
      setGroup(response.data)

      if (response.data.itemtables.length > 0){
        getGroupItemsTable(response.data.itemtables)
      }
    }).catch(error => console.log(error));
  }

  function getGroupPointsTable(pointTables){
    let tableArr = TableConverter.pointsToArray(pointTables)
    setPointsTableData(tableArr);
  }

  function getGroupItemsTable(itemsTable){
    let tableArr = TableConverter.itemsToArray(itemsTable)
    setItemsTableData(tableArr);
  }

  const handlePublishGroup = () => {

    setIsPublishing(true)

    const data = getGroupData(previewData);
    apiClient.post('groups', data, {isFormData: true}).then(() => {
      setShowConfirmModal(false);
      setIsPublishing(false)
      setGroupCreatedModal(true)
    }).catch((error) => console.log(error.response.data))
  }

  function getGroupData(data){
    const groupData = new FormData();
    groupData.append('group', JSON.stringify(data.group))

    if (data.pointTables.length > 0){
      groupData.append('pointTables', JSON.stringify(data.pointTables))
    }
    if (data.itemsTable.length > 0){
      groupData.append('itemsTable', JSON.stringify(data.itemsTable))
    }
    if (data.reviewSteps.length > 0){
      groupData.append('reviewSteps', JSON.stringify(data.reviewSteps))
    }
    if (data.requiredDocuments.length > 0){
      groupData.append('requiredDocuments', JSON.stringify(data.requiredDocuments))
    }
    if (data.attachments.length > 0 && Platform.OS !== "web"){
      for (let n = 0; n < data.attachments.length; n++){
        groupData.append('files[]', data.attachments[n])
      }
    }
    if (Platform.OS === "web" && data.webAttachments.length > 0){
      for (let n = 0; n < data.webAttachments.length; n++){
        groupData.append('files[]', data.webAttachments[n])
      }
    }

    return groupData;
  }

  const handleSubmitOffer = () => {
    if (group.bid_initial_price !== null){
      setShowConfirmOfferModal(true)
      return;
    }
    if (pointsTableData.length === 0){
      setShowConfirmOfferModal(true)
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: group.groupbuyingable.avatar,
      groupId,
      isPaidGroup: false,
      price: group.participation_cost,
      offerData: {
        ...offerData,
        offerPointsTables: group.pointtables,
        offerItemsTable: group.itemtables,
      }
    })
  }

  const submitOffer = () => {
    // If this is a demo account return alert message
    if (currentUser.id === 10193){
      setShowAlertModal(true);
      return;
    }

    setIsPublishing(true)

    const data = new FormData()

    data.append('data', JSON.stringify({
      ...offerData,
      offerItemsTable: group.itemtables,
      isPaid: 0
    }))

    apiClient.post(`groups/${groupId}/offers`, data, {isFormData: true}).then(() => {
      setShowConfirmOfferModal(false);
      setIsPublishing(false)

      navigation.navigate('OfferSent')
    }).catch((error) => console.log(error.response.data))
  }

  const handleCloseGroup = () => {
    // If this is a demo account return alert message
    if (currentUser.id === 10193){
      setShowAlertModal(true);
      return;
    }
    setIsPublishing(true)

    apiClient.post(`groups/${groupId}/action`, {
      action: 'closed',
    }).then((response) => {
      getGroup()
      setShowCloseGroupModal(false)
      setIsPublishing(false)
    }).catch((error) => console.log(error))
  }

  function saveDownloadHistory(attachmentId){
    return apiClient.post(`downloads`, {attachmentId})
  }

  const handleBid = (value) => {
    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        bid_amount: value.replace(regex, '')
      }
    })
  }

  const onDismissSingle = () => {
    setOpenDatePicker(false);
  };

  const onConfirmSingle = (params) => {
    setOpenDatePicker(false);
    setDate(params.date);

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        delivery_date: getFormattedDate(params.date)
      }
    })
  }

  function getFormattedDate(date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  const OfferInput = (index, type) => (
    <View>
      <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} keyboardType="numeric" onChangeText={(value) => {
        let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

        let offerValue = value.replace(regex, '');

        if (type === 'point'){
          group.pointtables[index].offer = offerValue;
          group.pointtables[index].total = offerValue * group.pointtables[index].points;

          let updatePointsTableData = [...pointsTableData];
          updatePointsTableData[index][4] = group.pointtables[index].total;
          setPointsTableData(updatePointsTableData);
        }
        if (type === 'price'){
          group.itemtables[index].price = offerValue;
          group.itemtables[index].total = offerValue * group.itemtables[index].quantity;

          let updateItemsTableData = [...itemsTableData];
          updateItemsTableData[index][4] = group.itemtables[index].total;
          setItemsTableData(updateItemsTableData);
        }
      }} textAlign='center' size="2xl" placeholder="- -"/>
    </View>
  );

  function isParticularGroup(){
    return group.groupbuyingable_type === 'App\\Models\\User'
  }

  const handleSubmitPaidOffer = () => {

    if (pointsTableData.length === 0){
      navigation.navigate("PaymentMethods", {
        price: group.participation_cost,
        groupId: groupId,
        offerData: {
          ...offerData,
          offerPointsTables: group.pointtables,
          offerItemsTable: group.itemtables,
        }
      })
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: group.groupbuyingable.avatar,
      groupId,
      isPaidGroup: true,
      price: group.participation_cost,
      offerData: {
        ...offerData,
        offerItemsTable: group.itemtables,
      }
    })
  }

  function goToUserProfile(){
    if (!!previewData) return;
    navigation.navigate('UserProfile', {
      profileId: group.groupbuyingable_id,
      profileType: group.groupbuyingable_type === "App\\Models\\Company" ? 'company' : 'user'
    })
  }

  if (!group.title){
    return (
      <TenderSkeleton/>
    )
  }

  function getGroupableName(){
    return group.groupbuyingable_type === "App\\Models\\Company"
      ? group.groupbuyingable.name : group.groupbuyingable.full_name
  }

  return (
    <View
      bg={isDark ? "coolGray.800" : "white"}
      px={3} h="100%"
    >
      {
        !previewData ? null : <View bg="appSecondary" py={1} px={10}>
          <Center>
            <View flexDirection="row">
              <Text ml={4} textAlign="center" fontWeight={500} fontSize={18} color="primary.50">
                {translate('drawer.groupBuying.previewText')}
              </Text>
              <Center ml={6}>
                <Icon color="primary.50" as={<Feather name="eye" />} size={7} />
              </Center>
            </View>
          </Center>
        </View>
      }

      <ScrollView>

        <View flexDirection="row" mt={7}>

          <View>
            <Avatar
              mt={2}
              size="xl"
              source={{ uri : group.groupbuyingable.avatar }}
            />
          </View>

          <View flex={1} ml={4} pt={2} >

            <Text p={2} textAlign="left" fontSize={18} fontWeight={500} >
              {
                getGroupableName() !== "[deleted]" ? getGroupableName() : translate('misc.deletedUser')
              } {' '}
              <Icon
                p={2}
                as={MaterialIcons}
                size="4"
                name="check-circle"
                color="appPrimary"
              />
            </Text>

            {
              getGroupableName() !== '[deleted]' ? (
                <View flexDirection="row" mt={3}>

                  {
                    !group.is_owner && !!group.groupbuyingable.text_me ? (
                      <Button
                        mr={2}
                        leftIcon={<Icon as={<MaterialCommunityIcons name="chat-processing-outline" />} size={5} />}
                        onPress={() => {
                          navigation.navigate('Chat', {
                            type: group.groupbuyingable_type === "App\\Models\\Company" ? 'companies' : 'users',
                            typeId: group.groupbuyingable_id,
                            currentSenderId: !!currentUser.company_id ? currentUser.company_id : currentUser.id,
                            token: token
                          })
                        }}
                      >
                        <Text textAlign="left" color="primary.50" fontSize={15} fontWeight={500}>
                          {translate('screens.tender.message')}
                        </Text>
                      </Button>
                    ) : null
                  }

                </View>
              ) : null
            }

          </View>
        </View>

        <View my={2}/>
        <Divider thickness={1}/>
        <View my={2}/>

        <Center
          flexDirection="row" mt={3}
          justifyContent={isParticularGroup() || isWebPlatform || !hasReviewSteps ? "flex-start" : "space-evenly"}
        >
          <Button
            mr={2} leftIcon={<Icon as={<MaterialIcons name="email" />} size={4} />}
            onPress={() => {
              if (!!previewData) return;
              navigation.navigate('ReceivedRequests', {groupId})
            }}
          >
            <Text textAlign="left" color="primary.50" fontSize={17} fontWeight={600}>
              {translate('drawer.groupBuying.requests')}
            </Text>
          </Button>

          {
            group.is_owner ? (
              <Button
                mr={2} leftIcon={<Icon as={<MaterialCommunityIcons name="file-document" />} size={4} />}
                onPress={() => {
                  if (!!previewData) return;

                  if (!group.is_closed) {
                    setCreateTenderNoteModal(true)
                  }
                  else if (group.is_closed) {
                    navigation.navigate('GroupTenderInfo', {priceTables:group.itemtables,
                      priceTablePreview: itemsTableData, branchId: group.branch.id})
                  }
                }}
              >
                <Text textAlign="left" color="primary.50" fontSize={17} fontWeight={600}>
                  {translate('drawer.groupBuying.createTender')}
                </Text>
              </Button>
            ) : null
          }

        </Center>
        {
          !!group.participation_cost === false ? null : <View
            py={3} mt={6} borderColor="appSecondary"
            borderWidth={1} borderRadius={10}
          >
            <Center flexDirection="row" justifyContent="space-evenly">
              <Icon mr={2} color="appSecondary" as={<MaterialIcons name="payment"/>} size={8}/>
              <Text textAlign="left" fontSize={18} fontWeight={500} color="appSecondary">
                {translate('screens.tender.participationFees')} : {group.participation_cost} {translate('misc.currency')}
              </Text>
            </Center>
          </View>
        }

        <View mt={10}>
          <Title
            title={group.title} />
        </View>

        <View mt={10} flexDirection="row" justifyContent="space-between">
          <SectionTitle title={translate('screens.tender.description')} />

          <Center h={9} px={2} flexDirection="row" justifyContent="space-between" bg="appAccent" borderRadius={8}>
            <Icon color="primary.50" as={<Feather name="eye" />} size={5} />
            <Text textAlign="left" fontWeight={500} color="primary.50" mx={1}>
              {!!group.views_count ? group.views_count : 0}
            </Text>
            <Text textAlign="left" fontWeight={500} color="primary.50">
              {translate('screens.tender.views')}
            </Text>
          </Center>
        </View>

        <HStack mt={4}>

          <View maxW="60%" mt={1} px={2} py={1} bg="appAccent" borderRadius={20} alignSelf="flex-start">
            <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
              {group.branch.name[localInfo.local]}
            </Text>
          </View>

          <HStack w="40%" alignItems="center" justifyContent="center">
            <Icon w="18%" as={<MaterialIcons name="location-pin"/>} size={6} ml="2"/>
            <Text w="82%" textAlign="left" fontWeight={500} fontSize={18}>
              {group.state.name[localInfo.local]}
            </Text>
          </HStack>

        </HStack>

        {
          group.latitude === null ? null : <View mt={6}>
            <Map
              isPreview={true}
              onPress={() => null}
              tenderCoordinate={{
                latitude: group.latitude,
                longitude: group.longitude
              }}
            />
          </View>
        }

        <Text textAlign="left" mt={4} fontSize={20}>
          {group.description}
        </Text>

        <View flexDirection="row" my={2} mt={8}>
          {
            !!group.closing_date ? (
              <View px={2} bg="appSecondary" borderRadius={20} alignSelf="flex-start">
                <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">
                  {translate('screens.tender.closingDate')} : {TimeFormatter.getFormattedDate(group.closing_date)}
                </Text>
              </View>
            ) : null
          }
          {/*<View ml={2} px={2} bg="appPrimary" borderRadius={20} alignSelf="flex-start">*/}
          {/*  <Text textAlign="left" fontSize={16} fontWeight={500} color="primary.50">*/}
          {/*    {translate('screens.tender.offers')} { !!group.offers_count ? group.offers_count : 0 }*/}
          {/*  </Text>*/}
          {/*</View>*/}
        </View>

        {
          itemsTableData.length === 0 ? null : <View mt={8}>
            <SectionTitle title={translate('drawer.groupBuying.itemsTable.groupItemsTable')} />

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={itemsTableHead} style={styles.head} textStyle={styles.headText}/>

                    {itemsTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={cellData}
                            textStyle={styles.text}
                          />
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>

            {/*<View my={4}>*/}
            {/*  <SectionTitle title={translate('misc.total')} suffix={group.currency.code[localInfo.local]}/>*/}
            {/*</View>*/}

            {/*<ScrollView horizontal={true}>*/}
            {/*  <View style={styles.container}>*/}
            {/*    <Table borderStyle={{ borderWidth: 1 }}>*/}
            {/*      <TableWrapper style={styles.row}>*/}
            {/*        <Col*/}
            {/*          style={styles.title}*/}
            {/*          textStyle={styles.text}*/}
            {/*          data={totalTableTitles}*/}
            {/*        />*/}
            {/*        <Rows data={totalTableData} widthArr={[170]} textStyle={styles.text} />*/}
            {/*      </TableWrapper>*/}
            {/*    </Table>*/}
            {/*  </View>*/}
            {/*</ScrollView>*/}

          </View>
        }

        {
          !!previewData ? (
            <>
              <View mt={12}/>
              <Divider thickness="2" />
              <View mt={12}/>
              <View
                flexDirection="row"
                justifyContent={"space-evenly"}
              >

                <Button
                  w={'70%'}
                  shadow={3}
                  borderRadius={10}
                  onPress={() => {
                    // If this is a demo account return alert message
                    if (currentUser.id === 10193){
                      setShowAlertModal(true);
                      return;
                    }
                    setShowConfirmModal(true)
                  }}
                >
                  <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                    {translate('misc.publish')}
                  </Text>
                </Button>

              </View>
            </>
          ) : null
        }

        <View mt={16} />

        {
          !!previewData || group.has_participate
          || (group.is_closed && !group.is_owner) ? null :
            <View mt={5}>
              <Button
                borderRadius={10}
                onPress={() => {
                  if (canAccessGroupBuying.current === false){
                    setNoPermissionModal(true);
                    return;
                  }
                  navigation.navigate('GroupApply', {groupData:group, previewData})
                }}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('drawer.groupBuying.apply.join')}
                </Text>
              </Button>
            </View>
        }
        {
          (!!group.is_the_closing_date && group.is_the_closing_date) || group.is_closed ? (
            <View mt={5}>
              <Button
                borderRadius={10}
                isDisabled={true}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('drawer.groupBuying.closed')}
                </Text>
              </Button>
            </View>
          ) : null
        }

        {
          !!previewData || !group.is_owner || (group.is_owner && group.is_closed) ? null : (
            <View mt={5}>
              <Button
                colorScheme="accent"
                borderRadius={10}
                onPress={() => setShowCloseGroupModal(true)}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('drawer.groupBuying.closeThisGroup')}
                </Text>
              </Button>
            </View>
          )
        }

        {/* Confirm Publish Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.publishModal.headerText')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text textAlign="left" fontWeight={600} fontSize={26}>
                    {translate('drawer.groupBuying.publishModal.areYouSure')}
                  </Text>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.publishModal.note')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={handlePublishGroup}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.publish')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Confirm offer Modal */}
        <Center>
          <Modal isOpen={showConfirmOfferModal} onClose={() => setShowConfirmOfferModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.tender.offerModal.headerText')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text textAlign="left" fontWeight={600} fontSize={26}>
                    {translate('screens.tender.publishModal.areYouSure')}
                  </Text>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('screens.tender.offerModal.note')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmOfferModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={submitOffer}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.submit')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Group Created Modal */}
        <Center>
          <Modal
            isOpen={groupCreatedModal}
            closeOnOverlayClick={false}
            onClose={() => setGroupCreatedModal(false)}
          >
            <Modal.Content>
              <Modal.Header>
                {translate('drawer.groupBuying.created')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.createdMsg')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button onPress={() => {
                    navigation.navigate("Groups")
                  }}
                  >
                    {translate('misc.Ok')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Close Group Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showCloseGroupModal} onClose={() => setShowCloseGroupModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.closeThisGroup')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.closeMsg')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowCloseGroupModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={handleCloseGroup}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('drawer.groupBuying.close')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Create Tender Note Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={createTenderNoteModal} onClose={() => setCreateTenderNoteModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.note')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.createTenderNote')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button onPress={() => setCreateTenderNoteModal(false)}>
                    {translate('misc.Ok')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* No Permission Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={noPermissionModal} onClose={() => setNoPermissionModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.note')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('screens.publishTender.noPermission')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button onPress={() => setNoPermissionModal(false)}>
                    {translate('misc.Ok')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        <View mt={48} />

        <AlertModal alertText={translate('demoAlert.message')} isOpen={showAlertModal} onClose={() => setShowAlertModal(false)}/>

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  wrapper: { flexDirection: 'row' },
  title: { flex: 1, backgroundColor: '#f6f8fa', width: 200 },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center'
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
