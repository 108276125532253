import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import {Platform} from "react-native";

const isWebPlatform = Platform.OS === 'web';

class StorageManager {

    static async storeString(key, value){
        try {
            if (!isWebPlatform){
                await SecureStore.setItemAsync(key, value);
                return;
            }
            await AsyncStorage.setItem(key, value)
        } catch (e) {
            console.log(e)
        }
    }

    static async storeObject(key, value){
        try {
            const jsonValue = JSON.stringify(value)

            if (!isWebPlatform){
                await SecureStore.setItemAsync(key, jsonValue);
                return;
            }

            await AsyncStorage.setItem(key, jsonValue)
        } catch (e) {
            console.log(e)
        }
    }

    static async getString(key){
        try {
            if (!isWebPlatform){
                return await SecureStore.getItemAsync(key);
            }

            return await AsyncStorage.getItem(key)
        } catch(e) {
            console.log(e)
        }
    }

    static async getObject(key){
        try {
            if (!isWebPlatform){
                const data = await SecureStore.getItemAsync(key);
                return data != null ? JSON.parse(data) : null;
            }
            const jsonValue = await AsyncStorage.getItem(key)
            return jsonValue != null ? JSON.parse(jsonValue) : null;
        } catch(e) {
            console.log(e)
        }
    }

    static async remove(key){
        try {
            if (!isWebPlatform){
                await SecureStore.deleteItemAsync(key);
                return;
            }
            await AsyncStorage.removeItem(key)
        } catch(e) {
            console.log(e)
        }
    }

}

export default StorageManager;
