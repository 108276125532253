export default {
    // Add new color
  // 3752FF new
  // #4655F7 new 2
  // 2F7E78 old primary
  black:{
    50: '#f2f2f2',
    100: '#d9d9d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0d0d0d',
  },
  primary: {
    // 50: '#fff',
    // 70: '#edeefe',
    // 100: '#daddfd',
    // 200: '#c8ccfd',
    // 300: '#b5bbfc',
    // 400: '#a3aafb',
    // 500: '#9099fa',
    // 600: '#7e88f9',
    // 700: '#6b77f9',
    // 800: '#5966f8',
    // 900: '#4655f7',
    50: '#fff',
    70: '#edeefe',
    100: '#c8ccfd',
    200: '#a3aafb',
    300: '#9099fa',
    400: '#6b77f9',
    500: '#4655F7', // main
    600: '#3f4dde',
    700: '#3844c6',
    800: '#313bad',
    900: '#2a3394',
  },
  secondary: {
    50: '#B01116',
    100: '#B01116',
    200: '#B01116',
    300: '#B01116',
    400: '#B01116',
    500: '#B01116',
    600: '#B01116',
    700: '#B01116',
    800: '#B01116',
    900: '#B01116',
  },
  accent: {
    50: '#0d0d0d',
    100: '#0d0d0d',
    200: '#0d0d0d',
    300: '#0d0d0d',
    400: '#0d0d0d',
    500: '#0d0d0d',
    600: '#0d0d0d',
    700: '#2E475D',
    800: '#374151',
    900: '#0d0d0d',
  },
  appWhite: {
    50: '#fff',
    100: '#f5f5f5',
    200: '#f8f8f8',
    300: '#fff',
    400: '#fff',
    500: '#fff',
    600: '#fff',
    700: '#486a87',
    800: '#fff',
    900: '#fff',
  },
  appPrimary: '#4655F7',
  particular: '#4655F7',
  appPrimaryDark: '#4ad0c6',
  appSecondary: '#B01116',
  appAccent: '#0d0d0d',
  appAccentDark: '#2E475D',
  appBackground: '#fff',
  // Redefinig only one shade, rest of the color will remain same.
  amber: {
    400: '#d97706',
  },
}

const Outfit = {
  100 : "Outfit_100Thin",
  200 : "Outfit_200ExtraLight",
  300 : "Outfit_300Light",
  400 : "Outfit_400Regular",
  500 : "Outfit_500Medium",
  600 : "Outfit_600SemiBold",
  700 : "Outfit_700Bold",
  800 : "Outfit_800ExtraBold",
  900 : "Outfit_900Black",
}
