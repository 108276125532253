import React, {useEffect, useState} from 'react';
import {Feather} from "@expo/vector-icons";

import GroupModel from "../../../models/GroupModel";
import apiClient from "../../../utils/apiClient";
import TableConverter from "../../../utils/TableConverter";

import { Table, TableWrapper, Row, Cell } from 'react-native-table-component-2';
import {
  View, Text, Icon, Center, ScrollView,
  Input, Button, Modal, FormControl, HStack, useColorMode, Spinner, Box, Avatar, useTheme
} from "native-base";
import {Platform, StyleSheet} from "react-native";
import {localInfo, translate} from "../../../utils/Translate";
import TenderSkeleton from "../../../components/skeleton/TenderSkeleton";
import * as DocumentPicker from "expo-document-picker";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}

const SectionTitle = ({ title, suffix }) => {
  return <View>
    <HStack alignContent="center">
      <Text textAlign="left" fontSize={28} fontWeight={500}>
        {title}
      </Text>
      {
        !!suffix ? <Text textAlign="left" ml={2} mt={2} fontSize={18} fontWeight={400}>
          ({suffix})
        </Text> : null
      }
    </HStack>
    <View height={1} backgroundColor="appPrimary" w={60}/>

  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const RequestSum = ({ item, unit, quantity, key }) => {
  return <HStack key={key} px={4} py={2} justifyContent="space-between">
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={18}>
      {item}
    </Text>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={18}>
      {`${quantity}  ${unit}`}
    </Text>
  </HStack>
}

const widthArr = [150, 150, 150]

export default ({ route, navigation }) => {

  const { joinRequest, previewData, groupbuyingId } = route.params;

  const {colorMode} = useColorMode();
  const { colors }  = useTheme();

  const isDark = colorMode === 'dark';

  const [showConfirmOfferModal, setShowConfirmOfferModal] = useState(false);
  const [group, setGroup] = useState(GroupModel[0]);
  const [itemsTableData, setItemsTableData] = useState([]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [groupRequiredDocs, setGroupRequiredDocs] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [requestSentModal, setRequestSentModal] = useState(false);

  const [inputErrors, setInputErrors] = React.useState({});
  const pointsTableIsValid = React.useRef({});

  const itemsTableHead = [
    translate('screens.tender.priceTableHeader.item'),
    translate('screens.tender.priceTableHeader.unit'),
    translate('screens.tender.priceTableHeader.quantity')
  ]


  useEffect(() => {

    getGroup()
  }, []);

  const validate = () => {
    // Validate offer max points input
    for (const index in pointsTableIsValid.current) {
      if (!pointsTableIsValid.current[index]){
        return false
      }
    }
    // Validate offer required documents
    for (const [index, doc] of groupRequiredDocs.entries()){
      if (doc.is_required && !doc.file){
        setInputErrors({ ...inputErrors,
          [`docRequired${index}`]: translate('screens.publishTender.requiredDocuments.requiredDocument')
        });

        return false;
      }
    }

    return true;
  };

  const pickDocument = async (index) => {
    let file = await DocumentPicker.getDocumentAsync({
      multiple: false
    });

    if (!!file.type && file.type === 'cancel'){
      return;
    }

    if (`docRequired${index}` in inputErrors) {
      let errors = {...inputErrors}
      delete errors[`docRequired${index}`]
      setInputErrors(errors);
    }

    if(Platform.OS === 'web'){
      const files = file.output;

      let requiredDocs = [...groupRequiredDocs]

      if (!!requiredDocs[index].file){
        delete requiredDocs[index].file
      }

      requiredDocs[index].file = files[0]
      setGroupRequiredDocs(requiredDocs)

      return;
    }

    let requiredDocs = [...groupRequiredDocs]

    if (!!requiredDocs[index].file){
      delete requiredDocs[index].file
    }

    requiredDocs[index].file = {
      uri: file.assets[0].uri,
      type: file.assets[0].mimeType,
      name: file.assets[0].name,
      size: file.assets[0].size
    }
    setGroupRequiredDocs(requiredDocs)
  };

  function getGroup(){

    apiClient.get(`groups/${groupbuyingId}`).then( response => {

      const gData = mergeItemsData2(response.data)
      setGroup(gData)

      getGroupItemsTable(response.data.itemtables)
      requestMaker(gData)
    }).catch(error => console.log(error));
  }

  function mergeItemsData(groupData){

    for (let joinRequestItem of joinRequest.grouprequestitemtables) {
      let matchingItem = groupData.itemtables.find(i => i.item === joinRequestItem.item);

      if (matchingItem) {
        // Update the quantity in groupData with the quantity from joinRequest
        matchingItem.quantity = joinRequestItem.quantity;
      } else {
        // If no matching item is found, add it to groupData
        groupData.itemtables.push(joinRequestItem);
      }
    }
    return groupData;
  }

  function mergeItemsData2(groupData){

    const requestItemsMap = new Map();

    joinRequest.grouprequestitemtables.forEach((item) => {
      requestItemsMap.set(item.item, item);
    });

    // Update quantities in groupItems
    groupData.itemtables.forEach((item) => {
      if (requestItemsMap.has(item.item)) {
        item.quantity = requestItemsMap.get(item.item).quantity;
      } else {
        item.quantity = "0";
      }
    });

    return groupData;
  }

  function getGroupItemsTable(priceTables){
    let tableArr = TableConverter.itemsToArray(priceTables)
    setItemsTableData(tableArr);
  }

  function requestMaker(userRequestData = null){

    const data = !!userRequestData ? userRequestData.itemtables : group.itemtables

    const itemsWithNonEmptyQuantity = data.filter(item => item.quantity !== "" && item.quantity !== null && item.quantity != 0);

    setRequestData(itemsWithNonEmptyQuantity)
  }

  const handleSubmitOffer = () => {

    if (!validate()) return;

    setShowConfirmOfferModal(true)
  }

  const submitOffer = () => {

    setIsPublishing(true)

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify({
      requestItems: group.itemtables
    }))

    apiClient.post(`requests/${joinRequest.id}`, data, {isFormData: true}).then(() => {
      setShowConfirmOfferModal(false);
      setIsPublishing(false)
      setRequestSentModal(true)
    }).catch((error) => {
      setIsPublishing(false)
      console.log(error.response.data)
    })
  }

  const OfferInput = (index, type) => {

    function getRequestQuantity(){
      const itemIndex = joinRequest.grouprequestitemtables.findIndex(i => i.item === group.itemtables[index].item);
      if (itemIndex !== -1){
        return `${joinRequest.grouprequestitemtables[itemIndex].quantity}`
      } else {
        return "- -"
      }
    }

    return <View>
      <FormControl>
        <Input
          placeholder={getRequestQuantity()}
          keyboardType="numeric" onChangeText={(value) => {
          let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

          let offerValue = value.replace(regex, '');

          if (type === 'price'){
            group.itemtables[index].quantity = offerValue;

            requestMaker()
          }
        }} textAlign='center' size="2xl" />
        <FormControl.ErrorMessage>

        </FormControl.ErrorMessage>
      </FormControl>
    </View>
  };

  if (!group.title){
    return (
      <TenderSkeleton/>
    )
  }

  return (
    <View
      bg={isDark ? "coolGray.800" : "white"}
      px={3} h="100%"
    >
      {
        !previewData ? null : <View bg="appSecondary" py={1} px={10}>
          <Center>
            <View flexDirection="row">
              <Text textAlign="center" fontWeight={500} fontSize={24} color="primary.50">
                {translate('screens.tender.previewText')}
              </Text>
              <Center ml={10}>
                <Icon color="primary.50" as={<Feather name="eye" />} size={7} />
              </Center>
            </View>
          </Center>
        </View>
      }

      <ScrollView>

        <View my={2}/>


        {
          itemsTableData.length === 0 ? null : <View mt={0}>
            <SectionTitle title={translate('drawer.groupBuying.itemsTable.title')}/>

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={itemsTableHead} style={styles.head} textStyle={styles.headText}/>

                    {itemsTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={(cellIndex === 2) ? OfferInput(index, 'price') : cellData}
                            textStyle={styles.text}
                          />
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>

            <View mt={6}>
              <SectionTitle title={translate('drawer.groupBuying.apply.yourRequest')} />
            </View>

          </View>
        }

        <Center mt={4}>
          <Box bg="#F7F7F7" w="100%" borderRadius={10} shadow={4}>

            {
              requestData.map((item, index) => (
                <RequestSum
                  key={index}
                  item={item.item}
                  unit={item.unit}
                  quantity={item.quantity}
                />
              ))
            }

            <SpacerMY space={2}/>
          </Box>

        </Center>

        {
          !!previewData ? <View mt={50}>
              <Button
                borderRadius={10}
                isDisabled={true}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('misc.send')}
                </Text>
              </Button>
            </View>
            :
            null
        }
        {
          joinRequest.is_group_closed ? null :
            <View mt={50}>
              <Button
                borderRadius={10}
                onPress={handleSubmitOffer}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('drawer.groupBuying.update')}
                </Text>
              </Button>
            </View>
        }
        {
          (!!group.is_the_closing_date && group.is_the_closing_date) || group.is_closed ? (
            <View mt={50}>
              <Button
                borderRadius={10}
                isDisabled={true}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('screens.tender.closed')}
                </Text>
              </Button>
            </View>
          ) : null
        }

        {/* Confirm offer Modal */}
        <Center>
          <Modal isOpen={showConfirmOfferModal} onClose={() => setShowConfirmOfferModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.groupBuying.apply.modalHeader')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text textAlign="left" fontWeight={600} fontSize={26}>
                    {translate('screens.tender.publishModal.areYouSure')}
                  </Text>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.apply.modalNote')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmOfferModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={submitOffer}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.send')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        {/* Request Sent Modal */}
        <Center>
          <Modal
            isOpen={requestSentModal}
            closeOnOverlayClick={false}
            onClose={() => setRequestSentModal(false)}
          >
            <Modal.Content>
              <Modal.Header>
                {translate('drawer.groupBuying.apply.requestSent')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('drawer.groupBuying.apply.requestSentMsg')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button onPress={() => {
                    navigation.goBack()
                  }}
                  >
                    {translate('misc.Ok')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        <View mt={48} />

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  wrapper: { flexDirection: 'row' },
  title: { flex: 1, backgroundColor: '#f6f8fa', width: 200 },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center'
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
