import React, {useState, useEffect, useRef} from 'react';
import { AntDesign } from "@expo/vector-icons";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import apiClient from "../utils/apiClient";
import OfferModel from "../models/OfferModel";
import TimeFormatter from "../utils/TimeFormatter";
import { Foundation } from '@expo/vector-icons';

import {
  View, Text, Icon, FlatList, Box, Center, useColorModeValue, Spinner, useTheme
} from "native-base";

import { Platform } from "react-native";

import OfferCard from "../components/OfferCard";
import {localInfo, translate} from "../utils/Translate";
import OfferCardSkeleton from "../components/skeleton/OfferCardSkeleton";
import NoItemYet from "../components/NoItemYet";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const Tab = createMaterialTopTabNavigator();

const OffersList = ({ tenderId, status }) => {

  const [passedOffers, setPassedOffers]         = useState(null)
  const [failedOffers, setFailedOffers]         = useState(null)
  const [acceptedOffers, setAcceptedOffers]     = useState([])
  const [selectedOffers, setSelectedOffers]     = useState([])
  const [showCantSeeAlert, setShowCantSeeAlert] = useState(false)
  const [closingDate, setClosingDate]           = useState(null)
  const [isLoadingData, setIsLoadingData]       = useState(false)

  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  useEffect(() => {
    getAllOffers()
  }, [])

  function getAllOffers(){
    getOffers('selected')
    getOffers('accepted')
    getOffers(status)
  }

  function getOffers(offerStatus, isLoadMore){

    let nextP = nextPage.current.length ? `&${nextPage.current}` : '';

    apiClient.get(`tenders/${tenderId}/offers?status=${offerStatus}${nextP}`).then( response => {

      if (!!response.data.error && response.data.error === 'cant-see-before-closing-date'){
        setShowCantSeeAlert(true)
        setClosingDate(response.data.closing_date)
        return;
      }

      if (offerStatus === 'passed'){
        if (isLoadMore && nextPage.current.length){
          getNextPage(response.data.next_page_url)
          setPassedOffers(old => [...old, ...response.data.data])
          setIsLoadingData(false)
          isLoadingMoreRef.current = false
          return;
        }
        getNextPage(response.data.next_page_url)
        setPassedOffers(response.data.data)
        setIsLoadingData(false)
        isLoadingMoreRef.current = false
      }
      else if (offerStatus === 'failed'){
        if (isLoadMore && nextPage.current.length){
          getNextPage(response.data.next_page_url)
          setFailedOffers(old => [...old, ...response.data.data])
          setIsLoadingData(false)
          isLoadingMoreRef.current = false
          return;
        }
        getNextPage(response.data.next_page_url)
        setFailedOffers(response.data.data)
        setIsLoadingData(false)
        isLoadingMoreRef.current = false
      }
      else if (offerStatus === 'selected'){
        setSelectedOffers(response.data.data)
      }
      else if (offerStatus === 'accepted'){
        setAcceptedOffers(response.data.data)
      }

    }).catch(error => console.log(error));
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  const loadMore = () => {
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    isLoadingMoreRef.current = true
    setIsLoadingData(true)

    getOffers(status, true)
  }

  const ClosingDateNotice = () => {
    return <Box bg="appSecondary" mx={2} borderRadius={8} mt={3}>
      <Center my={4}>
        <Icon as={<Foundation name="alert"/>} size={9} color="white" />
        <Text textAlign="center" fontWeight={500} fontSize={25} color="white">
          {translate('screens.receivedOffers.closingDateMsg')}
        </Text>
        <Text textAlign="left" fontWeight={500} fontSize={25} color="white">
          ({closingDate})
        </Text>
      </Center>
    </Box>
  }

  const FlatListHeader = () => {
    return (
      <>
        {
          selectedOffers.length === 0 ? null : !!selectedOffers[0].is_selected ? (
            <View>

              <OfferCard
                isSelectedOffer={true}
                id={selectedOffers[0].id}
                by={!!selectedOffers[0].company_id ? selectedOffers[0].company.name : !!selectedOffers[0].user ? selectedOffers[0].user.full_name : ''}
                avatar={selectedOffers[0].avatar}
                points={!!selectedOffers[0].points ? selectedOffers[0].points : null}
                tenderPoints={!!selectedOffers[0].tender.required_points ? selectedOffers[0].tender.required_points : null}
                bidAmount={!!selectedOffers[0].bid_amount ? selectedOffers[0].bid_amount : !!selectedOffers[0].price ? selectedOffers[0].price : null}
                currency={!!selectedOffers[0].currency ? selectedOffers[0].currency.code[localInfo.local] : ''}
                date={TimeFormatter.getFormattedDate(selectedOffers[0].created_at)}
              />

            </View>
          ) : null
        }
        {
          acceptedOffers.length === 0 ? null : !!acceptedOffers[0].is_selected ? (
            <View>

              <OfferCard
                isAcceptedOffer={true}
                id={acceptedOffers[0].id}
                by={!!acceptedOffers[0].company_id ? acceptedOffers[0].company.name : !!acceptedOffers[0].user ? acceptedOffers[0].user.full_name : ''}
                avatar={acceptedOffers[0].avatar}
                points={!!acceptedOffers[0].points ? acceptedOffers[0].points : null}
                tenderPoints={!!acceptedOffers[0].tender.required_points ? acceptedOffers[0].tender.required_points : null}
                bidAmount={!!acceptedOffers[0].bid_amount ? acceptedOffers[0].bid_amount : !!acceptedOffers[0].price ? acceptedOffers[0].price : null}
                currency={!!acceptedOffers[0].currency ? acceptedOffers[0].currency.code[localInfo.local] : ''}
                date={TimeFormatter.getFormattedDate(acceptedOffers[0].created_at)}
              />

            </View>
          ) : null
        }
      </>
    )
  }

  const ShowOffersList = () => {

    if (status === 'passed' && passedOffers === null && !( selectedOffers.length || acceptedOffers.length) ){
      return (
        <FlatList
          numColumns={Platform.OS === "web" ? 3 : 1 }
          data={[1,2,3]}
          renderItem={({item, index}) => (
            <OfferCardSkeleton/>
          )}
          keyExtractor={item => item}
        />
      );
    } else if (status === 'passed' && !!passedOffers && !passedOffers.length && !( selectedOffers.length || acceptedOffers.length) ){
      return (
        <NoItemYet text={translate('components.noItemYet.noOffers')}/>
      );
    } else if (status === 'failed' && failedOffers === null ){
      return (
        <FlatList
          numColumns={Platform.OS === "web" ? 3 : 1 }
          data={[1,2,3]}
          renderItem={({item, index}) => (
            <OfferCardSkeleton/>
          )}
          keyExtractor={item => item}
        />
      );
    } else if (status === 'failed' && !!failedOffers && !failedOffers.length ){
      return (
        <NoItemYet text={translate('components.noItemYet.noOffers')}/>
      );
    }

    return (
      <FlatList
        numColumns={Platform.OS === "web" ? 3 : 1 }
        data={
          status === 'passed'
            ? passedOffers : failedOffers
        }
        renderItem={({item, index}) => (
          <OfferCard
            id={item.id}
            isBestOffer={index === 0 && (status === 'passed' || status === 'accepted')}
            by={!!item.company_id ? item.company.name : !!item.user ? item.user.full_name : ''}
            avatar={item.avatar}
            points={!!item.points ? item.points : null}
            tenderPoints={!!item.tender ? item.tender.required_points : null}
            bidAmount={!!item.bid_amount ? item.bid_amount : !!item.price ? item.price : null}
            currency={!!item.currency ? item.currency.code[localInfo.local] : ''}
            date={TimeFormatter.getFormattedDate(item.created_at)}
          />
        )}
        keyExtractor={item => item.id}
        ListFooterComponent={() => <View mb={120}>
          {
            isLoadingData ? (
              <View mt={10}>
                <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
              </View>
            ) : null
          }
        </View>}
        ListHeaderComponent={FlatListHeader}
        onEndReachedThreshold={0.2}
        onEndReached={loadMore}
      />
    )
  }

  return (
    <View h="100%" bg={useColorModeValue("white", "coolGray.800")} px={2} pt={2}>

      {
        showCantSeeAlert ? (
          <ClosingDateNotice/>
        ) : null
      }
      {
        !showCantSeeAlert ? (
          (
            <ShowOffersList/>
          )
        ) : null
      }

    </View>
  );
};


export default ({ route }) => {

  const { tenderId, requiredPoints } = route.params;

  const { colors }  = useTheme();

  const screenOptions = {
    tabBarLabelStyle: { fontSize: 16, fontWeight: "bold" },
    tabBarItemStyle: {
      flexDirection: 'row'
    },
    tabBarActiveTintColor: "#fff",
    tabBarInactiveTintColor: "#000",
    tabBarStyle: {
      backgroundColor: "#ccc",
      height: 46,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    tabBarIndicatorStyle: {
      backgroundColor: colors.appPrimary, height: 46,
      marginRight: 4,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <View mt={4} mx={4}>
        <Title title={translate('screens.receivedOffers.title')} />
        <SpacerMY space={3}/>
      </View>

      {
        !!requiredPoints ? (
          <Tab.Navigator screenOptions={screenOptions} style={{ direction: localInfo.isRTL && Platform.OS !== "web" ? 'rtl' : "ltr" }}>
            <Tab.Screen
              name={`${translate('screens.receivedOffers.tabs.passed')}`}
              options={{
                tabBarIcon: ({color }) => <Icon as={<AntDesign name="checkcircle"/>} color={color} size={5} />,
              }} >
              {props => <OffersList tenderId={tenderId} status="passed" {...props} />}
            </Tab.Screen>
            <Tab.Screen
              name={`${translate('screens.receivedOffers.tabs.failed')}`}
              options={{
                tabBarIcon: ({color }) => <Icon as={<AntDesign name="closecircle"/>} color={color} size={5} />,
              }} >
              {props => <OffersList tenderId={tenderId} status="failed" {...props} />}
            </Tab.Screen>
          </Tab.Navigator>
        ) : (
          <OffersList tenderId={tenderId} status="passed" />
        )
      }

    </View>
  );
}
