import { extendTheme } from 'native-base';

import themeColors from "./colors";
import themeFont from "./fonts";

const theme = extendTheme({
  colors: themeColors,
  fontConfig: themeFont.fontConfig,
  fonts: themeFont.fonts,
  config: {
    // Changing initialColorMode to 'dark'
    // initialColorMode: 'dark',
  },
})

export default theme
