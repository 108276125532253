import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import { english } from '../lang/en';
import { arabic } from '../lang/ar';
import { french } from '../lang/fr';
import StorageManager from './StorageManager'
import {I18nManager} from "react-native";

i18n.fallbacks = true;
i18n.translations = {
  en: english,
  ar: arabic,
  fr: french
};

let localInfo = { isRTL: null, local: null }

async function getLocal(){
  let language = await StorageManager.getString('language');

  let userLocal = Localization.locale

  const regex = /(en|fr|ar)/g;
  const found = !!userLocal.match(regex) ? userLocal.match(regex) : '' ;

  const supportedLocals = ['ar', 'fr', 'en']

  const setUserLocal = supportedLocals.includes(found[0]) ? found[0] : "en"

  let local    = !!language ? language : setUserLocal;

  i18n.locale = local
  let isRtl = local.indexOf('ar') === 0;

  I18nManager.forceRTL(isRtl)
  I18nManager.allowRTL(isRtl)

    return new Promise((resolve) => {
      resolve({isRTL: isRtl, local})
    })
}

getLocal().then(info => {
  localInfo.isRTL = info.isRTL
  localInfo.local = info.local
})

const translate = value => i18n.t(value);

export {localInfo, translate};
