export const french = {
  screens: {
    auth: {
      signIn: {
        email: "Adresse électronique",
        password: "Mot de passe",
        haveAccount: "Vous n’avez pas encore de compte?",
        easyTendersTool: "Outil d’appel d’offres facile",
        emailOrPassError: "L'adresse e-mail ou le mot de passe est erroné",
        accountSuspended: "Ce compte a été suspendu",
        adminAccount: "Vous ne pouvez pas utiliser votre compte admin ici"
      },
      signUp: {
        companyName: "Nom de la société",
        fullName: "Nom complet",
        willSendPass: "Nous vous enverrons le mot de passe",
        alreadyHaveAccount: "Vous avez déjà un compte?",
        registration: "Enregistrement",
        accountType: "Type de compte"
      },
      index: {
        heading: "Trouvez les offres et soumettez les en un seule lieu",
        heading2: "Système semi-décentralisé",
        CreateAccount: "Créez un compte",
        note: "En vous inscrivant ou en signant, vous acceptez le",
        termsAndCondition: "Termes et conditions",
        andOur: "Et notre",
        privacyPolicy: "Privacy policy"
      },
      addPhone: {
        phoneNumber: "Numéro de téléphone",
        note: "Nous vous enverrons un code de vérification"
      },
      companyInfo: {
        CNRCInfo: "Information d'entreprise",
        uploadCommercialRegister: "Téléchargez le registre du commerce",
        frontPage: "Page de garde",
        backPage: "Dernière page",
        notOwner: "Je ne suis pas le propriétaire",
        yourJobTitle: "Intitulé de votre poste",
        areYouStartup: "Êtes-vous une startup?",
        yesStartup: "Oui, je suis une startup.",
        uploadLabel: "Téléverser Label",
        label: "Label",
      },
      interests: {
        selectInterests: "Choisissez les activités et les wilaya qui vous intéressent",
        selectStates: "Sélectionnez les wilayas",
        selectCountryStates: "Sélectionnez le pays et les états",
        selectCountryState: "Sélectionnez le pays et l'état",
        selectBranches: "Sélectionnez les activités",
        signUpModal: {
          headerTitle: "Création de votre compte",
          creatingText: "Création de votre compte, s’il vous plaît, patientez …",
          accountCreated: "Votre compte a été créé avec succès.",
          passSent: "Nous avons envoyé le mot de passe de votre compte dans votre e-mail"
        }
      },
      verifyPhone: {
        verifyPhone: "Vérifiez le numéro de téléphone",
        enterCode: "Entrez le code envoyé à votre numéro de portable",
        weSentCode: "On a envoyé un code à",
        invalidCode: "Ce code est invalide.",
        captchaError: "Erreur de Captcha, essayez encore"
      },
      underReview: {
        accountUnderReview: "Votre compte est en cours de révision",
        reviewingAccount: "Nous examinons votre compte",
        checkBack: "Cela prend généralement moins de 48 heures. Veuillez revenir bientôt.."
      }
    },
    home: {
      title: "Accueil",
      welcome: "Bienvenue",
      at: "au",
      latestTenders: "Vos derniers appels d’offres",
      seeAll: "Voir tout",
      historyStatistics: "Historique et statistiques",
      recentTenders: "Appels d’offres récents",
      exploreTenders: "Explorer les appels d’offres",
      articles: "Articles",
      companies: "Entreprises",
      otherTenders: "Autres appels d'offres"
    },
    inbox: {
      title: "Boîte de réception",
      searchPlaceholder: "Cherche …",
      inboxEmpty: "Vous n'avez pas encore de message",
    },
    publishTender: {
      searchPlaceholder: "Chercher …",
      title: "Appel d’offres pour la publication",
      noPermission: "Désolé, vous avez besoin de l'autorisation de votre entreprise pour faire cette action",
      info: {
        description: "Info d'appel d’offres",
        validation: {
          title: "Le titre est obligatoire",
          description: "Description requise",
          branch: "La activité est requise",
          state: "Le nom de la wilaya est requis",
          language: "Veuillez choisir une langue",
          participation: "Sélectionnez un type de participation",
          participationCost: "Veuillez ajouter le prix"
        },
        placeholder: {
          location: "Lieu",
          title: "Titre",
          description: "Description",
          language: "Langue",
          participationType: "Type de Participation",
          cost: "Coût"
        },
        participationCostNote: "Veuillez ajouter le coût en dinar algérien (DZD)",
        uploadFile: "Importer un fichier (facultatif)",
        clickToUpload: "Cliquez pour importer un fichier de spécifications",
        filesToUpload: "Fichiers à importer",
        tenderLocation: "Lieu de l’appel d’offres"
      },
      requiredDocuments:{
        title: "Documents requis",
        modalHeader: "Ajouter le document requis",
        description: "Ici, vous pouvez demander au propriétaire de l'offre de joindre les documents requis",
        document: "Document",
        name: "Nom",
        add:{
          documentName: "Nom du document",
          documentNote: "Remarque",
          required: "Requis"
        },
        requiredDocument: "Ce document est obligatoire"
      },
      criteria: {
        title: "Critères de sélection",
        description: "Ajoutez ce qui correspond à votre offre, afin que les participants puissent le remplir",
        pointsTable: "Tableau des points",
        addPointsTable: "Ajoutez une table de points à votre offre d'outils",
        priceTable: "Tableau des prix",
        addPriceTable: "Ajouter un tableau de prix à votre offre",
        bid: "Offre",
        totalCost: "Le coût total de cet appel d’offres",
        closingDate: "L’appel d’offres sera fermé ce jour-là",
        placeholder: {
          currency: "Monnaie",
          TVA: "TVA",
          initialPrice: "Prix Initial",
          pickDate: "Choisissez une date de clôture"
        },
        validation: {
          initPrice: "Prix Initial requis",
          currency: "La monnaie est nécessaire"
        }
      },
      pointsTable: {
        title: "Créez votre tableau de points",
        description: "Créez votre propre tableau de points pour que les participants puissent le remplir",
        addItem: "Ajoutez un élément",
        addToTable: "Tableau des points ajoutés",
        validation: {
          itemName: "Le nom de l’élément est obligatoire",
          itemNumber: "Le numéro de l’élément est obligatoire",
          itemPoint: "Les points sont obligatoires"
        },
        placeholder: {
          itemName: "Nom de l’élément",
          requirementNumber: "Le nombre requis",
          numberOfPoints: "Nombre de points pour chaque élément",
          maxPoints: "Maximum Points"
        }
      },
      priceTable: {
        title: "Créez votre tableau des prix",
        description: "Créez votre propre tableau de prix pour que les participants puissent le remplir",
        addItem: "Ajouter un élément",
        addToTable: "Ajouter au tableau des prix",
        validation: {
          itemName: "Le nom de l’élément est requis",
          itemUnit: "Veuillez indiquer l'unité de l'article",
          itemQuantity: "La quantité de pièces est requise"
        },
        placeholder: {
          itemName: "Nom de l’élément",
          unit: "Unité",
          requiredQuantity: "Quantité requise"
        }
      },
      reviewSteps: {
        title: "Étapes de suivi",
        description: "Ajoutez les étapes de suivi que le soumissionnaire sélectionné doit remplir",
        stepNumber: "Numéro de l’étape",
        stepReviewers: "Les critiques pour cette étape",
        reviewers: "Réviseurs",
        addStep: "Ajoutez une étape",
        validation: {
          title: "Le titre est nécessaire",
          description: "Une description est nécessaire",
          action: "Sélectionnez une action"
        },
        placeholder: {
          stepTitle: "Titre de l'étape",
          description: "Description",
          action: "Action"
        },
        actions: {
          none: "Aucun",
          text: "Texte",
          files: "Dossiers"
        }
      }
    },
    articles: {
      title: "Articles et conseils",
      searchPlaceholder: "Cherche …"
    },
    help: {
      title: "Aide et assistance",
      chat: "Chatter avec nous",
      call: "Ligne d’appel",
      facebookPage: "Page Facebook",
      FAQ: "Questions fréquemment posées"
    },
    tenders: {
      userTendersTitle: "Vos d'appels d'offres",
      tendersTitle: "Appels d’offres",
      Branches: "Activités",
      states: "Wilayas"
    },
    tender: {
      previewText: "Voilà à quoi ressemble votre appel d'offre",
      share: "Partager",
      profile: "Profil",
      message: "Message",
      reviewSteps: "Étapes de suivi",
      receivedOffers: "Offres reçues",
      participationFees: "Frais de participation",
      description: "Description",
      views: "Vues",
      closingDate: "Date de clôture",
      offers: "Offres",
      documents: "Documents",
      requiredDocuments: "Documents requis",
      bid: "Offre",
      startingPrice: "Prix ​​initial",
      pointsTable: "Tableau des points",
      priceTable: "Tableau des prix",
      deliveryDate: "Date de livraison",
      sendYourOffer: "Envoyez votre offre.",
      closed: "L'appel d'offres est fermé",
      apply: "Soumettre",
      preview: "Aperçu",
      publishModal: {
        headerText: "Publier cet appel d'offres",
        areYouSure: "Êtes-vous sûr?",
        note: "Vous ne pouvez pas modifier votre offre après l'avoir publiée"
      },
      offerModal: {
        headerText: "Soumettez cette offre",
        note: "Souhaitez-vous soumettre cette offre ?"
      },
      pointsTableHeader: {
        item: "Article",
        requirement: "Exigence",
        points: "Points",
        offer: "Offre",
        total: "Totale"
      },
      priceTableHeader: {
        item: "Article",
        unit: "Unité",
        quantity: "Quantité",
        price: "Prix",
        total: "Totale"
      },
      totalTableTitles: {
        TOTAL_HT: "TOTALE HT",
        TVA: "TVA",
        TOTAL_TTC: "TOTALE TTC"
      }
    },
    ccp: {
      CCP: "CCP",
      CCPInfo: "CCP info",
      fullName: "Nom complet",
      RIP: "RIP",
      address: "Adresse",
      paymentProof: "Preuve de paiement",
      uploadPaymentProof: "Cliquez pour téléverser la preuve de paiement",
      IPayed: "J’ai payé.",
      confirmPayment: "Confirmation du paiement",
      didYouPaid: "Avez-vous payé?",
      YesIPaid: "Oui, j’ai payé."
    },
    chat: {
      message: "Message"
    },
    companyProfile: {
      title: "Profil de l’entreprise",
      interestsUpdated: "Intérêts actualisés",
      name: "Nom",
      address: "Adresse",
      website: "Site web",
      updateInterests: "Mise à jour des intérêts",
      updated: "Mise à jour.",
      withdrawRequestSent: "Retirez la demande.",
      withdrawRequestProcess: "Votre demande de retrait est en cours.",
      bankAccount: "Compte bancaire",
      RIPNumber: "Numéro de RIB",
      wantToWithdraw: "Voulez-vous retirer?",
      fillBank: "Veuillez d'abord renseigner les coordonnées bancaires",
      minimumWithdrawal: "Votre solde est inférieur au minimum de retrait: 10,000.OO DZD",
    },
    myProfile: {
      title: "Profil",
      passwordUpdated: "Mot de passe mis à jour!",
      passwordError: "Erreur: mot de passe est incorrect",
      passwordError2: "Erreur: le nouveau mot de passe est trop court",
      fullName: "Nom complet",
      birthDate: "Date de naissance",
      address: "Adresse",
      email: "Courrier électronique",
      phone: "Téléphone",
      company: "Entreprise",
      updateInterests: "Mise à jour des intérêts",
      updatePassword: "Mise à jour du mot de passe",
      oldPassword: "Ancien mot de passe",
      newPassword: "Nouveau mot de passe"
    },
    notifications: {
      title: "Notifications",
      markAsRead: "Marquer comme lu",
      earlier: "Antérieurement",
      new: "Nouveau",
      from: "de",
      newTender: "Nouvel appel d’offres",
      newOffer: "Nouvelle offre",
      congrats: "Félicitations.",
      withdrawAccepted: "Votre demande de retrait a été acceptée",
      withdrawRejected: "Votre demande de retrait a été rejetée",
      paymentAccepted: "Votre preuve de paiement a été acceptée",
      paymentRejected: "Votre preuve de paiement a été rejetée",
      yourTenderOffers: "Vous avez reçu",
      yourTenderOffers2: "Nouvelles offres",
      yourOfferAccepted: "Votre offre a été acceptée",
      yourOfferSelected: "Votre offre a été sélectionné",
      yourOfferCanceled: "Votre offre a été annulée",
      yourOfferReplyAccepted: "La réponse à l'étape d'examen de votre offre a été acceptée",
      yourOfferReplyRejected: "La réponse à l'étape d'examen de votre offre a été rejetée",
      noNotificationsYet: "Vous n'avez pas encore de notifications",
      yourRequestAccepted: "Votre demande d'adhésion a été acceptée",
      yourRequestRejected: "Votre demande d'adhésion a été rejetée",
      yourGroupRequests: "Vous avez",
      yourGroupRequests2: "demandes d'adhésion"
    },
    offer: {
      title: "Offre",
      reviewOptions: "Options de révision",
      reviewSteps: "Étapes de suivi",
      cancelSelection: "Annuler la sélection",
      reasonOfAccepting: "Motif d'acceptation",
      reasonOfCancelling: "Motif d’annulation",
      reasonOfSelecting: "Raison de la sélection",
      summary: "Résumé",
      points: "Points",
      bidAmount: "Montant de l’offre",
      submissionDate: "Date de soumission",
      pointsTableTitle: "Tableau des Points",
      priceTableTitle: "Tableau des prix",
      totalTitle: "Totale",
      selectOffer: "Sélectionnez l'offre",
      selectThisOffer: "Sélectionnez cette offre",
      whyChooseOfferText: "Veuillez expliquer brièvement pourquoi vous avez choisi cette offre. N'oubliez pas que la raison de cette sélection sera montrée à tous les participants",
      rejectThisOffer: "Rejeter cette offre",
      whyRejectOfferText: "Veuillez expliquer brièvement pourquoi vous avez rejeté cette offre. N'oubliez pas que la raison de ce rejet sera communiquée à tous les participants"
    },
    offerReview: {
      title: "Examen de l'offre",
      failed: "Echoué",
      passed: "Réussi",
      points: "Points",
      yourOfferPoints: "Vos points d'offre",
      requiredPoints: "Points requis",
      result: "Résultat",
      payAndSubmit: "Payer et soumettre",
      sendOffer: "Envoyez cette offre",
      warning: "Attention!",
      minimumPoints: "Vous n'avez pas le nombre minimum de points !",
      submitAnyway: "Soumettre quand même ?",
      submitOffer: "Soumettre cette offre?"
    },
    offerSent: {
      title: "Offre envoyée!",
      text: "Vous recevrez une alerte si votre offre a été retenue le jour de clôture",
      buttonText: "Accéder à la page d'accueil"
    },
    paymentMethods: {
      title: "Modes de paiement",
      paymentMethod: "Mode de paiement",
      paymentReviewType: "Type de revue de paiement",
      paymentReviewDuration: "Durée de la révision des paiements"
    },
    paymentReview: {
      title: "Paiement en cours de révision",
      text: "Notre équipe examinera votre transaction de paiement, vous recevrez une notification d’alerte si votre offre a été sélectionnée ou non le jour de la clôture",
      buttonText: "Accéder à la page d'accueil"
    },
    receivedOffers: {
      title: "Offres reçues",
      closingDateMsg: "Les offres seront affichées à la date de clôture",
      selectedOffer: "Offre sélectionnée",
      acceptedOffer: "Offre acceptée",
      tabs: {
        passed: "Réussi",
        failed: "Echoué"
      }
    },
    reviewSteps: {
      title: "Étapes de suivi",
      note: "Note: si toutes les étapes sont acceptées, l’offre sera automatiquement acceptée",
      replyUnReview: "Votre réponse est en cours de révision, vous recevrez une notification de mise à jour",
      replyRefused: "Votre réponse a été refusée",
      reason: "Motif",
      reviewers: "Examinateurs",
      uploadFiles: "Importer des fichiers",
      clickToUpload: "Cliquez pour importer des fichiers",
      reply: "Réponse",
      status: "Statut",
      noReply: "Aucune réponse pour le moment",
      documents: "Documents",
      accept: "Acceptez",
      reject: "Rejeter",
      send: "Envoyer",
      back: "Retour",
      next: "Prochaine",
      rejectStep: "Rejetez cette étape",
      whyRejectStep: "Veuillez expliquer brièvement pourquoi cette mesure a été rejetée.",
      sendingReply: "Envoyer la réponse",
      confirmSendingReply: "Êtes-vous sûr d’envoyer cette réponse?",
      acceptReply: "Acceptez la réponse",
      confirmAcceptReply: "Souhaitez-vous accepter cette réponse ?",
      statuses: {
        pending: "En attente",
        refused: "Refus",
        accepted: "Accepté"
      }
    },
    reviewStepsPreview: {
      title: "Étapes de suivi "
    },
    tenderClosed: {
      title: "Appel d’offres clôturé",
      text: "Toutes les étapes de suivi sont passées avec succès",
      text2: "L’appel d’offres a été clôturé et une notification a été envoyée à tous les soumissionnaires",
      close: "Fermer"
    },
    tenderPublished: {
      title: "Appel d’offres publié",
      text: "Votre nouvel appel d’offres a été publié avec succès",
      text2: "Vous recevrez des notifications lorsque vous aurez des offres de soumissionnaires",
      goHome: "Aller à la page d'accueil"
    },
    userProfile: {
      publishedTenders: "Appels d’offres publiés",
      noTenders: "Pas d’appels d’offres."
    },
    companies: {
      title: "Entreprises"
    },
    callLines: {
      paragraph1: "Veuillez consulter la page ' Questions fréquemment posées ' ou contactez notre équipe de support technique en cliquant sur le bouton ' Chat with Us '",
      paragraph2: "Si vous ne trouvez pas l’aide que vous cherchez, contactez-nous en nous appelant à ces numéros"
    },
    forgetPassword: {
      emailPage: {
        title: "Mot de passe oublié ?",
        description: "Entrez votre adresse e-mail enregistrée ci-dessous pour recevoir les instructions de réinitialisation du mot de passe",
        rememberPassword: "Se souvenir du mot de passe?"
      },
      resetPage: {
        title: "E-mail a été envoyé!",
        description: "Nous avons envoyé un code à 6 chiffres à votre adresse e-mail, utilisez ce code pour réinitialiser votre mot de passe.",
        code: "Code",
        codeRequired: "Le code est requis",
        codeInvalid: "Ce code n'est pas valide",
        resetPassword: "Réinitialiser le mot de passe",
        notReceived: "Vous n'avez pas reçu le code ?"
      },
      successPage: {
        title: "Mot de passe réinitialisé!",
        description: "Votre mot de passe a été réinitialisé avec succès."
      }
    }
  },
  components: {
    offerCard: {
      bestOffer: "Meilleure offre",
      selected: "Sélectionné",
      accepted: "Accepté",
      pending: "En attente",
      rejected: "Rejeté",
      points: "Points",
      bidAmount: "Montant de l’offre",
      submissionDate: "Date de soumission"
    },
    resend: {
      resend: "Renvoyer"
    },
    searchInput: {
      search: "Recherche"
    },
    tenderCard: {
      posted: "Posté",
      save: "Sauvegarder",
      report: "Signaler",
      closingDate: "Date de clôture",
      offers: "Offres",
      sendReport: "Envoyer un signalement",
      reason: "Motif"
    },
    userNoTendersYet: {
      noTenders: "Vous n'avez pas encore d'offres!",
      createTender: "Créer un appel d’offres"
    },
    userNoGroupsYet: {
      noGroups: "Vous n'avez pas encore de groupes!",
      createGroup: "Créer un achat groupé",
      noGroupsYet: "Il n'y a pas encore de groupes!",
      noRequestsYet: "Il n'y a aucune demande!"
    },
    userWithTitle: {
      select: "Sélectionnez"
    },
    selectList: {
      selectAll: "Sélectionnez tous"
    },
    noTendersYet: "Il n’y a pas encore d’appel d’offres!",
    outgoingOfferCard: {
      yourPoints: "Vos points"
    },
    noItemYet: {
      noCompaniesYet: "Il n'y a aucune entreprise à afficher",
      noOffers: "Il n'y a pas d'offres"
    },
    subscription: {
      oneMonth: "Un mois",
      threeMonths: "3 mois",
      sixMonths: "6 mois",
      oneYear: "Un ans",
      billedMonthly: "Mensuelle",
      billedTrimsterly: "Chaque 3 mois",
      billedSemesterly: "Chaque 6 mois",
      billedYearly: "Chaque ans",
      save: "économiser"
    },
    subscriptionExpired: {
      modalHeader: "L'abonnement a expiré",
      modalText: "**Il semble que votre abonnement ait expiré.**",
      modalText2: "Pour continuer à utiliser cette fonctionnalité, veuillez renouveler votre abonnement.",
      renewSubscription: "Renouveler l'abonnement",
      back: "Retour"
    }
  },
  drawer: {
    home: "Accueil",
    yourTenders: "Vos offres",
    teamManagement: "Gestion de l’équipe",
    SavedTenders: "Appels d'offres enregistrés",
    outgoingOffers: "Offres sortantes",
    downloads: "Téléchargements",
    statistics: "Statistiques",
    settings: "Paramètres",
    subscriptions: "Abonnements",
    journal: "Journal",
    content: {
      at: "au",
      signOut: "Déconnexion",
      signOutQuestion: "Voulez-vous vous déconnecter ?"
    },
    screens: {
      teamManagement: {
        title: "Gestion de l’équipe",
        description: "Gérer les membres de votre enterprise",
        select: "Sélectionner",
        members: "Membres",
        addMember: "Ajouter au nouveau membre",
        email: "Courrier électronique",
        fullName: "Nom complet",
        memberRole: "Rôle des membres",
        userPermissions: "Autorisations des utilisateurs",
        sendInvitation: "Envoyez une invitation",
        editMember: "Modifier l'utilisateur",
        deleteMember: "Supprimer un membre",
        deleting: "Suppression",
        delete: "Supprimer",
        confirmDelete: "Souhaitez-vous supprimer ce membre ?",
        permissions: {
          owner: "Propriétaire",
          manageMembers: "Gérer les membres",
          publishTenders: "Publier les appels d’offres",
          sendOffers: "Envoyer des offres",
          readReplyMessages: "Lire les messages de réponse",
          accessStatistics: "Accéder aux statistiques",
          accessCompanySettings: "Accéder aux paramètres de l'entreprise",
          manageGroupBuying: "Gérer les achats groupés"
        }
      },
      savedTenders: {
        title: "Appels d'offres enregistrés",
        description: "Retrouvez vos appels d'offres enregistrés",
        noTenders: "Vous n'avez pas encore d'appels d'offres enregistrés !"
      },
      outgoingOffers: {
        title: "Offres sortantes",
        description: "Vous pouvez suivre ici l'avancement des appels d'offres dans lesquels vous avez soumis des offres",
        noOffers: "Vous n'avez pas encore d'offre sortante !",
        browseTenders: "Parcourir les appels d'offres"
      },
      downloads: {
        title: "Téléchargements",
        description: "Trouvez les fichiers que vous avez téléchargés avec leurs détails.",
        noDownloads: "Vous n'avez pas encore de téléchargements!",
        fileItem:{
          source: "Source",
          here: "Ici",
          date: "Date",
        }
      },
      subscriptions: {
        title: "Abonnements",
        yourSubscription: "Votre abonnement actuel",
        state: "Etat",
        expireOn: "Expire le",
        expiredOn: "A expiré le",
        active: "Active",
        expired: "Expiré",
        choosePaymentMethod: "Choisissez le mode de paiement"
      },
      statistics: {
        title: "Statistiques",
        description: "Un résumé de toutes vos activités",
        totalOffersReceived: "Offres totales reçues",
        offers: "Offres",
        offersAccepted: "Offres acceptées",
        offersRejected: "Offres rejetées",
        totalOffersSent: "Total des offres envoyées"
      },
      settings: {
        title: "Paramètres",
        description: "Gérez les paramètres du compte de votre entreprise et choisissez vos préférences",
        messaging: "Messagerie",
        receiveMessages: "Recevoir les messages des utilisateurs",
        appearance: "Apparition",
        language: "Langue",
        deviceLanguage: "Langue de l'appareil",
        arabic: "Arabe",
        english: "Anglais",
        french: "Français",
        notifications: "Notifications",
        receiveNotifications: "Réception des notifications",
        account: "Compte",
        deleteAccount: "Supprimer mon compte",
        deleteAccountConfirm: "Êtes-vous sûr?, Votre profil et les informations de compte associées seront supprimés de notre plateforme.",
        deleteCompany: "Supprimer également le profil de l'entreprise?",
      }
    },
    groupBuying:{
      title: "Achat groupé",
      myGroups: "Mes groupes",
      create: "Créer achat groupé",
      created: "Achats groupés créés",
      createdMsg: "Achats groupés créés.",
      previewText: "Aperçu",
      requests: "Demandes",
      itemsTable: {
        title: "Tableau d'articles",
        description: "Créez votre propre tableau d'articles pour que les participants puissent le remplir",
        groupItemsTable: "Tableau des éléments de groupe",
      },
      publishModal: {
        headerText: "Publier",
        areYouSure: "Êtes-vous sûr?",
        note: "Vous ne pouvez pas modifier après la publication"
      },
      apply:{
        yourRequest: "Votre demande",
        modalHeader: "Envoyer cette demande",
        modalNote: "Voulez-vous envoyer cette demande?",
        requestSent: "Demande envoyée",
        requestSentMsg: "Votre demande à été envoyé.",
        join: "Rejoindre"
      },
      tabs:{
        explore: "Explorer",
        myGroups: "Mes groupes",
        myRequests: "Mes demandes",
      },
      noPendingRequests: "Il n'y a aucune demande en attente",
      noAcceptedRequests: "Il n'y a aucune demande acceptée",
      receivedRequestsTitle: "Demandes de rejoindre",
      acceptRequest: "Acceptez cette demande",
      acceptRequestMsg: "Souhaitez-vous accepter cette demande ?",
      rejectRequest: "Rejeter cette demande",
      rejectRequestMsg: "Souhaitez-vous rejeter cette demande ?",
      updateRequest: "Mettre à jour la demande",
      update: "Mise à jour",
      close: "Fermer",
      closed: "Fermé",
      closeThisGroup: "Fermer ce groupe",
      closeMsg: "Souhaitez-vous fermer ce groupe?",
      createTender: "Publier appel d'offres",
      note: "Note",
      createTenderNote: "Vous devez fermer le groupe pour pouvoir publier l'appel d'offres",
    }
  },
  stack: {
    webMenu: {
      home: "Accueil",
      createTender: "Créer un appel d’offres",
      inbox: "Messages",
      articles: "Articles",
      help: "Aide"
    }
  },
  tabs: {
    home: "Accueil",
    inbox: "Messages",
    add: "Ajouter",
    articles: "Articles",
    help: "Aide"
  },
  demoAlert:{
    message: "Vous utilisez un compte démo. Créez votre propre compte pour débloquer toutes les fonctionnalités."
  },
  misc: {
    currency: "DZD",
    next: "Suivant",
    cancel: "Annuler",
    select: "Sélectionner",
    reject: "Rejeter",
    publish: "Publier",
    submit: "Soumettre",
    done: "Fait",
    total: "Total",
    complete: "Compléter",
    city: "Ville",
    branch: "Activité",
    search: "Recherche",
    states: "Wilayas",
    branches: "Activités",
    signIn: "Se connecter",
    signUp: "Inscrivez-vous",
    tryAgain: "Réessayer",
    confirm: "Confirmer.",
    particular: "Particulier",
    professional: "Professionnel",
    serverError: "Erreur de serveur",
    errorTryAgain: "Une erreur s'est produite, veuillez réessayer plus tard",
    Ok: "Ok.",
    price: "Prix",
    filesToUpload: "Fichiers à importer",
    to: "à",
    SARLSafakat: "SARL Safakat",
    edit: "Editer",
    save: "Enregistrer",
    update: "Mise à jour",
    balance: "Solde",
    withdraw: "Retrait",
    reason: "Motif",
    darkMode: "Mode sombre",
    tenders: "Appels d’offres",
    add: "Ajouter",
    send: "Envoyer",
    optional: "(facultatif)",
    clickToUpload: "Importer des fichiers",
    max: "Max",
    points: "Points",
    yes: "Oui",
    no: "Non",
    deletedUser: "[utilisateur supprimé]",
    status: "Statut"
  },
  validation: {
    states: "Sélectionnez au moins une (1) wilaya",
    state: "Nom de la ville est nécessaire",
    branches: "Les activités sont obligatoires",
    branches2: "Sélectionner au moins une (1) branche",
    commercialRegisterFront: "La première page est nécessaire",
    commercialRegisterBack: "La dernière page est requise",
    role: "Le rôle de l’entreprise est trop court",
    companyName: "Le nom de la société est trop court",
    nameRequired: "Le nom est requis",
    nameShort: "Le nom est trop court",
    invalidEmail: "Email invalide",
    emailTaken: "Cet e-mail est pris",
    oldPass: "L'ancien mot de passe est requis",
    newPass: "Un nouveau mot de passe est requis",
    newPass2: "Mot de passe trop court",
    startupLabel: "Le label 'startup' est requise"
  }
}
