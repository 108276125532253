import {Center, View, Skeleton} from "native-base";
import React from "react";
import InboxItemSkeleton from "./InboxItemSkeleton";

export default () => {

  return (
    <View>
      <Center mt={8} mb={8}>
        <Skeleton size="8" rounded={'lg'} w={80} mt={4} />
      </Center>

      <View mx={3}>
        <InboxItemSkeleton/>
        <InboxItemSkeleton/>
        <InboxItemSkeleton/>
      </View>
    </View>
  )
}
