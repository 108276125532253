
import {
  View, Text,
  Icon, ScrollView,
  Image, HStack, useColorModeValue, Center, Input, FormControl, Button, Spinner
} from "native-base";
import React from "react";
import {FontAwesome, MaterialIcons} from "@expo/vector-icons";
import {localInfo, translate} from "../../utils/Translate";
import apiClient from "../../utils/apiClient";
import {Platform} from "react-native";

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ navigation }) => {

  const [errors, setErrors] = React.useState({});
  const [password, setPassword] = React.useState();
  const [code, setCode] = React.useState();
  const [isSending, setIsSending] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  function sendResetPassword(){
    setIsSending(true)
    apiClient.post(`passwords/reset`, {
      token: code,
      password
    }).then(() => {
      setIsSending(false)
      navigation.navigate('SuccessPage');
    }).catch((error) => {
      if (!!error.response.data.token){
        setErrors({ ...errors,
          code: translate('screens.forgetPassword.resetPage.codeInvalid')
        });
      }
      setIsSending(false)
    })
  }

  const handleCodeInput = (text) => {
    let error = {...errors}
    delete error.code
    setErrors(error);

    setCode(text)
  }

  const handlePasswordInput = (text) => {

    let error = {...errors}
    delete error.password
    setErrors(error);

    setPassword(text)
  }

  const validate = () => {

    if (code === undefined){
      setErrors({ ...errors,
        code: translate('screens.forgetPassword.resetPage.codeRequired')
      });
      return false;
    }
    if (password === undefined){
      setErrors({ ...errors,
        password: translate('validation.newPass')
      });
      return false;
    }
    if (!!password && password.length < 6){
      setErrors({ ...errors,
        password: translate('validation.newPass2')
      });
      return false;
    }

    return true;
  };

  const handleSend = () => {
    if (validate()){
      sendResetPassword()
    }
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>

        <SpacerMY space={16}/>
        <Center>
          <Text
            fontSize={20} fontWeight={500}
          >
            {translate('screens.forgetPassword.resetPage.title')}
          </Text>

          <SpacerMY space={3}/>

          <Text
            px={2}
            textAlign="center"
            fontSize={18}
          >
            {translate('screens.forgetPassword.resetPage.description')}
          </Text>

          <SpacerMY space={7}/>

          <Icon color="black" as={<FontAwesome name="envelope-open-o"/>} size={32}/>

          <SpacerMY space={4}/>

          <FormControl mt={4} w="85%" isInvalid={'code' in errors}>
            <Input
              textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.forgetPassword.resetPage.code')}
              rounded={10}
              size="2xl" borderColor="gray.500" pl={4} py={[4, 4, 2]}
              autoCapitalize="none"
              value={code}
              onChangeText={handleCodeInput}
            />
            {
              'code' in errors ? <FormControl.ErrorMessage>
                {errors.code}
              </FormControl.ErrorMessage> : null
            }
          </FormControl>

          <FormControl mt={4} w="85%" isInvalid={'password' in errors}>
            <Input
              textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.myProfile.newPassword')}
              rounded={10}
              size="2xl" borderColor="gray.500" pl={4} py={[4, 4, 2]}
              autoCapitalize="none"
              value={password}
              type={showPassword ? "text" : "password"}
              InputRightElement={<Icon as={<MaterialIcons name={showPassword ? "visibility" : "visibility-off"} />} size={7} mr="2" color="muted.400" onPress={() => setShowPassword(!showPassword)} />}
              onChangeText={handlePasswordInput}
            />
            {
              'password' in errors ? <FormControl.ErrorMessage>
                {errors.password}
              </FormControl.ErrorMessage> : null
            }
          </FormControl>

          <SpacerMY space={3}/>

          <Button
            w="85%"
            onPress={handleSend}
            size="lg"
            color="appPrimary"
            borderRadius={8}
          >
            {
              !isSending ? (
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                  {translate('screens.forgetPassword.resetPage.resetPassword')}
                </Text>
              ) : null
            }
            {
              isSending ? (
                <Spinner size="sm" color="white" accessibilityLabel="Loading Sign in" />
              ) : null
            }
          </Button>

          <SpacerMY space={3}/>

          <Text fontSize={18}>
            {translate('screens.forgetPassword.resetPage.notReceived')}
            <Text
              color="appPrimary"
              onPress={() => navigation.navigate('EmailPage')}
            >
              {` ${translate('components.resend.resend')}`}
            </Text>
          </Text>

        </Center>

      </ScrollView>
    </View>
  );
}
