import {Icon, Input, View} from "native-base";
import {MaterialIcons} from "@expo/vector-icons";
import React, {useState} from "react";
import {localInfo, translate} from "../utils/Translate";
import {Platform} from "react-native";

export default (
  {
    value, onSubmit, onChange, onEmpty, bg = undefined,
    textColor = undefined, isHovered = false, iconsColor = "muted.400"
  }) => {

  const [searchText, setSearchText] = useState(value)

  return (
    <View mt={3}>
      <Input
        _hover={{ bg: isHovered ? bg : undefined }}
        textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
        w="100%" size="2xl" borderRadius={10} bg={bg} color={textColor}
        autoCapitalize="none" returnKeyType="search"
        InputLeftElement={<Icon as={<MaterialIcons name="search" />} size={5} ml="2" color={iconsColor} />}
        placeholder={translate('components.searchInput.search')}
        placeholderTextColor={textColor}
        // InputRightElement={<Icon mr={2} as={<MaterialIcons name="mic" />} size={6} ml="2" color="muted.400" />}
        value={searchText}
        onChangeText={(text) => {
          setSearchText(text)
          if(!!onChange){
            onChange(text)
          }
          if (!!onEmpty && text.length === 0){
            onEmpty()
          }
        }}
        onSubmitEditing={() => onSubmit(searchText)}
        autoCorrect={false}
      />
    </View>
  )
}
