import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import {View} from "native-base";
import {
  StyleSheet,
  Text,
  Platform
} from 'react-native';

import Home from "../screens/Home";
import TabsUI from "../components/tab/TabsUI";
import { ROUTES } from "../constants/tab/Routes"
import SIZES from '../constants/tab/Sizes';
import Articles from "../screens/Articles";
import Inbox from "../screens/Inbox";
import Help from "../screens/Help";
import {translate} from "../utils/Translate";

const Tab = createBottomTabNavigator();

const { NAVIGATION_BOTTOM_TABS_HEIGHT } = SIZES;

export default function TabsNavigation() {

  const AddTenderScreen = () => {
    return (
      <View style={styles.container}>
        <Text>Add Tender Screen</Text>
      </View>
    );
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabStyle: {
      backgroundColor: 'transparent',
      borderTopWidth: 0,
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: NAVIGATION_BOTTOM_TABS_HEIGHT,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.55,
      shadowRadius: 3.22,

      // Android
      elevation: 16,
    }
  });

  const navigation = useNavigation();


  const tabs = useMemo(
    () => [
      {
        name: translate('tabs.help'),
        action: () =>
          navigation.navigate('Help'),
      },
      {
        name: translate('tabs.articles'),
        action: () =>
          navigation.navigate('Articles'),
      },
      {
        name: translate('tabs.add'),
        action: () =>
          navigation.navigate('Add'),
      },
      {
        name: translate('tabs.inbox'),
        action: () =>
          navigation.navigate('Inbox'),
      },
      {
        name: translate('tabs.home'),
        action: () =>
          navigation.navigate('Home'),
      },
    ],
    [],
  );

  const TabBar = ({ props }) => {
    return (
      <>
      {
        Platform.OS !== 'web' ? (
          <View style={styles.tabStyle}>
             <TabsUI {...{ tabs, ...props }}/>
          </View>
        ) : <></>
      }
      </>
    )
  }

  return (
    <Tab.Navigator
      screenOptions={{ tabBarVisible: false }}
      tabBar={(props) => <TabBar props={props} />}
    >
      <Tab.Screen name="Home" component={Home} options={{ headerShown: false }} />
      <Tab.Screen name="Inbox" component={Inbox} options={{ headerShown: false }} />
      <Tab.Screen name="Add" component={AddTenderScreen} options={{ headerShown: false }} />
      <Tab.Screen name="Articles" component={Articles} options={{ headerShown: false }} />
      <Tab.Screen name="Help" component={Help} options={{ headerShown: false }} />
    </Tab.Navigator>
  );

}
