import React, {useEffect, useState} from 'react';

import {
  View, Text, ScrollView, useColorModeValue,
} from "native-base";

import PaymentMethodCard from "../components/PaymentMethodCard";
import apiClient from "../utils/apiClient";
import {localInfo, translate} from "../utils/Translate";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = () => {
  return <View my={3}/>
}

export default ({ route, navigation }) => {

  const { price, offerData, tenderId } = route.params
  const [paymentMethods, setPaymentMethods] = useState(null);


  function getPaymentMethods(){
    apiClient.get(`payment-methods?status=activated`).then((res) => {
      setPaymentMethods(res.data)
    }).catch((error) => console.log(error.response.data))
  }

  useEffect(() => {
    getPaymentMethods()
  }, [])

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView mx={4} mt={4}>
        <Title title={translate('screens.paymentMethods.title')} />

        <SpacerMY/>

        {
          !!paymentMethods ? (
            paymentMethods.map((paymentMethod, index) => (
              <View key={index}>
                <PaymentMethodCard
                  id={paymentMethod.id}
                  logo={paymentMethod.logo}
                  navigation={navigation}
                  screen={paymentMethod.name[localInfo.local]}
                  method={paymentMethod.name[localInfo.local]}
                  available={ translate('screens.paymentMethods.paymentMethod') + ": " + paymentMethod.available[localInfo.local]}
                  reviewType={translate('screens.paymentMethods.paymentReviewType') + ": " + paymentMethod.review_type[localInfo.local]}
                  reviewDuration={translate('screens.paymentMethods.paymentReviewDuration') + ": " + paymentMethod.review_duration[localInfo.local]}
                  price={price}
                  offerData={offerData}
                  tenderId={tenderId}
                />
              </View>
            ))
          ) : null
        }
      </ScrollView>
    </View>
  );
}
