import React, {useEffect, useState} from 'react';

import {
  View, Text,
  Center, ScrollView,
  Divider, useColorModeValue, useTheme,
} from "native-base";
import {AnimatedCircularProgress} from "react-native-circular-progress";
import apiClient from "../../utils/apiClient";
import {AuthContext} from "../../utils/context/auth";
import {localInfo, translate} from "../../utils/Translate";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize={32} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="primary.50" w={60}/>
  </View>
}

export default ({ route, companyID, navigation }) => {

  const { isCompany } = React.useContext(AuthContext);
  const { colors }    = useTheme();
  const { companyId } = !!route.params ? route.params : {companyId: companyID} ;

  const [statistics, setStatistics] = useState({
    sent_offers: {
      total: 0,
      refused: 0,
      refused_percentage: 0,
      accepted: 0,
      accepted_percentage: 0
    },
    received_offers: {
      total: 0,
      refused: 0,
      refused_percentage: 0,
      accepted: 0,
      accepted_percentage: 0
    }
  })

  function getCompanyStatistics(id){
    apiClient.get(`companies/${id}/statistics`).then( response => {
      setStatistics(response.data)
    }).catch(error => console.log(error));
  }

  useEffect(() => {
    if (!!companyId){
      getCompanyStatistics(companyId)
    }

    navigation.addListener('focus', () => {
      if (!!companyId){
        getCompanyStatistics(companyId)
      }
    });
  }, [navigation])

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView>

        <View bg={isCompany ? colors.appPrimary : colors.particular} borderBottomRightRadius={600}>
          <View m={4}>
            <View flexDirection="row">
              <Title title={translate('drawer.screens.statistics.title')} />
            </View>
            <View flexDirection="row">
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} my={2} color="primary.50" fontSize={16}>
                {translate('drawer.screens.statistics.description')}
              </Text>
            </View>
          </View>
        </View>

        <View m={4}>

          <View mt={10} mb={3} flexDirection="row" justifyContent="space-between">
            <View>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={30} fontWeight={500}>
                {translate('drawer.screens.statistics.totalOffersReceived')}
              </Text>
            </View>
            <Center borderRadius={5} bg="appAccent" px={4}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500} color="primary.50">
                {statistics.received_offers.total}
              </Text>
            </Center>
          </View>

          <View mt={2} justifyContent="space-around" flexDirection="row">
            <View alignItems="center">
              <AnimatedCircularProgress
                size={120}
                width={13}
                fill={statistics.received_offers.accepted_percentage}
                tintColor={colors.appPrimary}
                backgroundColor="grey"
              >
                {
                  () => (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={600}>
                      {statistics.received_offers.accepted} {translate('drawer.screens.statistics.offers')}
                    </Text>
                  )
                }
              </AnimatedCircularProgress>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} fontSize={16}>{statistics.received_offers.accepted_percentage}%</Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
                {translate('drawer.screens.statistics.offersAccepted')}
              </Text>
            </View>
            <View alignItems="center">
              <AnimatedCircularProgress
                size={120}
                width={13}
                fill={statistics.received_offers.refused_percentage}
                tintColor={colors.appSecondary}
                backgroundColor="grey"
              >
                {
                  () => (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={600}>
                      {statistics.received_offers.refused} {translate('drawer.screens.statistics.offers')}
                    </Text>
                  )
                }
              </AnimatedCircularProgress>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} fontSize={16}>{statistics.received_offers.refused_percentage}%</Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
                {translate('drawer.screens.statistics.offersRejected')}
              </Text>
            </View>
          </View>

          <View mt="9" mx="8">
            <Divider thickness="1" bg="appPrimary" />
          </View>

          <View mt={10} mb={3} flexDirection="row" justifyContent="space-between">
            <View>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={30} fontWeight={500}>
                {translate('drawer.screens.statistics.totalOffersSent')}
              </Text>
            </View>
            <Center borderRadius={5} bg="appAccent" px={4}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500} color="primary.50">
                {statistics.sent_offers.total}
              </Text>
            </Center>
          </View>

          <View mt={2} justifyContent="space-around" flexDirection="row">
            <View alignItems="center">
              <AnimatedCircularProgress
                size={120}
                width={13}
                fill={statistics.sent_offers.accepted_percentage}
                tintColor={colors.appPrimary}
                onAnimationComplete={() => console.log("onAnimationComplete")}
                backgroundColor="grey"
              >
                {
                  () => (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={600}>
                      {statistics.sent_offers.accepted} {translate('drawer.screens.statistics.offers')}
                    </Text>
                  )
                }
              </AnimatedCircularProgress>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} fontSize={16}>{statistics.sent_offers.accepted_percentage}%</Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
                {translate('drawer.screens.statistics.offersAccepted')}
              </Text>
            </View>
            <View alignItems="center">
              <AnimatedCircularProgress
                size={120}
                width={13}
                fill={statistics.sent_offers.refused_percentage}
                tintColor={colors.appSecondary}
                onAnimationComplete={() => console.log("onAnimationComplete")}
                backgroundColor="grey"
              >
                {
                  () => (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={600}>
                      {statistics.sent_offers.refused} {translate('drawer.screens.statistics.offers')}
                    </Text>
                  )
                }
              </AnimatedCircularProgress>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} fontSize={16}>{statistics.sent_offers.refused_percentage}%</Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
                {translate('drawer.screens.statistics.offersRejected')}
              </Text>
            </View>
          </View>

        </View>

        <View mb={38}/>

      </ScrollView>
    </View>
  );
}
