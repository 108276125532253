import React, {useState} from 'react';

import {
  View, Text, ScrollView, HStack, useColorModeValue
} from "native-base";
import {localInfo, translate} from "../utils/Translate";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

const ReviewStepPreview = ({ number, title, description }) => {
  return <HStack>
    <View>
      <View bg="appPrimary" borderRadius={200} px={2}>
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="white" fontWeight={500} fontSize={24}> {number} </Text>
      </View>
    </View>
    <View ml={3}>
      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={24}>
        {title}
      </Text>
      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
        {description}
      </Text>
    </View>
  </HStack>
}

export default ({ route, navigation }) => {

  const { steps } = route.params

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4}>

        <View mt={4}/>
        <Title title={translate('screens.reviewStepsPreview.title')} />
        <SpacerMY space={7}/>

        {
          steps.map((step, index) => (
            <View key={index}>
              <ReviewStepPreview
                number={index + 1}
                title={step.title}
                description={step.description}
              />

              <SpacerMY space={3}/>
            </View>
          ))
        }

      </ScrollView>
    </View>
  );
}
