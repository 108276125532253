import {
  View, Text, Center, ScrollView, Divider,
  HStack, useColorModeValue, Skeleton
} from "native-base";
import {Platform} from "react-native";


const isWebPlatform = Platform.OS === 'web';

export default () => {

  return (
    <View
      bg={useColorModeValue("white", "coolGray.800")}
      px={3} h="100%"
    >

      <ScrollView>

        <View flexDirection="row" mt={7}>

          <View>
            <Skeleton size="24" rounded="full" mt={2} />
          </View>

          <View flex={1} ml={4} pt={2} >
            <Skeleton.Text lines={1} w={32} />

            <View flexDirection="row" mt={6}>
              <Skeleton size="10" w={32} />
              <Skeleton size="10" w={32} ml={4}/>
            </View>

          </View>
        </View>

        <View my={2}/>
        <Divider thickness={1}/>
        <View my={2}/>

        <Center
          flexDirection="row" mt={3}
          justifyContent={isWebPlatform ? "flex-start" : "space-evenly"}
        >
          <Skeleton size="10" w={32} />
          <Skeleton size="10" w={32} ml={4}/>
        </Center>


        <View mt={10}>
          <Skeleton size={4} w={72} />
        </View>

        <View mt={10} flexDirection="row" justifyContent="space-between">
          <Skeleton size={8} w={40} />
          <Skeleton size={8} w={20} />
        </View>

        <Skeleton.Text lines={8} mt={10} />

        <View flexDirection="row" my={2} mt={8}>

          <View px={2} alignSelf="flex-start">
            <Skeleton size={6} w={48} />
          </View>

          <View ml={2} px={2} alignSelf="flex-start">
            <Skeleton size={6} w={24} />
          </View>
        </View>

        <View mt={48} />

      </ScrollView>
    </View>
  );
}
