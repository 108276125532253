import {Avatar, Box, Center, Icon, Text, View, Pressable, useColorModeValue, useColorMode} from "native-base";
import React from "react";
import {AntDesign} from "@expo/vector-icons";
import { useNavigation } from '@react-navigation/native';
import {translate} from "../utils/Translate";

export default (props) => {
  const navigation = useNavigation();
  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const pending   = translate('components.offerCard.pending');
  const accepted  = translate('components.offerCard.accepted');

  const Tag = () => !!props.isPendingRequest || !!props.isAcceptedRequest ?
   <Box bg={ !!props.isPendingRequest ? isDark ? "appAccentDark" : "appAccent" : "appPrimary"} pt={4} p={2} borderTopLeftRadius={30} borderBottomRightRadius={8}>
    <Center>
      <Text textAlign="left" fontWeight={500} fontSize={18} color="white">
        { !!props.isPendingRequest ? pending : !!props.isAcceptedRequest ? accepted : null }
      </Text>
    </Center>
  </Box> : <View p={9}/>

  return (
    <Pressable
      width={["100%", "100%", "33%"]}
      onPress={() => navigation.navigate('JoinRequest', {requestId: props.id})}
    >
      <Box
        bg={useColorModeValue("#F7F7F7", "coolGray.700")}
        pl={3} pt={2} shadow={2} my={2} borderRadius={8}
        ml={[0, 0, 1]}
      >
        <View flexDirection="row">
          <Avatar size="lg" source={{ uri: props.avatar }} />
          <View ml={3}>
            <Text textAlign="left" fontWeight={600} fontSize={23}>
              {props.by !== "[deleted]" ? props.by : translate('misc.deletedUser')}
            </Text>
          </View>
        </View>
        <Center mt={4} flexDirection="row" justifyContent="space-between">
          <View>
            <Text textAlign="left" fontWeight={500} fontSize={16}>
              {translate('components.offerCard.submissionDate')}: {props.date}
            </Text>
          </View>

          <Tag/>
        </Center>
      </Box>
    </Pressable>
  )
}
