import {Button, Text, Modal} from "native-base";
import React, {useEffect, useState} from "react";
import {localInfo, translate} from "../utils/Translate";

export default ({alertText, isOpen, onClose}) => {

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen])

  return (
    <Modal size={["xl", "xl", "lg"]} isOpen={showModal}>
      <Modal.Content maxWidth="100%">
        <Modal.Body>
          <Text pt={4} pb={4} textAlign="center" fontSize={18}>
            {alertText}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button onPress={() => {
              onClose()
              setShowModal(false)
            }}>
              {translate('misc.Ok')}
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
