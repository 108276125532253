import React, {useEffect} from "react";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";

import {
  Icon, View, Image, Text,
  Button, Center, Box, FormControl, Spinner,
  Input, Heading, Alert, VStack, HStack, ScrollView, useColorModeValue, useTheme
} from "native-base";

import imgSignUp from '../../assets/sign-up.jpeg';
import imgLogo from "../../assets/logo_seconday.png";
import { AuthContext } from "../../utils/context/auth";
import {createMaterialTopTabNavigator} from "@react-navigation/material-top-tabs";
import {localInfo, translate} from "../../utils/Translate";
import {Platform} from "react-native";
import TermsModal from "../../components/TermsModal";
import PrivacyModal from "../../components/PrivacyModal";
import * as SplashScreen from "expo-splash-screen";

const isWebPlatform = Platform.OS === 'web';

export default function SignIn({ route, navigation }) {

  const { email } = !!route.params ? route.params : {}

  const [showTerms, setShowTerms] = React.useState(false);
  const [showPrivacy, setShowPrivacy] = React.useState(false);

  const { colors } = useTheme();
  const Tab = createMaterialTopTabNavigator();

  const screenOptions = {
    swipeEnabled: false,
    tabBarLabelStyle: { fontSize: 16, fontWeight: "bold" },
    tabBarItemStyle: {
      flexDirection: 'row'
    },
    tabBarActiveTintColor: "#fff",
    tabBarInactiveTintColor: "#000",
    tabBarStyle: {
      backgroundColor: "#ccc",
      height: 46,
      marginHorizontal: 20,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    tabBarIndicatorStyle: {
      backgroundColor: colors.appPrimary, height: 46,
      marginRight: 4,

      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }

  const SignInForm = () => {

    const [show, setShow] = React.useState(false);
    const [isSignIn, setIsSignIn] = React.useState(false);
    const [error, setError] = React.useState(null);

    const { signIn } = React.useContext(AuthContext);

    let passwordInputRef = React.useRef();

    const [data, setData] = React.useState({
      email: "demo@safakat.net",
      password: "demo123",
    });

    const handleEmailInput = (text) => {
      setError(null)

      return  setData({
        ...data,
        email: text,
      })
    }

    const handlePasswordInput = (text) => {
      setError(null)

      return  setData({
        ...data,
        password: text,
      })
    }

    const handleSignInPress = async () => {

      setIsSignIn(true)

      signIn(data).catch(error => {
        setIsSignIn(false)

        if (error === 'in-pending'){
          navigation.navigate('UnderReview')
        } else if (error === 'admin'){
          setError(translate('screens.auth.signIn.adminAccount'))
        } else if (error === 'deactivated'){
          setError(translate('screens.auth.signIn.accountSuspended'))
        } else if (error.error === "Either email or password is wrong."){
          setError(translate('screens.auth.signIn.emailOrPassError'))
        } else if (error === "password"){
          setError(translate('screens.auth.signIn.emailOrPassError'))
        }
      })
    }

    useEffect(() => {
      (async() => {
        await SplashScreen.hideAsync();
      })()

      if (!!email){
        setData({
          ...data,
          email: email,
          password: null
        })
      }
    }, [])

    return (
      <View bg={useColorModeValue("white", "coolGray.800")}>
          <Center mt={3}>
            <FormControl mt={4} w="85%">
              <Input
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                placeholder={translate('screens.auth.signIn.email')}
                rounded={10} size="2xl" borderColor="gray.500" pl={4} py={[4, 4, 2]}
                autoCapitalize="none" returnKeyType="next"
                value={data.email}
                onChangeText={handleEmailInput}
                onSubmitEditing={() => { passwordInputRef.focus(); }}
                blurOnSubmit={false}
              />
            </FormControl>
            <FormControl mt={4} w="85%">
              <Input
                ref={(input) => { passwordInputRef = input }}
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                borderRadius={10} size="2xl" borderColor="gray.500" pl={4} py={[4, 4, 2]}
                autoCapitalize="none" returnKeyType="send"
                placeholder={translate('screens.auth.signIn.password')}
                type={show ? "text" : "password"}
                value={data.password}
                onChangeText={handlePasswordInput}
                onSubmitEditing={handleSignInPress}
                InputRightElement={<Icon as={<MaterialIcons name={show ? "visibility" : "visibility-off"} />} size={7} mr="2" color="muted.400" onPress={() => setShow(!show)} />}
              />
            </FormControl>
          </Center>

          {
            !!error ? (
              <Center mt={5}>
                <Alert w="85%" status="error">
                  <VStack space={2} flexShrink={1} w="100%">
                    <HStack flexShrink={1} space={2} justifyContent="space-between">
                      <HStack space={2} flexShrink={1}>
                        <Alert.Icon mt="1" />
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="md" color="coolGray.800">
                          {error}
                        </Text>
                      </HStack>
                    </HStack>
                  </VStack>
                </Alert>
              </Center>
            ) : null
          }

          <Center mt={10}>
            <Button
              w="85%"
              onPress={ handleSignInPress }
              size="lg"
              color="appPrimary"
              borderRadius={8}
            >
              {
                !isSignIn ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                    {translate('misc.signIn')}
                  </Text>
                ) : null
              }
              {
                isSignIn ? (
                  <Spinner size="sm" color="white" accessibilityLabel="Loading Sign in" />
                ) : null
              }
            </Button>
          </Center>

      </View>
    )
  }

  function getBoxWith(){
    if (Platform.OS === 'web') return [0, 0, 48, 96]
    return 0
  }

  return (
      <View>
        <ScrollView bg={useColorModeValue("white", "coolGray.800")}>
          <Box mt={Platform.OS === 'web' ? "-10" : "-16"} width="100%" height="348" bg="primary.600" >
            <Center>
              <Image w={[500, 700, 1400]} h={400} position="absolute" source={imgSignUp} resizeMode="cover" alt="imgSignUp" />
              <View w={[500, 700, 1400]} h={400} mt={24} opacity={.7} bg="primary.700" position="absolute" ></View>
              <Image mt={[0, -2, -8]} alt="imgLogo" size={[300, 300, 250]} resizeMode={"contain"} source={imgLogo} />
            </Center>
          </Box>

          <Center mt={[-32,-32, -48]} textAlign="center">
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize="32" fontWeight="bold">
               {translate('screens.auth.signIn.easyTendersTool')}
            </Text>
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize="lg" fontWeight="bold">
              {translate('screens.auth.index.heading')}
            </Text>
          </Center>

          <Box
            px={getBoxWith()}
            mt={5} roundedTop="20" bg={useColorModeValue("white", "coolGray.800")}
          >

            <View ml={8} mb={2} mr={2}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt="6" fontSize="lg" fontWeight="bold">
                {translate('misc.signIn')}
              </Text>
            </View>

            <View h="310">
              <Tab.Navigator screenOptions={screenOptions} style={{ direction: localInfo.isRTL && Platform.OS !== "web" ? 'rtl' : "ltr" }}>
                <Tab.Screen
                  name={translate('misc.particular')}
                  children={() => <SignInForm/>}
                  options={{
                    tabBarIcon: ({color }) => <Icon as={<Ionicons name="person"/>} color={color} size={5} />,
                  }} />
                <Tab.Screen
                  name={translate('misc.professional')}
                  children={() => <SignInForm/>}
                  options={{
                    tabBarIcon: ({color }) => <Icon as={<Ionicons name="briefcase"/>} color={color} size={5} />,
                  }} />
              </Tab.Navigator>
            </View>

            <Center mt={4}>

              <HStack>
                <Text
                  fontWeight={700}
                  fontSize={17}
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                >
                  {`Demo: `}
                </Text>

                <Text
                  fontSize={17}
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                >
                  {`demo@safakat.net | demo123`}
                </Text>
              </HStack>

              <Text
                mt={6}
                onPress={() => navigation.navigate('EmailPage')}
                fontSize={20} fontWeight={500}
              >
                {translate('screens.forgetPassword.emailPage.title')}
              </Text>
            </Center>

            <Center>

              <Heading mt={6} mb={7} size="md">
                {translate('screens.auth.signIn.haveAccount')}
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appPrimary" onPress={() => navigation.navigate('Sign-up') }>
                  {` ${translate('misc.signUp')}`}
                </Text>
              </Heading>

              <Text fontSize={17} textAlign="center" mt="3" fontWeight="medium" w="85%">
                {translate('screens.auth.index.note')}
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} onPress={() => setShowTerms(true)} color="appPrimary">
                  {` ${translate('screens.auth.index.termsAndCondition')} `}
                </Text>
                {translate('screens.auth.index.andOur')}{" "}
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} onPress={() => setShowPrivacy(true)} color="appPrimary">
                  {translate('screens.auth.index.privacyPolicy')}
                </Text>
              </Text>

            </Center>

            <TermsModal isOpen={showTerms} onClose={() => setShowTerms(false)}/>
            <PrivacyModal isOpen={showPrivacy} onClose={() => setShowPrivacy(false)}/>

            <View mt={20}/>
          </Box>
        </ScrollView>
      </View>
  );
}
