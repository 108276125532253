import React, {useState, useEffect} from 'react';
import { Ionicons } from "@expo/vector-icons";
import apiClient from "../../utils/apiClient";

import {
  View, Text, Icon, Center, Button,
  ScrollView, FormControl,
  Input, TextArea, Divider, Select, useColorModeValue
} from "native-base";
import {TouchableOpacity} from "react-native";

import {localInfo, translate} from "../../utils/Translate";
import SelectList from "../../components/SelectList";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space }) => {
  return <View my={space}/>
}

export default ({ route, navigation }) => {

  const { tenderData } = route.params

  const stepModel = {
    title: '',
    description: '',
    action: '',
    reviewers: [],
    isModalOpen: false
  }

  const [steps, setSteps] = useState([stepModel])
  const [companyMembers, setCompanyMembers] = useState([])

  const validate = () => {

    for (let n=0; n < steps.length; n++){
      if (steps[n].title.length === 0){
        let updateSteps = [...steps]
        updateSteps[n].titleError = translate('screens.publishTender.reviewSteps.validation.title')
        setSteps(updateSteps)
        return false;

      } else if (steps[n].description.length === 0){
        let updateSteps = [...steps]
        updateSteps[n].descriptionError = translate('screens.publishTender.reviewSteps.validation.description')
        setSteps(updateSteps)
        return false;

      } else if (steps[n].action.length === 0){
        let updateSteps = [...steps]
        updateSteps[n].actionError = translate('screens.publishTender.reviewSteps.validation.action')
        setSteps(updateSteps)
        return false;

      }
    }

    return true;
  };

  const handleNextButton = () => {

    if (!validate()) return;

    tenderData.reviewSteps = steps
    navigation.navigate('Tender', { previewData: tenderData })
  }

  function getCompanyMembers(){
    apiClient.get(`companies/${tenderData.tenderable.id}/members`).then((response) => {
      setCompanyMembers(response.data)
    }).catch((error) => console.log(error.response.data))
  }

  useEffect(() => {
    if (tenderData.reviewSteps.length !== 0){
      setSteps(tenderData.reviewSteps)
    }
    getCompanyMembers()
  }, [])

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView mx={4} showsVerticalScrollIndicator={false}>

        <View mt={4}/>
        <Title title={translate('screens.publishTender.title')} />
        <SpacerMY space={3}/>

        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={26}>
          {translate('screens.publishTender.reviewSteps.title')}
        </Text>
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>
          {translate('screens.publishTender.reviewSteps.description')}
        </Text>

        <Divider my={5}/>

        {
          steps.map((step, index) => {
            return (
              <View key={index} mb={5}>
                <Center flexDirection="row" justifyContent="space-between">
                  <View flexDirection="row" alignItems="center">
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={24} >
                      {translate('screens.publishTender.reviewSteps.stepNumber')} : </Text>
                    <View bg="appPrimary" borderRadius={200} px={2}>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="white" fontWeight={500} fontSize={24}> {index + 1} </Text>
                    </View>
                  </View>
                  {
                    index === 0 ? null : <View mt={2} jualignItems="flex-end" alignItems="flex-end">
                      <TouchableOpacity onPress={() => {
                        setSteps(steps.filter((step, stepIndex) => stepIndex !== index));
                      }}>
                        <Icon as={Ionicons} name="trash-bin-outline" size={7} color="appSecondary" />
                      </TouchableOpacity>
                    </View>
                  }
                </Center>

                <FormControl mt={4} isInvalid={'titleError' in steps[index]}>
                  <Input
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={translate('screens.publishTender.reviewSteps.placeholder.stepTitle')} rounded={10} size="xl"
                    borderColor="gray.500" pl={4} py={3}
                    value={steps[index].title}
                    onChangeText={(text) => {
                      let updateSteps = [...steps]
                      updateSteps[index].title = text
                      delete updateSteps[index].titleError
                      setSteps(updateSteps)
                    }}
                  />
                  {
                    'titleError' in steps[index] ? (
                      <FormControl.ErrorMessage>
                        {steps[index].titleError}
                      </FormControl.ErrorMessage>
                    ) : null
                  }
                </FormControl>

                <FormControl mt={3} isInvalid={'descriptionError' in steps[index]}>
                  <TextArea
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    placeholder={translate('screens.publishTender.reviewSteps.placeholder.description')} h={160} rounded={10} size="xl"
                    borderColor="gray.500"
                    value={steps[index].description}
                    onChangeText={(text) => {
                      let updateSteps = [...steps]
                      updateSteps[index].description = text
                      delete updateSteps[index].descriptionError
                      setSteps(updateSteps)
                    }}
                  />
                  {
                    'descriptionError' in steps[index] ? (
                      <FormControl.ErrorMessage>
                        {steps[index].descriptionError}
                      </FormControl.ErrorMessage>
                    ) : null
                  }
                </FormControl>

                <FormControl isInvalid={'actionError' in steps[index]}>
                  <Select
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    selectedValue={steps[index].action}
                    accessibilityLabel="Field type"
                    placeholder={translate('screens.publishTender.reviewSteps.placeholder.action')}
                    _selectedItem={{bg: "primary.100"}}
                    borderRadius={10} size="xl" mt={2} borderColor="gray.500" pl={4} py={3}
                    dropdownIcon={
                      <Icon
                        as={Ionicons} name={"chevron-down-sharp"} size="5" color="darkgray"
                        key={
                          Math.random()
                        }
                      />
                    }
                    onValueChange={(itemValue) => {
                      let updateSteps = [...steps]
                      updateSteps[index].action = itemValue
                      delete updateSteps[index].actionError
                      setSteps(updateSteps)
                    }}
                  >
                    <Select.Item
                      label={translate('screens.publishTender.reviewSteps.actions.none')}
                      value="none" />
                    <Select.Item
                      label={translate('screens.publishTender.reviewSteps.actions.text')}
                      value="text" />
                    <Select.Item
                      label={translate('screens.publishTender.reviewSteps.actions.files')}
                      value="files-upload" />
                  </Select>
                  {
                    'actionError' in steps[index] ? (
                      <FormControl.ErrorMessage>
                        {steps[index].actionError}
                      </FormControl.ErrorMessage>
                    ) : null
                  }
                </FormControl>

                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={3} fontWeight={500} fontSize={22}>
                  {translate('screens.publishTender.reviewSteps.stepReviewers')}
                </Text>

                {
                  companyMembers.length ? (
                    <SelectList
                      isUsersList={true}
                      selectText={translate('screens.publishTender.reviewSteps.reviewers')}
                      modalHeaderText={translate('screens.auth.interests.selectStates')}
                      doneText={translate('misc.done')}
                      cancelText={translate('misc.cancel')}
                      onDone={(ids) => {
                        let updateSteps = [...steps];
                        updateSteps[index].reviewers = ids;
                        setSteps(updateSteps);
                      }}
                      items={companyMembers}
                      alreadySelectedItems={steps[index].reviewers}
                    />
                  ) : null
                }

              </View>
            )
          })
        }

        <Center mb={16} mt={10}>
          <Button
            w="85%"
            leftIcon={<Icon as={Ionicons} name="add" size="sm" color="white" />}
            onPress={ () => {
              setSteps([...steps, stepModel])
              tenderData.reviewSteps = steps
            } }
            size="lg"
            colorScheme="accent"
            borderRadius={8}
            isDisabled={steps[0].title === null}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('screens.publishTender.reviewSteps.addStep')}
            </Text>
          </Button>
        </Center>

        <Center mb={16} mt={10}>
          <Button
            w="95%"
            onPress={ handleNextButton }
            size="lg"
            color="appPrimary"
            borderRadius={8}
          >
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
              {translate('misc.next')}
            </Text>
          </Button>
        </Center>

      </ScrollView>
    </View>
  );
}
