import React, {useEffect, useRef, useState} from 'react';

import {
  View, Text, ScrollView, useColorModeValue, Spinner,
} from "native-base";
import ArticleCard from "../components/ArticleCard";
import {localInfo, translate} from "../utils/Translate";
import apiClient from "../utils/apiClient";
import SearchInput from "../components/SearchInput";
import {Platform} from "react-native";

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const SpacerMY = ({ space = 3 }) => {
  return <View my={space}/>
}

export default () => {

  const [articles, setArticles] = useState()
  const [isLoadingData, setIsLoadingData] = useState(false)

  const searchText = useRef('')
  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)

  function getArticles(){
    apiClient.get(`articles?lang=${localInfo.local}`).then( response => {
      getNextPage(response.data.next_page_url)
      setArticles(response.data.data)
    }).catch(error => console.log(error));
  }

  function getFilteredArticles(isLoadMore = false){

    let titleFilter = `filter[title]=${searchText.current}`
    let nextP       = nextPage.current.length ? `&${nextPage.current}` : '';

    let query = `articles?lang=${localInfo.local}&${titleFilter}${nextP}`;

    apiClient.get(query).then( response => {

      if (isLoadMore && nextPage.current.length){
        getNextPage(response.data.next_page_url)
        setArticles(old => [...old, ...response.data.data])

        setIsLoadingData(false)
        isLoadingMoreRef.current = false
        return;
      }

      getNextPage(response.data.next_page_url)
      setArticles(response.data.data)

      setIsLoadingData(false)
      isLoadingMoreRef.current = false
    }).catch(error => console.log(error));
  }

  const onSearch = (text) => {
    searchText.current = text;
    getFilteredArticles();
  }

  const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 100;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  function loadMore(){
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    setIsLoadingData(true)
    isLoadingMoreRef.current = true

    getFilteredArticles(true)
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  useEffect(() => {
    getArticles()
  }, []);

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView
        mx={4} mt={4}
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            loadMore()
          }
        }}
        scrollEventThrottle={400}
      >
        <Title title={translate('screens.articles.title')} />

        <SpacerMY space={1}/>

        <SearchInput
          bg="appPrimary" textColor="white" iconsColor="white" isHovered={true}
          onSubmit={onSearch}
          value={searchText.current}
          onChange={(text) => {
            searchText.current = text
          }}
          onEmpty={() => {
            searchText.current = '';
            getFilteredArticles();
          }}
        />

        <SpacerMY/>

        {
          !!articles ? (
            <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
              {
                articles.map((article, index) => {
                  return (
                    <View key={index}>
                      <ArticleCard
                        article={article}
                      />
                    </View>
                  );
                })
              }

              {
                isLoadingData ? (
                  <View mt={10}>
                    <Spinner size="sm" color="appPrimary" accessibilityLabel="Loading more.." />
                  </View>
                ) : null
              }
            </View>
          ) : null
        }

        <SpacerMY space={20}/>

      </ScrollView>
    </View>
  );
}
